import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  CheckIcon,
  ClipboardIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import {
  ClipboardDocumentListIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import Pagination from 'components/UI/Pagination';
import Tooltip from 'components/UI/Tooltip';
import Loader from 'components/UI/Loader';
import { format } from 'date-fns';

import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import { getStateAcronym } from 'utils/getStateAcronym';

export default () => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState();
  const [count, setCount] = useState(0);
  const [toasCopy, setToastCopy] = useState(false);

  const [allFiles, setAllFiles] = useState([]);
  const [typeFiles, setTypeFiles] = useState();
  const [order, setOrder] = useState();
  const [approved, setApproved] = useState(null);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    get_users();
  }, [typeFiles, order, approved]);

  const get_users = async ({ take = 50, skip = 0 } = {}) => {
    try {
      setLoader(true);
      setUsers([]);

      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/files/pending`,
        params: {
          take,
          skip,
          typeFiles,
          order,
          active: approved,
        },
      });

      setLoader(false);
      setCount(response.data.data?.count?._count || 0);
      setUsers(response.data.data.users || []);
      setAllFiles(response.data.data.countAllFiles || []);
    } catch (error) {
      console.log('Error get users: ', error);
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error get users',
        })
      );
    }
  };

  const copyText = event => {
    const { id } = event.target;

    if (id) {
      navigator.clipboard.writeText(id);
      setToastCopy(true);

      setTimeout(() => {
        setToastCopy(false);
      }, 1500);
    }
  };

  const get_content_tab = (type = null, active = null) => {
    if (!type) return allFiles.length;

    return allFiles.filter(e => {
      let item = e.files.find(
        file => String(file.type).match(type) && file.active == active
      );
      if (item) return e;
    }).length;
  };

  const tabSwitch = (type = '', status = null) => {
    setTypeFiles(type);
    setApproved(status);
  };

  return (
    <>
      <div className="w-full h-dashboard bg-gray-100 overflow-auto px-4 sm:px-6 lg:px-8 pt-8">
        <div className="max-w-7xl mx-auto">
          <div className="mx-auto mb-6 px-6">
            <div className="sm:flex sm:items-center pb-3 border-b">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">Files</h1>
                <p className="mt-2 text-sm text-gray-700">
                  A list of pending files per user including name, firebase id
                  and number of files.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto mb-6 px-6">
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              <div
                className={`${
                  !typeFiles && 'border-2 border-blue-kiwi shadow'
                } relative overflow-hidden rounded-lg bg-white px-4 pt-5 border border-gray-300 sm:px-6 sm:pt-6 cursor-pointer`}
                onClick={() => tabSwitch('')}
              >
                <dt>
                  <div className="absolute rounded-md bg-blue-kiwi p-3">
                    <ClipboardDocumentListIcon className="h-6 w-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    Total files
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {get_content_tab()}
                  </p>
                </dd>
              </div>

              <div
                className={`${
                  typeFiles === 'preapproved' &&
                  !approved &&
                  'border-2 border-blue-kiwi shadow'
                } relative overflow-hidden rounded-lg bg-white px-4 pt-5 border border-gray-300 sm:px-6 sm:pt-6 cursor-pointer`}
                onClick={() => tabSwitch('preapproved')}
              >
                <dt>
                  <div className="absolute rounded-md bg-yellow-500 p-3">
                    <ExclamationTriangleIcon className="h-6 w-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    Pre approved
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {get_content_tab('preapproved')}
                  </p>
                </dd>
              </div>

              <div
                className={`${
                  typeFiles === 'postapproved' &&
                  'border-2 border-blue-kiwi shadow'
                } relative overflow-hidden rounded-lg bg-white px-4 pt-5 border border-gray-300 sm:px-6 sm:pt-6 cursor-pointer`}
                onClick={() => tabSwitch('postapproved')}
              >
                <dt>
                  <div className="absolute rounded-md bg-green-500 p-3">
                    <CheckIcon className="h-6 w-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    Post approved
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {get_content_tab('postapproved')}
                  </p>
                </dd>
              </div>

              <div
                className={`${
                  typeFiles === 'preapproved' &&
                  approved &&
                  'border-2 border-blue-kiwi shadow'
                } relative overflow-hidden rounded-lg bg-white px-4 pt-5 border border-gray-300 sm:px-6 sm:pt-6 cursor-pointer`}
                onClick={() => tabSwitch('preapproved', true)}
              >
                <dt>
                  <div className="absolute rounded-md bg-green-500 p-3">
                    <HandThumbUpIcon className="h-6 w-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    Approved
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {get_content_tab('preapproved', true)}
                  </p>
                </dd>
              </div>
            </dl>
          </div>

          <div className="mx-auto mb-6 px-6">
            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                      <Pagination count={count} onTake={get_users} />
                    </div>

                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            User
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Number files
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            State
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Recent file
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 bg-white">
                        {users &&
                          users.map(item => (
                            <>
                              <tr key={item.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 text-sm text-gray-500">
                                  <div className="text-gray-900 font-semibold">{`${item.first_name} ${item.last_name}`}</div>
                                  <div className="text-gray-500 flex align-middle">
                                    <span className="text-gray-600 font-semibold mr-1">
                                      {' '}
                                      Postgres:{' '}
                                    </span>
                                    {item.id}

                                    <Tooltip tooltip="Copy id Postgres">
                                      <ClipboardIcon
                                        id={item.id}
                                        onClick={copyText}
                                        className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-blue-kiwi"
                                      />
                                    </Tooltip>
                                  </div>
                                  <div className="text-gray-500 flex align-middle">
                                    <span className="text-gray-600 font-semibold mr-1">
                                      {' '}
                                      Firebase:{' '}
                                    </span>
                                    {item.firebase_id}
                                    <Tooltip tooltip="Copy id Firebase">
                                      <ClipboardIcon
                                        id={item.firebase_id}
                                        onClick={copyText}
                                        className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-blue-kiwi"
                                      />
                                    </Tooltip>
                                  </div>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                  <span className="mr-7 inline-flex rounded-full bg-yellow-100 px-2 text-xs uppercase font-semibold leading-5 text-yellow-800">
                                    {item.files.length}
                                  </span>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                                  <span className=" inline-flex rounded-full px-2 text-xs uppercase font-semibold leading-5 text-gray-800">
                                    <p className="capitalize font-semibold flex gap-x-2">
                                      {getStateAcronym(item?.state) === 'PR' ? (
                                        <img
                                          src={require('../../assets/puerto-rico.png')}
                                          className="w-4"
                                          alt="loading"
                                        />
                                      ) : (
                                        <img
                                          src={require('../../assets/estados-unidos.png')}
                                          className="w-4"
                                          alt="loading"
                                        />
                                      )}

                                      {getStateAcronym(item?.state)}
                                    </p>
                                  </span>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {format(
                                    new Date(
                                      item.files[0]?.created_at || new Date()
                                    ),
                                    'MMMM dd, yyyy - p'
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <Link
                                    to={`/users/${item.id}/detail-files`}
                                    className="text-blue-kiwi hover:text-sky-400"
                                  >
                                    See Details
                                  </Link>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Loader status={loader}></Loader>

          {toasCopy ? (
            <div
              id={Math.random()}
              aria-live="assertive"
              className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
              <div
                id={Math.random()}
                className="w-full flex flex-col items-center space-y-4 sm:items-center"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                  <div className="relative flex flex-col items-center group">
                    <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                      <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">
                        Id copied successfully
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
