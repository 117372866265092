import { DocumentChartBarIcon } from '@heroicons/react/24/outline';
import Tabs from "./TabsEnhanced"

const fixName = (value) => {
    let fix = String(value).split("_").join(" ")
    return fix.charAt(0).toUpperCase() + fix.slice(1)
}

export default ({ showModal = false, data = { plaid: {}, plot: {} }, closeModal }) => {
    const records = []

    for (const item of Object.keys(data.plaid)) {
        if (!["index", "user_id", "created_at"].includes(item)) {
            records.push({
                title: fixName(item),
                value: data.plaid[item] == 'null' ? "" : data.plaid[item]
            })
        }
    }

    return (
        <>
            {
                showModal ?
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-full my-6 mx-auto max-w-4xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-center justify-between p-4 border-b border-solid border-slate-200 rounded-t bg-gray-100 px-5">
                                        <div className='flex align-middle'>
                                            <DocumentChartBarIcon className="mr-1 h-5 w-5" aria-hidden="true" />

                                            <h4 className="text-1xl font-semibold">
                                                Enhanced variables
                                            </h4>

                                        </div>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={closeModal}
                                        >
                                            <span className="text-black-900 font-semibold h-6 w-6 block">
                                                ×
                                            </span>
                                        </button>
                                    </div>

                                    <div className="relative flex-auto max-h-[520px] overflow-y-auto px-2 pb-2">
                                        <Tabs records={records} chart={data.plot}></Tabs>
                                    </div>

                                    <div className="flex items-center justify-end px-6 py-2 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md border border-transparent bg-red-100 px-4 py-3 text-sm font-medium leading-4 text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                    : null
            }
        </>
    )
}