import { useState } from 'react';
import { CursorArrowRaysIcon, DocumentPlusIcon, DocumentMinusIcon } from '@heroicons/react/24/outline';

import RenewalRejectedRequests from './RenewalRejectedRequests';
import RenewalZeroCreditLimitRequests from './RenewalZeroCreditLimitRequests';

const requestStatus = {
    NOT_STATUS: 'not_status',
    REJECTED_STATUS: 'rejected_requests',
    ZERO_CREDIT_LIMIT_STATUS: 'zero_credit_limit_requests'
}

export default function RenewalRequests() {
    const [currentStatusRequests, setCurrentStatusRequests] = useState(requestStatus.NOT_STATUS);
    const request_status = [
        {
            id: 1,
            name: 'Renewal Model Rejections',
            description: 'Requests rejected',
            icon: () => <DocumentPlusIcon className="h-5 w-5 text-dark-kiwi" />,
            status: 'rejected_requests'
        },
        {
            id: 2,
            name: 'Zero Credit Limit Model Rejections',
            description: 'Requests with zero credit limit',
            icon: () => <DocumentMinusIcon className="h-5 w-5 text-dark-kiwi" />,
            status: 'zero_credit_limit_requests'
        },
    ];

    return (
        <div className="w-full pt-8 h-dashboard bg-gray-100">

            { currentStatusRequests === requestStatus.NOT_STATUS && (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center pb-3 border-b">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">
                              Renewal Requests
                            </h1>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 py-10">
                        {request_status.map((item, index) => (
                            <div
                              key={index}
                              to={item.path}
                              className="bg-white relative hover:shadow-none py-6 px-5 rounded-md shadow-md h-full flex cursor-pointer"
                              onClick={() => setCurrentStatusRequests(item.status)}
                            >
                                <div className="absolute right-4 top-4">
                                  <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
                                </div>
                                <div className="flex gap-4">
                                    <i className="bg-blue-50-kiwi p-2 h-10 w-10 min-w-[2.5rem] flex items-center justify-center rounded-lg">
                                        <p>{item.icon()}</p>
                                    </i>
                                    <div>
                                        <h1 className="text-xl font-semibold text-dark-kiwi">
                                            {item.name}
                                        </h1>
                                        <p className="text-gray-400 text-xs mt-2">
                                             {item.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            { currentStatusRequests === requestStatus.REJECTED_STATUS && (
                <RenewalRejectedRequests
                    hasBack={true}
                    setStatusPage={setCurrentStatusRequests}
                    statusPage={requestStatus.NOT_STATUS}
                />
            )}

            { currentStatusRequests === requestStatus.ZERO_CREDIT_LIMIT_STATUS && (
                <RenewalZeroCreditLimitRequests 
                    hasBack={true}
                    setStatusPage={setCurrentStatusRequests}
                    statusPage={requestStatus.NOT_STATUS}
                />
            )}
            
        </div>
    );
}
