import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { newLoanStatus, addStatusToLoan, getLoan, setLoan, getLoanApplications, setLoanApplications, setApplicationsTotal, getUser, setUser, getBankData, setBankData } from "redux/reducer"
import { actions } from "redux/reducer"
import Api from '../api'

function* fetchAuthUser() {
    try {
        const { data } = yield call(Api.fetchAuthUser);
        yield put(actions.setAuthUser(data.auth));
    } catch (error) {
        console.log(error);
        yield put(actions.setAuthUser(null));
    }
}

function* fetchLoan(action) {
    try {
        const { data } = yield call(Api.fetchLoan, { id: action.payload.id });
        yield put(setLoan(data.application));
    } catch (error) {
        yield put(setLoan(null));
    }
}

function* updateLoan(action) {
    try {
        yield call(Api.updateLoan, { id: action.payload.loan_id, data: action.payload.data });
    } catch (error) {
        console.log(error);
    }
}

function* fetchLoanApplications(action) {
    try {
        const { data } = yield call(Api.fetchApplications, { ...action.payload });
        yield put(setLoanApplications(data.applications));
        yield put(setApplicationsTotal(data.total));
    } catch (error) {
        yield put(setLoanApplications(null));
    }
}

function* createLoanStatus(action) {
    try {
        const { data } = yield call(Api.createLoanStatus, {
            application_id: action.payload.application_id,
            value: action.payload.value,
            reason: action.payload.reason
        });
        yield put(addStatusToLoan(data.status));
    } catch (error) {
        console.log(error);
    }
}

function* fetchUser(action) {
    try {
        const { data } = yield call(Api.fetchUser, { id: action.payload.user_id });
        yield put(setUser(data.user));
    } catch (error) {
        console.log(error);
        yield put(setUser(null));
    }
}

function* fetchBankData(action) {
    try {
        const { data } = yield call(Api.fetchBankData, { id: action.payload.user_id });
        yield put(setBankData(data.user));
    } catch (error) {
        console.log(error);
        yield put(setBankData(null));
    }
}

function* mySaga() {
    yield takeLatest(actions.getAuthUser.type, fetchAuthUser);
    yield takeLatest(getLoanApplications.type, fetchLoanApplications);
    yield takeLatest(getLoan.type, fetchLoan);
    yield takeEvery(actions.updateLoan.type, updateLoan);
    yield takeLatest(newLoanStatus.type, createLoanStatus);
    yield takeLatest(getUser.type, fetchUser);
    yield takeLatest(getBankData.type, fetchBankData);
}

export default mySaga;