import {
  CheckCircleIcon,
  StarIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import statusPlaid from "components/UI/StatusPlaid";
import { formatDateWithHour } from "utils/format-dates";
import { actions } from "redux/reducer";
import { differenceInHours } from "date-fns";
import { ApplicationStatus, DqModelResponse } from "constants";
import { useDispatch } from "react-redux";

const folders = [
  {
    name: "Created",
    description: "",
    value: "created",
    category: {
      label: "📁 Application",
      value: "application",
    },
  },
  {
    name: "Pending",
    description: "(Before plaid)",
    value: "pending",
    category: {
      label: "📁 Application",
      value: "application",
    },
  },
  {
    name: "Plaid",
    description: "",
    value: "plaid",
    category: {
      label: "📁 Application",
      value: "application",
    },
  },
  {
    name: "Finicity",
    description: "",
    value: "finicity",
    category: {
      label: "📁 Application",
      value: "application",
    },
  },
  {
    name: "Need bank statements",
    description: "",
    value: "bank_statements",
    category: {
      label: "📁 Application",
      value: "application",
    },
  },

  {
    name: "Approved",
    description: "",
    value: "approved",
    category: {
      label: "✅ Positive",
      value: "positive",
    },
  },
  {
    name: "ID Photo",
    description: "",
    value: "id-photo",
    category: {
      label: "✅ Positive",
      value: "positive",
    },
  },
  {
    name: "Signed",
    description: "",
    value: "signed",
    category: {
      label: "✅ Positive",
      value: "positive",
    },
  },
  {
    name: "Funded",
    description: "",
    value: "funded",
    category: {
      label: "✅ Positive",
      value: "positive",
    },
  },
  {
    name: "Rejected",
    description: "",
    value: "rejected",
    category: {
      label: "⚠️ Negative",
      value: "negative",
    },
  },
  {
    name: "Verified",
    description: "",
    value: "verification",
    category: {
      label: "✅ Positive",
      value: "positive",
    },
  },
  {
    name: "Black List",
    description: "",
    value: "black_list",
    category: {
      label: "⚠️ Negative",
      value: "negative",
    },
  },
  {
    name: "Promote",
    description: "",
    value: "promote",
    category: {
      label: "✅ Positive",
      value: "offer_improvement",
    },
  },
  {
    name: "Set Score",
    description: "",
    value: "set_score",
    category: {
      label: "✅ Positive",
      value: "set_score",
    },
  },
  {
    name: "Expired",
    description: "",
    value: "expired",
    category: {
      label: "⚠️ Negative",
      value: "negative",
    },
  },
];

function LoanApplicationsItem({ data, state }) {
  const params = useParams();
  const dispatch = useDispatch();

  const bgStatus = (score) => {
    let classSpan =
      "inline-flex rounded-full px-2 text-xs uppercase font-semibold leading-5 ";

    if (score < 570) {
      return `${classSpan} bg-red-100 text-red-800`;
    } else if (score < 650) {
      return `${classSpan} bg-amber-100 text-yellow-800`;
    }

    return `${classSpan} bg-green-100 text-green-800`;
  };

  const getStatusCategory = () => {
    return (
      folders.find((item) => item.value === data?.last_status?.value)?.category
        ?.label || ""
    );
  };

  const goReview = () => {
    dispatch(
      actions.setBackPath({
        path: `/applications/${params.state}/${params.status}`,
      })
    );
  };

  const renderAutomaticApprovalStatus = (data) => {
    const status = data?.last_status?.value;
    const status_created_at = data?.last_status?.created_at;
    const diff_between_last_status = differenceInHours(
      new Date(),
      new Date(status_created_at)
    );
    if (
      status === ApplicationStatus.created &&
      diff_between_last_status < 2 &&
      data.dq_decision_value === DqModelResponse.approved
    ) {
      return (
        <div className="flex items-center">
          <p>Waiting Cronjob</p>
          <p>⏰</p>
        </div>
      );
    }

    if (
      status === ApplicationStatus.created &&
      diff_between_last_status >= 2 &&
      data.dq_decision_value === DqModelResponse.approved
    ) {
      return (
        <div className="flex items-center">
          <p>Need Review</p>
          <p>⚠️</p>
        </div>
      );
    }
  };

  return (
    <div
      className="w-full border border-b-0 bg-white border-gray-300 overflow-hidden first:rounded-t-lg last:rounded-b-lg last:border-b-1"
      key={data.id}
    >
      <div className="w-full justify-between flex gap-4 justify-items-center pb-4">
        {data.user && (
          <div className="w-80 text-ellipsis overflow-hidden justify-self-start whitespace-nowrap pl-6 py-4 text-sm text-gray-500 col-span-2">
            <h3 className="text-sm  font-semibold text-gray-900">{`${
              data.user.first_name ?? ""
            } ${data.user.second_name ?? ""} ${
              data.user.first_surname ?? data.user.last_name ?? ""
            } ${data.user.second_surname ?? ""}`}</h3>
            <p>{data.user.email}</p>
            <p>{data.user.phone_number}</p>
            <p>{data.user.firebase_id}</p>
            {data.user.referrer && (
              <div className="flex items-center mt-2">
                <StarIcon className="w-5 h-5 text-yellow-400 mr-1" />
                <p className="underline">
                  referred by{" "}
                  <span className="text-sm font-semibold text-gray-900">
                    {data.user.referrer.first_name}
                  </span>
                </p>
              </div>
            )}
          </div>
        )}
        <div className="w-56 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <h3 className="text-sm font-semibold text-gray-900">
            User created at
          </h3>
          <p className="mb-2">{formatDateWithHour(data?.user?.created_at)}</p>
          <h3 className="text-sm font-semibold text-gray-900">
            Application at
          </h3>
          {<p className="mb-2">{formatDateWithHour(data.created_at)}</p>}

          <h3 className="text-sm font-semibold text-gray-900">
            Status created at
          </h3>
          {data?.last_status?.created_at ? (
            <p>{formatDateWithHour(data?.last_status?.created_at)}</p>
          ) : (
            <p>{formatDateWithHour(data?.created_at)}</p>
          )}
        </div>
        {data.status.length ? (
          <div className="w-40 whitespace-nowrap px-3 py-4 text-gray-500">
            <h3 className="text-sm font-semibold text-gray-900">
              {getStatusCategory()}
            </h3>
            <p> - {data.last_status?.value || ""}</p>
          </div>
        ) : (
          <div className="w-40 whitespace-nowrap px-3 py-4 text-gray-500">
            <h3 className="text-sm font-semibold text-gray-900">
              📁 Application
            </h3>
            <p> - Pending</p>
          </div>
        )}

        <div className="w-36 px-3 py-4">
          {data.user && (
            <div className="mb-2">
              <span className="text-[13px] font-semibold text-gray-900 mr-2">
                {" "}
                Score{" "}
              </span>
              <span
                className={`${bgStatus(data?.user?.score_transunion || 0)} `}
              >
                {data?.user?.score_transunion || 0}
              </span>
            </div>
          )}
          {data.user && data?.user?.plaid && (
            <div className="flex items-center mb-2">
              <span className="text-[13px] font-semibold text-gray-900 mr-4">
                Plaid
              </span>
              <span>{statusPlaid(data?.user)}</span>
            </div>
          )}
          {data.user && (
            <div className="flex items-center mb-2">
              <span className="text-[13px] font-semibold text-gray-900 mr-4">
                Inquiry ID
              </span>
              <span>
                {data.user.inquiry_id ? (
                  <CheckCircleIcon
                    className="text-green-600 h-4 w-4"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="text-red-500 h-4 w-4"
                    aria-hidden="true"
                  />
                )}
              </span>
            </div>
          )}
          {data.user && (
            <div className="flex items-center mb-2">
              <span className="text-[13px] font-semibold text-gray-900 mr-4">
                Statements
              </span>
              <span>
                {data?.user?.files?.length ? (
                  <CheckCircleIcon
                    className="text-green-600 h-4 w-4"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="text-red-500 h-4 w-4"
                    aria-hidden="true"
                  />
                )}
              </span>
            </div>
          )}
          {data.user && data.dq_decision_rule && (
            <div className="flex items-center mb-2">
              <span className="text-[13px] font-semibold text-gray-900 mr-4">
                {data.dq_decision_rule}
              </span>
            </div>
          )}
          {data.user && (
            <div className="flex items-center mb-2">
              <span className="text-[13px] font-semibold text-gray-900 mr-4">
                {renderAutomaticApprovalStatus(data)}
              </span>
            </div>
          )}
        </div>
        <div className="whitespace-nowrap px-3 py-4">
          <p className="front-semibold"></p>
        </div>
        <div className="flex items-center justify-end relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <Link
            to={`/users/${data.user_id}/personal?applications=true&state=${state}`}
            onClick={goReview}
            className="text-blue-kiwi hover:text-blue-dark-kiwi"
          >
            Review
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoanApplicationsItem;
