export default [
  {
    id: 40,
    name: "Lajas",
    subportfolio: 39,
  },
  {
    id: 73,
    name: "Barranquitas",
    subportfolio: 40,
  },
  {
    id: 84,
    name: "Centro Sur",
    subportfolio: 41,
  },
  {
    id: 99,
    name: "Juana Diaz Mall",
    subportfolio: 42,
  },
  {
    id: 90,
    name: "Lares Mall",
    subportfolio: 43,
  },
  {
    id: 103,
    name: "Fajardo Mall",
    subportfolio: 44,
  },
  {
    id: 149,
    name: "Crida Shopping",
    subportfolio: 45,
  },
  {
    id: 156,
    name: "Aibonito Shopping",
    subportfolio: 46,
  },
  {
    id: 157,
    name: "Naranjito Shopping",
    subportfolio: 47,
  },
  {
    id: 190,
    name: "Oulet Barcelo",
    subportfolio: 48,
  },
  {
    id: 69,
    name: "Post",
    subportfolio: 49,
  },
  {
    id: 86,
    name: "Aguada Mall",
    subportfolio: 50,
  },
  {
    id: 102,
    name: "Dorado",
    subportfolio: 51,
  },
  {
    id: 109,
    name: "Plz Guayama",
    subportfolio: 52,
  },
  {
    id: 171,
    name: "Plaza Penuelas",
    subportfolio: 53,
  },
  {
    id: 4,
    name: "Rio Piedras",
    subportfolio: 54,
  },
  {
    id: 55,
    name: "Utuado",
    subportfolio: 55,
  },
  {
    id: 128,
    name: "San Patricio",
    subportfolio: 56,
  },
  {
    id: 147,
    name: "Las Piedras Shopping",
    subportfolio: 57,
  },
  {
    id: 168,
    name: "El Monto Town C",
    subportfolio: 58,
  },
  {
    id: 61,
    name: "Patillas",
    subportfolio: 29,
  },
  {
    id: 66,
    name: "Yauco Plaza",
    subportfolio: 30,
  },
  {
    id: 68,
    name: "Jayuya",
    subportfolio: 31,
  },
  {
    id: 82,
    name: "Arecibo Norte",
    subportfolio: 32,
  },
  {
    id: 100,
    name: "S. German Mall",
    subportfolio: 33,
  },
  {
    id: 129,
    name: "Palma Real",
    subportfolio: 34,
  },
  {
    id: 133,
    name: "Mayaguez Mall",
    subportfolio: 35,
  },
  {
    id: 136,
    name: "Plz S. Sebastian",
    subportfolio: 36,
  },
  {
    id: 124,
    name: "Plz Isabela",
    subportfolio: 37,
  },
  {
    id: 150,
    name: "Coamo Shopping",
    subportfolio: 38,
  },

  {
    id: 87,
    name: "Vega Baja Mall Tda",
    subportfolio: 3,
  },
  {
    id: 170,
    name: "Morovis Plaza Tda",
    subportfolio: 4,
  },
  {
    id: 160,
    name: "65th Infantería Tda",
    subportfolio: 6,
  },
  {
    id: 120,
    name: "Montehiedra Tda",
    subportfolio: 7,
  },
  {
    id: 173,
    name: "Baldorioty ( Norte Shopping Center )",
    subportfolio: 5,
  },
  {
    id: 190,
    name: "The Outlets at Montehiedra",
    subportfolio: 10,
  },
  {
    id: 118,
    name: "Reparto Metropolitano",
    subportfolio: 8,
  },
  {
    id: 139,
    name: "Plaza del Sol",
    subportfolio: 9,
  },
  {
    id: 81,
    name: "Corozal",
    subportfolio: 11,
  },
  {
    id: 85,
    name: "Levittown",
    subportfolio: 12,
  },
  {
    id: 185,
    name: "Rexville Plaza",
    subportfolio: 13,
  },
  {
    id: 189,
    name: "Los Filtros",
    subportfolio: 14,
  },
  {
    id: 145,
    name: "Plaza Guaynabo",
    subportfolio: 15,
  },
  {
    id: 16,
    name: "Plaza Carolina",
    subportfolio: 16,
  },
  {
    id: 126,
    name: "Los Colobos",
    subportfolio: 17,
  },
  {
    id: 50,
    name: "Trujillo Alto",
    subportfolio: 18,
  },
  {
    id: 176,
    name: "Plaza Canóvanas",
    subportfolio: 19,
  },
  {
    id: 175,
    name: "Rio Grande",
    subportfolio: 20,
  },
  {
    id: 186,
    name: "Plaza Centro Mall",
    subportfolio: 21,
  },
  {
    id: 179,
    name: "Villa Blanca",
    subportfolio: 22,
  },
  {
    id: 141,
    name: "Juncos Plaza",
    subportfolio: 23,
  },
  {
    id: 142,
    name: "Cayey Shopping",
    subportfolio: 24,
  },
  {
    id: 151,
    name: "Ponce Town Center",
    subportfolio: 25,
  },
  {
    id: 10,
    name: "Ponce",
    subportfolio: 59
  },
  {
    id: 57,
    name: "Aguas Buenas",
    subportfolio: 60
  },
  {
    id: 67,
    name: "Plaza Caribe Mall",
    subportfolio: 61
  },
  {
    id: 93,
    name: "Orocovis",
    subportfolio: 62
  },
  {
    id: 112,
    name: "Plaza Ferran",
    subportfolio: 63
  },
  {
    id: 164,
    name: "Comercio",
    subportfolio: 64
  },
  {
    id: 172,
    name: "Anasco Shopping",
    subportfolio: 65
  },
  {
    id: 184,
    name: "Hatillo Town Center",
    subportfolio: 66
  },
  {
    id: 12,
    name: "Wetern Plaza",
    subportfolio: 67
  },
  {
    id: 15,
    name: "Barrio Obrero",
    subportfolio: 68
  },
  {
    id: 49,
    name: "Quebradillas",
    subportfolio: 69
  },
  {
    id: 60,
    name: "Canton Mall",
    subportfolio: 70
  },
  {
    id: 162,
    name: "Monte Real Plaza",
    subportfolio: 71
  },
  {
    id: 167,
    name: "Cabo Rojo Plaza",
    subportfolio: 72
  },
  {
    id: 44,
    name: "Sabana Grande",
    subportfolio: 73
  },
  {
    id: 132,
    name: "Aguadilla Mall",
    subportfolio: 74
  },
  {
    id: 152,
    name: "San Lorenzo Shopping",
    subportfolio: 75
  },
  {
    id: 187,
    name: "Plaza del Norte",
    subportfolio: 76
  },
  {
    id: 106,
    name: "Moca",
    subportfolio: 78
  },
  {
    id: 111,
    name: "Europonce",
    subportfolio: 79
  },
  {
    id: 144,
    name: "Plaza Atlantico",
    subportfolio: 80
  },
  {
    id: 165,
    name: "Plaza Rio Hondo",
    subportfolio: 81
  },
  {
    id: 166,
    name: "Rincon",
    subportfolio: 82
  },
  //news
  {
    id: 161,
    name: "Yabucoa Plaza", 
    subportfolio: 83
  },
  {
    id: 100,
    name: "Santa Rosa Mall", 
    subportfolio: 84
  },
  {
    id: 153,
    name: "Montellanos Shopping", 
    subportfolio: 85
  },
  {
    id: 64,
    name: "Villalba", 
    subportfolio: 86
  },
  {
    id: 178,
    name: "Adjuntas Plaza", 
    subportfolio: 87
  },
  {
    id: 100,
    name: "Carolina Shopping Court", 
    subportfolio: 88
  },
  {
    id: 52,
    name: "Bayamon Oeste", 
    subportfolio: 89
  },
  {
    id: 134,
    name: "Las Catalinas", 
    subportfolio: 90
  },
  {
    id: 183,
    name: "Oriental Plaza", 
    subportfolio: 91
  },
  {
    id: 91,
    name: "Naguabo", 
    subportfolio: 92
  },
  {
    id: 158,
    name: "Plaza Aquarium", 
    subportfolio: 93
  },
  {
    id: 34,
    name: "Isabela", 
    subportfolio: 94
  },
];
