import { ArchiveBoxIcon } from '@heroicons/react/24/solid';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useEffect } from "react";
import { useState } from "react";

import Viewer from "./Viewer";

export default ({ status = true, files = [], close, index = 0 } = {},) => {
    const [viewFile, setViewFile] = useState({ name: null, size: null })

    useEffect(() => {
        if (files.length) setViewFile(files[index])
    }, [files])

    return (<>
        {
            status ? <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-200/40"
            >
                <div className="slide-files relative mx-auto w-[100%] h-[100%]">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full">
                        <div className="flex items-start justify-between px-4 py-4 border-b border-solid border-slate-200 rounded-t">
                            <ArchiveBoxIcon className="mt-1 mr-1 h-5 w-5" aria-hidden="true" />

                            <div>
                                <h5 className="text-lg font-normal">
                                    {viewFile?.name || ''}
                                </h5>
                                <div className="flex text-sm">
                                    {viewFile?.size || ''}
                                </div>
                            </div>

                            <button
                                className="ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={close}
                            >
                                <span className="text-black-900 font-semibold h-6 w-6 block">
                                    ×
                                </span>
                            </button>
                        </div>

                        <Splide
                            className='h-full'
                            options={{
                                rewind: true,
                                gap: '1rem',
                                type: 'loop'

                            }}
                            aria-labelledby="basic-example-heading"
                            onMove={(e) => {
                                index = e.index
                                setViewFile(files[index])
                            }}                            
                        >
                            {
                                files.length && files.map((item, index) => (
                                    <SplideSlide className="h-full" key={index}>
                                        <Viewer key={index} file={item} />
                                    </SplideSlide>
                                ))
                            }
                        </Splide>
                    </div>
                </div>
            </div> : ''
        }

    </>)
}