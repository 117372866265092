import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

export default ({ 
    open = false, 
    onAccept, 
    onCancel, 
    message, 
    textAcceptButton, 
    title,
    bgAcceptButton,
    hoverAcceptButton
}) => {
    const DEFAULT_TEXT_ACCEPT_BUTTON = 'Accept';
    const DEFAULT_TITLE = 'Confirm';
    const DEFAULT_BG_ACCEPT_BUTTON = 'bg-green-600';
    const DEFAULT_HOVER_ACCEPT_BUTTON = 'hover:bg-green-700';
    return (
        <>
            {
                open ? <div className='fixed inset-0 z-10 overflow-y-auto' style={{ background: '#cccccc38' }}>
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>

                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                    { title ? title : DEFAULT_TITLE}
                                    </h3>

                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {message}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className={`inline-flex w-full justify-center rounded-md border border-transparent ${bgAcceptButton ? bgAcceptButton : DEFAULT_BG_ACCEPT_BUTTON} px-4 py-2 text-base font-medium text-white shadow-sm ${hoverAcceptButton ? hoverAcceptButton : DEFAULT_HOVER_ACCEPT_BUTTON} focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                    onClick={() => onAccept(false)}
                                >
                                    { textAcceptButton ? textAcceptButton : DEFAULT_TEXT_ACCEPT_BUTTON}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-kiwi focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={() => onCancel(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }

        </>
    )
}