import axios from 'axios';

export const PersonaServices = {
    approvePersona: async (inquery_id, user_id) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/persona/${inquery_id}/accept`,
            data: {
              user_id
            }
        });
    },
    rejectPersona: async (inquery_id, user_id) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/persona/${inquery_id}/reject`,
            data: {
              user_id
            }
        });     
    }

}