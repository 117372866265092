import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

export default ({ onRequest }) => {
    return (<div className="text-center">
        <DocumentMagnifyingGlassIcon className="mx-auto h-12 w-12 text-gray-400"/>
        <h3 className="mt-2 text-sm font-medium">No Credit Report</h3>
        <p className="mt-1 text-sm text-gray-500">This user don't have any report yet, please get one.</p>
        <div className="mt-6">
            <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-dark-kiwi hover:bg-dark-kiwi focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark-kiwi"
                onClick={() => { onRequest() }}
            >
                <ArrowPathIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Get First Report
            </button>
        </div>
    </div>)
}