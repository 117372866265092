import React, { useEffect, useState } from "react";
import axios from "axios";

import Pagination from "components/UI/Pagination";
import ModalIpUser from "components/ModalIpUser";

import Loader from "components/UI/Loader";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

const IpUser = () => {
    const dispatch = useDispatch();
    const [ips, setIps] = useState();
    const [count, setCount] = useState(0);

    const [order, setOrder] = useState();
    const [search, setSearch] = useState()
    const [loader, setLoader] = useState(false)

    const [dataModal, setDataModal] = useState({ status: false, data: [] })

    const setHandlers = {
        order: setOrder,
        search: setSearch,
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }

    useEffect(() => {
        getIps()
    }, [order])

    useEffect(() => {
        const delay = setTimeout(() => {
            getIps()
        }, 1000)

        return () => clearTimeout(delay)
    }, [search])

    const getIps = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)

            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/ip-user`,
                params: {
                    take,
                    skip,
                    order
                }
            })

            setLoader(false)
            setCount(response.data.data?.count?._count || 0)
            setIps(response.data.data.ip_user || []);
        } catch (error) {
            console.log("Error get ips: ", error)
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get ips'
            }))
        }
    }

    return (
        <div className="max-w-7xl mx-auto">
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center pb-3 border-b">

                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">User - IP information</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all the ips of the users including their name, user id and date created.
                        </p>
                    </div>
                </div>

                <br />

                <div className="sm:flex sm:items-center my-5">
                    <div className="sm:flex-auto">
                        <div>
                            <label htmlFor="orderByDateAt" className="block text-sm font-medium text-gray-700">
                                Order by created At
                            </label>
                            <select
                                id="orderByDateAt"
                                name="order"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                onChange={handleChange}
                                value={order}
                            >
                                <option value="desc">Descending</option>
                                <option value="asc">Asceding</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                                    <Pagination count={count} onTake={getIps} />
                                </div>

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="pl-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                IP
                                            </th>
                                            <th scope="col" className="py-3.5 text-center text-sm font-semibold text-gray-900">
                                                Number of users
                                            </th>
                                            <th scope="col" className="py-3.5 text-center text-sm font-semibold text-gray-900">
                                                <span className="sr-only">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {ips && ips.map((item) => (
                                            <tr key={item.phone_number}>

                                                <td className="whitespace-nowrap py-4 pr-3 pl-3 text-sm text-gray-500">
                                                    {`${item.ip_client}`}
                                                </td>

                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 text-sm text-gray-500 text-center">
                                                    {`${item?._count?.ip_client || 0}`}
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                                                    <div
                                                        className="text-blue-kiwi hover:text-sky-400"
                                                        onClick={() => setDataModal({ status: true, data: item })}
                                                    >
                                                        See Details
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Loader status={loader} ></Loader>
            <ModalIpUser
                showModal={dataModal.status}
                dataIp={dataModal.data}
                closeModal={() => setDataModal({ status: false, data: {} })}
            >
            </ModalIpUser>
        </div>
    )
}

export default IpUser