import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";
import Tooltip from "components/UI/Tooltip";

import { format } from 'date-fns';
import { AdjustmentsVerticalIcon, ClipboardIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import ModalViewDataJson from '../components/ipAddressClient/modalViewDataJson'

export default function ({ showModal, dataIp = {}, closeModal } = {}) {
    const dispatch = useDispatch();

    const [count, setCount] = useState(0);
    const [dataUsers, setDataUsers] = useState([])
    const [modalIpClient, setModalIpClient] = useState({
        status: false,
        data: {}
    })

    const [loader, setLoader] = useState(false)
    const [toasCopy, setToastCopy] = useState(false)

    useEffect(() => {
        if (showModal && dataIp?.ip_client) getUsers()
    }, [showModal])

    const getUsers = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)

            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/ip-user/${dataIp.ip_client}`,
                params: {
                    take,
                    skip,
                }
            })

            setLoader(false)
            setCount(response.data.data?.count?._count || 0)
            setDataUsers(response.data?.data?.users || []);
        } catch (error) {
            console.log("Error get users: ", error)
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get users by ip'
            }))
        }
    }

    const copyText = (event) => {
        const { id } = event.target

        if (id) {
            navigator.clipboard.writeText(id);
            setToastCopy(true)

            setTimeout(() => {
                setToastCopy(false)
            }, 1500);
        }
    }

    return (
        <> {
            showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none"
                        style={{ background: 'rgb(204 204 204 / 25%)' }}
                    >
                        <div className="relative w-full my-6 mx-auto max-w-6xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}

                                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                    <UserGroupIcon className=" mr-1 h-6 w-6" aria-hidden="true" />

                                    <h3 className="text-xl font-semibold">
                                        Users
                                    </h3>

                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={closeModal}
                                    >
                                        <span className="text-black-900 font-semibold h-6 w-6 block">
                                            ×
                                        </span>
                                    </button>
                                </div>

                                {/* Body */}

                                <div className="relative flex-auto">
                                    <div className='inline-block min-w-full align-middle p-4'>
                                        <div className="max-h-[470px] overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Name user
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Item
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Created At
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            <span className="sr-only">Actions</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white  overflow-y-auto">
                                                    {dataUsers && dataUsers.map((item) => (
                                                        <tr key={item.id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 text-sm text-gray-500">
                                                                <div className="text-gray-900 font-semibold">{`${item?.user?.first_name ?? ''} ${item?.user?.first_surname ?? item?.user?.last_name ?? ''} ${item?.user?.second_surname ?? ''}`}</div>

                                                                <div className="text-gray-500 flex align-middle">
                                                                    <span className="text-gray-600 font-semibold mr-1"> Postgres: </span>
                                                                    {item?.user_id}

                                                                    <Tooltip tooltip="Copy id Postgres">
                                                                        <ClipboardIcon id={item?.user_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-sky-400" />
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="text-gray-500 flex align-middle">
                                                                    <span className="text-gray-600 font-semibold mr-1"> Firebase: </span>
                                                                    {item?.user?.firebase_id}

                                                                    <Tooltip tooltip="Copy id Firebase">
                                                                        <ClipboardIcon id={item?.user?.firebase_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-sky-400" />
                                                                    </Tooltip>
                                                                </div>

                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <div className="flex align-middle font-medium text-gray-900 font-semibold truncate max-w-xs">
                                                                    {
                                                                        JSON.stringify(item.item || '')
                                                                    }
                                                                </div>
                                                            </td>

                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {format(new Date(item.created_at), 'MMMM dd, yyyy - p')}
                                                            </td>

                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                <Tooltip tooltip={"View data Json"}>
                                                                    <button onClick={() => {
                                                                        setModalIpClient({
                                                                            status: true,
                                                                            data: item?.item || {}
                                                                        })
                                                                    }}>
                                                                        <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-400 xs:h-9 md:w-9" aria-hidden="true" />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="bg-white px-5 py-4 border-t border-gray-200 sm:px-6">
                                        <Pagination count={count} onTake={getUsers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Loader status={loader} ></Loader>
                </>
            ) : null


        }

            {
                toasCopy ? <div id={Math.random()} aria-live="assertive"
                    className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                >
                    <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-center">
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                            <div className="relative flex flex-col items-center group">

                                <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">Id copied successfully</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> : ''


            }

            <ModalViewDataJson
                showModal={modalIpClient.status}
                data={modalIpClient.data}
                closeModal={() => setModalIpClient({ status: false, data: {} })}
            >
            </ModalViewDataJson>

        </>

    )
}