import { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';

const customStyles = {
  header: {
    style: {
      paddingLeft: '0px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',

      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',

      borderRightStyle: 'solid',
      borderRightWidth: '1px',

      backgroundColor: '#f8fafc',
    },
  },

  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '',
      },
    },
  },
};

const columns = [
  {
    name: 'Date',
    selector: row => row.date,
    sortable: true,
    value: 'date',
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    sortable: true,
    value: 'amount',
  },
  {
    name: 'Name',
    selector: row => row.name,
    sortable: true,
    value: 'name',
  },
  {
    name: 'Type',
    selector: row => row.type,
    sortable: true,
    value: 'type',
  },
];

const convertArrayOfObjectsToCSV = array => {
  const keys = ['date', 'amount', 'name', 'type'];
  const delimiter = ';';

  let result = keys.join(delimiter);
  result += '\n';

  for (const item of array) {
    let ctr = 0;

    for (const key of keys) {
      if (ctr > 0) result += delimiter;

      result += JSON.stringify(item[key] || '');
      ctr++;
    }

    result += '\n';
  }

  return result;
};

const downloadCSV = array => {
  const element = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  let csvFile = new Blob([csv], { type: 'text/csv' });
  let account_id = array[0]?.accountId ?? array[0]?.account_id;
  const filename = `${account_id}${new Date().getTime()}.csv`;

  element.download = filename;
  let url = window.URL.createObjectURL(csvFile);
  element.href = url;

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export default function Example({ data = [], id }) {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const [currentInput, setCurrentInput] = useState('');

  const FilterComponent = ({ filterText, onFilter }) => (
    <input
      id={id}
      type="text"
      autoFocus={currentInput === id ? true : undefined}
      onClick={() => setCurrentInput(id)}
      name="monthly_income"
      placeholder="Search by name"
      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
      value={filterText}
      onChange={onFilter}
    />
  );

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle, currentInput]);

  const Export = ({ onExport }) => (
    <button
      type="button"
      className="inline-flex items-center rounded border border-transparent bg-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      title="Export CSV"
      onClick={e => onExport(e.target.value)}
    >
      Export CSV
    </button>
  );
  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadCSV(filteredItems)} />,
    [filteredItems]
  );

  return (
    <DataTable
      title="Transactions"
      columns={columns}
      data={filteredItems}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      customStyles={customStyles}
      actions={actionsMemo}
    />
  );
}
