import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

export default function Index() {
  const models = [
    {
      title: 'Hudson Data',
      route: 'hudson-data',
    },
    {
      title: 'Pricing Model',
      route: 'pricing',
    },
    {
      title: 'DTI',
      route: 'history-dti',
    },
  ];

  return (
    <div className="p-8 flex flex-col gap-5">
      <div>
        <p className="font-semibold text-xl">Models</p>
        <p className="font-light">
          In this section you will find all the models developed by the data
          science area, you can consult one by one for more information.
        </p>
      </div>
      <div className="grid grid-cols-3 w-full gap-5">
        {models.map((model, index) => (
          <Link
            to={model.route}
            key={index}
            className="border p-5 bg-white hover:bg-gray-50 rounded-lg flex items-center justify-between gap-2.5 group transition-all duration-300 ease-in-out"
          >
            <div className="flex flex-col">
              <span className="text-sm font-light">Name</span>
              <p className="font-semibold text-lg">{model.title}</p>
            </div>
            <ChevronRightIcon className="h-5 group-hover:translate-x-2.5 transition-all duration-300 ease-in-out" />
          </Link>
        ))}
      </div>
    </div>
  );
}
