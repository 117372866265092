import React, { useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";

import Loader from "components/UI/Loader";
import TableStore from 'components/BNPLStores/TableStore'
import ModalStore from 'components/BNPLStores/ModalStore'

import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

const BNPLStores = () => {
    const dispatch = useDispatch();
    const [BNPLStores, setBNPLStores] = useState([])
    const [search, setSearch] = useState()
    const [result, setResult] = useState(0)

    const [showModalStore, setShowModalStore] = useState(false);
    const [loader, setLoader] = useState(false)
    const [form, setForm] = useState({})

    const setHandlers = {
        search: setSearch
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }

    const editStore = (e) => {
        setForm(e)
        setShowModalStore(true)
    }

    useEffect(() => {
        const delay = setTimeout(() => {
            getBNPLStores()
        }, 500)

        return () => clearTimeout(delay)
    }, [search])

    const getBNPLStores = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)
            setBNPLStores([])
            setResult([])

            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/bnpl_store`,
                params: {
                    take,
                    skip,
                    search
                }
            })

            setTimeout(() => {
                setLoader(false)
                setBNPLStores(response.data.data.stores);
                setResult(response.data.data.count)
            }, 350);

        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get users'
            }))
        }
    }

    return (
        <div className="max-w-7xl mx-auto">
            <div className="h-dashboard py-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center mb-4">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">BNPL stores</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all the stores including their name, location and email.
                        </p>
                    </div>

                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                        onClick={() => setShowModalStore(true)}
                    >
                        New store
                        <BuildingOfficeIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                    </button>
                </div>

                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700">Search store</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <div className="relative flex items-stretch flex-grow">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                name="search"
                                className="focus:ring-blue-kiwi focus:border-blue-kiwi block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                                placeholder="Name, Email, Phone"
                                onChange={handleChange}
                            />
                        </div>
                        <button type="button" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-kiwi focus:border-blue-kiwi">
                            <span>Search</span>
                        </button>
                    </div>
                </div>

                <div className="mt-8 flex flex-col">
                    <TableStore dataStores={BNPLStores} count={result} onTake={getBNPLStores} onEdit={editStore}></TableStore>
                </div>
            </div>

            <ModalStore
                dataBNPLStore={form}
                show={showModalStore}

                close={(refresh = false) => {
                    setForm({})
                    setShowModalStore(false);
                    if (refresh) getBNPLStores();
                }}

            />

            <Loader status={loader}></Loader>
        </div>
    )
}


export default BNPLStores;