export default ({ value }) => {
  return (
    <div>
      <div className="bg-gray-900 text-gray-100">
        <pre className="whitespace-pre-wrap">
            {JSON.stringify(value, null, 2)}
        </pre>
      </div>
    </div>
  )
}