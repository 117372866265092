import { useEffect, useState } from "react";
import { Bars4Icon, ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Api from "redux/api";
import { useSelector } from "react-redux";
import Help from "components/UI/Help";

const reasons = [
    {
        label: "Incomplete",
        value: "incomplete"
    },
    {
        label: "Verified",
        value: "verified"
    },
]

const VerificationLog = ({ application, onMove }) => {

    const auth = useSelector(state => state.auth);
    const currentLog = useSelector(state => state.currentLog);

    const [checklist, setChecklist] = useState({
        checklist_clean_id_selfie: false,
        checklist_match_name_id: false,
        checklist_all_flag_green: false,
        checklist_duplicate_ssn: false,
        checklist_duplicate_bank_account: false,
        checklist_clean_loanpro_full_name: false,
        checklist_clean_loanpro_ssn: false,
        checklist_clean_loanpro_email: false,
        checklist_clean_loanpro_address: false,
        checklist_bank_account: false,
        checklist_incomes: false,
    });
    const [isEditable, setIsEditable] = useState(false);
    const [reason, setReason] = useState(null);
    const [requirements, setRequirements] = useState(["incomes", "bank_account", "duplicate_bank_account"]);
    const [selectedRequirements, setSelectedRequirements] = useState([]);

    useEffect(() => {
        let checklistTMP = {};
        if (!application.verification) checklistTMP = { ...checklist }
        else {
            Object.keys(checklist).forEach((key) => {
                checklistTMP = { ...checklistTMP, [key]: application.verification[key] };
            })
        }

        if (application) {
            setChecklist(checklistTMP)
            setSelectedRequirements(application?.verification?.requirements || [])
        }
    }, [application])

    useEffect(() => {
        if (currentLog.reason !== "incomplete" && currentLog.reason !== "verified") {
            setIsEditable(true)
        }
    }, [currentLog])

    const handleChecklist = (event) => {
        setChecklist({ ...checklist, [event.target.name]: event.target.checked })
    }

    const showStatusListPanel = () => {
        onMove("list");
    }

    const saveVerification = () => {

        let no_checked = Object.keys(checklist).filter((key) => checklist[key] === false);

        if (!selectedRequirements.includes("incomes")) {
            no_checked = no_checked.filter(item => item !== "checklist_incomes")
        }

        if (!selectedRequirements.includes("bank_account")) {
            no_checked = no_checked.filter(item => item !== "checklist_bank_account")
        }

        if (!selectedRequirements.includes("duplicate_bank_account")) {
            no_checked = no_checked.filter(item => item !== "checklist_duplicate_bank_account")
        }

        Api.updateVerification({
            id: application.id,
            ...checklist,
            requirements: selectedRequirements
        });

        Api.updateLog({
            id: currentLog.id,
            reason: no_checked.length ? null : "verified",
            member_id: auth.id
        })
        onMove("list");
    }

    const addRequeriment = (event) => {
        setSelectedRequirements([event.target.value, ...selectedRequirements]);
        setRequirements(requirements.filter(item => item !== event.target.value));
    }

    const removeRequirement = (requirement) => {
        setRequirements([requirement, ...requirements]);
        setSelectedRequirements(selectedRequirements.filter(item => item !== requirement));
    }

    const handleReason = (event) => {
        setReason(event.target.value);
    }

    return <div className="h-full flex flex-col overflow-auto border-t" >

        <div className="w-full flex justify-between items-center p-4 bg-gray-100 border-b">
            <p>Verification</p>
            <button onClick={showStatusListPanel} type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi">
                <Bars4Icon className="w-4 h-4" />
            </button>
        </div>

        <div className="px-4 py-5">
            <fieldset className="space-y-2 mb-8">
                <h3 className="text-sm font-bold">Persona & Inquiry ID</h3>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="clean_id_selfie"
                            name="checklist_clean_id_selfie"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_clean_id_selfie}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="clean_id_selfie" className="font-medium text-gray-700">Clean ID and Selfie Photos</label>
                    </div>
                </div>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="match_name_id"
                            name="checklist_match_name_id"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_match_name_id}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="match_name_id" className="font-medium text-gray-700">Match Name on ID</label>
                    </div>
                </div>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="all_flag_green"
                            name="checklist_all_flag_green"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_all_flag_green}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="all_flag_green" className="font-medium text-gray-700">All Flags in Green</label>
                    </div>
                </div>
                <h3 className="text-sm font-bold pt-4">Duplicate Check</h3>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="duplicate_ssn"
                            name="checklist_duplicate_ssn"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_duplicate_ssn}
                            disabled={!isEditable}
                            onChange={handleChecklist} />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="duplicate_ssn" className="font-medium text-gray-700">SSN</label>
                    </div>
                </div>
                <h3 className="text-sm font-bold pt-4">Clean LoanPro Data</h3>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="clean_loanpro_full_name"
                            name="checklist_clean_loanpro_full_name"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_clean_loanpro_full_name}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="clean_loanpro_full_name" className="font-medium text-gray-700">First, Middle and Last Name</label>
                    </div>
                </div>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="clean_loanpro_ssn"
                            name="checklist_clean_loanpro_ssn"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_clean_loanpro_ssn}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="clean_loanpro_ssn" className="font-medium text-gray-700">SSN</label>
                    </div>
                </div>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="clean_loanpro_email"
                            name="checklist_clean_loanpro_email"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_clean_loanpro_email}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="clean_loanpro_email" className="font-medium text-gray-700">Email & Phone Number</label>
                    </div>
                </div>
                <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="clean_loanpro_address"
                            name="checklist_clean_loanpro_address"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_clean_loanpro_address}
                            disabled={!isEditable}
                            onChange={handleChecklist}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="clean_loanpro_address" className="font-medium text-gray-700">Address & Zip Code</label>
                    </div>
                </div>
            </fieldset>

            <div className="space-y-2 border-t pt-6 mb-6">
                <h3 className="text-sm font-bold">Extra Requirements</h3>
                <div>
                    <Help message={'Add multiples requirements depends on user profile'} >
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                            Requirements
                        </label>
                    </Help>

                    <div className="relative mt-1 mb-4">
                        <label htmlFor="requirements" className="flex w-full rounded-md border-gray-300 shadow-sm focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm border p-2">
                            <div className="w-full flex flex-wrap gap-2">
                                {!selectedRequirements.length && <p>Choose an option</p>}
                                {selectedRequirements.map(item => <div className="flex items-center bg-gray-200 px-2 py-1 text-xs relative z-20" key={`selected_${item}`}>
                                    <p>{item}</p>
                                    {isEditable && <XMarkIcon onClick={() => removeRequirement(item)} className="w-3 h-3 ml-2 cursor-pointer" />}
                                </div>)}
                            </div>
                            <div>
                                <ChevronDownIcon className="w-4 h-4 ml-2" />
                            </div>
                        </label>
                        <select id="requirements" className="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer" disabled={!isEditable} onChange={addRequeriment}>
                            <option>Choose an option</option>
                            {requirements.map(item => <option value={item} key={item}>{item}</option>)}
                        </select>
                    </div>


                </div>

                {selectedRequirements.includes("bank_account") && <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="account_verification"
                            name="checklist_bank_account"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_bank_account}
                            disabled={!isEditable}
                            onChange={handleChecklist} />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="account_verification" className="font-medium text-gray-700">Bank Account (Me Salve)</label>
                    </div>
                </div>}

                {selectedRequirements.includes("incomes") && <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="income_verification"
                            name="checklist_incomes"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_incomes}
                            disabled={!isEditable}
                            onChange={handleChecklist} />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="income_verification" className="font-medium text-gray-700">Incomes</label>
                    </div>
                </div>}

                {selectedRequirements.includes("duplicate_bank_account") && <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                        <input
                            id="duplicate_bank_account"
                            name="checklist_duplicate_bank_account"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-kiwi focus:ring-blue-kiwi"
                            checked={checklist.checklist_duplicate_bank_account}
                            disabled={!isEditable}
                            onChange={handleChecklist} />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="duplicate_bank_account" className="font-medium text-gray-700">Duplicate bank account</label>
                    </div>
                </div>}

            </div>
        </div>
        {currentLog?.reason == "verified" && <div className="mt-auto px-4 py-3 bg-gray-50 text-center border-t sm:px-6">
            <p className="text-blue-kiwi font-semibold">Verified</p>
        </div>}
        {(currentLog?.reason !== "verified" && currentLog?.reason !== "incomplete") && <div className="mt-auto px-4 py-3 bg-gray-50 text-center border-t sm:px-6">
            <button
                onClick={saveVerification}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi disabled:opacity-50"
            >
                Save Checklist
            </button>
        </div>}
    </div>
}

export default VerificationLog;