/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import UserHeader from 'components/UserHeader';
import ApplicationLogs from 'components/History';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUser, actions } from 'redux/reducer';
import Sidebar from 'components/User/Sidebar';
import Loader from 'components/UI/Loader';
import { UserServices } from 'services/User/User.services';

const ADMIN_ROLE = 'admin';

export default function User() {
  const { search } = useLocation();

  const { user_id } = useParams();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const user = useSelector(state => state.user);
  const auth = useSelector(state => state.auth);

  const getLocalUser = () => {
    if (user_id) dispatch(getUser({ user_id }));
  };

  const activeSoftDeletedUser = async () => {
    try {
      setLoader(true);

      await UserServices.activeSoftDeletedUser(user_id);
      window.location.reload();
      dispatch(
        actions.setMessage({
          type: 'success',
          title: 'Success',
          message: 'User has been activated',
        })
      );
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error activating user',
        })
      );
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!user) return;

    setLoader(false);
  }, [user]);

  useEffect(() => {
    getLocalUser();
  }, [user_id]);

  useEffect(() => {
    setLoader(true);
    return () => {
      dispatch(setUser(null));
    };
  }, []);

  return (
    <>
      <div className="min-h-full">
        <Loader status={loader} />
        {user && (
          <main className="flex">
            <Sidebar />
            {user.is_deleted === false &&
              (search ? (
                <div className="w-full h-dashboard bg-white overflow-auto flex-1 max-w-6xl mx-auto">
                  <div className="w-full bg-white overflow-hidden">
                    <UserHeader data={user} />
                    <Outlet />
                  </div>
                </div>
              ) : (
                <div className="w-full h-dashboard overflow-auto">
                  <div className="w-full flex-1 max-w-5xl mx-auto pt-0">
                    <UserHeader data={user} />
                    <Outlet />
                  </div>
                </div>
              ))}
            {user.is_deleted == true && (
              <div className="w-full h-dashboard overflow-auto">
                <div className="w-full flex-1 max-w-5xl mx-auto pt-0">
                  <div className="my-5 border rounded py-5 px-5 border-gray-400 text-gray-700 shadow">
                    This user's account has been deleted. If you encounter any
                    problems or have any questions, please don't hesitate to
                    reach out to our technical support team for assistance.
                  </div>

                  {auth.profile === ADMIN_ROLE && (
                    <div className="w-full flex justify-end">
                      <button
                        className="p-2 px-4 outline outline-2 outline-dark-kiwi rounded cursor-pointer"
                        onClick={activeSoftDeletedUser}
                      >
                        Activate User
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            <ApplicationLogs onChange={getLocalUser} />
          </main>
        )}
      </div>
    </>
  );
}
