import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PatternFormat } from "react-number-format";
import Api from "redux/api";
import { actions } from "redux/reducer";
import { UserServices } from "services/User/User.services";
import phoneFormatter from "phone-formatter";
import { UserMapper } from "./Mapper/user.mapper";
import Loader from "components/UI/Loader";
import * as yup from "yup";

const TRENGO_IDENTIFIER = {
  EMAIL: "email",
  PHONE: "voip",
};
const ERROR_RESPONSE = {
  EMAIL_EXISTS: "INVALID_EMAIL",
  PHONE_EXISTS: "INVALID_PHONE",
  SSN_EXISTS: "INVALID_SSN",
  EMAIL_EXISTS_CUSTOMERIO: "INVALID_EMAIL_CUSTOMERIO",
  EMAIL_EXISTS_LOANPRO: "INVALID_EMAIL_LOANPRO",
  PHONE_NUMBER_EXISTS_LOANPRO: "INVALID_PHONE_LOANPRO",
  INVALID_ADDRESS: "INVALID_ADDRESS",
};

const PHONE_INPUT_NAME = "phone_number";

const UserIntegrationData = () => {
  const invalidCharacterRegex = /^[a-zA-Z0-9]*$/;
  const invalidCharacterRegexWithSpaces = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
  const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.user);
  const [user, setUser] = useState(null);
  const [userLoanpro, setUserLoanpro] = useState(null);
  const [userAlgolia, setUserAlgolia] = useState(null);
  const [userCustomerIO, setUserCustomerIO] = useState(null);
  const [userMixpanel, setUserMixpanel] = useState(null);
  const [userTrengo, setUserTrengo] = useState(null);
  const [loader, setLoader] = useState(true);

  const [errorsYup, setErrorYup] = useState({
    first_name: null,
    first_surname: null,
    email: null,
    phone_number: null,
    birthdate: null,
    zipcode: null,
  });
  const [errorUpdate, setErrorUpdate] = useState({
    email_exists: null,
    phone_number_exists: null,
    ssn_number_exitst: null,
  });
  const [cellPhone, setCellPhone] = useState();

  const subtractYears = (date, years) => {
    date.setFullYear(date.getFullYear() - years);
    return date;
  };

  let personalSchema = yup.object().shape({
    first_name: yup
      .string()
      .required("First name is required")
      .matches(invalidCharacterRegex, "Invalid Caracter"),
    first_surname: yup
      .string()
      .required("First surname is required")
      .matches(invalidCharacterRegexWithSpaces, "Invalid Caracter"),
    email: yup
      .string()
      .required("Email is required")
      .matches(emailValidation, "It is not a valid email"),
    phone_number: yup
      .string()
      .required("Phone Number is required")
      .length(10, "Invalid number"),
    zipcode: yup
      .string()
      .required("Postal code is required")
      .length(5, "Invalid postal code"),
    birthdate: yup
      .date()
      .typeError("Invalid date")
      .min("01/01/1900", "Date of birth must be later than 01/01/1900")
      .max(subtractYears(new Date(), 21), "The required age is 21 years"),
  });

  const handleUser = (e) => {
    const { name, value } = e.target;

    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorYup((prevData) => ({
      ...prevData,
      [name]: null,
    }));
  };

  const handleError = ({ name, value = false }) => {
    setErrorYup((prevData) => ({
      ...prevData,
      [name]: value ? true : false,
    }));

    setErrorUpdate((prevData) => ({
      ...prevData,
      [name]: value ? true : false,
    }));
  };

  const handleCellPhone = (values) => {
    const { value } = values;

    setCellPhone(value.trim());

    setErrorUpdate((prevData) => ({
      ...prevData,
      phone_number_exists: null,
    }));

    setErrorYup((prevData) => ({
      ...prevData,
      [PHONE_INPUT_NAME]: null,
    }));
  };

  const formatPhoneNumber = (phone) => {
    phone = String(phone).replace(/\D+/g, "");
    return phone.length ? String(phone).substring(phone.length - 10) : phone;
  };

  const getUserLoanpro = async () => {
    try {
      const response = await Api.fetchUserLoanpro({
        user_id: dataUser.loanpro_id,
      });
      setUserLoanpro(response.data.data !== null ? response.data.data : null);
    } catch (error) {
      setUserLoanpro(null);
    }
  };

  const getUserAlgolia = async () => {
    try {
      const response = await UserServices.getAlgoliaInformation(dataUser.id);
      setUserAlgolia(response.data.data !== null ? response.data.data : null);
    } catch (error) {
      setUserAlgolia(null);
    }
  };

  const getUserCustomerIo = async () => {
    try {
      const response = await UserServices.getCustomerIoInformation(dataUser.id);
      setUserCustomerIO(
        response.data.data !== null
          ? response.data.data.customer.attributes
          : null
      );
    } catch (error) {
      setUserCustomerIO(null);
    }
  };

  const getUserMixpanel = async () => {
    try {
      const response = await UserServices.getMixpanleInformation(dataUser.id);
      setUserMixpanel(response.data.data !== null ? response.data.data : null);
    } catch (error) {
      setUserMixpanel(null);
    }
  };

  const getUserTrengo = async () => {
    try {
      const response = await UserServices.getTrengoInformation(dataUser.id);
      setUserTrengo(response.data.data !== null ? response.data.data : null);
    } catch (error) {
      setUserTrengo(null);
    }
  };

  const updateUserDb = async (data) => {
    const phone_number = phoneFormatter.format(cellPhone, "+1 (NNN) NNN-NNNN");
    const payload = {
      first_name: data.first_name,
      second_name: data.second_name,
      first_surname: data.first_surname,
      second_surname: data.second_surname,
      email: data.email,
      phone_number,
      identification_number: data.ssn_number,
      birthdate: data.birthdate,
      state: data.state,
      city: data.city,
      address: data.address,
      zipcode: data.zipcode,
    };
    await UserServices.updateDbUserInformation(data.id, payload);
    dispatch(actions.getAuthUser());
  };

  const updateUserCustomerIo = async (data) => {
    const phone_number = phoneFormatter.format(cellPhone, "+1 (NNN) NNN-NNNN");
    const payload = {
      first_name: data.first_name,
      last_name: data.first_surname,
      phone: phone_number,
      email: data.email,
      city: data.city,
      birthdate: data.birthdate,
    };
    await UserServices.updateCustomerIoUserInformation(data.id, payload);
  };

  const updateUserAlgolia = async (data) => {
    const phone_number = phoneFormatter.format(cellPhone, "+1 (NNN) NNN-NNNN");
    const payload = {
      first_name: data.first_name,
      second_name: data.second_name,
      first_surname: data.first_surname,
      second_surname: data.second_surname,
      email: data.email,
      phone_number: phone_number,
      identification_number: data.ssn_number,
      birthdate: data.birthdate,
      state: data.state,
      city: data.city,
      address: data.address,
      zipcode: data.zipcode,
    };

    await UserServices.updateAlgoliaUserInformation(data.id, payload);
  };

  const updateUserMixpanel = async (data) => {
    const phone_number = phoneFormatter.format(cellPhone, "+1 (NNN) NNN-NNNN");

    const payload = {
      $first_name: `${data.first_name} ${data.second_name}`,
      $last_name: `${data.first_surname} ${data.second_surname}`,
      $email: data.email,
      $city: data.city,
      $phone: phone_number,
      Birthdate: data.birthdate,
      State: data.state,
      Suite: data.city,
      Address: data.address,
      Zipcode: data.zipcode,
    };
    await UserServices.updateMixpanelUserInformation(data.id, payload);
  };

  const updateUserTrengo = async (data) => {
    const phone_number = phoneFormatter.format(cellPhone, "+1 (NNN) NNN-NNNN");
    const full_name = `${data.first_name} ${data.second_name} ${data.first_surname} ${data.second_surname}`;
    const payload = UserMapper.convertToUpdateUserTrengoInfo(
      userTrengo,
      phone_number,
      data.email,
      full_name
    );
    await UserServices.updateTrengoUserInformation(data.id, payload);
  };

  const updateUserLoanpro = async (data) => {
    const phone_number = phoneFormatter.format(cellPhone, "+1 (NNN) NNN-NNNN");
    const last_name =
      data.second_surname?.length > 0
        ? `${data.first_surname}-${data.second_surname}`
        : `${data.first_surname}`;

    const payload = {
      loanpro_id: data.loanpro_id,
      birthDate: data.birthdate,
      firstName: data.first_name,
      lastName: last_name,
      ssn: data.ssn_number,
      email: data.email,
      phone_number,
      primaryAddress: {
        address1: data.address,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
      },
    };

    await UserServices.updateLoanproUserInformation(data.id, payload);
  };

  const findErrors = async (data) => {
    const phone_number = formatPhoneNumber(cellPhone);
    const payload = {
      first_name: data.first_name,
      first_surname: data.first_surname,
      email: data.email,
      phone_number,
      identification_number: data.ssn_number,
      birthdate: data.birthdate,
      state: data.state,
      city: data.city,
      address: data.address,
      zipcode: data.zipcode,
    };
    try {
      clearSpecialErrors();
      await personalSchema.validate(payload, { abortEarly: false });
      return false;
    } catch (err) {
      let yupErrorsObject = {};

      err.inner.map((validationError) => {
        if (!yupErrorsObject.hasOwnProperty([validationError.path])) {
          yupErrorsObject[validationError.path] = validationError.errors[0];
        }
      });

      setErrorYup((prevData) => ({
        ...prevData,
        ...yupErrorsObject,
      }));
      return true;
    }
  };

  const errorResponseValidation = (error) => {
    if (error === ERROR_RESPONSE.EMAIL_EXISTS) {
      handleError({ name: "email_exists", value: true });
    }

    if (error === ERROR_RESPONSE.PHONE_EXISTS) {
      handleError({ name: "phone_number_exists", value: true });
    }

    if (error === ERROR_RESPONSE.SSN_EXISTS) {
      handleError({ name: "ssn_number_exitst", value: true });
    }

    if (error === ERROR_RESPONSE.EMAIL_EXISTS_LOANPRO) {
      handleError({ name: "email_exists_loanpro", value: true });
    }

    if (error === ERROR_RESPONSE.PHONE_NUMBER_EXISTS_LOANPRO) {
      handleError({ name: "phone_number_exists_loanpro", value: true });
    }

    if (error === ERROR_RESPONSE.EMAIL_EXISTS_CUSTOMERIO) {
      handleError({ name: "email_exists_customer_io", value: true });
    }

    if (error === ERROR_RESPONSE.INVALID_ADDRESS) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Address information invalid",
        })
      );
    }
  };

  const clearSpecialErrors = () => {
    handleError({ name: "email_exists", value: false });
    handleError({ name: "ssn_number_exitst", value: false });
    handleError({ name: "phone_number_exists", value: false });
    handleError({ name: "email_exists_customer_io", value: false });
    handleError({ name: "email_exists_loanpro", value: false });
    handleError({ name: "phone_number_exists_loanpro", value: false });
  };

  const handleSubmit = async (data) => {
    try {
      setLoader(true);
      const hasErros = await findErrors(data);
      if (hasErros) {
        setLoader(false);
        return;
      }
      if (userLoanpro) {
        await updateUserLoanpro(data);
      }
      if (userTrengo) {
        await updateUserTrengo(data);
      }
      await updateUserCustomerIo(data);
      await updateUserAlgolia(data);
      await updateUserMixpanel(data);
      await updateUserDb(data);
      window.location.reload();
    } catch (error) {
      const {
        data: { messages },
      } = error.response;
      errorResponseValidation(messages);
      setLoader(false);
    }
  };

  useEffect(() => {
    setUser(dataUser);
  }, [dataUser]);

  useEffect(() => {
    dispatch(actions.getAuthUser());
    getUserLoanpro();
    getUserAlgolia();
    getUserCustomerIo();
    getUserMixpanel();
    getUserTrengo();
    setLoader(false);
  }, []);

  return (
    user && (
      <>
        <div className="space-y-8 divide-y divide-gray-200 px-6 lg:px-8 pb-8">
          <Loader status={loader}></Loader>

          {/* PERSONAL INFORMATION */}
          <div className="space-y-8 divide-y divide-gray-200 px-6 lg:px-8">
            <div className="pt-8">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Personal Information
                  </h3>
                </div>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.first_name}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="first_name"
                      autoComplete="first_name"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errorsYup.first_name && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.first_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Second name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.second_name || ""}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="second_name"
                      autoComplete="second_name"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First surname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.first_surname}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="first_surname"
                      autoComplete="first_surname"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errorsYup.first_surname && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.first_surname}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Second surname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.second_surname}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="second_surname"
                      autoComplete="second_surname"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      value={user.email}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="email"
                      autoComplete="email"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errorsYup.email && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.email}
                      </p>
                    )}
                    {errorUpdate.email_exists && (
                      <p className="text-red-500 text-sm mt-2">
                        This email already exists.
                      </p>
                    )}
                    {errorUpdate.email_exists_loanpro && (
                      <p className="text-red-500 text-sm mt-2">
                        This email already exists in loanpro.
                      </p>
                    )}
                    {errorUpdate.email_exists_customer_io && (
                      <p className="text-red-500 text-sm mt-2">
                        This email already exists in customerio.
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <PatternFormat
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      format="+1 (###) ### ####"
                      placeholder="+1 (000) 000-0000"
                      mask=""
                      value={formatPhoneNumber(user?.phone_number || "")}
                      onValueChange={handleCellPhone}
                      name="phone_number"
                      disabled={false}
                    />

                    {errorsYup.phone_number && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.phone_number}
                      </p>
                    )}
                    {errorUpdate.phone_number_exists && (
                      <p className="text-red-500 text-sm mt-2">
                        Phone number already exists.
                      </p>
                    )}
                    {errorUpdate.phone_number_exists_loanpro && (
                      <p className="text-red-500 text-sm mt-2">
                        This phone number already exists in loanpro.
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="ssn_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Social Security Number - SSN
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.identification_number}
                      name="ssn_number"
                      autoComplete="ssn_number"
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                    {errorsYup.ssn_number && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.ssn_number}
                      </p>
                    )}
                    {errorUpdate.ssn_number_exitst && (
                      <p className="text-red-500 text-sm mt-2">
                        SSN is registered.
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="birthdate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date of Birth
                  </label>
                  <div className="mt-1">
                    <input
                      type="date"
                      value={user.birthdate}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="birthdate"
                      autoComplete="birthdate"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errorsYup.birthdate && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.birthdate}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Province
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.state}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="state"
                      autoComplete="state"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.city}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="city"
                      autoComplete="city"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.address}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="address"
                      autoComplete="address"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="zipcode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.zipcode}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="zipcode"
                      autoComplete="zipcode"
                      className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errorsYup.zipcode && (
                      <p className="text-red-500 text-sm mt-2">
                        {errorsYup.zipcode}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* LOANPRO INFORMATION */}
          <div className="space-y-8 px-6 lg:px-8">
            <div className="pt-8">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Loanpro Information
                  </h3>
                </div>
              </div>
            </div>
            {userLoanpro ? (
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.firstName}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Middle name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.middleName}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First surname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.lastName}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    SSN
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.ssn}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      value={userLoanpro?.email}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <PatternFormat
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      name="phone_number"
                      format="+1 (###) ### ####"
                      placeholder="+1 (000) 000-0000"
                      mask=""
                      value={formatPhoneNumber(userLoanpro?.primaryPhone || "")}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="ssn_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.primaryAddress?.address1}
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.primaryAddress?.city}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.primaryAddress?.state}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Zip code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userLoanpro?.primaryAddress?.zipcode}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <h3>User not found</h3>
            )}
          </div>
          {/* CUSTOMERIO INFORMATION */}
          <div className="space-y-8 px-6 lg:px-8">
            <div className="pt-8">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    CustomerIo Information
                  </h3>
                </div>
              </div>
            </div>
            {userCustomerIO ? (
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userCustomerIO.first_name}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userCustomerIO.last_name}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="second_surname"
                      autoComplete="second_surname"
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      value={userCustomerIO.email}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <PatternFormat
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      name="phone_number"
                      format="+1 (###) ### ####"
                      placeholder="+1 (000) 000-0000"
                      mask=""
                      value={formatPhoneNumber(userCustomerIO?.phone || "")}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="birthdate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date of Birth
                  </label>
                  <div className="mt-1">
                    <input
                      type="date"
                      value={userCustomerIO.birthdate}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <h3>User not found</h3>
            )}
          </div>
          {/* ALGOLIA INFORMATION */}
          <div className="space-y-8 px-6 lg:px-8">
            <div className="pt-8">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Algolia Information
                  </h3>
                </div>
              </div>
            </div>
            {userAlgolia ? (
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.first_name}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Second name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.second_name || ""}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First surname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.first_surname}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="second_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Second surname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.second_surname}
                      onChange={(e) => {
                        handleUser(e);
                        handleError({ name: e.target.name });
                      }}
                      name="second_surname"
                      autoComplete="second_surname"
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      value={userAlgolia.email}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <PatternFormat
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      name="phone_number"
                      format="+1 (###) ### ####"
                      placeholder="+1 (000) 000-0000"
                      mask=""
                      value={formatPhoneNumber(userAlgolia?.phone_number || "")}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="ssn_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Social Security Number - SSN
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={user.identification_number}
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="birthdate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date of Birth
                  </label>
                  <div className="mt-1">
                    <input
                      type="date"
                      value={userAlgolia.birthdate}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Province
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.state}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.city}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.address}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="zipcode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userAlgolia.zipcode}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <h3>User not found</h3>
            )}
          </div>
          {/* MIXPANEL INFORMATION */}
          <div className="space-y-8 px-6 lg:px-8">
            <div className="pt-8">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Mixpanel Information
                  </h3>
                </div>
              </div>
            </div>
            {userMixpanel ? (
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userMixpanel?.firts_name}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_surname"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First surname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userMixpanel?.first_surname}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      value={userMixpanel?.email}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <PatternFormat
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      name="phone_number"
                      format="+1 (###) ### ####"
                      placeholder="+1 (000) 000-0000"
                      mask=""
                      value={formatPhoneNumber(
                        userMixpanel?.phone_number || ""
                      )}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="ssn_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userMixpanel?.address}
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userMixpanel?.city}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userMixpanel?.state}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Zip code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userMixpanel?.zipcode}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <h3>User not found</h3>
            )}
          </div>
          {/* TRENGO INFORMATION */}
          <div className="space-y-8 px-6 lg:px-8">
            <div className="pt-8">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Trengo Information
                  </h3>
                </div>
              </div>
            </div>
            {userTrengo ? (
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={userTrengo[0]?.name}
                      className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                      disabled={true}
                    />
                  </div>
                </div>
                {userTrengo.map((contact) =>
                  contact.type === TRENGO_IDENTIFIER.EMAIL ? (
                    <div className="sm:col-span-3" key={contact.contact_id}>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          value={contact.email}
                          className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  ) : contact.type === TRENGO_IDENTIFIER.PHONE ? (
                    <div className="sm:col-span-3" key={contact.contact_id}>
                      <label
                        htmlFor="phone_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                      <div className="mt-1">
                        <PatternFormat
                          className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                          name="phone_number"
                          format="+1 (###) ### ####"
                          placeholder="+1 (000) 000-0000"
                          mask=""
                          value={formatPhoneNumber(contact?.phone || "")}
                          disabled={true}
                        />
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            ) : (
              <h3>User not found</h3>
            )}
          </div>
        </div>
        <div className="w-full h-16 bg-white border-t sticky left-0 bottom-0 flex items-center">
          {user && (
            <div className="w-full max-w-6xl mx-auto flex justify-end sm:px-6 lg:px-8">
              <button
                type="button"
                className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => handleSubmit(user)}
              >
                Save changes
              </button>
            </div>
          )}
        </div>
      </>
    )
  );
};

export default UserIntegrationData;
