import { setHours, setMinutes, startOfDay, parseISO, subHours } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';


export const formatDateWithHour = (date) => {
  try {
    return format(new Date(date), 'MMMM dd, yyyy - p')
  } catch (error) {
    return '---- --, ---- - --:-- --'
  }
}
export const transformToUtc = (date) => {
  const utcDate = utcToZonedTime(date, 'UTC');
  return utcDate;
}


export const getUtcDate = (myDate, isEnd) => {
  if (typeof myDate === 'undefined') {
    return undefined;
  }

  const date = startOfDay(parseISO(myDate));

  let hour;

  if (isEnd) {
    hour = setHours(date, 23);
    hour = setMinutes(hour, 59);
  } else {
    hour = subHours(setHours(date, -5), 5);
    hour = setMinutes(hour, 0);
  }
  
  return transformToUtc(hour);
};