import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'redux/reducer';
import Loader from 'components/UI/Loader';

import Transations from 'pages/Dashboard/User/BankData/Transations';
import {
  ArrowTopRightOnSquareIcon,
  AdjustmentsVerticalIcon,
} from '@heroicons/react/24/outline';
import Tooltip from 'components/UI/Tooltip';
import axios from 'axios';
import { actions } from 'redux/reducer';

export default function BankDataItem({
  account,
  transactions,
  finicity = false,
  viewDataJson,
}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [data_account, setDataAccount] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (user) {
      getBankData();
    }
  }, [user]);

  const getBankData = async () => {
    try {
      setLoader(true);

      let historical_transations = transactions.filter(e => {
        // plaid
        if (e.account_id && e.account_id == account.account_id) return e;
        // finicity
        if (e.accountId && e.accountId == account.id) return e;
      });

      setLoader(false);

      setDataAccount({
        account,
        historical_transations,
      });
    } catch (error) {
      setLoader(false);
      console.log('[Error, conultando bank data]', error);
    }
  };

  const refreshStatement = async () => {
    try {
      setLoader(true);
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/files/bank-statement-finicity`,
        data: {
          user_id: user.id,
          accountId: data_account.account?.id,
        },
      });

      dispatch(getUser({ user_id: user.id }));
    } catch (error) {
      let data_error =
        error?.response?.data?.messages?.message || 'Error refresh statement';
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: data_error,
        })
      );
    }
  };

  const openBankStatement = async () => {
    try {
      setLoader(true);
      let statement = getStatement();

      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/files/signed-url/${statement.id}`,
      });

      const url = response.data?.data?.url || '';
      window.open(url);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getStatement = () => {
    let statement = user?.files?.find(
      e => e.type == `account_finicity_${data_account?.account?.id}`
    );
    return statement;
  };

  const getOwner = (item = {}) => {
    let owner = item?.owner || null;

    if (item?.owner?.hasOwnProperty('data')) {
      owner = item.owner.data;
    }

    return owner;
  };

  return (
    <div className="bg-white overflow-hidden">
      <div className="flex justify-between items-center px-4 py-5 sm:px-6 bg-gray-100">
        <div>
          <h3 className="text-lg leading-6 font-semibold text-gray-900">{`${
            data_account.account?.name || ''
          }`}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            <strong className="font-semibold"> Number in dictionary: </strong>
            {`${
              data_account.account?.account_id || data_account.account?.id || ''
            } `}
          </p>
        </div>
        <div className="flex w-min-w-50">
          {finicity && (
            <Tooltip tooltip="Get bank statement">
              <button
                type="button"
                class="outline outline-green-500 outline-1 inline-flex items-center mr-2 px-3 py-2 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-green-500 hover:text-white"
                onClick={refreshStatement}
              >
                Statements
              </button>
            </Tooltip>
          )}

          {getStatement() ? (
            <>
              <Tooltip tooltip="Open bank statement" place="bottom">
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  onClick={openBankStatement}
                >
                  <ArrowTopRightOnSquareIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
            </>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          {/* plaid */}

          {
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Owner's name:
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2 flex justify-between">
                {account.owners?.length
                  ? account.owners.map((owner, index) => {
                      return (
                        <Fragment key={index}>
                          <div>{`${owner?.names?.join(' ') || ''}`}</div>
                          <button onClick={() => viewDataJson(owner)}>
                            <Tooltip tooltip="View data JSON">
                              <AdjustmentsVerticalIcon
                                className="-ml-1 mr-2 h-5 w-5 text-gray-400 xs:h-9 md:w-9"
                                aria-hidden="true"
                              />
                            </Tooltip>
                          </button>
                        </Fragment>
                      );
                    })
                  : null}
              </dd>
            </div>
          }

          {/* finicity */}

          {getOwner(data_account.account) ? (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Owner name:
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${getOwner(data_account.account)?.ownerName || ''}`}
              </dd>
            </div>
          ) : (
            ''
          )}

          {/* finicity */}
          {getOwner(data_account.account) ? (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Owner address:
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${getOwner(data_account.account)?.ownerAddress || ''}`}
              </dd>
            </div>
          ) : (
            ''
          )}
          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-900">
              {data_account.account?.mask ? 'Mask:' : 'Account number last 4'}
            </dt>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
              {`${
                data_account.account?.mask ||
                data_account.account?.realAccountNumberLast4 ||
                ''
              }`}
            </dd>
          </div>
          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-900">Name:</dt>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${
              data_account.account?.name || ''
            }`}</dd>
          </div>
          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-900">Subtype: </dt>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
              {`${
                data_account.account?.subtype ||
                data_account.account?.type ||
                ''
              }`}
            </dd>
          </div>
          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-900">
              Available Balance:{' '}
            </dt>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
              {`${
                data_account.account?.balances?.available ||
                data_account.account?.detail?.availableBalanceAmount ||
                ''
              }`}
            </dd>
          </div>
          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-900">
              Current Balance:{' '}
            </dt>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
              {`${
                data_account.account?.balances?.current ||
                data_account.account?.balance ||
                ''
              }`}
            </dd>
          </div>

          {/* Plaid */}

          {data_account.account?.bank_numbers && (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Account number:{' '}
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${data_account.account?.bank_numbers?.account || ''}`}
              </dd>
            </div>
          )}

          {data_account.account?.bank_numbers && (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">Routing: </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${data_account.account?.bank_numbers?.routing || ''}`}
              </dd>
            </div>
          )}

          {data_account.account?.bank_numbers && (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">ABA: </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${data_account.account?.bank_numbers?.wire_routing || ''}`}
              </dd>
            </div>
          )}

          {/*  */}

          {data_account.account?.accountNumberDisplay ? (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Number display:{' '}
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${data_account.account?.accountNumberDisplay || ''}`}
              </dd>
            </div>
          ) : (
            ''
          )}

          {data_account.account?.formatLastTransactionDate ? (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Last transaction date:{' '}
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${data_account?.account?.formatLastTransactionDate || ''}`}
              </dd>
            </div>
          ) : (
            ''
          )}

          {data_account.account?.formatOldesTransactionDate ? (
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-semibold text-gray-900">
                Oldest transaction date:{' '}
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
                {`${data_account?.account?.formatOldesTransactionDate || ''}`}
              </dd>
            </div>
          ) : (
            ''
          )}
        </dl>
      </div>

      <div>
        <div className={`mt-8 grid grid-cols-1 gap-4 `}>
          <div className="inline-block min-w-full p-2 align-middle">
            <Transations
              data={data_account.historical_transations}
              id={data_account.account?.account_id}
            />
          </div>
        </div>
      </div>

      <Loader status={loader}></Loader>
    </div>
  );
}
