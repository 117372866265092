
const Contract = () => {
    return (<div class="max-w-6xl mx-auto">
        <p class="c5 c49"><span class="c16 c68"></span></p>
        <p class="c5"><span class="c0">CONTRATO DE PRÉSTAMO DE PUERTO RICO</span></p>
        <p class="c12"><span class="c21">Información del Prestatario</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c21">Información del Prestamista</span></p>
        <p class="c12"><span class="c2">Kiwi Financial, LLC</span></p>
        <p class="c12"><span class="c2">33 Calle Resolución, Suite 701-A, San Juan, PR 00920</span></p>
        <p class="c12"><span class="c2">800 260-1007</span></p>
        <p class="c12"><span class="c2">OCIF Número de Licencia: #PPP-035</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c21">Loan Information</span></p>
        <p class="c12"><span class="c2">Número de Acuerdo:</span></p>
        <p class="c12"><span class="c2">Fecha de Origen:</span></p>
        <p class="c12"><span class="c2">Duración del Préstamo (Meses):</span></p>
        <p class="c12"><span class="c2">Fecha de Vencimiento:</span></p>
        <p class="c12"><span class="c2">Nuestro Cierre de Operaciones en la Fecha de Vencimiento: 04:00 pm (EST)</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="mb-8">
            Este Contrato de Préstamo (el "Contrato") se celebra entre KIWI
            FINANCIAL, LLC denominado "Prestamista", "nosotros", "nos" o
            "nuestro" y el PRESTATARIO denominado "usted", "Su" o "suyo"
            a partir de la fecha anterior, sujeto a los términos y condiciones establecidos y todas y cada una de
            las representaciones que el PRESTATARIO haya hecho al PRESTAMISTA en relación con esta
            transacción.
        </p>

        <table class="border-collapse border border-slate-500 mb-6">
            <tr class="c25">
                <td class="c89" colspan="5" rowspan="1">
                    <p class="c5"><span class="c0">LEY FEDERAL DE VERACIDAD EN PRéSTAMOS</span></p>
                </td>
            </tr>
            <tr class="c91">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c4">TASA PORCENTUAL ANUAL</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c4">CARGO FINANCIERO</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5"><span class="c4">MONTO FINANCIADO</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c4">TOTAL DE PAGOS</span></p>
                </td>
            </tr>
            <tr class="c71">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c45 c13">El costo de su crédito como una tasa anual</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c45 c13">El monto en dólares que le costar&aacute; el
                        crédito</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5"><span class="c45 c13">La cantidad de crédito que se le proporcionó a usted o
                        en su nombre</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c45 c13">La cantidad que habr&aacute; pagado después de haber hecho
                        todos los pagos programados</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c5 c49"><span class="c47"></span></p>
                </td>
                <td class="c46 c74" colspan="1" rowspan="1">
                    <p class="c5 c49"><span class="c47"></span></p>
                </td>
                <td class="c53 c46" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c47"></span></p>
                </td>
                <td class="c62 c46" colspan="1" rowspan="1">
                    <p class="c5 c49"><span class="c47"></span></p>
                </td>
            </tr>
            <tr class="c6">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c4">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c4">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5"><span class="c4">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c4">&nbsp;</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c12"><span class="c0">Calendario de Pago Aplicable a este Préstamo:</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="c10" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Número de Pagos</span></p>
                </td>
                <td class="c36" colspan="2" rowspan="1">
                    <p class="c5"><span class="c0">Fecha de Vencimiento de Pago</span></p>
                </td>
                <td class="c40" colspan="2" rowspan="1">
                    <p class="c5"><span class="c0">Monto Total de Pago</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">1</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">5</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">6</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c12"><span class="c0">ITEMIZACIóN DEL MONTO FINANCIADO</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Monto Financiado</span></p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Monto Entregado a Usted Directamente</span></p>
                </td>
                <td class="c42" colspan="2" rowspan="1">
                    <p class="c5"><span class="c0">Monto de Pagos Mensuales</span></p>
                </td>
                <td class="c60 c46" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
            </tr>
            <tr class="c25">
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c5 c49"><span class="c7"></span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
            </tr>
            <tr class="c6">
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c12"><span class="c2">Consulte los siguientes términos para m&aacute;s
                        información</span></p>
                </td>
                <td class="border border-slate-700" colspan="2" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
                <td class="border border-slate-700" colspan="1" rowspan="1">
                    <p class="c37"><span class="c2">&nbsp;</span></p>
                </td>
            </tr>
        </table>

        <p class="text-center font-bold mb-4"><span class="c0">TÉRMINOS Y CONDICIONES DEL PRéSTAMO</span></p>

        <p class="mb-4"><strong class="c22">Términos de Repago:</strong><span class="c2">&nbsp;Ha solicitado un
            préstamo el &quot;Préstamo&quot; por el monto del Monto Financiado indicado anteriormente
            el &quot;Principal&quot;. Si lo solicita espec&iacute;ficamente, nosotros, como PRESTAMISTA, le
            adelantamos el monto del Principal. Por lo tanto, usted, como PRESTATARIO, acepta pagar al PRESTAMISTA la
            cantidad establecida en el programa de cuotas anterior a su vencimiento, que consiste en capital e
            intereses, de conformidad con este Acuerdo. Usted comprende que, para cada Fecha de Vencimiento de Pago, el
            Monto Total de Pago que se muestra en la tabla anterior se basa en el supuesto de que todos los pagos a
            plazos se realizar&aacute;n en las fechas de vencimiento programadas.</span></p>
        
        

        <p class="mb-4"><strong class="c22">Pagos Tard&iacute;os:</strong><span class="c2"> El PRESTATARIO tendr&aacute; un
            per&iacute;odo de gracia de 10 d&iacute;as en cualquier Fecha de Vencimiento de Pago sin multas si se
            env&iacute;a una notificación por escrito al PRESTAMISTA al siguiente correo electrónico
            support@kiwicredito.com al menos dos d&iacute;as h&aacute;biles antes de la Fecha de Vencimiento de Pago. El
            pago se considerar&aacute; atrasado fuera de los par&aacute;metros descritos anteriormente. El PRESTAMISTA
            cobrara el interés acumulado diario sobre los pagos realizados después de la fecha de pago
            original. Si un pago est&aacute; atrasado por m&aacute;s de los 10 d&iacute;as de gracia se generara un
            cargo del 3% del el valor del pago. Si el per odo de gracia de 10 d&iacute;as termina en un d&iacute;a
            festivo o el PRESTAMISTA no puede recibir el pago, el per&iacute;odo de gracia se extender&aacute;
            autom&aacute;ticamente hasta el siguiente d&iacute;a h&aacute;bil / disponible.</span></p>
        

        <p class="mb-4"><strong class="c22">Cargos por pagos devueltos:</strong><span class="c2">&nbsp;El PRESTAMISTA
            cobrar&aacute; un cargo de $10 dólares por cada pago del préstamo que sea devuelto por Fondos
            Insuficientes o Fondos No Disponibles.</span>
        </p>


        <h1 class="mb-4"><strong class="c13">Pagos por Adelantado y Prepagos:</strong><span class="c2">El PRESTATARIO puede
            pagar por adelantado el monto total del préstamo sin tener que pagar cargos, multas o intereses sobre
            la parte del monto Principal pagado antes del Calendario de Pago. En estos casos, se cancelar&aacute; el
            saldo del monto Principal adeudado en la fecha de pago, m&aacute;s cualquier pago atrasado adeudado. El
            PRESTATARIO puede realizar pagos parciales por adelantado y recibir un crédito o reembolso, a
            discreción del PRESTAMISTA, por la parte de los cargos correspondientes a las cuotas pagadas por
            adelantado.</span></h1>


        <h1 class="mb-4"><strong class="c13">Cambios de fecha de pago:</strong><span class="c2">&nbsp;El PRESTAMISTA permite
            cambiar sus fechas de pago en los primeros 15 d&iacute;as de recibir el contrato de préstamo. Cambios
            de fecha de pago afuera de los primeros 15 d&iacute;as llevara un costo de $5 dólares. &nbsp;Para
            poder cambiar una fecha de pago se requiere una notificación por medio de correo electrónico a
            support@kiwicredito de al menos dos d&iacute;as h&aacute;biles.</span></h1>
        <h1 class="c17 c19" id="h.30j0zll"><span class="c2"></span></h1>

        <h1 class="mb-4"><span class="c13">Asignación de Pagos y Pagos Adicionales:</span><span
            class="c13 c80">&nbsp;</span><span class="c2">Los pagos y créditos se aplicar&aacute;n en el
                siguiente orden: cualquier monto de pago atrasado; cualquier tarifa o cargo adeudado, intereses devengados o
                cargos financieros y el principal pendiente. Los pagos que se realicen adem&aacute;s de los pagos
                programados regularmente se aplicar&aacute;n en el mismo orden.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Tasa de Interés</span><span class="c2">: Este es un préstamo de tasa
            de interés simple; el pago anticipado disminuir&aacute; el Monto Financiado y el pago tard&iacute;o
            aumentar&aacute; el Monto Financiado.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Responsabilidad del Prestatario</span><span class="c2">: Usted promete
            notificarnos de cualquier cambio en su nombre, dirección o empleo. Promete no solicitar un
            préstamo si sabe que existe una probabilidad razonable de que no pueda pagar su obligación de
            acuerdo con los términos de este Préstamo. Se compromete a informarnos de cualquier
            información nueva que se relacione con su capacidad para pagar su obligación. Usted promete no
            enviar información falsa o inexacta ni ocultar intencionalmente información sobre su
            solvencia, solvencia o capacidad crediticia.</span></h1>


        <p class="c59 c50"><span class="c22">Incumplimiento del Préstamo</span><span class="c2">: Usted
            incurrir&aacute; en incumplimiento bajo este Acuerdo si: a no paga el Total de Pagos en o antes de la
            Fecha de Vencimiento de Pago que se muestra arriba, o b proporciona información falsa o
            engañosa sobre usted, su empleo o su condición financiera incluida la cuenta de la que se
            extraen los pagos antes de entrar en este Acuerdo. </span></p>
        <p class="c59 c50 c49"><span class="c2"></span></p>
        <p class="c50 c59"><span class="c22">Aceleración</span><span class="c2">: El PRESTATARIO acepta que, si el
            PRESTATARIO incumple los términos de este Acuerdo y no subsana dicho incumplimiento dentro de los 15
            d&iacute;as posteriores a la notificación del PRESTAMISTA de dicho incumplimiento, el PRESTAMISTA
            tendr&aacute; la opción de declarar la totalidad del monto principal restante y pendiente, as&iacute;
            como cualquier intereses devengados, que vencen y son pagaderos inmediatamente.</span></p>
        <p class="c59 c50 c49"><span class="c0"></span></p>
        <h1 class="c17"><span class="c13">Consecuencias del Incumplimiento y Cobros</span><span class="c2">: Si el
            PRESTAMISTA encuentra que el PRESTATARIO est&aacute; en incumplimiento, el PRESTAMISTA puede, a su propia
            discreción, ejercer uno o m&aacute;s de los siguientes recursos: a si el pago no se realiza
            después de la demanda por escrito, el PRESTAMISTA puede ir a la corte y obtener un juicio contra el
            PRESTATARIO por el monto no pagado de sus obligaciones con el PRESTAMISTA.</span>
        </h1>



        <h1 class="mb-4"><span class="c13">Demora en la Ejecución</span><span class="c2">: EL PRESTATARIO acepta y
            comprende que el PRESTAMISTA puede retrasar la ejecución de cualquiera de sus derechos en virtud de
            este Acuerdo o de la ley aplicable, y dicha demora no se considerar&aacute; una renuncia o renuncia a tales
            derechos.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Pagos Irregulares</span><span class="c2">: Podemos aceptar pagos atrasados o pagos
            parciales, aunque estén marcados como &quot;pago completo&quot;, sin perder ninguno de nuestros
            derechos bajo este Acuerdo o cualquier derecho bajo la ley aplicable.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Coprestatario</span><span class="c2">: Si firma este Acuerdo como coprestatario,
            acepta ser igualmente responsable con el PRESTATARIO, pero el PRESTAMISTA puede demandar al PRESTATARIO o al
            los coprestatario s, o a todos ustedes. No tenemos que notificarle que este Acuerdo no ha sido pagado.
            Podemos extender los términos de pago sin notificarlo o liberarlo de su responsabilidad en este
            Acuerdo.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Veracidad de Aplicación</span><span class="c2">: Certifica que la
            información declarada en este contrato es verdadera y correcta. Usted comprende que confiamos en la
            Aplicación y este Acuerdo. Nos autoriza a verificar cualquier información a través de
            cualquier fuente, incluido el uso de un informe crediticio.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Cargos de la Institución Financiera del Prestatario</span><span
            class="c2">: Usted no responsabilizar&aacute; al PRESTAMISTA ni a nuestros agentes por las tarifas que deba
            pagar como resultado de las transacciones entre nosotros y la cuenta de su institución financiera
            designada.</span>
        </h1>


        <h1 class="mb-4"><span class="c13">Autorización de Elección de Desembolso y Pago</span><span
            class="c2">: A menos que elija enviarnos por correo un cheque s o un giro postal como pago de este
            Préstamo, usted nos autoriza voluntariamente a iniciar créditos de desembolso y débitos
            de pago que haya autorizado. Esta Autorización de Elección de Desembolso y Pago es parte de y
            se relaciona con este Acuerdo. Haremos este crédito de desembolso mediante el uso de cualquier
            método comercialmente disponible que elijamos, como pero no limitado a entradas de la C&aacute;mara
            de Compensación Automatizada ACH, transferencias bancarias o transacciones a través de su
            tarjeta de débito accediendo a su Cuenta Bancaria. Como medida de seguridad de los datos, nos
            proporcionar&aacute; por separado la información de su tarjeta de débito.</span>
        </h1>


        <h1 class="c17"><span class="c13">Pagos Autom&aacute;ticos</span><span class="c2">: Usted nos autoriza a procesar
            las entradas de débito de pago de su Cuenta Bancaria mediante el uso de cualquier método
            comercialmente disponible que elijamos, como pero no limitado a entradas de ACH o transacciones a
            través de su tarjeta de débito accediendo a su Cuenta Bancaria de acuerdo con el Calendario de
            Pago anterior, m&aacute;s cualquier cargo por mora, cargos por pago devuelto y, si est&aacute; en
            incumplimiento, todos los cargos de capital, financiamiento y otros montos adeudados a nosotros según
            lo dispuesto en el Acuerdo. Usted nos autoriza a volver a procesar las entradas de débito por los
            mismos montos si se rechaza cualquier intento de transacción de pago.</span>
        </h1>


        <h1 class="c17"><span class="c13">Pagos que Realizar&aacute; Directamente</span><span class="c2">: Tenga en cuenta
            que no es necesario que autorice esta opción de autorización de opción de pago para
            poder financiar su préstamo. Si prefiere pagar mediante pagos manuales, comun&iacute;quese con el
            servicio de atención al cliente en support@kiwicredito.com para configurar esta opción de pago
            alternativa. Si elige pagar mediante pagos manuales, su préstamo no se financiar&aacute; hasta que se
            haya comunicado con el servicio de atención al cliente y haya completado un acuerdo de
            préstamo con pagos manuales como opción de pago. Los pagos manuales incluyen pagos mediante
            cheque de caja o giro postal.</span>
        </h1>


        <h1 class="c17"><span class="c13">Cheques Creados Remotamente</span><span class="c2">: Si no recibimos su cheque o
            giro postal o pago a través de ACH o mediante la autorización de la tarjeta de débito
            antes de las Fechas de Vencimiento de Pago, usted acepta realizar todos los pagos requeridos en virtud del
            Acuerdo de préstamo al hacernos crear cheques en papel emitidos en su cuenta bancaria y con su nombre
            en lugar de su firma manuscrita Cheques Creados Remotamente. Usted nos autoriza a nosotros y a nuestros
            agentes, sucesores y cesionarios a enviar cada Cheque Creado Remotamente para su pago al Banco Pagador por
            la cantidad de cada pago adeudado en virtud de este Acuerdo de Préstamo, incluidos los cargos por
            pago devuelto u otras cantidades que se nos adeuden al acelerar este Préstamo como resultado de su
            Incumplimiento. Su nombre mecanografiado constituir&aacute; su firma autorizada reflejando completamente su
            intención de autenticar estos Cheques Creados Remotamente, que también se conocen como giros a
            la vista, telecheques, giros preautorizados o giros en papel. Si cree que cargamos su cuenta bancaria de una
            manera no contemplada en esta autorización, comun&iacute;quese con nosotros envi&aacute;ndonos un
            correo electrónico a support@kiwicredito.com. Usted nos autoriza a variar el monto de cualquier pago
            preautorizado por Cheque Creado Remotamente según sea necesario para pagar las cuotas adeudadas del
            préstamo según sea modificado por cualquier pago anticipado.</span>
        </h1>


        <h1 class="c17"><span class="c13">AVISO DE CANTIDADES VARIABLES</span><span class="c2">: Para aquellos clientes que
            hayan elegido la Autorización de Débito ACH, tenga en cuenta que tiene derecho a recibir un
            aviso de todos los retiros de su cuenta bancaria mediante un Débito ACH que var&iacute;an en monto.
            Sin embargo, al aceptar permitirnos retirar el dinero de su Cuenta Bancaria, acepta que solo tenemos que
            informarle el rango de retiros que podemos hacer. El rango de retiros ser&aacute; un monto igual a su pago a
            plazos o un monto igual al saldo pendiente bajo el Préstamo que puede ser mayor o menor que un pago
            a plazos según su historial de pagos. Para cualquier retiro fuera de este rango especificado, le
            enviaremos un aviso 10 d&iacute;as antes de la fecha del débito. Por lo tanto, al firmar este Acuerdo
            a continuación, reconoce que solo recibir&aacute; un aviso cuando un retiro exceda la cantidad en el
            rango especificado. Usted nos autoriza a variar el monto de cualquier retiro según sea necesario para
            reembolsar las cuotas adeudadas del Préstamo según las modificaciones de los pagos anticipados
            parciales que realice.</span></h1>
        <h1 class="c17 c19 c51"><span class="c2"></span></h1>
        <h1 class="c17"><span class="c2">Usted acepta que esta Autorización de Elección de Pago
            permanecer&aacute; vigente hasta que su préstamo, incluidos el capital, los cargos financieros y
            otros cargos, se pague en su totalidad. Solo puede revocar la autorización anterior
            comunic&aacute;ndose con nosotros directamente. Si revoca su autorización, acepta proporcionarnos
            otra forma de pago aceptable para nosotros.</span></h1>
        <h1 class="c17 c19"><span class="c2"></span></h1>
        <h1 class="c17"><span class="c13">Representaciones y Garant&iacute;as</span><span class="c13 c80">:</span><span
            class="c13 c80">&nbsp;</span><span class="c2">Ambas Partes declaran que est&aacute;n plenamente autorizadas
                para celebrar este Contrato de Préstamo. El desempeño y las obligaciones de cualquiera de las
                partes no violar&aacute;n ni infringir&aacute;n los derechos de ningún tercero ni violar&aacute;n
                ningún otro acuerdo entre las Partes, individualmente, y cualquier otra persona, organización
                o empresa o cualquier ley o reglamento gubernamental.</span></h1>
        <h1 class="c17 c19"><span class="c2"></span></h1>
        <h1 class="c17"><span class="c13">Divisibilidad</span><span class="c2">: En el caso de que alguna disposición
            de este Acuerdo se considere inv&aacute;lida o inaplicable, en su totalidad o en parte, esa parte se
            separar&aacute; del resto del Acuerdo y todas las dem&aacute;s disposiciones deber&aacute;n continuar en
            pleno vigor y efecto como v&aacute;lidas y ejecutables.</span></h1>
        <h1 class="c17 c19"><span class="c2"></span></h1>
        <h1 class="c17"><span class="c13">Renuncia</span><span class="c2">: El hecho de que cualquiera de las Partes no
            ejerza cualquier derecho, poder o privilegio bajo los términos de este Acuerdo no se
            interpretar&aacute; como una renuncia a cualquier ejercicio subsequente o posterior de ese derecho, poder o
            privilegio o el ejercicio de cualquier otro derecho, poder o privilegio.</span></h1>
        <h1 class="c17 c19 c51"><span class="c2"></span></h1>
        <h1 class="c17"><span class="c13">Ley Aplicable y Jurisdicción</span><span class="c2">: Tanto el PRESTATARIO
            como el PRESTAMISTA acuerdan que este Acuerdo se regir&aacute; por las leyes del Estado Libre Asociado de
            Puerto Rico.</span></h1>
        <h1 class="c19 c76"><span class="c21"></span></h1>
        <h1 class="c17"><span class="c2">Al seleccionar &quot;Acepto el Acuerdo de Préstamo&quot;, acepta nuestros
            términos de uso https://www.kiwicredito.com/terms-of-use?lang=es y nuestra pol&iacute;tica de
            privacidad https://www.kiwicredito.com/privacy-policy?lang=es as&iacute; como el contrato de
            préstamo adjunto se aplicar&aacute;n a este préstamo. Si hay m&aacute;s de un prestatario,
            usted acepta que todas las condiciones del préstamo y los acuerdos de garant&iacute;a que rigen este
            préstamo se aplicar&aacute;n tanto de manera conjunta como solidaria. Usted reconoce que ha recibido
            una copia del contrato de préstamo y la declaración de divulgación &quot;Nota&quot;.
            También acusar&aacute; recibo de las solicitudes, divulgaciones y contratos relacionados con este
            préstamo.</span></h1>
        <h1 class="c17 c19"><span class="c2"></span></h1>
        <h1 class="c17"><span class="c13">Aviso de Información Negativa</span><span class="c2">: Podemos reportar
            información sobre su cuenta a las agencias de crédito. Los pagos tard&iacute;os, pagos
            atrasados u otros incumplimientos en su cuenta pueden reflejarse en su informe de crédito.</span>
        </h1>
        <h1 class="c19 c32"><span class="c21"></span></h1>
        <h1 class="c17"><span class="c21">AVISO AL CONSUMIDOR: ESTA ES UNA TRANSACCIóN DE CRéDITO PARA EL
            CONSUMIDOR. A NO FIRME NADA ANTES DE LEERLO O SI CONTIENE ALGúN ESPACIO EN BLANCO. B TIENE
            DERECHO A UNA COPIA EXACTA DE CUALQUIER ACUERDO QUE FIRME. C USTED TIENE DERECHO EN CUALQUIER MOMENTO A
            PAGAR POR ANTICIPADO EL SALDO NO PAGADO DEBIDO EN VIRTUD DE ESTE CONTRATO.</span></h1>
        <h1 class="c17 c19"><span class="c21"></span></h1>
        <h1 class="c17"><span class="c21">ESTE ACUERDO ESCRITO REPRESENTA EL ACUERDO FINAL ENTRE LAS PARTES Y NO PUEDE SER
            CONTRADICADO POR EVIDENCIA DE ACUERDOS ORALES PREVIOS, CONTEMPOR&Aacute;NEOS O POSTERIORES DE LAS PARTES. NO
            EXISTEN ACUERDOS ORALES NO ESCRITOS ENTRE LAS PARTES.</span></h1>
        <h1 class="c17 c19"><span class="c21"></span></h1>
        <h1 class="c17"><span class="c21">PRECAUCIóN - ES IMPORTANTE QUE LEA ATENTAMENTE EL CONTRATO ANTES DE
            FIRMARLO</span></h1>
        <h1 class="c17 c19"><span class="c21"></span></h1><a id="t.2843f7edea9aec405cdef730628ad680276acec4"></a><a
            id="t.1"></a>
        <table class="c14">
            <tr class="c82">
                <td class="c66" colspan="1" rowspan="1">
                    <h1 class="c17"><span class="c0">PRESTATARIO:</span></h1>
                    <h1 class="c17 c19"><span class="c45 c13"></span></h1>
                    <h1 class="c17"><span class="c13 c45">Firma del Prestatario</span></h1>
                    <h1 class="c17 c19"><span class="c2"></span></h1>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                    <h1 class="c17"><span class="c0">PRESTAMISTA:</span></h1>
                    <h1 class="c17"><span class="c0">Kiwi Financial, LLC</span></h1>
                    <h1 class="c17"><span class="c45 c13">Representante Autorizado</span></h1>
                    <h1 class="c17 c19"><span class="c2"></span></h1>
                </td>
            </tr>
        </table>





        <p class="c5"><span class="c0">AVISO PARA RESIDENTES DE PUERTO RICO</span></p>
        <p class="c12"><span class="c48 c79 c13">DIVULGACIONES OBLIGATORIAS DEL ESTADO LIBRE ASOCIADO DE PUERTO RICO</span>
        </p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c2">Bajo la Ley de Préstamos Personales Pequeños de Puerto Rico deL 1965,
            10 L.P.R.A. &sect; 941et seq, estamos obligados a proporcionarle las siguientes divulgaciones.</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c5"><span class="c0">CARGOS</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c2">a Cargo m&aacute;ximo. - La Junta Financiera tendr&aacute; facultad para
            fijar, regular, aumentar, disminuir o dejar a la libre competencia por reglamento y durante el tiempo que
            fuese necesario, los tipos de interés y cargos m&aacute;ximos permitidos por ley. También, la
            Junta Financiera podr&aacute; determinar el método del cómputo de intereses.</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c2">b Cargo por diferimiento de los plazos. - El concesionario podr&aacute;
            otorgar diferimiento de plazos sujeto a los términos y condiciones que para ello establezca el
            Comisionado mediante reglamento.</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c2">c Cargo por seguro. - A opción del prestatario, se cobrar&aacute; un
            cargo por el costo de un seguro de crédito al consumidor, con arreglo a las disposiciones del
            Cap&iacute;tulo 18.050 y 18.60 del Código de Seguros de Puerto Rico, 26 L.P.R.A. &sect; 105, et seq.
            Disponiéndose, que: </span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12 c29"><span class="c2">1 Dicho seguro podr&aacute; ser obtenido por el prestatario o por el
            concesionario del préstamo, con el consentimiento del prestatario. </span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12 c29"><span class="c2">2 En el caso de seguros de crédito al consumidor, no exceder&aacute; la
            cantidad establecida en los Art&iacute;culos 18.050 y 18.60 del Código de Seguros de Puerto
            Rico.</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12 c54"><span class="c2">3 Ningún concesionario de préstamo podr&aacute; exigir como
            condición para el otorgamiento de un préstamo que:</span></p>
        <p class="c1 c54"><span class="c2"></span></p>
        <p class="c12 c20"><span class="c2">A El prestatario obtenga un seguro de crédito al consumidor, </span>
        </p>
        <p class="c1 c20"><span class="c2"></span></p>
        <p class="c12 c88"><span class="c2">B y de obtener dicho seguro, que el mismo se provea por conducto de
            determinada persona, agente, corredor o solicitador o con algún asegurador en particular. </span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12 c29"><span class="c2">4 Si el contrato de préstamo ha de incluir un renglón para
            seguro, éste deber&aacute; contener un aviso en forma clara, escrito en letra m&aacute;s oscura, el
            doble del tamaño de las dem&aacute;s letras, a los efectos de que se ofrece el seguro de
            crédito al consumidor sobre una base voluntaria y el prestatario no viene obligado en forma alguna a
            acogerse a él. </span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12 c29"><span class="c2">5 Se ofrecer&aacute; el seguro de crédito al consumidor
            únicamente luego de notificar al cliente que</span></p>
        <p class="c12 c29"><span class="c2">su préstamo personal fue aprobado por el concesionario. </span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c2">d Pago por adelantado. - Un prestatario podr&aacute; pagar por adelantado la
            totalidad de un préstamo o uno o m&aacute;s plazos de éste. Si pagare el préstamo en su
            totalidad, mediante la entrega en efectivo, con el otorgamiento de un nuevo préstamo o el
            refinanciamiento del préstamo original, el concesionario no le cobrar&aacute; la porción de
            los cargos correspondientes a los plazos no vencidos. Si se hicieran pagos parciales por adelantado, el
            prestatario recibir&aacute; un crédito o reembolso por la porción del cargo correspondiente a
            los plazos as&iacute; adelantados. Los créditos o reembolsos se computar&aacute;n usando aquel
            método que sea m&aacute;s beneficioso para el consumidor, conforme al reglamento que emita el
            Comisionado. </span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c12"><span class="c87">e Otros cargos. - Ningún concesionario aconsejar&aacute;,
            exigir&aacute; o permitir&aacute; a cualquier persona, o a su cónyuge, o a ambas conjuntamente, a
            obligarse bajo m&aacute;s de un contrato de préstamo al mismo tiempo, o a desglosar o dividir
            cualquier préstamo o préstamos con el propósito o con el resultado de obtener cargos
            mayores. No se cargar&aacute;, ni se contratar&aacute; o recibir&aacute; del prestatario, directa o
            indirectamente, ninguna cantidad o cargo que no sea autorizado por la Junta </span><span class="c2">por
                medio de reglamento.</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c26"><span class="c16 c9">33 Calle Resolucion, </span></p>
        <p class="c26"><span class="c9 c16">San Juan, PR, 00920</span></p>
        <p class="c26"><span class="c16 c9">787-490-2004</span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c5"><span class="c16 c35">AUTORIZACIóN DE CLIENTES PARA PAGOS ACH</span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c41">Yo nosotros autorizo autorizamos </span><span class="c55">Kiwi Financial, LLC
        </span><span class="c16 c41">para debitar electrónicamente a mi nuestra cuenta.</span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c16 c41">Y, de ser necesario, acreditar autom&aacute;ticamente a mi nuestra cuenta
            para corregir débitos erróneos como sigue: </span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c23">Tipo de Cuenta: </span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c16 c41">Cuenta de cheques___ Cuenta de ahorros ___ seleccionar una </span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c16 c41">Yo autorizo débitos/créditos electrónicos de ACH a la
            institución financiera depositante nombrada a continuación &ldquo;Kiwi Financial LLC&rdquo;.
            Yo nosotros acepto aceptamos que las transacciones ACH que yo nosotros autorice autoricemos cumplan
            con todas las leyes aplicables. </span></p>





        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c28"><span class="c9">       Nombre en la cuenta: </span><a
            id="kix.7egpj778vww3"></a><span class="c9"></span><span
                class="c48 c9">_______________________________</span></p>
        <p class="c28"><span class="c9">         Nombre del banco:
        </span><span class="c48 c9">_______________________________</span></p>
        <p class="c28"><span class="c9">  Número de cuenta bancaria:</span><a
            id="kix.h5yfzt8iibn"></a><span class="c9"> </span><span
                class="c48 c9">_______________________________</span></p>
        <p class="c28"><span class="c9">    Número de ruta bancaria:</span><a
            id="kix.k3o6i1hnfd9f"></a><span class="c9"> </span><span
                class="c9 c48">_______________________________</span></p>
        <p class="c44"><span class="c9">    Ciudad/Estado del Banco: </span><a
            id="kix.lp8iuod6kypd"></a><span class="c9"></span><span
                class="c48 c9">_______________________________</span><span class="c16 c55"></span></p>
        <p class="c12"><span class="c55">Esta cuenta bancaria esta h&aacute;bil para transacciones de ACH </span><span
            class="c55 c73">X</span><span class="c55"> </span><span class="c9">Yes</span><span class="c55"></span><span class="c9">No</span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c23">Fechas y/o frecuencia de débitos:</span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c41">Una vez ___ Periódico__</span><span class="c41 c48">X</span><span
            class="c16 c41">_ seleccione una </span></p>
        <p class="c12"><span class="c41">Número de meses____ Numero de cuartos____ Monto en dólares
            autorizado_______</span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c34"><span class="c16 c41">Yo nosotros entiendo entendemos que esta autorización
            permanecer&aacute; en pleno vigor y efecto hasta que yo nosotros le notifique notifiquemos por escrito a
            Kiwi Financial, LLC que deseo deseamos cancelar esta autorización. Yo nosotros entiendo
            entendemos que Kiwi Financial LLC requiere al menos 15 d&iacute;as de aviso previo para cancelar esta
            autorización. En caso de incumplimiento de pagos, entiendo que Kiwi Financial, LLC podr&aacute;
            debitarme todo lo adeudado a la fecha de retraso, lo cual puede diferir de la cantidad establecida arriba.
        </span></p>





        <p class="c12"><span class="c16 c41">Nombres
            _____________________________________________________________________________________ </span></p>
        <p class="c12"><span class="c16 c41">Por favor imprimir </span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c16 c41">Fecha __________ Firmas
            __________________________________________________________________ </span></p>
        <p class="c50 c49 c57"><span class="c16 c39"></span></p>
        <p class="c57 c50"><span class="c16 c39">Certifico que soy un firmante autorizado de la cuenta indicada
            anteriormente y que tengo la autoridad para autorizar esta/estas transaccioneses. Entiendo que debido a
            que se trata de una transacción electrónica, estos fondos pueden retirarse de mi cuenta tan
            pronto como en la fecha de transacción indicada anteriormente, y que tendré un tiempo limitado
            para informar y disputar errores. En el caso de que la transacción se reverse por fondos
            insuficientes NSF, entiendo que Kiwi Financial LLC puede, a su discreción, intentar procesar el
            cargo nuevamente dentro de los siguientes 30 d&iacute;as, y acepta un cargo adicional de $10.00 por cada
            intento reversado NSF, que se iniciar&aacute; como una transacción separada del pago autorizado. He
            certificado que la cuenta bancaria anterior est&aacute; habilitada para transacciones ACH y acepto
            reembolsar a Kiwi Financial LLC por todas las multas y cargos incurridos como resultado de que mi banco
            rechace los débitos o créditos de ACH como resultado de que la cuenta no esté
            configurada correctamente para transacciones ACH. Ambas partes acuerdan estar sujetas a las Reglas
            Operativas de NACHA en lo que se refiere a esta transacción. Reconozco que la realización de
            transacciones ACH a mi cuenta debe cumplir con las disposiciones de la ley de EE. UU. Acepto no disputar
            esta transacción con mi banco siempre que la transacción corresponda a los términos
            indicados en este formulario de autorización.</span></p>
        <p class="c57 c50 c49"><span class="c16 c39"></span></p>

        <p class="c26"><span class="c16 c9">33 Calle Resolucion, </span></p>
        <p class="c26"><span class="c16 c9">San Juan, PR, 00920</span></p>
        <p class="c26"><span class="c16 c9">787-490-2004</span></p>
        <p class="c57 c50 c49"><span class="c16 c39"></span></p>






        <p class="c50 c96"><span class="c16 c35">AUTORIZACIóN DE PAGO CON TARJETA DE DéBITO
            &quot;Autorización de Tarjeta de Débito&quot; a Kiwi Financial, LLC</span></p>
        <p class="c26 c49 c50"><span class="c16 c35"></span></p>
        <p class="c34"><span class="c16 c41">Para efectuar 1 los pagos a plazos debidos a Kiwi Financial, LLC y 2 las
            cuotas adeudadas a CAB en su acuerdo de servicios de crédito asociado con su préstamo, usted
            por la presente voluntariamente nos autoriza y a nuestros agentes, sucesores y cesionarios a iniciar
            asientos de débito recurrentes por los montos totales establecidos en el calendario de pagos y a su
            tarjeta de débito que nos proporcionó en su solicitud de préstamo Tarjeta de
            Débito. No es requerido que seleccione este método de pago como condición para obtener
            un préstamo a plazos. Usted acepta que podamos iniciar las entradas de débito recurrentes para
            pagos por Tarjeta de Débito en relación a su acuerdo de préstamo el &quot;Contrato de
            Préstamo&quot;, y esta Autorización de Tarjeta de Débito, incluidos el capital,
            intereses, cargos por mora u otros montos adeudados al prestamista. Si realiza cualquier prepago parcial de
            su préstamo, entonces nos autoriza a variar el monto de cualquier pago preautorizado mediante
            débito ACH según sea necesario para reflejar esos pagos anticipados parciales.</span></p>
        <p class="c34 c49"><span class="c16 c41"></span></p>
        <p class="c34"><span class="c16 c41">Terminación. Esta Autorización de tarjeta de débito
            permanecer&aacute; en pleno vigor y efecto hasta que el acuerdo de cuenta se pague por completo o recibamos
            notificación de su parte de que desea revocarlo. Usted comprende y reconoce que puede anular esta
            autorización de tarjeta de débito notific&aacute;ndonos en el momento y la forma debida para
            permitirnos y a su banco una oportunidad razonable de actuar en consecuencia. Esta Autorización de
            Tarjeta de Débito también terminar&aacute; de forma inmediata una vez complete el pago final
            de la cantidad que nos debe bajo el acuerdo de préstamo.</span></p>
        <p class="c34 c49"><span class="c16 c41"></span></p>
        <p class="c34"><span class="c16 c41">Información de cuenta bancaria faltante o errónea. Usted declara
            y garantiza que la tarjeta de débito es una tarjeta de débito leg&iacute;tima, abierta y
            activa.</span></p>
        <p class="c34"><span class="c16 c41">tarjeta. Si hay información faltante o errónea con respecto a la
            Tarjeta de Débito, entonces nos autoriza a verificar y corregir dicha información, incluidos,
            entre otros, el número de tarjeta de débito, el número de cuenta bancaria y el
            número de ruta. Usted acepta que las transacciones autorizadas en este documento cumplan con todas
            las leyes aplicables de Estados Unidos. Si cree que realizamos un debido su cuenta bancaria de una
            manera no establecida por esta Autorización de Tarjeta de Crédito, por favor
            cont&aacute;ctenos.</span></p>
        <p class="c34 c49"><span class="c16 c41"></span></p>
        <p class="c34"><span class="c16 c41">Entrada de crédito. Usted nos autoriza a iniciar entradas de
            crédito de tarjeta de débito en su tarjeta de débito según sea necesario para
            obtener un saldo exacto bajo el contrato de préstamo.</span></p>
        <p class="c34 c49"><span class="c16 c41"></span></p>
        <p class="c34"><span class="c16 c41">ESTA AUTORIZACION DE PAGO ES PARA SU CONVENIENCIA AL PAGAR LAS CANTIDADES
            DEBIDAS BAJO SU CONTRATO DE PRéSTAMO Y NO SON REQUERIDOS PARA QUE USTED OBTENGA UN
            PRéSTAMO.</span></p>
        <p class="c24"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c16 c41">Nombres
            _____________________________________________________________________________________ </span></p>
        <p class="c12"><span class="c16 c41">Por favor imprimir </span></p>
        <p class="c1"><span class="c16 c41"></span></p>
        <p class="c12"><span class="c16 c41">Fecha __________ Firmas
            __________________________________________________________________ </span></p>
        <p class="c57 c50 c49"><span class="c16 c39"></span></p>
        <p class="c34 c49"><span class="c16 c39"></span></p>
        <p class="c1"><span class="c2"></span></p>
    </div>)
}

export default Contract;