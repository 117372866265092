import { useState } from "react";
import Pagination from "components/UI/Pagination";
import Item from "components/Loans/Item";

const LoansList = ({ status, loans, total, fetchApplications }) => {

    const [page, setPage] = useState(1);

    const handleTake = ({ page, ...payload }) => {
        setPage(page);
        fetchApplications(page, payload.take);
    };

    return loans && (<>
        <div className="">
            <div className="w-full rounded-lg overflow-hidden mb-6 border-b border-gray-300">
                {loans && loans.map((loan) => <Item status={status} data={loan} key={loan.id} />)}
            </div>
        </div>
        <div className="bg-white px-4 py-3 border border-gray-300 rounded-lg sm:px-6 mb-4">
            <Pagination status={status} count={total} onTake={handleTake} page={page} />
        </div>
    </>)
}

export default LoansList;