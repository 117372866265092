import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios'
import Api from "redux/api";


const LoanPro = () => {
    const { user_id } = useParams();
    const dataUser = useSelector(state => state.user);
    const [user, setUser] = useState(null)
    const [userLoanpro, setUserLoanpro] = useState(null)
    const [payment_profiles, setPaymentProfiles] = useState(null);
    const [creditReports, setCreditReports] = useState([]);

    useEffect(() => {
        setUser(dataUser)
        getPaymentProfile();
        getUserLoanpro();

    }, [])

    useEffect(() => {
        if (user?.id) getReports();
    }, [user])

    const getPaymentProfile = async () => {
        try {
            const response = await Api.fetchPaymentProfiles({ user_id });
            setPaymentProfiles(response.data.payment_profiles);
        } catch (error) {
            console.log(error);
        }
    }

    const getUserLoanpro = async () => {
        try {
          if (dataUser.loanpro_id) {
            const response = await Api.fetchUserLoanpro({ user_id: dataUser.loanpro_id });
            setUserLoanpro(response.data.data !== null ? response.data.data : null)
          }
          
        } catch (error) {
            console.log(error);
        }
    }

    const getReports = async () => {
        try {
            let { data } = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/credit-reports`,
                params: {
                    user_id: user_id
                }
            })

            let credit_reports = data.credit_reports.map((report) => ({
                ...report,
                explanation: report.factors ? report.factors || [] : [],
            }));

            setCreditReports(credit_reports ? credit_reports[0] : {});
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className={`mt-6 grid grid-cols-1 gap-y-6 gap-x-4 ${dataUser.loanpro_id ? "sm:grid-cols-9" : "sm:grid-cols-2"} mb-10 ${!dataUser.loanpro_id && "ml-auto mr-auto max-w-[66%]"}`}>
                            <div className={`${dataUser.loanpro_id ? "sm:col-span-3" : "sm:col-span-1"}`}>
                                <h3 className="font-semibold mb-2">PostgreSQL</h3>
                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.first_name}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                        name="first_name"
                                        autoComplete="first_name"
                                    />
                                </div>
                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                    Middle name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.second_name}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                        name="first_name"
                                        autoComplete="first_name"
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    First surname *
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.first_surname}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    SSN
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.identification_number}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.email}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.address}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.city}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    State
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.state}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Zip code
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.zipcode}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className={`sm:col-span-${dataUser.loanpro_id ? 3 : 1}`}>
                                <h3 className="font-semibold mb-2">Transunion</h3>
                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.first_name}

                                        name="first_name"
                                        autoComplete="first_name"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                    Middle name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.middle_name}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                        name="first_name"
                                        autoComplete="first_name"
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    First surname *
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.last_name}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true} />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    SSN
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.ssn}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={user?.email}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.addresses ? creditReports?.addresses[0]?.street.name._text : ""}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.addresses ? creditReports?.addresses[0]?.location.city._text : ""}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    State
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.addresses ? creditReports?.addresses[0]?.location.state._text : ""}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Zip code
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={creditReports?.addresses ? creditReports?.addresses[0]?.location.zipCode._text : ""}
                                        name="first_surname"
                                        autoComplete="first_surname"
                                        className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            {dataUser.loanpro_id && <div className="sm:col-span-3">
                                <h3 className="font-semibold mb-2">Loanpro {userLoanpro === null ? " - Sin datos" : ""}</h3>
                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.firstName}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                    Middle name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.middleName}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    First surname
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.lastName}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    SSN
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.ssn}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.email}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.primaryAddress?.address1}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.primaryAddress?.city}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    State
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.primaryAddress?.state}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                                <label htmlFor="first_surname" className="block text-sm font-medium text-gray-700">
                                    Zip code
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={userLoanpro?.primaryAddress?.zipcode}
                                        className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                                        disabled={true}
                                    />
                                </div>
                            </div>}

                        </div>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Payment Profile ID
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Title
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Is Primary
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {payment_profiles && payment_profiles.map((profile) => (
                                        <tr key={profile.id}>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{profile.id}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{profile.title}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{profile.isPrimary === 1 ? "TRUE" : "FALSE"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoanPro;