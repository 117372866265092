import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PatternFormat } from "react-number-format";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

import ModalViewDataJson from "../../../components/ipAddressClient/modalViewDataJson";
import DeleteModal from "../../../components/DeleteModal";
import Notification from "components/UI/Notifications";
import { actions } from "redux/reducer";

const UserPersonal = () => {
  const EMPTY_STRING = "";

  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.user);
  const dataAuth = useSelector((state) => state.auth);
  const [user, setUser] = useState(null);
  const [modalIpClient, setModalIpClient] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [paramsNotification, setParamsNotification] = useState({
    type: EMPTY_STRING,
    title: EMPTY_STRING,
    message: EMPTY_STRING,
  });
  const [modalConfirmDeleteUserState, setModalConfirmDeleteUserState] =
    useState(false);
  const hasPermission = (role) => {
    const canDelelete = ["admin"];
    return canDelelete.includes(role);
  };

  const formatPhoneNumber = (phone) => {
    phone = String(phone).replace(/\D+/g, EMPTY_STRING);
    return phone.length ? String(phone).substring(phone.length - 10) : phone;
  };

  const closeModalConfirmDelete = () => {
    setModalConfirmDeleteUserState(false);
  };

  const openModalConfirmDelete = () => {
    setModalConfirmDeleteUserState(true);
  };

  const showDeleteNotification = (paramsNotification) => {
    setShowNotification(true);
    setParamsNotification(paramsNotification);
  };

  useEffect(() => {
    setUser(dataUser);
  }, [dataUser]);

  useEffect(() => {
    dispatch(actions.getAuthUser());
  }, []);

  return (
    user && (
      <>
        <div className="space-y-8 divide-y divide-gray-200 px-6 lg:px-8 pb-16">
          <div className="pt-8">
            <div className="flex justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
              <Link
                to="../integration-data"
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
              >
                <PencilSquareIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Edit user
              </Link>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.first_name}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="second_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Second name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.second_name || EMPTY_STRING}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="first_surname"
                  className="block text-sm font-medium text-gray-700"
                >
                  First surname
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.first_surname}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="second_surname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Second surname
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.second_surname}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    value={user.email}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone_number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="mt-1">
                  <PatternFormat
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    format="+1 (###) ### ####"
                    placeholder="+1 (000) 000-0000"
                    mask=""
                    value={formatPhoneNumber(
                      user?.phone_number || EMPTY_STRING
                    )}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="ssn_number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Social Security Number - SSN *
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.identification_number}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="birthdate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    value={user.birthdate}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.state}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.city}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.address}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="zipcode"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP / Postal code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    value={user.zipcode}
                    className="shadow-sm bg-gray-200 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {user && user?.application && (
            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Approved
                </h3>
              </div>
              <div className="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
                <div className="">
                  <label
                    htmlFor="credit_limit"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Credit Limit
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="credit_limit"
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      value={user.application.credit_limit}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="customer_employer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Interest
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="customer_employer"
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      value={user.application.interest_rate}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="income_frequency"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Term
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="income_frequency"
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      value={user.application.term_months}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="income_frequency"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fee
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="income_frequency"
                      className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                      value={user.application.fee}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Signup Survey
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="monthly_income"
                  className="block text-sm font-medium text-gray-700"
                >
                  Monthly Icome
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="monthly_income"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].monthly_income
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="customer_employer"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer Employer
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="customer_employer"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].customer_employer
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="income_frequency"
                  className="block text-sm font-medium text-gray-700"
                >
                  Income Frequency
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="income_frequency"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].income_frequency
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="loan_purpose"
                  className="block text-sm font-medium text-gray-700"
                >
                  Loan Purpose
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="loan_purpose"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].loan_purpose
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="signup_source"
                  className="block text-sm font-medium text-gray-700"
                >
                  Signup Source
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="signup_source"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].signup_source
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="durationInWork"
                  className="block text-sm font-medium text-gray-700"
                >
                  Months receiving income
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="durationInWork"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].months_receiving_income
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="wantCash"
                  className="block text-sm font-medium text-gray-700"
                >
                  Want cash in affiliate store?
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="wantCash"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].want_cash_in_affiliate_store
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="amountLoanSurvey"
                  className="block text-sm font-medium text-gray-700"
                >
                  Loan Ask
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="amountLoanSurvey"
                    className="shadow-sm bg-gray-200 cursor-not-allowed focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                    value={
                      user.surveys?.length > 0
                        ? user.surveys[0].loan_ask
                        : "None"
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div className="grid grid-cols-3 place-content-start border border-red-600 p-8 rounded-lg">
              <div>
                {dataAuth && hasPermission(dataAuth?.profile) ? (
                  <button
                    type="button"
                    className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={openModalConfirmDelete}
                  >
                    Delete account
                  </button>
                ) : (
                  EMPTY_STRING
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalViewDataJson
          showModal={modalIpClient}
          data={user.ip_address_client}
          closeModal={() => setModalIpClient(false)}
        ></ModalViewDataJson>

        <DeleteModal
          showModal={modalConfirmDeleteUserState}
          data={{
            auth: dataAuth,
            user,
          }}
          closeModal={(state) => closeModalConfirmDelete(state)}
          onDelete={showDeleteNotification}
        ></DeleteModal>

        <Notification
          onHide={() => {
            setShowNotification(false);
          }}
          open={showNotification}
          params={paramsNotification}
        />
      </>
    )
  );
};

export default UserPersonal;
