import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const PricingModelServices = {
    getVerifiedPrincing: async (user_id) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/pricing/verified/${user_id}`,
            headers: HEADERS(),
        });
    },
    getUnVerifiedPrincing: async (user_id) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/pricing/unverified/${user_id}`,
            headers: HEADERS(),
        });
    }
}