import { XMarkIcon } from '@heroicons/react/24/outline';
import BaseModal from 'components/BaseModal';

export default function DocumentViewLoanModal({ closeModal, pdfUrl, isOpen }) {
  return (
    <BaseModal closeModal={closeModal} isOpen={isOpen}>
      {pdfUrl && (
        <div className="w-[97.5vw] h-[95vh] relative">
          <button
            onClick={closeModal}
            className="absolute bg-[#323639]  flex items-center gap-2.5 px-10 py-4 right-0 top-0 rounded-l-xl rounded-tr-xl"
          >
            <XMarkIcon onClick={closeModal} className="h-6 w-6 stroke-white" />
            <p className="font-semibold text-white">Exit view</p>
          </button>
          <iframe
            src={pdfUrl}
            width="100%"
            height="100%"
            title="pdf"
            className="rounded-xl"
          />
        </div>
      )}
    </BaseModal>
  );
}
