import { useMemo, useRef, useState } from "react";
import { createAutocomplete } from "@algolia/autocomplete-core";
import algoliasearch from "algoliasearch/lite";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME);
const sourceId = process.env.REACT_APP_ALGOLIA_INDEX_NAME;

const AutocompleteItem = ({ id, fullName, phone_number }) => {
  return (
    <li>
      <a href={`/users/${id}`} className="hover:bg-blue-300 p-4 w-full block">
        <div>
          <h3 className="text-sm font-semibold">{fullName}</h3>
          <p className="text-xs text-gray-600">{phone_number}</p>
        </div>
      </a>
    </li>
  );
};

export default function Search(props) {
  const [autocompleteState, setAutocompleteState] = useState({
    collections: [],
    isOpen: false,
  });

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        placeholder: "Buscar un usuario...",
        onStateChange: ({ state }) => setAutocompleteState(state),
        getSources: () => [
          {
            sourceId,
            getItems: ({ query }) => {
              if (!!query) {
                return index
                  .search(query, {
                    attributesToRetrieve: [
                      "id",
                      "first_name",
                      "first_surname",
                      "phone_number",
                      "email",
                      "identification_number",
                      "firebase_id",
                    ],
                  })
                  .then(({ hits }) => {
                    const items = hits.map((hit) => ({
                      id: hit.id,
                      fullName: hit.first_name + " " + hit.first_surname,
                      phone_number: hit.phone_number,
                    }));
                    return items;
                  });
              }
            },
          },
        ],
        ...props,
      }),
    [props]
  );

  const formRef = useRef(null);
  const inputRef = useRef(null);
  const panelRef = useRef(null);

  const formProps = autocomplete.getFormProps({
    inputElement: inputRef.current,
  });
  const inputProps = autocomplete.getInputProps({
    inputElement: inputRef.current,
  });

  return (
    <form ref={formRef} className="justify-center w-fit" {...formProps}>
      <div className="flex relative ">
        <input
          ref={inputRef}
          className="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
          {...inputProps}
        />
        {autocompleteState.isOpen && (
          <div
            className="absolute max-h-80 overflow-y-auto mt-16 top-0 left-0 border border-gray-100 bg-white rounded-lg shadow-lg z-10 w-full"
            ref={panelRef}
            {...autocomplete.getPanelProps()}
          >
            {autocompleteState.collections.map((collection, index) => {
              const { items } = collection;
              return (
                <section key={`section-${index}`}>
                  {items.length > 0 && (
                    <ul {...autocomplete.getListProps()}>
                      {items.map((item) => (
                        <AutocompleteItem key={item.id} {...item} />
                      ))}
                    </ul>
                  )}
                </section>
              );
            })}
          </div>
        )}
      </div>
    </form>
  );
}
