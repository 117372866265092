import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import BaseModal from 'components/BaseModal';
import React from 'react';

export default function ApprovedOfferModal({
  isOpen,
  closeModal,
  confirmOffer,
}) {
  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="p-8 min-w-[20rem] max-w-md flex flex-col gap-5">
        <div className="bg-green-50 w-max p-2.5 rounded-full">
          <ExclamationCircleIcon className="stroke-green-500 h-6 w-6" />
        </div>
        <p className="text-gray-500 text-left text-sm">
          Are you sure you want to proceed approving an offer surpassing $1000?
        </p>
        <div className="grid grid-cols-2 text-sm gap-2.5">
          <button
            onClick={closeModal}
            className="border-gray-500  border py-2 rounded-md text-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={confirmOffer}
            className="border-green-500 bg-green-100 border py-2 rounded-md text-green-500 font-semibold"
          >
            Confirm
          </button>
        </div>
      </div>
    </BaseModal>
  );
}
