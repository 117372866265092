import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  DevicePhoneMobileIcon,
  ArchiveBoxIcon,
  BuildingLibraryIcon,
  ClipboardDocumentListIcon,
  FolderIcon,
  IdentificationIcon,
  PencilSquareIcon,
  Squares2X2Icon,
  ClipboardDocumentCheckIcon,
  BeakerIcon,
  ServerStackIcon,
} from '@heroicons/react/24/outline';

const UserTabs = ({ className }) => {
  const { search, pathname } = useLocation();

  const [tabs, setTabs] = useState([
    {
      name: 'Personal',
      href: 'personal',
      icon: IdentificationIcon,
      current: false,
    },
    {
      name: 'Credit Bureau',
      href: 'credit-reports',
      icon: ClipboardDocumentListIcon,
      current: false,
    },
    {
      name: 'Open Banking',
      href: 'bank-data',
      icon: BuildingLibraryIcon,
      current: false,
    },
    {
      name: 'UW Details',
      href: 'uw-details',
      icon: PencilSquareIcon,
      current: false,
    },
    {
      name: 'Verifications',
      href: 'verifications',
      icon: ClipboardDocumentCheckIcon,
      current: false,
    },
    {
      name: 'Models',
      href: 'models',
      icon: BeakerIcon,
      current: false,
    },
    {
      name: 'Government ID',
      href: 'persona-status',
      icon: FolderIcon,
      current: false,
    },
    {
      name: 'Files',
      href: 'detail-files',
      icon: ArchiveBoxIcon,
      current: false,
    },
    { name: 'Loans', href: 'loans', icon: Squares2X2Icon, current: false },
    { name: 'LoanPro', href: 'loanpro', icon: ServerStackIcon, current: false },
    {
      name: 'Dialpad',
      href: 'dialpad',
      icon: DevicePhoneMobileIcon,
      current: false,
    },
  ]);

  useEffect(() => {
    const result = tabs.map(tab => {
      tab.current = false;
      if (pathname.includes(tab.href)) {
        tab.current = true;
      }
      return tab;
    });
    setTabs(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="border-r border-gray-200">
      <nav
        className="w-full h-full pt-8 pb-4 flex flex-col gap-2 px-4"
        aria-label="Tabs"
      >
        {tabs.map((tab, index) => (
          <Link
            key={index}
            to={`${tab.href}${search}`}
            className={`${
              tab.current
                ? 'bg-blue-kiwi/10 text-blue-kiwi'
                : 'border-transparent text-gray-600 hover:text-gray-700 hover:bg-gray-200'
            } group flex gap-2.5 items-center py-3 pl-10 pr-20 font-medium text-sm rounded transition-all duration-300 ease-in-out`}
          >
            <tab.icon
              className={`${
                tab.current
                  ? 'text-blue-kiwi'
                  : 'text-gray-600 group-hover:text-gray-500'
              } -ml-0.5 mr-2 h-5 w-5 flex-none transition-all duration-300 ease-in-out`}
              aria-hidden="true"
            />
            <p className="flex-none">{tab.name}</p>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default UserTabs;
