import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import Api from 'redux/api';
const EXPIRED_STATUS = 'expired';
const ERROR = {
  ACTIVE_LOAN: 'ACTIVE_LOAN',
  OFAC_MATCH: 'OFAC_VALIDATION',
};

const Expired = ({ application, onMove, onCreate }) => {
  const auth = useSelector(state => state.auth);
  const [selectOption, setSelectOption] = useState('');
  const [disabledStatus, setDisableStatus] = useState(false);
  const dispatch = useDispatch();

  const handleChange = event => {
    setSelectOption(event.target.value);
  };

  const handleWithdrawnRequest = async () => {
    try {
      setDisableStatus(true);
      const response = await Api.createLoanStatus({
        application_id: application.id,
        value: EXPIRED_STATUS,
        reason: selectOption,
        member_id: auth.id,
      });
      onCreate(response.data.status);
      onMove('list');
      setDisableStatus(false);
    } catch (error) {
      if (error.response.data.status === ERROR.ACTIVE_LOAN) {
        alert('User has active loan');
      }

      if (error?.response?.data?.status === ERROR.OFAC_MATCH) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'OFAC MATCH',
            message: 'Action not allowed',
          })
        );
      }
    } finally {
      setSelectOption('');
      onMove('list');
    }
  };

  return (
    <div className="mt-auto">
      <label className="block text-sm font-medium text-gray-700">Action</label>
      <select
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
        onChange={handleChange}
      >
        <option>Choose an option</option>
        <option value="withdrawn">Withdrawn</option>
        <option value="special_case">Special case</option>
      </select>
      {selectOption && (
        <div className="mt-auto px-4 py-3 text-right sm:px-6 bg-white">
          <button
            disabled={disabledStatus}
            onClick={handleWithdrawnRequest}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
          >
            Expired request
          </button>
        </div>
      )}
    </div>
  );
};

export default Expired;
