import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import Tooltip from 'components/UI/Tooltip';

export default (data = {}) => {
    let transaction_status = data?.plaid[data?.plaid?.length - 1]?.transaction_status ?? null

    if (transaction_status == null && data?.plaid_token) {
        return <CheckCircleIcon className="text-green-600 h-4 w-4" aria-hidden="true" />
    }

    if (transaction_status == false && data?.plaid_token) {
        return <Tooltip tooltip='Pending'>
            <ExclamationTriangleIcon className="text-yellow-400 h-4 w-4" aria-hidden="true" />
        </Tooltip>
    }

    return transaction_status ? <CheckCircleIcon className="text-green-600 h-4 w-4" aria-hidden="true" />
        : <XCircleIcon className="text-red-500 h-4 w-4" aria-hidden="true" />
}