import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function GeneralAlert({ description }) {
  return (
    <div className="w-full bg-amber-50 text-amber-600 p-5 rounded-lg text-sm flex items-center gap-5">
      <ExclamationTriangleIcon className="h-6 min-w-[1.5rem]" />
      <p>{description}</p>
    </div>
  );
}
