import { useEffect, useState } from 'react';
import axios from 'axios';

const PersonaPhotoLoader = ({ url, onClose = () => {} }) => {
  const [isLoading, setLoading] = useState(true);
  const [photo, setPhoto] = useState();

  const getPersonaPhoto = async () => {
    try {
      // Waiting fix in api
      // const { data } = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/persona-photo/${encodeURIComponent(
      //     url
      //   )}`,
      //   {
      //     responseType: 'blob',
      //   }
      // );
      const {data} = await axios.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer persona_production_12648c4c-f274-449c-a9de-7baac0d6fc49`,
        },
      });
      
      let reader = new window.FileReader();
      reader.readAsDataURL(data);
      reader.onload = function () {
        setPhoto(reader.result);
        setLoading(false);
      };
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (url) getPersonaPhoto();
  }, [url]);

  return (
    <div
      class="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div class="mt-3 text-center sm:mt-5">
              {isLoading ? (
                <div className="h-full w-full flex items-center justify-center text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-width="2"
                    >
                      <path
                        stroke-dasharray="2 4"
                        stroke-dashoffset="6"
                        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          dur="0.6s"
                          repeatCount="indefinite"
                          values="6;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="30"
                        stroke-dashoffset="30"
                        d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.1s"
                          dur="0.3s"
                          values="30;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="10"
                        stroke-dashoffset="10"
                        d="M12 8v7.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.5s"
                          dur="0.2s"
                          values="10;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="6"
                        stroke-dashoffset="6"
                        d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.7s"
                          dur="0.2s"
                          values="6;0"
                        />
                      </path>
                    </g>
                  </svg>
                </div>
              ) : (
                <img
                  src={photo}
                  alt=""
                  className="w-full h-96 object-contain "
                />
              )}
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                onClick={onClose}
                type="button"
                class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-kiwi focus:ring-offset-2 sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaPhotoLoader;
