import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Popover } from '@headlessui/react'

import Tooltip from "components/UI/Tooltip";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

export default ({ item,  setLoader, refresh } = {}) => {
    const dispatch = useDispatch();
    const dataAuth = useSelector(state => state.auth);
    const [comment, setComment] = useState()


    const update_status = async (item, status) => {
        try {
            setLoader(true)
            await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/files/status`,
                data: {
                    status,
                    comment: comment || "",
                    member_id: dataAuth.id || "",
                    files: item.files || [],
                }
            })

            setComment("")
            setLoader(false)
            refresh()
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Files: Error update status'
            }))
        }
    }

    return (
        <>
            <span className=" inline-flex rounded-md shadow-sm">
                <Tooltip tooltip="Approve">
                    <button
                        disabled={item.active != null ? true : false}
                        type="button"
                        className={"relative inline-flex items-center rounded-l-md border px-4 py-2 text-sm bg-gray-100" + (item.active != null ? '' : 'border-gray-300 bg-gray-50 font-medium text-gray-700 hover:bg-green-100')}
                        onClick={() => {
                            update_status(item, true)
                        }}
                    >
                        <CheckIcon className={"h-4 w-4 text-green-500"} />
                    </button>
                </Tooltip>

                <Tooltip tooltip="Decline">
                    <Popover>
                        {({ close }) => (<>
                            <Popover.Button
                                disabled={item.active != null ? true : false}
                                className={"relative -ml-px inline-flex items-center rounded-r-md border px-4 py-2 text-sm font-medium bg-gray-100" + (item.active != null ? '' : 'border-gray-300 bg-gray-50 text-gray-700 hover:bg-red-100')}
                            >
                                <XMarkIcon className="h-4 w-4 text-red-500" />
                            </Popover.Button>

                            <Popover.Panel className="absolute -translate-x-full flex flex-col items-center mb-2 group-hover:flex z-50">
                                <div className="overflow-hidden rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                                    <div className="bg-gray-50 p-2 w-72">
                                        <label htmlFor="about" className="block text-sm font-medium text-gray-700 text-left pb-1">
                                            Comment
                                        </label>
                                        <div className="mb-2">
                                            <textarea
                                                rows={2}
                                                name="comment"
                                                value={comment}
                                                onChange={(e) => { setComment(e.target.value) }}
                                                className="block w-full resize-none rounded-md border-gray-300 shadow-sm focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                                            />
                                        </div>
                                        <span className=" flex justify-end rounded-md shadow-sm text-right">
                                            <button
                                                type="button"
                                                className="outline outline-green-500 outline-1 inline-flex items-center px-2 py-1 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-green-500 hover:text-white"
                                                onClick={() => {
                                                    close();
                                                    update_status(item, false);
                                                }}
                                            >
                                                <CheckIcon className="h-5 w-5 text-green hover:text-white" aria-hidden="true" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </>)}
                    </Popover>
                </Tooltip>
            </span>
        </>
    )
}