import { useState, useEffect } from 'react';
import Api from 'redux/api';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

const get_status = status => {
  let data = {};

  if (status === 'funded') {
    data = { text: 'Funded', class: 'bg-green-100 text-green-800' };
  } else {
    data = { text: 'Pending', class: 'bg-yellow-100 text-yellow-800' };
  }

  return (
    <span
      className={
        'inline-flex rounded-full px-2 text-xs uppercase font-semibold leading-5 ' +
        data.class
      }
    >
      {data.text}
    </span>
  );
};

const UserLoansItem = ({ loan, viewDocuments, loader }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(loan);
  }, [loan]);

  const fund = async id => {
    setLoading(true);
    try {
      const { data } = await Api.fundLoan({
        loan_id: id,
      });
      setData(data.loan);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Funding failed',
        })
      );
    }
    setLoading(false);
  };

  return (
    <>
      {data && (
        <tr key={data.id}>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {data.loanpro_id}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            ${data.amount} USD
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {data.created_at}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-start">
            {data.type == 'mesalve' ? (
              <div className="mt-2 text-sm py-1 px-2 rounded text-red-700 bg-red-300">
                Me Salve
              </div>
            ) : (
              <div className="text-sm py-1 px-2 rounded text-blue-kiwi bg-blue-50-kiwi">
                Transferencia
              </div>
            )}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {data.type === 'credit_builder' ? (
              <CheckBadgeIcon
                className="text-green-500 h-6 w-6 mx-auto"
                aria-hidden="true"
              />
            ) : (
              <XCircleIcon
                className="text-red-500 h-6 w-6 mx-auto"
                aria-hidden="true"
              />
            )}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {get_status(data.status)}
          </td>
          <td
            onClick={() => viewDocuments(data.loanpro_id)}
            className="whitespace-nowrap px-3 py-4 text-sm text-blue-dark-kiwi underline cursor-pointer flex items-center gap-2"
          >
            View all
            {loader === true ? (
              <ArrowPathIcon className="text-blue-dark-kiwi h-4 w-4 animate-spin" />
            ) : null}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {loading && (
              <img src={require('../assets/loader.gif')} className="w-10" />
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default UserLoansItem;
