import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Loader from 'components/UI/Loader';

import { actions } from 'redux/reducer';

const incomeVerificationSource = [
  { value: '', text: 'N/A' },
  { value: 'fincity', text: 'Fincity' },
  { value: 'plaid', text: 'Plaid' },
  { value: 'bank statements', text: 'Bank Statements' },
  { value: 'paystubs', text: 'Paystubs' },
];

const incomeScheduleFrequencyValues = [
  { value: '', text: 'N/A' },
  { value: 'Monthly', text: 'Monthly' },
  { value: 'Bi-weekly', text: 'Bi-weekly' },
  { value: 'Weekly', text: 'Weekly' },
];

const paymentDatesValues = [
  { value: '', text: 'N/A' },
  { value: '1st and 15th', text: '1st and 15th' },
  { value: '15th and 30th', text: '15th and 30th' },
  { value: 'Once a month', text: 'Once a month' },
  { value: '3rd Wednesday of the month', text: '3rd Wednesday of the month' },
  {
    value: '2nd and 4th Wednesday of the month',
    text: '2nd and 4th Wednesday of the month',
  },
  {
    value: '2nd and 4th Friday of the month',
    text: '2nd and 4th Friday of the month',
  },
  { value: 'Every Friday', text: 'Every Friday' },
  { value: 'Inconsistent', text: 'Inconsistent' },
];

const accountTypes = [
  { value: '', text: 'N/A' },
  { value: 'Savings', text: 'Savings' },
  { value: 'Checking', text: 'Checking' },
];
const joinRange = inputArray => {
  try {
    const sortedArray = inputArray.sort((a, b) => a - b);
    let result = [];
    let start = sortedArray[0];
    let end = sortedArray[0];

    for (let i = 1; i < sortedArray.length; i++) {
      if (sortedArray[i] === end + 1) {
        end = sortedArray[i];
      } else {
        if (start !== end) {
          result.push(`${start}-${end}`); // Paso 3: Agregar rango al resultado
        } else {
          result.push(start.toString()); // Paso 4: Agregar número al resultado
        }
        start = sortedArray[i];
        end = sortedArray[i];
      }
    }

    if (start !== end) {
      result.push(`${start}-${end}`); // Manejo del último rango
    } else {
      result.push(start.toString());
    }

    return result.join(','); // Paso 5: Unir los elementos con comas
  } catch (error) {
    return '';
  }
};

const expandRanks = inputString => {
  try {
    const elements = inputString.split(',');
    let result = [];

    for (let element of elements) {
      if (element.includes('-')) {
        const [start, end] = element.split('-').map(Number);
        for (let num = start; num <= end; num++) {
          result.push(num);
        }
      } else {
        result.push(Number(element));
      }
    }
    return result;
  } catch (error) {
    return [];
  }
};

const UWDetail = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [loader, setLoader] = useState(false);

  const [uwDetail, setUwDetail] = useState({
    customer_employer: '',
    monthly_income: 0,
    nsf_count: 0,
    income_verification_source: '',
    income_schedule_frequency: '',
    payment_dates: '',
    current_balance: 0,
    second_balance: 0,
    special_comments: '',
    support_account_type: '',
    support_account_number: '',
    support_routing_number: '',
    default_payment_dates: '',
  });

  const [errors, setError] = useState({
    customer_employer: false,
    monthly_income: false,
    nsf_count: false,
    income_schedule_frequency: false,
    income_verification_source: false,
    payment_dates: false,
    support_account_number: false,
  });

  const handleUwDetail = e => {
    const { name, value } = e.target;

    setUwDetail(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleError = ({ name, value = false }) => {
    setError(prevData => ({
      ...prevData,
      [name]: value ? true : false,
    }));
  };

  useEffect(() => {
    if (user) {
      getUwDetail();
    }
  }, [user]);

  const getUwDetail = async () => {
    try {
      setLoader(true);
      const uw_details = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/users/${user.id}/uw-detail`,
        data: {
          user_id: user.id,
        },
      });

      setLoader(false);
      if (uw_details.data.data)
        setUwDetail({
          ...uw_details.data.data,
          default_payment_dates: joinRange(
            uw_details.data.data.default_payment_dates
          ),
        });
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error loading UW Details',
        })
      );
    }
  };

  const updateUwDetails = async () => {
    try {
      setLoader(true);

      const {
        customer_employer,
        monthly_income,
        nsf_count,
        income_schedule_frequency,
        income_verification_source,
        payment_dates,
        current_balance,
        second_balance,
        special_comments,
        support_account_type,
        support_account_number,
        support_routing_number,
        default_payment_dates,
      } = uwDetail;

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/users/${user.id}/uw-detail`,
        data: {
          customer_employer,
          monthly_income: parseFloat(monthly_income),
          nsf_count: parseFloat(nsf_count),
          income_schedule_frequency,
          income_verification_source,
          payment_dates,
          current_balance: parseFloat(current_balance),
          second_balance: parseFloat(second_balance),
          special_comments,
          support_agent: user?.auth?.email || '',
          support_account_type,
          support_account_number,
          support_routing_number,
          user_id: user.id,
          default_payment_dates: String(expandRanks(default_payment_dates)),
        },
      });

      setLoader(false);
      getUwDetail();
      dispatch(
        actions.setMessage({
          type: 'success',
          title: 'Success',
          message: 'Updated successfully',
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error saving',
        })
      );
    }
  };

  return (
    <div className="bg-white divide-y divide-gray-200 pb-10 px-6 lg:px-8">
      <div className="pt-8">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Uw Details
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              <span className="font-medium text-gray-900">Support Agent: </span>
              {user?.auth?.email || ''}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-6 gap-x-4 py-3 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              Customer employer
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={uwDetail.customer_employer}
                onChange={e => {
                  handleUwDetail(e);
                  handleError({ name: e.target.name });
                }}
                name="customer_employer"
                className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {errors.customer_employer && (
                <p className="text-red-500 text-sm mt-2">
                  Customer employer is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Payment dates
            </label>

            <div className="mt-1">
              <select
                value={uwDetail.payment_dates}
                onChange={e => {
                  handleUwDetail(e);
                  handleError({ name: e.target.name });
                }}
                name="payment_dates"
                autoComplete="payment_dates"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
              >
                {paymentDatesValues &&
                  paymentDatesValues.map(item => (
                    <option key={item.value} value={item.value}>
                      {' '}
                      {`${item.text}`}
                    </option>
                  ))}
              </select>

              {errors.payment_dates && (
                <p className="text-red-500 text-sm mt-2">
                  Payment dates is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Nsf count
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={uwDetail.nsf_count}
                onChange={e => {
                  handleUwDetail(e);
                  handleError({ name: e.target.name });
                }}
                name="nsf_count"
                className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {errors.nsf_count && (
                <p className="text-red-500 text-sm mt-2">
                  Nsf count is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Default payment dates
            </label>
            <div className="mt-1">
              {user.state !== 'Florida' ? (
                <input
                  type="text"
                  value={uwDetail.default_payment_dates}
                  onChange={e => {
                    handleUwDetail(e);
                  }}
                  placeholder="Example: 1-12, 22, 25, 28-30"
                  name="default_payment_dates"
                  className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                />
              ) : (
                <input
                  type="text"
                  placeholder="No available"
                  disabled="true"
                  name="default_payment_dates"
                  className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
                />
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Income verification source
            </label>

            <div className="mt-1">
              <select
                value={uwDetail.income_verification_source}
                onChange={e => {
                  handleUwDetail(e);
                  handleError({ name: e.target.name });
                }}
                name="income_verification_source"
                autoComplete="income_verification_source"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
              >
                {incomeVerificationSource &&
                  incomeVerificationSource.map(item => (
                    <option key={item.value} value={item.value}>
                      {' '}
                      {`${item.text}`}
                    </option>
                  ))}
              </select>
              {errors.income_verification_source && (
                <p className="text-red-500 text-sm mt-2">
                  Income schedule frequency is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Monthly income
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={uwDetail.monthly_income}
                onChange={e => {
                  handleUwDetail(e);
                  handleError({ name: e.target.name });
                }}
                name="monthly_income"
                className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {errors.monthly_income && (
                <p className="text-red-500 text-sm mt-2">
                  Monthly income is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Income schedule frequency
            </label>

            <div className="mt-1">
              <select
                value={uwDetail.income_schedule_frequency}
                onChange={e => {
                  handleUwDetail(e);
                  handleError({ name: e.target.name });
                }}
                name="income_schedule_frequency"
                autoComplete="income_schedule_frequency"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
              >
                {incomeScheduleFrequencyValues &&
                  incomeScheduleFrequencyValues.map(item => (
                    <option key={item.value} value={item.value}>
                      {' '}
                      {`${item.text}`}
                    </option>
                  ))}
              </select>
              {errors.income_schedule_frequency && (
                <p className="text-red-500 text-sm mt-2">
                  Income schedule frequency is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Min current balance month
            </label>

            <div className="mt-1">
              <input
                type="text"
                value={uwDetail.current_balance}
                onChange={handleUwDetail}
                name="current_balance"
                className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Min second balance month
            </label>

            <div className="mt-1">
              <input
                type="text"
                value={uwDetail.second_balance}
                onChange={handleUwDetail}
                name="second_balance"
                className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-6 w-5/5">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Special Comments
            </label>

            <div className="mt-1">
              <textarea
                style={{ resize: 'none' }}
                rows="3"
                className="shadow-sm block w-full focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm border border-gray-300 rounded-md"
                value={uwDetail.special_comments}
                onChange={handleUwDetail}
                name="special_comments"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center py-3">
          <div>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              <span className="font-medium text-gray-900">Support: </span>
              Payment profile
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-6 gap-x-4 py-3 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Account type
            </label>

            <select
              value={uwDetail.support_account_type}
              onChange={handleUwDetail}
              name="support_account_type"
              autoComplete="support_account_type"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
            >
              {accountTypes &&
                accountTypes.map(item => (
                  <option key={item.value} value={item.value}>
                    {' '}
                    {`${item.text}`}
                  </option>
                ))}
            </select>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Account number
            </label>

            <div className="mt-1">
              <input
                type="number"
                value={uwDetail.support_account_number}
                onChange={handleUwDetail}
                name="support_account_number"
                required
                className="custom-number-input shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {errors.support_account_number && (
                <p className="text-red-500 text-sm mt-2">
                  Account number is required.
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Routing number
            </label>

            <div className="mt-1">
              <input
                type="text"
                value={uwDetail.support_routing_number}
                onChange={handleUwDetail}
                name="support_routing_number"
                className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>

        <div className="w-full h-16 bg-white sticky left-0 bottom-0 flex items-center">
          <div className="w-full max-w-6xl mx-auto flex justify-end">
            <button
              type="button"
              onClick={updateUwDetails}
              className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>

      <Loader status={loader}></Loader>
    </div>
  );
};

export default UWDetail;
