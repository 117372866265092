import Loader from 'components/UI/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from 'redux/api';
import { actions } from 'redux/reducer';

const UserModels = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [loader, setLoader] = useState(false);
  const [hudsonData, setHudsonData] = useState();

  useEffect(() => {
    getHudsonDataByUser(user.id);
  }, [user]);

  const getHudsonDataByUser = async () => {
    try {
      setLoader(true);

      const response = await api.getHudsonUserData({
        user_id: user.id,
      });
      setHudsonData(response.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error get user data',
        })
      );
    }
  };

  return (
    <div className="p-8">
      <Loader status={loader}></Loader>

      <div className="max-w-screen-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
        <div className="flex items-center justify-between p-8">
          <div>
            <h2 className="text-xl font-semibold">Kiwi Score</h2>
            <p className="text-gray-700">
              Modelado avanzado que evalúa usuarios y otorga puntuación.
            </p>
          </div>

          {!loader && (
            <div className="flex items-center">
              {hudsonData?.post_process_pdv1_output ? (
                <div>
                  <div className="w-24 h-24 bg-green-500 rounded-full flex items-center justify-center text-white font-bold mr-4">
                    <h1 className="text-xl font-semibold">
                      {hudsonData.post_process_pdv1_output}
                    </h1>
                  </div>
                  <p>Credit risk</p>
                </div>
              ) : (
                <div className="w-24 h-24 bg-gray-500 rounded-full flex items-center justify-center text-white font-bold mr-4">
                  <h1 className="text-xl font-semibold">N/R</h1>
                </div>
              )}

              {hudsonData?.post_process_pdv1_output ? (
                <div>
                  <div className="w-24 h-24 bg-green-500 rounded-full flex items-center justify-center text-white font-bold mr-4">
                    <h1 className="text-xl font-semibold">
                      {hudsonData.post_process_rv1_output}
                    </h1>
                  </div>
                  <p>Pricing model</p>
                </div>
              ) : (
                <div className="w-24 h-24 bg-gray-500 rounded-full flex items-center justify-center text-white font-bold mr-4">
                  <h1 className="text-xl font-semibold">N/R</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserModels;
