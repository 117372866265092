import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import HeaderStore from 'components/BNPLStores/HeaderStore';
import UsersStore from 'pages/Dashboard/BNPLStore/UsersStore';
import axios from "axios";

export default function BNPLStores() {
    const { store_id } = useParams();
    const [store, setStore] = useState({})

    useEffect(() => {
        if (store_id) getStore()
    }, [])

    const getStore = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/bnpl_store/${store_id}`,
            })

            setStore(response.data.data || {})
        } catch (error) {
            console.log("[Error get store]")
        }
    }

    return (
        <>
            <div className="max-w-7xl mx-auto">
                <div className="h-dashboard py-4 px-4 sm:px-6 lg:px-8">
                    <HeaderStore data={store} />
                    <UsersStore />
                </div>
            </div>
        </>
    )
}