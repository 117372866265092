import Item from 'components/Applications/Item'
import React from 'react'

const ListApplication = ({ applicationsIncresaeLimit }) => {

  return applicationsIncresaeLimit && (
    <div className="h-dashboard bg-gray-100 overflow-auto px-4 sm:px-6 lg:px-8 pt-8">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">{applicationsIncresaeLimit.length} Applications</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all loans with including the user, balance, payload and status.
          </p>
        </div>
      </div>
      <div>
        <div className="w-full rounded-lg overflow-hidden mb-6 border-b border-gray-300">
          {applicationsIncresaeLimit && applicationsIncresaeLimit.map((loan) => (
            <Item data={loan} key={loan.id} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListApplication