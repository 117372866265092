import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
  return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const UserServices = {
    deleteUser: async (user_id, member_id, comment, reason) => {
        return await axios({
            headers: HEADERS(),
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/users/soft-delete`,
            data: {
              user_id,
              member_id,
              comment,
              reason
            }
        });
    },
    getAlgoliaInformation: async (user_id) => {
      return await axios({
          headers: HEADERS(),
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/users/${user_id}/algolia`,
      });
    },
    getCustomerIoInformation: async (user_id) => {
      return await axios({
          headers: HEADERS(),
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/users/${user_id}/customer-io`,
      });
    },
    getMixpanleInformation: async (user_id) => {
      return await axios({
          headers: HEADERS(),
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/users/${user_id}/mixpanel`,
      });
    },
    getTrengoInformation: async (user_id) => {
      return await axios({
          headers: HEADERS(),
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/users/${user_id}/trengo`,
      });
    },
    updateDbUserInformation: async(user_id, data) => {
      return await axios({
        headers: HEADERS(),
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/user/db/${user_id}`,
        data
      });
    },
    updateCustomerIoUserInformation: async(user_id, data) => {
      return await axios({
        headers: HEADERS(),
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/user/customer-io/${user_id}`,
        data
      });
    },
    updateAlgoliaUserInformation: async(user_id, data) => {
      return await axios({
        headers: HEADERS(),
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/user/algolia/${user_id}`,
        data
      });
    },
    updateMixpanelUserInformation: async(user_id, data) => {
      return await axios({
        headers: HEADERS(),
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/user/mixpanel/${user_id}`,
        data
      });
    },
    updateTrengoUserInformation: async(user_id, data) => {
      return await axios({
        headers: HEADERS(),
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/user/trengo/${user_id}`,
        data
      });
    },
    updateLoanproUserInformation: async(user_id, data) => {
      return await axios({
        headers: HEADERS(),
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/user/loanpro/${user_id}`,
        data
      });
    },
    activeSoftDeletedUser: async (user_id) => {
      return await axios({
          headers: HEADERS(),
          method: 'PUT',
          url: `${process.env.REACT_APP_API_URL}/user/${user_id}/soft-active`
      });
  },
}