import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline'
import ReactJsonView from 'react-json-view'

export default function example({ showModal = false, bankData, closeModal } = {}) {

    return (
        <>
            {
                showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 mx-auto max-w-5xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                    {/*header*/}
                                    <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                        <AdjustmentsVerticalIcon className="mt-1.5 mr-1 h-5 w-5" aria-hidden="true" />

                                        <h3 className="text-2xl font-semibold">
                                            View data Json
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={closeModal}
                                        >
                                            <span className="text-black-900 font-semibold h-6 w-6 block">
                                                ×
                                            </span>
                                        </button>
                                    </div>

                                    {/*body*/}
                                    <div className="relative  flex-auto">
                                        <div className="mt-10 sm:mt-0">

                                            <div className="mt-5 md:mt-0 md:col-span-2">

                                                <div className="px-4 py-4 bg-white ">
                                                    <div className="grid grid-cols-12 gap-3">

                                                        <div className="col-span-12 sm:col-span-12">


                                                            <ReactJsonView
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginBottom: 8,
                                                                    flex: 1,
                                                                    minHeight: 500,
                                                                    maxHeight: 500,
                                                                    overflow: 'auto',
                                                                }}
                                                                src={bankData}
                                                                indentWidth={2}
                                                                displayDataTypes={false}
                                                                displayObjectSize={false}
                                                                collapsed={2}
                                                                enableClipboard={false}
                                                                theme="brewer"
                                                            ></ReactJsonView>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md border border-transparent bg-red-100 px-4 py-3 text-sm font-medium leading-4 text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
        </>
    )
}