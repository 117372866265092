import { useEffect, useState } from "react";
import { Bars4Icon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import Approved from "./Approved";
import Rejected from "./Rejected";
import Pending from "./Pending";
import SetScore from "./SetScore";
import Promote from "./Promote";
import Api from "redux/api";
import Help from "components/UI/Help";
import ExpiredStatus from "./Expired";
import ApplyIncreaseLimit from "./ApplyIncreaseLimit";

const status_options = {
  set_score: "set_score",
  approved: "approved",
  offer_improvement: "offer_improvement",
  rejected: "rejected",
  pending: "pending",
  apply_increase_limit: "apply_increase_limit",
  expired: "expired",
  promote: "promote",
};

const OFAC_MATCH = "OFAC_VALIDATION";

const status_list_options = [
  {
    label: "Set Score",
    value: "set_score",
  },
  {
    label: "Need Bank Statements",
    value: "bank_statements",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Promote",
    value: "promote",
  },
  {
    label: "Verification",
    value: "verification",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Black List",
    value: "black_list",
  },
  {
    label: "Incomplete",
    value: "incomplete",
  },
  {
    label: "Expired",
    value: "expired",
  },
  {
    label: "Apply increase limit",
    value: "apply_increase_limit",
  },
];

function CreateStatus({ application, onMove, onCreate }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [status_list, setStatusList] = useState(null);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStatusList(status_list_options);
  }, [application]);

  const showStatusListPanel = () => {
    onMove("list");
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const create = async () => {
    try {
      setLoading(true);
      const response = await Api.createLoanStatus({
        application_id: application.id,
        value: status,
        member_id: auth.id,
      });
      if (status === "black_list") {
        await Api.updateUser({ ...user, black_list: true });
      }
      onMove("list");
      onCreate(response.data.status);
    } catch (error) {
      if (error?.response?.data?.status === OFAC_MATCH) {
        dispatch(
          actions.setMessage({
            type: "wrong",
            title: "OFAC MATCH",
            message: "Action not allowed",
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full flex flex-col overflow-auto border-t">
      <div className="w-full flex justify-between items-center p-4 bg-gray-100 border-b">
        <p>Create Status</p>
        <button
          onClick={showStatusListPanel}
          type="button"
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
        >
          <Bars4Icon className="w-4 h-4" />
        </button>
      </div>

      <div className="grid gap-4 px-4 py-5">
        <div className="w-full mt-auto border-b pb-5">
          <Help message={"Choose the type of state to create"}>
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
          </Help>

          <select
            onChange={handleStatus}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
          >
            <option>Choose an option</option>
            {status_list &&
              status_list.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
          </select>
        </div>
        {status === status_options.set_score && (
          <SetScore
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}
        {status === status_options.approved && (
          <Approved
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}
        {status === status_options.promote && (
          <Promote
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}
        {status === status_options.apply_increase_limit && (
          <ApplyIncreaseLimit
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}
        {status === status_options.rejected && (
          <Rejected
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}
        {status === status_options.pending && (
          <Pending
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}

        {status === status_options.expired && (
          <ExpiredStatus
            application={application}
            onMove={onMove}
            onCreate={onCreate}
          />
        )}
        {[
          "bank_statements",
          "black_list",
          "verification",
          "incomplete",
        ].includes(status) && (
          <div className="mt-auto px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              onClick={create}
              className={`${
                loading ? " disabled:opacity-75 " : ""
              } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi`}
              disabled={loading}
            >
              Create Status
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateStatus;
