import React, { useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { AdjustmentsVerticalIcon, ClipboardIcon } from "@heroicons/react/24/outline";

import Pagination from "components/UI/Pagination";
import Tooltip from "components/UI/Tooltip";
import Loader from "components/UI/Loader";
import { format } from 'date-fns';

import ModalViewDataJson from "components/LogsFinicity/ModalViewDataJson"

import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

export default () => {
    const dispatch = useDispatch();
    const [distintcCodes, setDistintcCodes] = useState();
    const [logsFinicity, setLogsFinicity] = useState([])
    const [toasCopy, setToastCopy] = useState(false)
    const [typeCode, setTypeCode] = useState();
    const [count, setCount] = useState(0);

    const [search, setSearch] = useState()
    const [order, setOrder] = useState();
    const [modalJson, setModalJson] = useState({
        state: false,
        data: {}
    })

    const [loader, setLoader] = useState(false)

    const setHandlers = {
        search: setSearch,
        order: setOrder,
        typeCode: setTypeCode
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }

    useEffect(() => {
        get_logs()
    }, [order, typeCode])

    useEffect(() => {
        const delay = setTimeout(() => get_logs(), 500)

        return () => clearTimeout(delay)
    }, [search])


    const get_logs = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)
            setDistintcCodes([])
            setLogsFinicity([])

            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/finicity/logs`,
                params: {
                    take,
                    skip,
                    search,
                    order,
                    typeCode
                }
            })

            let distinct = response.data.data.distinct.sort((a, b) => a.code_finicity - b.code_finicity)

            setLoader(false)
            setCount(response.data.data?.count?._count || 0)
            setLogsFinicity(response.data.data.logs || [])
            setDistintcCodes(distinct || [])
        } catch (error) {
            console.log("Error get logs: ", error)
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get logs'
            }))
        }
    }

    const copyText = (event) => {
        const { id } = event.target

        if (id) {
            navigator.clipboard.writeText(id);
            setToastCopy(true)

            setTimeout(() => {
                setToastCopy(false)
            }, 1500);
        }
    }


    return (
        <>
            <div className="max-w-7xl mx-auto">
                <div className="py-8 px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center pb-3 border-b">

                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Logs finicity</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                An error log list including response code, the error response and error code.
                            </p>
                        </div>
                    </div>

                    <br />

                    <div className="sm:flex sm:items-center my-5">
                        <div className="sm:flex-auto mr-4 mr-4">
                            <div className="col-span-2">
                                <div>
                                    <label htmlFor="referrer" className="block text-sm font-medium text-gray-700">
                                        Search log
                                    </label>

                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <div className="relative flex items-stretch flex-grow">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                                            </div>

                                            <input type="text"
                                                className="pl-10 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                name="search"
                                                onChange={handleChange}
                                                placeholder="User id" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="sm:flex-auto mr-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Code finicity filter
                                </label>

                                <select
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                    onChange={handleChange}
                                    value={typeCode}
                                    name="typeCode"
                                >
                                    <option value={""}>All the types</option>

                                    {distintcCodes && distintcCodes.map((item, index) => (

                                        <option value={item.code_finicity} key={item.code_finicity}>{item.code_finicity}</option>

                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="sm:flex-auto">
                            <div>
                                <label htmlFor="orderByDateAt" className="block text-sm font-medium text-gray-700">
                                    Order by created At
                                </label>
                                <select
                                    id="orderByDateAt"
                                    name="order"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                    onChange={handleChange}
                                    value={order}
                                >
                                    <option value="desc">Latest</option>
                                    <option value="asc">Oldest</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                                        <Pagination count={count} onTake={get_logs} />
                                    </div>
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    User
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Status code
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Code finicity
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Message
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Created At
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <span className="sr-only">Actions</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {logsFinicity && logsFinicity.map((item) => (<>
                                                <tr key={item.id}>


                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 text-sm text-gray-500">
                                                        <div className="text-gray-900 font-semibold">{`${item?.user?.first_name ?? ''} ${item?.user?.first_surname ?? item?.user?.last_name ?? ''} ${item?.user?.second_surname ?? ''}`}</div>
                                                        <div className="text-gray-500 flex align-middle">
                                                            <span className="text-gray-600 font-semibold mr-1"> Postgres: </span>
                                                            {item.user_id}

                                                            <Tooltip tooltip="Copy id Postgres">
                                                                <ClipboardIcon id={item.user_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-kiwi-400" />
                                                            </Tooltip>
                                                        </div>
                                                        <div className="text-gray-500 flex align-middle">
                                                            <span className="text-gray-600 font-semibold mr-1"> Firebase: </span>
                                                            {item?.user?.firebase_id}
                                                            <Tooltip tooltip="Copy id Firebase">
                                                                <ClipboardIcon id={item?.user?.firebase_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-kiwi-400" />
                                                            </Tooltip>
                                                        </div>
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                                        <span className="mr-7 inline-flex rounded-full bg-yellow-100 px-2 text-xs uppercase font-semibold leading-5 text-yellow-800">
                                                            {item.status_code}
                                                        </span>
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                                        <span className="mr-7 inline-flex rounded-full bg-yellow-100 px-2 text-xs uppercase font-semibold leading-5 text-yellow-800">
                                                            {item.code_finicity}
                                                        </span>
                                                    </td>

                                                    <td className="px-3 py-4 text-sm text-gray-500">
                                                        {item.response?.message || ""}
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {
                                                            format(new Date(item.created_at), 'MMMM dd, yyyy - p')
                                                        }
                                                    </td>

                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <Tooltip tooltip={"View data Json"}>
                                                            <button onClick={() => {
                                                                setModalJson({
                                                                    state: true,
                                                                    data: item
                                                                })
                                                            }}>
                                                                <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-400 xs:h-9 md:w-9" aria-hidden="true" />
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </>))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Loader status={loader} ></Loader>

                    <ModalViewDataJson
                        showModal={modalJson.state}
                        data={modalJson.data}
                        closeModal={() => {
                            setModalJson({
                                state: false,
                                data: {}
                            })
                        }}
                    >
                    </ModalViewDataJson>

                    {toasCopy ? <div id={Math.random()} aria-live="assertive"
                        className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                    >
                        <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-center">
                            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                                <div className="relative flex flex-col items-center group">

                                    <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">Id copied successfully</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> : ''}


                </div>
            </div>
        </>
    )
}