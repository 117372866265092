import axios from "axios";
import { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

import TableWaitingList from 'components/BNPLStores/TableWaitingList'
import Loader from "components/UI/Loader";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

export default function WaitingList() {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)

    const [search, setSearch] = useState()
    const [result, setResult] = useState(0)
    const [waitingList, setWaitingList] = useState()

    const setHandlers = {
        search: setSearch
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }

    useEffect(() => {
        const delay = setTimeout(() => {
            getWaitingList()
        }, 500)

        return () => clearTimeout(delay)
    }, [search])

    useEffect(() => {
        getWaitingList()
    }, [])

    const getWaitingList = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)
            setWaitingList([])
            setResult([])

            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/bnpl_store/waiting_list`,
                params: {
                    take,
                    skip,
                    search,
                }
            })

            setLoader(false)
            setWaitingList(response.data.data.waiting_list);
            setResult(response.data.data.count)
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get waiting list'
            }))
        }
    }

    return (
        <>
            <div className="max-w-7xl mx-auto">
                <div className="h-dashboard py-4 px-4 sm:px-6 lg:px-8">

                    <div className="divide-gray-200">
                        <div className="h-dashboard py-8">
                            <div className="sm:flex sm:items-center mb-4">
                                <div className="sm:flex-auto">
                                    <h1 className="text-xl font-semibold text-gray-900">BNPL store - Waiting list</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        A list of all waiting stores including their name, email and phone.
                                    </p>
                                </div>
                            </div>

                            <div className="mb-6">
                                <label className="block text-sm font-medium text-gray-700">Search store</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <div className="relative flex items-stretch flex-grow">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                                        </div>
                                        <input
                                            type="text"
                                            name="search"
                                            className="focus:ring-blue-kiwi focus:border-blue-kiwi block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                                            placeholder="Name, Email, Phone"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button type="button" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-kiwi focus:border-blue-kiwi">
                                        <span>Search</span>
                                    </button>
                                </div>
                            </div>

                            <div className="mt-8 flex flex-col">
                                <TableWaitingList dataWaitingList={waitingList} count={result} onTake={getWaitingList} />
                            </div>

                        </div>
                    </div>
                </div>
                <Loader status={loader}></Loader>
            </div>

        </>
    )
}