const EMPTY_STRING = 0;
const EMPTY_VALUE = " "
export const replaceUnderScores = (string) => {
    if(!string || string?.length === EMPTY_STRING){
        return EMPTY_VALUE;
    }

    return string.replace("_",EMPTY_VALUE);
}

export const capitalizeString = (string) => {
    if(!string || string?.length === EMPTY_STRING){
        return EMPTY_VALUE;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);    
}