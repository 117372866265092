import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "redux/reducer";

import Health from "pages/Health";

import Auth from "./pages/Auth";
import Logout from "pages/Logout";
import Dashboard from "./pages/Dashboard";

import Home from "./pages/Dashboard/Home";
import Applications from "pages/Dashboard/Applications";
import NonApplications from "pages/Dashboard/Applications/Non";
import States from "pages/Dashboard/Applications/States";

import Loans from "./pages/Dashboard/Loans";

import CreditReportDetails from "./pages/Dashboard/User/CreditReport/Details";
import CreditResportList from "./pages/Dashboard/User/CreditReport/History";
import Referrals from "./pages/Dashboard/Referrals/Referrals";
import AdminUsers from "./pages/Dashboard/AdminUsers";

import Users from "./pages/Dashboard/Users";
import User from "./pages/Dashboard/User/Index";
import UserPersona from "pages/Dashboard/User/Persona";
import UserPersonal from "./pages/Dashboard/User/Personal";
import Files from "pages/Dashboard/Files";
import TabFiles from "pages/Dashboard/User/Files";
import UserLoanpro from "./pages/Dashboard/User/LoanPro";
import UserLoans from "./pages/Dashboard/User/Loans";
import UserDialpad from "./pages/Dashboard/User/Dialpad";
import UserIntegrationData from "./pages/Dashboard/User/IntegrationData";
import LogsFinicity from "./pages/Dashboard/LogsFinicity";
import IpUser from "./pages/Dashboard/IpUser";
import UserVerifications from "pages/Dashboard/User/Verifications/Index";

import BNPLStores from "./pages/Dashboard/BNPLStores";
import BNPLStore from "./pages/Dashboard/BNPLStore/Index";
import BNPLStoreWaitingList from "./pages/Dashboard/BNPLStore/WaitingList";

import FloridaWaitingList from "./pages/Dashboard/WaitingListFlorida";

import TestingNumbers from "pages/Dashboard/TestingNumbers";
import UserLeads from "pages/Dashboard/UserLeads";
import UsersOldApp from "pages/Dashboard/UsersOldApp";
import UserBankDataHistory from "pages/Dashboard/User/BankData/History";
import UserBankDataDetails from "pages/Dashboard/User/BankData/Details";
import ATHPayments from "pages/Dashboard/ATHPayments";
import ChangeDueDate from "pages/Dashboard/ChangeDueDate";
import RenewalRequests from "pages/Dashboard/RenewalRequests/index";

import Testing from "./pages/Testing";
import Contract from "./pages/Contract";

import UWDetails from "pages/Dashboard/User/UWDetails";
import Notification from "./components/UI/Notifications";

import IncreaseLimitRequests from "pages/Dashboard/IncreaseLimitRequests";
import UserModels from "pages/Dashboard/User/Models/Index";
import HudsonData from "pages/Dashboard/User/Models/HudsonData";
import PrincingModel from "pages/Dashboard/User/Models/PricingModel";
import HistoryDTI from "pages/Dashboard/User/Models/HistoryDTI";
import Verifications from "pages/Dashboard/Verifications/Index";
import AuthOkta from "pages/AuthOkta";
import DPDCampaigns from "pages/Dashboard/DPDCampaigns";

import "./App.css";

function App() {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState({ profile: "admin" });
  const dataAuth = useSelector((state) => state.auth);
  const stateMessage = useSelector((state) => state.message);

  const [showNotification, setShowNotification] = useState(false);
  const [paramsNotification, setParamsNotification] = useState({
    type: "",
    title: "",
    message: "",
  });

  const handleChangeNotification = (data) => {
    let { type, title, message } = data;
    setParamsNotification({ type, title, message });
    setShowNotification(true);
  };

  useEffect(() => {
    dispatch(actions.getAuthUser());
  }, []);

  useEffect(() => {
    if (dataAuth) setAuth(dataAuth);
  }, [dataAuth]);

  useEffect(() => {
    if (stateMessage) {
      let error = structuredClone(stateMessage);

      handleChangeNotification({
        type: error?.type || "wrong",
        title: error?.title || "Error",
        message: error?.message || "",
      });

      dispatch(actions.setMessage(null));
    }
  }, [stateMessage]);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="health" element={<Health />} />
          <Route path="contract/:loan_id" element={<Contract />} />
          <Route path="auth" element={<Auth />} />
          <Route path="oauth/callback" element={<AuthOkta />} />
          <Route path="oauth/logout" element={<Logout />} />
          <Route path="logout" element={<Logout />} />
          <Route path="/" element={<Dashboard />}>
            <Route index element={<Home />} />
            <Route path="testing" element={<Testing />} />
            <Route path="verifications" element={<Verifications />} />
            <Route path="verifications/:value" element={<Verifications />} />
            <Route path="applications" element={<States />} />
            <Route path="applications/:state" element={<Applications />} />
            <Route
              path="applications/:state/:status"
              element={<Applications />}
            />
            <Route
              path="applications/registered"
              element={<NonApplications />}
            />
            <Route path="loans/:status" element={<Loans />} />
            <Route path="users" element={<Users />} />
            <Route path="users/:user_id" element={<User />}>
              <Route index element={<UserPersonal />} />
              <Route path="personal" element={<UserPersonal />} />
              <Route path="persona-status" element={<UserPersona />} />
              <Route path="credit-reports" element={<CreditResportList />} />
              <Route
                path="credit-reports/:report_id"
                element={<CreditReportDetails />}
              />
              <Route path="bank-data" element={<UserBankDataHistory />} />
              <Route
                path="bank-data/:account_id"
                element={<UserBankDataDetails />}
              />
              <Route path="uw-details" element={<UWDetails />} />
              <Route path="detail-files" element={<TabFiles />} />
              <Route path="loanpro" element={<UserLoanpro />} />
              <Route path="verifications" element={<UserVerifications />} />
              <Route path="loans" element={<UserLoans />} />
              <Route path="dialpad" element={<UserDialpad />} />
              <Route path="models" element={<UserModels />} />
              <Route path="models/hudson-data" element={<HudsonData />} />
              <Route path="models/pricing" element={<PrincingModel />} />
              <Route path="models/history-dti" element={<HistoryDTI />} />
              <Route
                path="integration-data"
                element={<UserIntegrationData />}
              />
            </Route>
            <Route path="files" element={<Files />} />
            <Route path="logs-finicity" element={<LogsFinicity />} />
            <Route path="ip-user" element={<IpUser />} />
            <Route path="referrals/:status" element={<Referrals />} />
            <Route path="bnpl-stores" element={<BNPLStores />} />
            <Route path="testing-numbers" element={<TestingNumbers />} />
            <Route path="user-leads" element={<UserLeads />} />
            <Route path="users-old-app" element={<UsersOldApp />} />
            <Route path="waiting" element={<FloridaWaitingList />} />
            <Route
              path="waiting-list-bnpl"
              element={<BNPLStoreWaitingList />}
            />
            <Route
              path="waiting-list-florida"
              element={<FloridaWaitingList />}
            />
            <Route path="bnpl-stores/:store_id" element={<BNPLStore />} />
            <Route path="ath-payments" element={<ATHPayments />} />
            <Route path="chage-due-dates" element={<ChangeDueDate />} />
            <Route path="renewal-requests" element={<RenewalRequests />} />
            <Route path="dpd-campaigns" element={<DPDCampaigns />} />


            <Route
              path="admin-users"
              element={
                auth?.profile === "admin" ? <AdminUsers /> : <Navigate to="/" />
              }
            />

            <Route
              path="increase-limit-requests"
              element={<IncreaseLimitRequests />}
            />
          </Route>
        </Routes>

        <Notification
          onHide={() => {
            setShowNotification(false);
          }}
          open={showNotification}
          params={paramsNotification}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
