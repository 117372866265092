import axios from "axios"
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { ClipboardIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Loader from "components/UI/Loader";
import Item from "components/Files/Item";

import { actions } from "redux/reducer";

export default () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [files, setFiles] = useState([])
    const [filterGroup, setFilterGroup] = useState({
        preapproved: [],
        postapproved: []
    })
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (user) get_files()
    }, [user])

    useEffect(() => {
        files.map(e => {
            e.formatDate = e.created_at.split("T")[0]
            return e
        })

        let order = {
            preapproved: orderFiles("preapproved"),
            postapproved: orderFiles("postapproved")
        }

        setFilterGroup(order)
    }, [files])

    const get_files = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)

            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/files/user`,
                params: {
                    user_id: user?.id || "",
                    take,
                    skip,
                }
            })

            setLoader(false)
            setFiles(response.data.data.files || [])
        } catch (error) {
            setLoader(false)
            console.log("Error get files: ", error)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Files: Error loading data'
            }))
        }
    }

    const groupBy = (array, call) => {
        let groups = {};

        array.forEach(function (item) {
            let group = JSON.stringify(call(item));
            groups[group] = groups[group] || [];
            groups[group].push(item);
        });

        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    const orderFiles = (type) => {
        let order = []
        let data = files.filter(e => String(e.type).match(type))

        const result = groupBy(data, function (item) {
            return [item.type, item.active, item.formatDate];
        });

        let index = 0
        for (const item of result) {
            index = item[0].active == null ? ++index : index

            order.push({
                type: item[0].type.split("/")[1],
                active: item[0].active,
                comment: item[0].comment,
                created_at: item[0].formatDate,
                number_files: item.length,
                files: item,
                defaultShow: index == 1 ? true : false
            })
        }

        return order
    }

    return (
        <div className="bg-white divide-y divide-gray-200 pb-10">
            <div className="pt-8">
                <div className="mb-8">
                    <h1 className="text-xl font-semibold text-gray-900">Files</h1>
                    <p className="mt-1 text-sm text-gray-500">A list of all files with including the original name, MIME type, size and status.</p>
                </div>

                <div className='flex items-center'>
                    <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-yellow-500" />

                    <div className='flex flex-wrap'>
                        <h3 className="text-lg leading-6 font-medium text-gray-900" style={{ width: '100%' }}>{filterGroup.preapproved.length || 0} Pre-approved</h3>
                    </div>

                </div>

                <div className="mt-2 flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle">

                            {
                                filterGroup && filterGroup.preapproved.map((item) => (<>
                                    <Item
                                        dataFile={item}
                                        key={item.id}
                                        setLoader={setLoader}
                                        refresh={get_files}
                                        defaultShow={item.defaultShow}
                                    />
                                </>))
                            }

                        </div>
                    </div>
                </div>

                <div className='flex items-center mt-6'>
                    <ClipboardIcon className="h-6 w-6 mr-3 text-green-500" />

                    <div className='flex flex-wrap'>
                        <h3 className="text-lg leading-6 font-medium text-gray-900" style={{ width: '100%' }}>{filterGroup.postapproved.length || 0} Post-approved</h3>
                    </div>

                </div>

                <div className="mt-2 flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle">

                            {
                                filterGroup && filterGroup.postapproved.map((item) => (<>
                                    <Item
                                        dataFile={item}
                                        key={item.id}
                                        setLoader={setLoader}
                                        refresh={get_files}
                                        defaultShow={item.defaultShow}
                                    />
                                </>))
                            }

                        </div>
                    </div>
                </div>

            </div>
            <Loader status={loader} ></Loader>
        </div>
    )
}