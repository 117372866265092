import React, { useState } from 'react';

const Health = () => {
  // State variables to track health data
  const [steps, setSteps] = useState(0);
  const [hoursOfSleep, setHoursOfSleep] = useState(0);
  const [waterIntake, setWaterIntake] = useState(0);

  return (
    <div>
      <h1>Health Dashboard</h1>

      <div>
        <h2>Steps</h2>
        <p>Today's Steps: {steps}</p>
        <button onClick={() => setSteps(steps + 1000)}>Add 1000 Steps</button>
      </div>

      <div>
        <h2>Sleep</h2>
        <p>Hours of Sleep: {hoursOfSleep}</p>
        <button onClick={() => setHoursOfSleep(hoursOfSleep + 1)}>Add 1 Hour</button>
      </div>

      <div>
        <h2>Water Intake</h2>
        <p>Today's Water Intake: {waterIntake} oz</p>
        <button onClick={() => setWaterIntake(waterIntake + 8)}>Add 8 oz</button>
      </div>
    </div>
  );
};

export default Health;