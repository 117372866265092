import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  auth: null,
  user: null,
  loan_applications: null,
  applications_total: null,
  loan: null,
  plaid: null,
  reviewers: null,
  message: null,
  currentLog: null,
  startDate: undefined,
  endDate: undefined,
  backPath: null,
};
const rootReducer = createSlice({
  name: 'reducer',
  initialState,
  reducers: {
    getStartDate() {},
    setStartDate: (state, action) => {
      return { ...state, startDate: action.payload };
    },
    getEndDate() {},
    setEndDate: (state, action) => {
      return { ...state, endDate: action.payload };
    },
    getAuthUser() {},
    setAuthUser(state, action) {
      return { ...state, auth: action.payload };
    },
    getLoan() {},
    setLoan(state, action) {
      return { ...state, loan: action.payload };
    },
    updateLoan() {},
    newLoanStatus() {},
    addStatusToLoan(state, action) {
      return {
        ...state,
        loan: { ...state.loan, status: [action.payload, ...state.loan.status] },
      };
    },
    getLoanApplications() {},
    setLoanApplications(state, action) {
      return { ...state, loan_applications: action.payload };
    },
    setApplicationsTotal(state, action) {
      return { ...state, applications_total: action.payload };
    },
    getUser() {},
    setUser(state, action) {
      return { ...state, user: action.payload };
    },
    getBankData() {},
    setBankData(state, action) {
      return { ...state, plaid: action.payload };
    },
    setReviewers(state, action) {
      return { ...state, reviewers: action.payload };
    },
    setMessage(state, action) {
      return { ...state, message: action.payload };
    },
    setCurrentLog(state, action) {
      return { ...state, currentLog: action.payload };
    },
    setBackPath(state, action) {
      return { ...state, backPath: action.payload };
    },
  },
});

export const {
  getLoan,
  setLoan,
  updateLoan,
  newLoanStatus,
  addStatusToLoan,
  getLoanApplications,
  setLoanApplications,
  setApplicationsTotal,
  getUser,
  setUser,
  getBankData,
  setBankData,
  setMessage,
  setCurrentLog,
  setStartDate,
  setEndDate,
  setBackPath,
} = rootReducer.actions;
export const actions = rootReducer.actions;

export default rootReducer.reducer;
