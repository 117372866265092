import React, { Fragment, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import InputSearchAlgolia from 'components/InputSearchAlgolia';
import store from '../redux/store';
import {
  Squares2X2Icon,
  BuildingStorefrontIcon,
  BanknotesIcon,
  ClipboardDocumentListIcon,
  PhoneIcon,
  CommandLineIcon,
  QueueListIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ArrowPathIcon,
  DocumentPlusIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/solid';

const user = {
  name: 'Kiwi',
  email: 'kiwi@kiwicredito.com',
  imageUrl: '/profile.png',
};

const userNavigation = [{ name: 'Sign out', href: '/logout' }];

export const navigationRoutes = [
  {
    name: 'Home',
    href: '/',
    show: true,
  },
  {
    name: 'Applications',
    href: '/applications',
    show: true,
    description:
      'A list of all loans with including the user, balance, payload and status.',
  },
  {
    name: 'Verifications',
    href: '/verifications',
    show: true,
  },
  {
    name: 'Document Center',
    href: '/files',
    show: true,
    icon: ClipboardDocumentListIcon,
    description:
      'A list of pending files per user including name, firebase id and number of files.',
  },
  {
    name: 'Loans',
    href: '/loans/ready_fund',
    show: true,
    icon: Squares2X2Icon,
    description:
      'A list of all loans including the user, balance, payload and status.',
  },
  {
    name: 'Users',
    href: '/users',
    show: true,
    children: [
      {
        name: 'Referrals',
        href: '/referrals/ready_to_pay',
        icon: ChatBubbleOvalLeftEllipsisIcon,
        description:
          'A list of all the users in your account including their name, title, email and role.',
      },
      {
        name: 'Increase Limit Requests',
        href: '/increase-limit-requests',
        icon: QueueListIcon,
        description:
          'A list of users who have requested an increase in their quota.',
      },
    ],
    description:
      'A list of all the users in your account including their name, title, email and role.',
  },
  {
    name: 'Stores',
    href: '/bnpl-stores',
    icon: BuildingStorefrontIcon,
    description:
      'A list of all the stores including their name, location and email.',
  },
  {
    name: 'Testing',
    href: '/testing-numbers',
    icon: PhoneIcon,
    description: 'A list of all numbers for development and testing.',
  },
  {
    name: 'Admins',
    href: '/admin-users',
    icon: CommandLineIcon,
    description: 'A list of all admin users with email and profile type.',
  },
  {
    name: 'ATH Payments',
    href: '/ath-payments',
    icon: BanknotesIcon,
    description:
      'A list of all payments of ath that we receive for ath webhook',
  },
  {
    name: 'Change due dates',
    href: '/chage-due-dates',
    icon: ArrowPathIcon,
    description: 'Create a new change due date register',
  },
  {
    name: 'Renewal requests',
    href: '/renewal-requests',
    icon: DocumentPlusIcon,
    description: 'A list of all renewal request that need review',
  },
  {
    name: 'Tickets',
    href: '#',
    icon: BanknotesIcon,
    description: 'A list of closed tickets (chats) for internal evaluation'
  },
  {
    name: 'DPD Campaigns',
    href: '/dpd-campaigns',
    icon: PaperAirplaneIcon,
    description: 'Send and Check History DPD Campaigns',
  }
];

const Header = () => {
  const { pathname } = useLocation();

  const [auth, setAuth] = useState({});

  store.subscribe(() => {
    let data = store.getState().auth;
    setAuth(data);
  });

  return (
    <Disclosure as="nav" className="bg-gray-50 border-b border-gray-200">
      {({ open }) => (
        <>
          <div className="relative h-16 flex items-center max-w-7xl mx-auto">
            <div
              className={`h-full w-full flex items-center ${
                pathname !== '/' && 'lg:gap-10'
              }`}
            >
              <Link
                to="/"
                className="w-56 h-full grid place-content-center bg-gray-50"
              >
                <img
                  className="h-6 w-auto"
                  src={require('../assets/kiwi-logo.png')}
                  alt="Workflow"
                />
              </Link>

              {/* Links section */}
              <div className="hidden lg:block flex w-full">
                <div className="flex items-center flex-1 space-x-4 justify-center">
                  {navigationRoutes.map(
                    (item, index) =>
                      item.show && (
                        <div className="relative group" key={index}>
                          <Link
                            key={index}
                            to={item.href}
                            className={`px-3 py-2 rounded-md text-sm font-medium text-gray-400 ${
                              item.current
                                ? 'bg-gray-100'
                                : 'hover:text-dark-kiwi'
                            } ${
                              item.href === '/admin-users' &&
                              auth?.profile !== 'admin' &&
                              'hidden'
                            } ${
                              ((pathname === '/' && item.href === '/') ||
                                (item.href !== '/' &&
                                  pathname.includes(item.href))) &&
                              'bg-gray-200/70 text-blue-dark-kiwi font-semibold'
                            }`}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        </div>
                      )
                  )}
                  <InputSearchAlgolia />
                </div>
              </div>
            </div>

            {/* Actions section */}
            <div className="hidden lg:block ml-auto">
              <div className="flex items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative flex-shrink-0">
                  <div>
                    <Menu.Button className="bg-gray-50 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="rounded-full h-8 w-8"
                        src={user.imageUrl}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <a
                              key={index}
                              href={item.href}
                              className={`${
                                active && 'bg-gray-100'
                              } block py-2 px-4 text-sm text-gray-700`}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
