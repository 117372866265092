export const formatDate = (dateIso) => {
    if (!dateIso) return ''

    let [date, hour] = new Date(dateIso).toLocaleString().split(',')
    let format_date = date.split('/').reverse().join('-')

    return format_date + ',  ' + hour;
}
export const formatToDollars = (number) => {
    const formattedNumber = Number(number).toFixed(2);
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `$${parts.join('.')}`;
}