import React, { useEffect, useState } from "react";
import axios from "axios";
import { PhoneIcon, TrashIcon } from "@heroicons/react/24/outline";
import Loader from "components/UI/Loader";
import { formatDistance } from 'date-fns'
import { Switch } from '@headlessui/react'

import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import { classNames } from "utils/class_names";

const dataForm = () => {
    return {
        phone_number: '',
        code: '',
        method: 'create',
        test: false,
    }
}

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }

    return null;
}

const TestingNumbers = () => {
    const dispatch = useDispatch();
    const [testing_numbers, setTestingNumbers] = useState();
    const [loader, setLoader] = useState(false)
    const [showModalUser, setShowModalUser] = useState(false);

    const [form, setForm] = useState(dataForm)

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setForm(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const validateForm = () => {
        form.test = form.test === "on" ? true : false
        if (!form.phone_number) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Phone number is required'
            }))
        } else if (!form.code) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Email is required'
            }))
        } else {
            setShowModalUser(false)
            if (form.method == "create") { create(form) } else { update(form) };
        }
    }

    useEffect(() => {
        getTestingNumbers();
    }, [])

    const getTestingNumbers = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/testing-phone-numbers`,
            })

            setTestingNumbers(response.data.testing_phone_numbers);
        } catch (error) {
            console.log(error)

            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error loading auths'
            }))
        }
    }

    const create = async (data) => {
        try {
            setLoader(true)
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/testing-phone-numbers`,
                data
            })
            setTestingNumbers([response.data.testing_phone_number, ...testing_numbers]);
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Created success'
            }))
        } catch (error) {
            console.log(error);
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error create auth'
            }))
        }
    }

    const update = async ({ id, ...payload }) => {
        try {
            setLoader(true)
            const { data } = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/testing-phone-numbers/${id}`,
                data: payload
            })
            const numbers = testing_numbers.map(number => {
                if (number.id === id) number = data.testing_phone_number;
                return number;
            });
            setTestingNumbers(numbers);
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Updated success'
            }))
        } catch (error) {
            console.log(error);
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error update auth'
            }))
        }
    }

    const remove = async ({ id }) => {
        try {
            setLoader(true)
            await axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_URL}/testing-phone-numbers/${id}`,
            })
            const new_numbers = testing_numbers.filter(number => number.id !== id);
            setTestingNumbers(new_numbers);
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Delete success'
            }))
        } catch (error) {
            console.log(error);
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error delete auth'
            }))
        }
    }

    const handleActive = ({ id, value }) => {
        update({
            id,
            active: value
        })
    }

    const showUpdateModal = (testing_number) => {
        setForm({
            id: testing_number.id,
            phone_number: testing_number.phone_number,
            code: testing_number.code,
            reason: testing_number.reason,
            test: testing_number.test,
            method: 'update'
        })
        setShowModalUser(true)
    }

    return (
        <div className="max-w-7xl mx-auto">
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Testing Phone Numbers</h1>
                    </div>

                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                        onClick={() => setShowModalUser(true)}
                    >
                        New Testing Number
                        <PhoneIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <br />

                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Phone number
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Code (6 Digits)
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                For
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Reason
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Created At
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Active?
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Delete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {testing_numbers && testing_numbers.map((testing_number) => (
                                            <tr key={testing_number.phone_number}>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <div onClick={() => showUpdateModal(testing_number)} className="text-gray-900 cursor-pointer font-semibold">{formatPhoneNumber(testing_number.phone_number)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{testing_number.code}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">
                                                        {testing_number.test ? <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                                            Test
                                                        </span> : <span className="inline-flex items-center rounded-full bg-pink-100 px-3 py-0.5 text-sm font-medium text-pink-800">
                                                            User
                                                        </span>}
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500" onClick={() => showUpdateModal(testing_number)}>{testing_number.reason || "-- --"}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500">{formatDistance(new Date(testing_number.created_at), new Date())}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="text-gray-500 flex align-middle">

                                                        <Switch
                                                            checked={testing_number.active}
                                                            onChange={(value) => handleActive({ id: testing_number.id, value })}
                                                            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-kiwi focus:ring-offset-2"
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                                                            <span
                                                                aria-hidden="true"
                                                                className={classNames(
                                                                    testing_number.active ? 'bg-blue-kiwi' : 'bg-gray-200',
                                                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                                                )}
                                                            />
                                                            <span
                                                                aria-hidden="true"
                                                                className={classNames(
                                                                    testing_number.active ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                                                )}
                                                            />
                                                        </Switch>

                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="text-gray-500 flex align-middle">

                                                        <div className="relative flex flex-col items-center group">

                                                            <TrashIcon id={testing_number.id} onClick={() => remove({ id: testing_number.id })}
                                                                className="ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-red-600" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" />

                                                            <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                                                                <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">Remove testing number</span>
                                                                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModalUser ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-96 my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                    <PhoneIcon className="mt-1.5 mr-1 h-5 w-5" aria-hidden="true" />

                                    <h3 className="text-2xl font-semibold">
                                        {form?.method === 'create' ? 'New testing phone number' : 'Update testing phone number'}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() =>
                                            setShowModalUser(false)

                                        }
                                    >
                                        <span className="text-black-900 font-semibold h-6 w-6 block">
                                            ×
                                        </span>
                                    </button>
                                </div>

                                {/*body*/}
                                <div className="relative  flex-auto">
                                    <div className="mt-10 sm:mt-0">

                                        <div className="mt-5 md:mt-0 md:col-span-2">

                                            <div className="px-4 py-4 bg-white ">
                                                <div className="grid grid-cols-12 gap-3">

                                                    <div className="col-span-12 sm:col-span-12">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Phone number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="phone_number"
                                                            value={form.phone_number}
                                                            onChange={handleChangeForm}
                                                            autoComplete="phone_number"
                                                            className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>

                                                    <div className="col-span-12 sm:col-span-12">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Code
                                                        </label>
                                                        <input
                                                            type="number"
                                                            maxLength={6}
                                                            name="code"
                                                            value={form.code}
                                                            onChange={handleChangeForm}
                                                            autoComplete="code"
                                                            className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="col-span-12 sm:col-span-12">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Real or test
                                                        </label>
                                                        <input name="test" type="checkbox" onChange={handleChangeForm}>
                                                        </input>
                                                        
                                                    </div>
                                                    <div className="col-span-12 sm:col-span-12">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Reason
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="reason"
                                                            value={form.reason}
                                                            onChange={handleChangeForm}
                                                            autoComplete="code"
                                                            className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-red-500 text-white active:bg-red-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-red-700 mr-1"
                                        type="button"
                                        onClick={() => {
                                            setShowModalUser(false);
                                            setForm(dataForm)
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-emerald-700"
                                        type="button"
                                        onClick={validateForm}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <Loader status={loader}></Loader>

        </div>
    )
}

export default TestingNumbers;