const Auth = () => {
  const loginOkta = async () => {
    try {
      window.location.href = `https://kiwicredito.okta.com/oauth2/v1/authorize?client_id=${process.env.REACT_APP_OKTA_CLIENT}&response_type=code&response_mode=query&scope=profile+openid&redirect_uri=${process.env.REACT_APP_OKTA_CALLBACK}&state=open`;
    } catch (error) {
      alert("Login Failed");
    }
  };

  return (
    <div className="h-screen flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-10 w-auto"
              src={require("../assets/kiwi-logo.png")}
              alt="Workflow"
            />
            <h2 className="mt-10 text-3xl font-medium">
              Sign in to your account
            </h2>
            <p className="mt-2 text-xs text-gray-500">
              This authentication is only for admins
            </p>
          </div>

          <div className="mt-6">
            <div className="mt-6">
              <div className="form space-y-6">
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-kiwi hover:bg-blue-kiwi focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                    onClick={() => loginOkta()}
                  >
                    Sign in with OKTA
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
          alt=""
        />
      </div>
    </div>
  );
};

export default Auth;
