import { format } from 'date-fns';
import Tooltip from 'components/UI/Tooltip';
import Skeleton from 'react-loading-skeleton';
import GeneralAlert from '../../../../components/GeneralAlert';

const statusOptions = [
  { status: 'resolved', styles: 'bg-green-100 text-green-600' },
  { status: 'rejected', styles: 'bg-amber-100 text-amber-600' },
  { status: 'cancelled', styles: 'bg-red-100 text-red-600' },
];

export default function PreviousVerifications({ data, loader }) {
  const handleCurrentStatus = status => {
    return statusOptions.find(item => item.status === status).styles ?? '';
  };

  return (
    <div className="flex flex-col gap-5">
      <p className="font-semibold">Previous Verifications</p>
      {loader ? (
        <Skeleton height={64} borderRadius={8} />
      ) : (
        <>
          {data ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Label
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Created at
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Updated at
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Agent
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Comments
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item?.label}
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          className={`${handleCurrentStatus(
                            item?.status
                          )} w-max px-5 py-1 capitalize rounded-lg`}
                        >
                          {item?.status}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {format(new Date(item?.created_at), 'MMM dd yyyy')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {format(new Date(item?.updated_at), 'MMM dd yyyy')}
                      </td>
                      <td className="px-6 py-4">
                        {item?.member?.full_name ?? '---'}
                      </td>
                      <Tooltip tooltip={item?.payload ?? '---'} place="bottom">
                        <td className="px-6 py-4 truncate max-w-xs">
                          {item?.payload ?? '---'}
                        </td>
                      </Tooltip>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <GeneralAlert description="No information for this section" />
          )}
        </>
      )}
    </div>
  );
}
