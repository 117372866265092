import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("kiwi_auth_token");
    Cookies.remove("kiwi_gpt_dashboard_token");
    navigate("/auth");
  }, []);

  return (
    <div>
      <h1>Good Bye!</h1>
    </div>
  );
};

export default Logout;
