/* eslint-disable react-hooks/exhaustive-deps */
/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon,ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import { useIsMount } from 'utils/is_mount';
const { setStartDate, setEndDate } = actions;

export default function Pagination({ onTake, count, page = 1, status }) {

  const isMount = useIsMount();
  const dispatch = useDispatch();

  const [take, setTake] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  let maxPages = 0

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const nextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  useEffect(() => {
    
    dispatch(setStartDate(undefined))
    dispatch(setEndDate(undefined))
    if (isMount) return
    setCurrentPage(1)
    onTake({ take, skip: 50, page: currentPage })
  },[status])

  const getDataShowing = () => {
    return { current: (currentPage * take) - take, last: (currentPage * take) }
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setTake(value)
  }

  useEffect(() => {
    if (!isMount) {
      let { current } = getDataShowing();
      onTake({ take, skip: current, page: currentPage })
    }
  }, [take, currentPage])



  const getPagination = () => {
    maxPages = Math.ceil(count / take)

    let leftSide = currentPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = currentPage + 2;

    if (rightSide > maxPages) rightSide = maxPages;
    let items = []

    for (let number = leftSide; number <= rightSide && number <= maxPages; number++) {
        items.push(
          <div
            key={number}
            className={(number === currentPage ? 'cursor-pointer z-10 border-blue-kiwi text-blue-kiwi relative inline-flex items-center px-4 py-2 border text-sm font-medium' :
              'cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm font-medium')}
            name="currentPage"
            onClick={() => { setCurrentPage(number) }}
          >
            {number}
          </div>
        )
    }

    return items
  }

  const getShowing = () => {
    let { current, last } = getDataShowing()
    if (last > count) last = count;

    return (
      <p className="text-sm text-gray-700">
        Showing 
        <span className="ml-1 mr-1 font-medium">{count === 0 ? 0 : current + 1}</span> to 
        <span className="ml-1 font-medium">{last}</span> of{' '}
        <span className="ml-1 font-medium">{count}</span> results
      </p>
    )
  }

  return (
    <div className="w-full flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        <div
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </div>
        <div
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {getShowing()}
        </div>

        <div className='flex items-center'>
          <div className='flex items-center mr-5'>
            <label htmlFor="take" className="block text-xs font-medium text-gray-700 mr-2">
              Rows per page:
            </label>
            <select
              id="take"
              name="take"
              className="block pl-2 pr-8 py-2 text-xs border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
              onChange={handleChange}
              value={take}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>

            </select>
          </div>

          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" >
            <div
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => setCurrentPage(1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <div
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={prevPage}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>

            {getPagination()}

            <div
              className="relative inline-flex items-center px-2 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={nextPage}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <div
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => setCurrentPage(maxPages)}
            >
              <span className="sr-only">Next</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}