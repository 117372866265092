import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const ChangePaymentDateServices = {
    getAllCmsLogs: async ({take, skip}) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/change-log-payment-date/cms`,
            headers: HEADERS(),
            params: {
                take,
                skip
            }
        });
    },
    deleteLog: async (id) => {
        return await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_URL}/change-log-payment-date/${id}`,
            headers: HEADERS()
        });
    },
    updateLog: async (id, data) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/change-log-payment-date/${id}`,
            data:{
                ...data
            },
            headers: HEADERS()
        });
    }
}