
const Characteristic = ({ description, id, value }) => {
    return (<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">{description}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <div className="grid grid-cols-4 gap-y-10">
                <div>
                    <p className="mb-2">ID</p>
                    <p className="font-bold">{id}</p>
                </div>
                <div>
                    <p className="mb-2">Value</p>
                    <p className="font-bold">{Number(value)}</p>
                </div>
            </div>
        </dd>
    </div>)
}

export default Characteristic;