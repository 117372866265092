import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import Api from 'redux/api';
import Filters from 'components/Applications/Filters';
import Loader from 'components/UI/Loader';
import ListWithPagination from 'components/Applications/List';
import {add, format} from 'date-fns';
import {formatState} from 'utils/formatState';

const sort = {
    pending: 'created_at',
    me_salve: 'created_at',
    new_uw: 'created_at',
};

export default function Loans() {
    const params = useParams();
    const {status} = useParams();
    const navigate = useNavigate();
    const [applications, setApplications] = useState(null);
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(false);

    const filters = {
        all: {
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        no_model_income_verified: {
            bucket: 'no_model_income_verified',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        no_dq_pending_verify_income: {
            bucket: 'no_model_pending_verify_income',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        new: {
            user: {
                state: formatState(params.state),
                black_list: false,
                AND: [
                    {
                        plaid_token: null,
                    },
                    {
                        finicity_token: null,
                    },
                ],
                files: {
                    none: {
                        type: 'preapproved/bank-statements',
                        active: true,
                        created_at: {
                            gte: add(
                                new Date(format(new Date(), 'yyyy-MM-dd')),
                                {
                                    days: -30,
                                },
                            ),
                        },
                    },
                },
            },
            last_status: {
                value: 'created',
            },
            dq_decision_value: 'APPROVED_DQ',
        },
        approved_dq_income_verified: {
            bucket: 'approved_dq_income_verified',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        approved_dq_pending_verify_income: {
            bucket: 'approved_dq_pending_verify_income',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        soft_dq_income_verified: {
            bucket: 'soft_dq_income_verified',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        soft_dq_pending_verify_income: {
            bucket: 'soft_dq_pending_verify_income',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        approved: {
            bucket: 'approved',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        need_bank_account: {
            user: {
                state: formatState(params.state),
                black_list: false,
            },
            verification: {
                requirements: {
                    hasSome: 'bank_account',
                },
                checklist_bank_account: false,
            },
        },
        duplicated_bank_account: {
            user: {
                state: formatState(params.state),
                black_list: false,
            },
            verification: {
                requirements: {
                    hasSome: 'duplicate_bank_account',
                },
                checklist_duplicate_bank_account: false,
            },
        },
        verified: {
            bucket: 'verification',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        rejected: {
            bucket: 'rejected',
            user: {
                state: formatState(params.state),
                black_list: false,
            },
        },
        hard_dq: {
            user: {
                state: formatState(params.state),
                black_list: false,
            },
            last_status: {
                value: 'rejected',
            },
            dq_decision_value: 'HARD_DQ',
        },
        black_list: {
            user: {
                state: formatState(params.state),
                black_list: true,
            },
        },
        direct_mail_marketing: {
            user: {
                state: formatState(params.state),
                black_list: false,
            },
            last_status: {
                reason: 'direct_mail_marketing',
            },
        },
    };

    useEffect(() => {
        if (status) {
            fetchApplications({});
            setTimeout(() => {
                setLoader(false);
            }, 12000);
        } else {
            navigate(`/applications/${params.state}/all`);
        }
    }, [status]);

    const fetchApplications = async ({search, take, skip}) => {
        try {
            setLoader(true);
            const response = await Api.fetchApplications({
                where: filters[status],
                search,
                take,
                skip,
                orderBy: sort[status] || 'last_status_created',
            });
            setApplicationsWithCondition(response.data.applications);
            setTotal(response.data.total);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            alert('ERROR');
        }
    };

    const setApplicationsWithCondition = values => {
        let new_applications = values;
        if (status == 'new_uw') {
            new_applications = new_applications.map(item => {
                let plaid = item.status.find(
                    status =>
                        status.value == 'plaid' || status.value == 'finicity',
                );
                if (plaid) item = {...item, created_at: plaid.created_at};
                return item;
            });

            new_applications = new_applications.sort((a, b) => {
                let a_date = new Date(a.created_at);
                let b_date = new Date(b.created_at);
                return a_date < b_date ? 1 : b_date > a_date ? -1 : 0;
            });
            setApplications(new_applications);
        } else if (status == 'funded') {
            new_applications = new_applications.map(item => {
                let funded = item.status.find(
                    status => status.value == 'funded',
                );
                if (funded) item = {...item, created_at: funded.created_at};
                return item;
            });

            new_applications = new_applications.sort((a, b) => {
                let a_date = new Date(a.created_at);
                let b_date = new Date(b.created_at);
                return a_date > b_date ? 1 : b_date > a_date ? -1 : 0;
            });
            setApplications(new_applications);
        } else {
            setApplications(values);
        }
    };

    const search = value => {
        fetchApplications({search: value});
    };

    return (
        <div className="loans w-full">
            <Loader status={loader}></Loader>
            <Filters state={params.state} />
            <ListWithPagination
                applications={applications}
                total={total}
                fetchApplications={fetchApplications}
                search={search}
                state={params.state}
            />
        </div>
    );
}
