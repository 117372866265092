import React, {  useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import { Link } from "react-router-dom";

import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";
import ModalConfirm from "components/UI/ModalConfirm";
import { TermsRenewalServices } from "services/TermsRenewal/TermsRenewal.services";
import { format } from "date-fns-tz";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const EMPTY_ARRAY = []

const RenewalRejectedRequests = ({
    hasBack, 
    setStatusPage,
    statusPage
}) => {
    const dispatch = useDispatch();
    const [modalApproveRequestStatus, setModalApproveRequestStatus] = useState(false);
    const [modalRejectRequestStatus, setModalRejectRequestStatus] = useState(false);
    const [idRequest, setIdRequest] = useState();
    const [loader, setLoader] = useState(false);
    const [requests, setRequests] = useState();
    const [count, setCount] = useState(0);
    const [requestSelected, setRequestSelected] = useState(null);
    
    useEffect(() => {
        getRequests();
    }, [])

    const getRequests = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true);
            const response = await TermsRenewalServices.getAllNeedReview(take, skip);
            setRequests(response.data.data.logs || EMPTY_ARRAY);
            setCount(response.data.data.count || 0);
            setLoader(false);
        } catch (error) {
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error loading auths'
            }));
            setLoader(false);
        }
    }

    const onApproveRequest = (id) => {
        setIdRequest(id);
        setModalApproveRequestStatus(true);
    }

    const approveRequest = async () => {
        try {
            setLoader(true);
            await TermsRenewalServices.approveRequest(idRequest);
            setLoader(false);
            setModalApproveRequestStatus(false);
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Request updated'
            }));
            await getRequests();
        } catch (error) {
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error'
            }));
            setLoader(false);
            setModalApproveRequestStatus(false);
        }
    }

    const closeApproveRequestModal = () => {
        setModalApproveRequestStatus(false);
    }

    const onRejectRequest = (id) => {
        setIdRequest(id);
        setModalRejectRequestStatus(true);
    }

    const rejectRequest = async () => {
        try {
            setLoader(true);
            await TermsRenewalServices.rejectRequest(idRequest);
            setLoader(false);
            setModalRejectRequestStatus(false);
            setIdRequest();
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Request updated'
            }));
            await getRequests();
        } catch (error) {
            setLoader(false);
            setModalRejectRequestStatus(false);
            setIdRequest();
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error'
            }));
        }
    }

    const closeRejectRequestModal = () => {
        setModalRejectRequestStatus(false);
    }

    const onSeeDetails = (index) => {
        setRequestSelected(index);
    }

    return (
        <div className="max-w-7xl mx-auto">
            <Loader status={loader} />
            { hasBack &&(<div className="flex items-center">
                <ChevronLeftIcon
                    onClick={() => setStatusPage(statusPage)}
                    className="w-5 h-5 text-slate-600 cursor-pointer" 
                />
                <p className="cursor-pointer" onClick={() => setStatusPage(statusPage)}>Volver</p>
            </div>
            )}
            <div className="mt-8 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Renewal Model Rejections Requests</h1>
                </div>
            </div>
            <br />
            <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <div className="bg-white px-4 py-3 sm:px-6">
                                    <Pagination count={count} onTake={getRequests} />
                                </div>
                                <table className="min-w-full divide-y">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                User
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Reason
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Decision
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                User Information
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Actions
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Created At
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {requests && requests.map((request) => (
                                            <>
                                                <tr 
                                                    key={request.id}
                                                >
                                                    <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        <div className="text-gray-500 tracking-widest">{request.user.first_name} {request.user.first_surname}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        { request.zero_credit_limit 
                                                            ?
                                                                <div className="text-gray-500 tracking-widest">zero_credit_limit</div>
                                                            :
                                                                <div className="text-gray-500 tracking-widest">{request.reason}</div>
                                                        }
                                                        
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        { request.zero_credit_limit 
                                                            ?
                                                                <div className="text-gray-500 tracking-widest">Without decision</div>
                                                            :
                                                                <div className="text-gray-500 tracking-widest">{request.decision}</div>
                                                        }
                                                    </td>
                                                    <td 
                                                        onClick={() => { onSeeDetails(request.id)}}
                                                        className={`${requestSelected === request.id && "underline font-semibold"} whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 `}>
                                                        <Link 
                                                            className="text-blue-kiwi hover:text-sky-400"
                                                            to={`/users/${request.user.id}/personal`}
                                                            target="_blank"
                                                        >
                                                            See Details
                                                        </Link> 
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pr-3 text-sm sm:pl-6">
                                                        <div className="flex gap-2.5">
                                                            <button
                                                                onClick={() => { onApproveRequest(request.id) }}
                                                                className="focus:outline-none text-black bg-gray-200 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5"
                                                            >
                                                                Keep expired
                                                            </button>
                                                            <button
                                                                onClick={ () => { onRejectRequest(request.id) }}
                                                                className="focus:outline-none text-black bg-gray-200  focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5"
                                                            >
                                                                Reconsider decision
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        <div className="text-gray-500 tracking-widest">{format(new Date(request.created_at), 'yyyy-MM-dd')}</div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            </div>
            <ModalConfirm
                open={modalApproveRequestStatus}                    
                message={"Are you sure that you want to keep this request expired?"}
                onAccept={() => { approveRequest() }}
                onCancel={() => { closeApproveRequestModal() }}
            />

            <ModalConfirm
                open={modalRejectRequestStatus}                    
                message={"Are you sure you want to reconsider?"}
                onAccept={() => { rejectRequest() }}
                onCancel={() => { closeRejectRequestModal() }}
            />
        </div>
    )
}

export default RenewalRejectedRequests;