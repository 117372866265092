import { Switch } from '@headlessui/react'
import { classNames } from 'utils/class_names'

const SwitchStatus = ({ text_label = 'Status', checked, onChange }) => {
    return (
        <>
            <Switch.Group as="div" className="flex items-center">
                <Switch.Label as="span" >
                    <span className="text-sm font-medium text-gray-900 mr-3">{text_label} </span>
                </Switch.Label>

                <Switch
                    checked={checked}
                    onChange={onChange}
                    className={classNames(
                        checked ? 'bg-green-500 focus:ring-green-400' : 'bg-red-500 focus:ring-red-400',
                        'relative inline-flex flex-shrink-0 h-5 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={classNames(
                            checked ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                    />
                </Switch>
            </Switch.Group>
        </>
    )
}

export default SwitchStatus