import { AtSymbolIcon, CheckCircleIcon, MapPinIcon, PhoneIcon, XCircleIcon } from "@heroicons/react/24/solid"

const HeaderStore = ({ data }) => {

    return (
        <header className="divide-gray-200 pt-0 pb-4 border-b" >
            <div className="max-w-7xl mx-auto xl:flex xl:items-center xl:justify-between">

                <div>
                    <div className="flex items-center">
                        <div
                            className="h-16 w-16"
                            style={{
                                backgroundImage: `url(${data.logo})`,
                                backgroundSize: '100% auto',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }}
                        >
                        </div>

                        <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            {data.name} {data.code}
                        </h1>
                    </div>

                    <dl className="mt-6 flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap">
                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <MapPinIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            {data.address} {data.office ? '- ' + data.office : ''}
                        </dd>

                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <PhoneIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            {data.phone_number}
                        </dd>

                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <AtSymbolIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            {data.email}
                        </dd>

                        <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">

                            {data.active ?
                                <CheckCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                />
                                : <XCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                />
                            }

                            {data.active ? 'Active' : 'Disabled'}
                        </dd>
                    </dl>
                </div>
            </div>

        </header>)
}

export default HeaderStore;