/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ClipboardIcon, DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import LateralNav from 'components/Referrals/LateralNav';
import Pagination from "components/UI/Pagination";
import Tooltip from "components/UI/Tooltip";
import Loader from "components/UI/Loader";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import { ReferralServices } from "services/Referrals/Referral.services";
import { useParams } from "react-router-dom";
import { useIsMount } from "utils/is_mount";

const Referrals = () => {
    let { status } = useParams();
    const dispatch = useDispatch();
    const isMount = useIsMount();
    const [referrals, setReferrals] = useState();
    const [referrer, setReferrer] = useState();
    const [order, setOrder] = useState();
    const [result, setResult] = useState(0)
    const [toastCopy, setToastCopy] = useState(false)
    const [search, setSearch] = useState()
    const [file, setFile] = useState();
    const [loader, setLoader] = useState(false)
    const [referralsEdited, setReferralsEdited] = useState([])
    const [uniqueReferrers, setUniqueReferrers] = useState([])
    const setHandlers = {
        referrer: setReferrer,
        order: setOrder,
        search: setSearch
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }

    useEffect(() => {
        getReferrals();
        if (status !== 'no_ready') setReferrer('')
    }, [status,referrer, order]);

    useEffect(() => {
        if (isMount) return
        const delay = setTimeout(() => {
            getReferrals()
        }, 500)

        return () => clearTimeout(delay)
    }, [search])

    useEffect(() => {
        document.getElementById("referralDownload").click();
    }, [file])

    const getReferrals = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)
            setResult([])
            setReferrals([]);
            setUniqueReferrers([]);
            let response;
            switch (status) {
                case 'paid':
                    response = await ReferralServices.gatReferralsPaid({ take, skip, referrer, order, search })
                    setReferrals(response.data.data.referrals);
                    break;
                case 'ready_to_pay':
                    response = await ReferralServices.getReferralsReadyToPay({ take, skip, referrer, order, search })
                    setReferrals(response.data.data.referrals);
                    break;
                default:
                    response = await ReferralServices.gatReferralsNoReady({ take, skip, referrer, order, search })
                    setReferrals(response.data.data.referrals);
                    const allUsers = await ReferralServices.gatReferralsNoReady({ take, skip})
                    const uniqueIdsSet = new Set();
                    const referred = allUsers.data.data.referrals.filter((value) => {
                        if (!uniqueIdsSet.has(value.user_from.id)) {
                            uniqueIdsSet.add(value.user_from.id);
                            return true;
                        }
                        return false;
                    });
                    setUniqueReferrers(referred);
                    break;
            }
            setLoader(false)
            setResult(response.data.data.count)
        } catch (error) {
            console.log(error, 'error');
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get referrals'
            }))
        }
    }

    const checkIfIdExists = (id) => {
        return referralsEdited.some((obj) => obj.id === id);
    };

    const downloadReferrals = async () => {
        try {
            setLoader(true)

            switch (status) {
                case 'ready_to_pay':
                    const responseReadyToPay = await ReferralServices.downloadReferralsRedayToPay()
                    setFile(window.URL.createObjectURL(new Blob([responseReadyToPay.data])));
                    break;
                case 'paid':
                    const responsePaid = await ReferralServices.downloadReferralsPaid()
                    setFile(window.URL.createObjectURL(new Blob([responsePaid.data])));;
                    break;
                default:
                    const response = await ReferralServices.downloadReferrals()
                    setFile(window.URL.createObjectURL(new Blob([response.data])));
                    break;
            }
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Generated report'
            }))
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error creating report'
            }))
        }
    }

    const handleInputReferral = async (id, value) => {
        setReferralsEdited(referralsEdited => {
            const index = referralsEdited.findIndex(obj => obj.id === id);
            if (index !== -1) {
              const objUpdated = { ...referralsEdited[index], note: value };
              const newArray = [...referralsEdited];
              newArray[index] = objUpdated;
              return newArray;
            } else {
              const newObj = { id, note: value, paymentMethod: 'credit' };
              return [...referralsEdited, newObj];
            }
          });
    };

    const handleSelectReferral = async (id, value) => {
        setReferralsEdited(referralsEdited => {
            const index = referralsEdited.findIndex(obj => obj.id === id);
            if (index !== -1) {
              const objUpdated = { ...referralsEdited[index], paymentMethod: value };
              const newArray = [...referralsEdited];
              newArray[index] = objUpdated;
              return newArray;
            } else {
              const newObj = { id, paymentMethod: value, note: '' };
              return [...referralsEdited, newObj];
            }
          });
    };

    const handleSaveReferral = async (id) => {
        try {
            setLoader(true)
            const index = referralsEdited.findIndex(obj => obj.id === id);
            const referral = referralsEdited[index];
            await ReferralServices.newPaymentReferral(referral);
            setLoader(false)
            await getReferrals();
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Referral updated'
            }))
            setReferralsEdited(referralsEdited => {
                const newArray = [...referralsEdited];
                newArray.splice(index, 1);
                return newArray;
            });
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error updating referral'
            }))
        }
    };

    const format_date = (data = "") => {
        let date = data.split("T")
        return date[0]
    }

    const generateFielName = () => {
        const date = new Date().toISOString().substring(0, 10);

        return `referrals_${status}_${date}`
    }

    const validateCurrentStatus = (currentStatus) => {
        return status === currentStatus;
    }

    const copyText = (event) => {
        const { id } = event.target

        if (id) {
            navigator.clipboard.writeText(id);
            setToastCopy(true)

            setTimeout(() => {
                setToastCopy(false)
            }, 1500);
        }
    }

    return (
        <div className="flex">
            <LateralNav />
            <div className="max-w-7xl w-full mx-auto">
                <div className="py-8 px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center pb-3 border-b">

                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Referrals</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>
                        </div>

                        <div className="grid grid-flow-col gap-4 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            { <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                                onClick={() => downloadReferrals()}
                            >
                                Download
                                    <DocumentArrowDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                                </button>
                            }
                            <a id="referralDownload" className="w-0 h-0 opacity-0" href={file} download={`${generateFielName()}.csv`}></a>
                        </div>

                    </div>
                    <br />
                    <div className="sm:flex sm:items-center my-5">
                        <div className="sm:flex-auto mr-4">
                            <div>
                                <label htmlFor="referrer" className="block text-sm font-medium text-gray-700">
                                    Search referral
                                </label>

                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <div className="relative flex items-stretch flex-grow">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                                        </div>

                                        <input type="text"
                                            className="pl-10 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            name="search"
                                            onChange={handleChange}
                                            placeholder="Name, firebase id, phone number" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="sm:flex-auto mr-4">
                            {status === 'no_ready' && <div>
                                <label htmlFor="referrer" className="block text-sm font-medium text-gray-700">
                                    Referred filter
                                </label>

                                <select
                                    id="referrer"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                    onChange={handleChange}
                                    value={referrer}
                                    name="referrer"
                                >
                                    <option value={""}>Clear filter</option>

                                    {uniqueReferrers && uniqueReferrers.map((referral, index) => (
                                            <option value={referral.from} key={index}>
                                                {`${referral.user_from.first_name ?? ''} ${referral.user_from.first_surname}`}
                                            </option>
                                    ))}
                                </select>

                            </div>}
                        </div>
                        <div className="sm:flex-auto">
                            <div>
                                <label htmlFor="orderByDateAt" className="block text-sm font-medium text-gray-700">
                                    Order by created At
                                </label>
                                <select
                                    id="orderByDateAt"
                                    name="order"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                    onChange={handleChange}
                                    value={order}
                                >
                                    <option value="desc">Latest</option>
                                    <option value="asc">Oldest</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* PAGINATOR */}
                    <Pagination count={result} onTake={getReferrals} />

                    <div className="mt-8 w-full overflow-x-auto rounded-md border-collapse border-slate-400 border-2">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        New User
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Referrer
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Code
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Reference Date
                                    </th>
                                    { !validateCurrentStatus('no_ready') && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Was paid
                                    </th>}
                                    {!validateCurrentStatus('no_ready') && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Payment method
                                    </th>}
                                    {!validateCurrentStatus('no_ready') && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Note
                                    </th>}
                                    
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {referrals && referrals.map((referral, index) => (
                                    <tr key={index}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 text-sm text-gray-500">
                                            <div className="text-gray-900 font-semibold">{`${referral.user_to.first_name} ${referral.user_to.first_surname}`}</div>
                                            <div className="text-gray-500 flex align-middle">
                                                <span className="text-gray-600 font-semibold mr-1"> Postgres: </span>
                                                <span className="text-ellipsis w-32 overflow-hidden">{referral.user_to.id}</span>
                                                <Tooltip tooltip="Copy id Postgres">
                                                    <ClipboardIcon id={referral.user_to.id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-blue-kiwi" />
                                                </Tooltip>
                                            </div>
                                            <div className="text-gray-500 flex align-middle">
                                                <span className="text-gray-600 font-semibold mr-1"> Firebase: </span>
                                                <span className="text-ellipsis w-32 overflow-hidden">{referral.user_to.firebase_id}</span>
                                                <Tooltip tooltip="Copy id Firebase">
                                                    <ClipboardIcon id={referral.user_to.firebase_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-blue-kiwi" />
                                                </Tooltip>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <div className="font-medium text-gray-900 font-semibold">{`${referral.user_from.first_name} ${referral.user_from.first_surname}`}</div>
                                            <div className="text-gray-500 flex align-middle">
                                                <span className="text-gray-600 font-semibold mr-1"> Postgres: </span>
                                                <span className="text-ellipsis w-32 overflow-hidden">{referral.user_from.id}</span>
                                                <Tooltip tooltip="Copy id Postgres">
                                                    <ClipboardIcon id={referral.user_from.id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-blue-kiwi" />
                                                </Tooltip>
                                            </div>
                                            <div className="text-gray-500 flex align-middle">
                                                <span className="text-gray-600 font-semibold mr-1"> Firebase: </span>
                                                <span className="text-ellipsis w-32 overflow-hidden">{referral.user_from.firebase_id}</span>
                                                <Tooltip tooltip="Copy id Firebase">
                                                    {referral.user_from.firebase_id && <ClipboardIcon id={referral.user_from.firebase_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-blue-kiwi" />}
                                                </Tooltip>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs uppercase font-semibold leading-5 text-green-800">
                                                {referral.user_from.phone_number}
                                            </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{format_date(referral.user_from.created_at)}</td>
                                        {!validateCurrentStatus('no_ready') && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {referral.was_paid ? <span className="inline-flex rounded-full bg-green-100 px-2 text-xs uppercase font-semibold leading-5 text-green-800">
                                                Yes
                                            </span> : <span className="inline-flex rounded-full bg-red-100 px-2 text-xs uppercase font-semibold leading-5 text-red-800">
                                                No
                                            </span>}
                                        </td>}
                                        {validateCurrentStatus('ready_to_pay') && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <select name="payment" id="payment" className="rounded-md" onChange={(event) => handleSelectReferral(referral.id, event.target.value)}>
                                                <option value="credit">Credit</option>
                                                <option value="deposit_direct">Deposit direct</option>
                                            </select>
                                        </td>}
                                        {validateCurrentStatus('paid') &&
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {referral.payment_method}
                                            </td>}
                                        {validateCurrentStatus('ready_to_pay') && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <input
                                                type="text"
                                                name="note"
                                                id="note"
                                                placeholder="Note"
                                                className={`rounded-md`}
                                                onChange={(event) => handleInputReferral(referral.id, event.target.value)}
                                            />
                                        </td>}
                                        {validateCurrentStatus('paid') &&
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {referral.note}
                                            </td>}
                                        {validateCurrentStatus('ready_to_pay') && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <button
                                                className={`bg-blue-kiwi text-white px-4 py-2.5 rounded-md ${!checkIfIdExists(referral.id) ? 'cursor-not-allowed opacity-50' : ''}`}
                                                onClick={() => handleSaveReferral(referral.id)}
                                                disabled={!checkIfIdExists(referral.id)}
                                                >
                                                Save
                                            </button>
                                        </td>}
                                    </tr>
                                ))}
                                {
                                    (!referrals || referrals.length <= 0) && <tr>
                                        <td colSpan="7" className="text-center py-4 text-sm text-gray-500">No data found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <Loader status={loader}></Loader>

                {toastCopy ? <div id={Math.random()} aria-live="assertive"
                    className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                >
                    <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-center">
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                            <div className="relative flex flex-col items-center group">

                                <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">Id copied successfully</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> : ''}



            </div>
        </div>
    )
}

export default Referrals;