import React, { useEffect, useState } from "react";
import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";
import Api from "redux/api"
import { ClipboardDocumentCheckIcon, HandRaisedIcon, PlusCircleIcon, CheckIcon, DocumentArrowDownIcon, FolderIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducer";
import Tooltip from "components/UI/Tooltip";
import { format } from 'date-fns';
import ModalConfirm from "components/UI/ModalConfirm";
import Comment from "components/ATHPayment/Comment";

const list_status = ["DONE", "FIXED", "FAILED", "MANUALLY"]

const ATHPayments = () => {
    const dispatch = useDispatch();
    const [result, setResult] = useState(0)
    const [searchBy, setSearchBy] = useState("email")
    const [searchValue, setSearchValue] = useState("")
    const [status, setStatus] = useState("");
    const [order, setOrder] = useState("desc");
    const [file, setFile] = useState();
    const [comment, setComment] = useState()

    const [loader, setLoader] = useState(false)
    const [payments, setPayments] = useState()
    const [open, setOpen] = useState(false)
    const [proccess, setProccess] = useState({ payment: null, status: null })
    const dataAuth = useSelector(state => state.auth);

    const setHandlers = {
        order: setOrder,
        search_by: setSearchBy,
        search_value: setSearchValue,
        status: setStatus
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }

    const handleProccess = (payment, status) => {
        setOpen(true)
        setProccess({ payment, status })
    }

    const onSubmitActions = () => {
        setOpen(false)

        if (proccess.status == 'done') addPaymentLoan(proccess.payment)
        else if (["fixed", "manually", "archived_loan"].includes(proccess.status)) {
            updateStatusPayment(proccess.payment, proccess.status)
        }
    }

    useEffect(() => {
        getATHPayments();
    }, [order, status])

    useEffect(() => {
        const delay = setTimeout(() => getATHPayments(), 500)

        return () => clearTimeout(delay)
    }, [searchValue])

    useEffect(() => {
        document.getElementById("paymentsDownload").click();
    }, [file])

    const getATHPayments = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)
            setResult([])
            setPayments([])

            const response = await Api.fetchATHPayments({
                take,
                skip,
                order,
                status,
                search_by: searchBy,
                search_value: searchValue,
            });

            setLoader(false)
            setResult(response.data.data.total)
            setPayments(response.data.data.athPayments);
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get ATH payments'
            }))
        }
    }

    const addPaymentLoan = async (item) => {
        try {
            setLoader(true)

            await Api.fetchATHPaymentsAdd({
                payment_id: item.id,
                member_id: dataAuth.id || ""
            })

            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Payment added successfully'
            }))

            getATHPayments()
            setLoader(false)
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error add ATH payments'
            }))
        }
    }

    const updateStatusPayment = async (item, status) => {
        try {
            setLoader(true)

            await Api.fetchATHPaymentsUpdate({
                payment_id: item.id,
                status: status.toUpperCase(),
                member_id: dataAuth.id || "",
                comment
            })

            setComment("")
            getATHPayments()
            setLoader(false)
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error fixed ATH payments'
            }))
        }
    }

    const downloadAthPayments = async () => {
        try {
            setLoader(true)
            const response = await Api.downloadATHPayments({
                order,
                status,
                search_by: searchBy,
                search_value: searchValue,
            })

            setLoader(false)
            setFile(window.URL.createObjectURL(new Blob([response.data])));
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Report generated'
            }))
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error creating report'
            }))
        }
    }

    const get_status = (status) => {
        let data = {
            text: status ? String(status).replace("_", " ").toUpperCase() : "",
            class: ""
        }

        switch (status) {
            case "done":
                data.class = "bg-green-100 text-green-800"
                break;
            case "fixed":
                data.class = "bg-yellow-100 text-yellow-800"
                break;
            case "failed":
                data.class = "bg-red-100 text-red-800"
                break;
            case "archived_loan":
                data.class = "bg-amber-100 text-amber-800"
                break;
            default:
                data.class = "bg-gray-100 text-gray-800"
                break;
        }

        return <span
            className={"inline-flex rounded-full px-2 text-xs uppercase font-semibold leading-5 " + data.class}
        >
            {data.text}
        </span>
    }

    const formatDate = (dateString) => {
        return new Date(dateString).toISOString().replace("T", " ").replace("Z", "");
    };

    return (
        <div className="max-w-full mx-auto">
            <div className="py-8 px-4 sm:px-6 lg:px-8">

                <div className="sm:flex sm:items-center pb-3 border-b">

                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">ATH payments</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of payments made on your account including name, phone number and email address.
                        </p>
                    </div>

                    <div className="grid grid-flow-col gap-4 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                            onClick={() => downloadAthPayments()}
                        >
                            Download
                            <DocumentArrowDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                        </button>

                        <a id="paymentsDownload" className="w-0 h-0 opacity-0" href={file} download={`ath_payments_${new Date().getTime()}.csv`}></a>
                    </div>
                </div>

                <div className="sm:flex sm:items-center my-5">
                    <div className="sm:flex-auto mr-4">
                        <div className="col-span-2">
                            <div>
                                <label
                                    htmlFor="referrer"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Search payment
                                </label>

                                <div className="relative mt-1 rounded-md shadow-sm flex">
                                    <div className="flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm pr-px">
                                        <select
                                            name="search_by"
                                            onChange={handleChange}
                                            className="h-full rounded-l-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 outline-none focus:border-blue-kiwi focus:ring-blue-kiwi"
                                        >
                                            <option value="email">Email</option>
                                            <option value="full_name">Full Name</option>
                                            <option value="phone_number">Phone Number</option>
                                        </select>
                                    </div>

                                    {searchBy === 'email' && (
                                        <input
                                            type="text"
                                            name="search_value"
                                            onChange={handleChange}
                                            className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                                            placeholder="user@mail.com"
                                        />
                                    )}

                                    {searchBy === 'full_name' && (
                                        <input
                                            type="text"
                                            name="search_value"
                                            onChange={handleChange}
                                            className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                                            placeholder="Full Name"
                                        />
                                    )}

                                    {searchBy === 'phone_number' && (
                                        <input
                                            type="text"
                                            name="search_value"
                                            onChange={handleChange}
                                            className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                                            placeholder="1787000000"
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sm:flex-auto mr-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Status
                            </label>

                            <select
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                onChange={handleChange}
                                value={status}
                                name="status"
                            >
                                <option value={""}>All status</option>

                                {list_status.map((item) => (
                                    <option value={item} key={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="sm:flex-auto">
                        <div>
                            <label htmlFor="orderByDateAt" className="block text-sm font-medium text-gray-700">
                                Order by report date
                            </label>
                            <select
                                id="orderByDateAt"
                                name="order"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                onChange={handleChange}
                                value={order}
                            >
                                <option value="desc">Latest</option>
                                <option value="asc">Oldest</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                                    <Pagination count={result} onTake={getATHPayments} />
                                </div>

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Customer
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Message / Reference Number
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                LoanPro
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Report date
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Comment
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {payments && payments.map((payment) => (
                                            <tr key={payment.id}>

                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="tracking-widest">
                                                        <p className="font-semibold">{payment.full_name}</p>
                                                        <p>Phone: {payment.phone_number}</p>
                                                        <p>Email: {payment.email}</p>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 max-w-[15px]">
                                                    <div className="text-gray-500 tracking-widest">
                                                        <p className="truncate"> {payment.message}  </p>
                                                        <p className="truncate"> Ref.: {payment.reference_number} </p>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{get_status(payment.status)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{payment.loanpro_id}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">${payment.total} USD</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">
                                                        {
                                                            formatDate(payment.report_date)
                                                        }
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{payment.comment}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="flex text-center">
                                                        <Tooltip tooltip="Automatic">
                                                            {payment.status == "automatic" && <button type="button">
                                                                <ClipboardDocumentCheckIcon className="ml-1.5 h-5 w-5 text-gray-400 hover:text-green-600" aria-hidden="true" />
                                                            </button>}
                                                        </Tooltip>

                                                        {
                                                            payment.status == null &&
                                                            <Comment
                                                                title={'Archived loan'}
                                                                icon={<FolderIcon className="ml-1.5 h-5 w-5 text-gray-400 hover:text-amber-500" aria-hidden="true" />}
                                                                comment={comment}
                                                                setComment={setComment}
                                                                submit={() => {
                                                                    handleProccess(payment, "archived_loan")
                                                                }}
                                                            />
                                                        }

                                                        {
                                                            payment.status == null &&
                                                            <Comment
                                                                title={"Manually"}
                                                                icon={<HandRaisedIcon className="ml-1.5 h-5 w-5 text-gray-400 hover:text-yellow-500" aria-hidden="true" />}
                                                                comment={comment}
                                                                setComment={setComment}
                                                                submit={() => {
                                                                    handleProccess(payment, "manually")
                                                                }}
                                                            />
                                                        }

                                                        <Tooltip tooltip="Add Payment">
                                                            {payment.status == null && <button type="button"
                                                                onClick={() => handleProccess(payment, "done")}
                                                            >
                                                                <PlusCircleIcon className="ml-1.5 h-5 w-5 text-gray-400 hover:text-emerald-500" aria-hidden="true" />
                                                            </button>
                                                            }
                                                        </Tooltip>

                                                        <Tooltip tooltip="Fix Payment">
                                                            {payment.status == 'failed' && <button type="button"
                                                                onClick={() => handleProccess(payment, "fixed")}
                                                            >
                                                                <CheckIcon className="ml-1.5 h-5 w-5 text-gray-400 hover:text-yellow-500" aria-hidden="true" />
                                                            </button>

                                                            }
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Loader status={loader}></Loader>
            <ModalConfirm
                open={open}
                onCancel={() => {
                    setOpen(false)
                    setComment("")
                }}
                onAccept={onSubmitActions}
                message={"¿Are you sure you want to continue?"}
            >
            </ModalConfirm>
        </div>
    )
}

export default ATHPayments;