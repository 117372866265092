import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const TermsRenewalServices = {
    getAllNeedReview: async (take, skip, order) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/terms-renewal/need-review`,
            headers: HEADERS(),
            params: {
                take,
                skip,
                order
            }
        });
    },
    approveRequest: async (id) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/terms-renewal/approve/${id}`,
            headers: HEADERS()
        });
    },
    rejectRequest: async (id) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/terms-renewal/rejecte/${id}`,
            headers: HEADERS()
        });
    },
    getAllZeroCreditLimitNeedReview: async (take, skip, order) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/terms-renewal/zero-credit-limit`,
            headers: HEADERS(),
            params: {
                take,
                skip,
                order
            }
        });
    }
}