import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Loader from 'components/UI/Loader';
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';

export default function States() {
  const [loader, setLoader] = useState(false);
  const states = [
    {
      state: 'Puerto Rico',
      path: 'puerto_rico',
      icon: '🇵🇷',
    },
    {
      state: 'Florida',
      path: 'florida',
      icon: '🇺🇸',
    },
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const state = searchParams.get('state');
  const [currentState, setCurrentState] = useState(state);

  useEffect(() => {
    setLoader(true);
    try {
      setCurrentState(state);
      if (!currentState) return;
    } catch (error) {
      setLoader(false);
    } finally {
      setLoader(false);
    }
  }, [state]);

  return (
    <div className="w-full pt-8 h-dashboard bg-gray-100">
      <Loader status={loader}></Loader>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center pb-3 border-b">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Applications
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all applications separated by state in which they made
              the registration
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 py-10">
          {states.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="bg-white relative hover:shadow-none py-6 px-5 rounded-md shadow-md h-full flex"
            >
              <div className="absolute right-4 top-4">
                <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
              </div>
              <div className="flex gap-4">
                <i className="bg-blue-50-kiwi p-2 h-10 w-10 min-w-[2.5rem] text-blue-kiwi flex items-center justify-center rounded-lg">
                  <p>{item.icon}</p>
                </i>
                <div>
                  <h1 className="text-xl font-semibold text-dark-kiwi">
                    {item.state}
                  </h1>
                  <p className="text-gray-400 text-xs mt-2">
                    {`All applications in ${item.state} state`}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
