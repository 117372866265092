import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import { useParams } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

import Loader from 'components/UI/Loader';
import { DTIServices } from 'services/DTI/DTI.services';

const DEFAULT_SOURCE = 'cms';

const HistoryDTI = () => {
  const dispatch = useDispatch();
  const [historyDti, setHistoryDti] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const { user_id } = useParams();

  const getHistoryDti = async () => {
    try {
      setLoaderStatus(true);
      const history_dti = await DTIServices.getHistory(user_id);
      console.log(history_dti.data.data);
      setHistoryDti(history_dti?.data?.data || null);
      setLoaderStatus(false);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error loading DTI history',
        })
      );
      setLoaderStatus(false);
    }
  };

  const createVerifiedDti = async () => {
    try {
      setLoaderStatus(true);
      await DTIServices.requestVerifiedDTI(user_id, DEFAULT_SOURCE);
      await getHistoryDti();
      setLoaderStatus(false);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error creating request',
        })
      );
      setLoaderStatus(false);
    }
  };

  const createUnVerifiedDti = async () => {
    try {
      setLoaderStatus(true);
      await DTIServices.requestUnVerifiedDTI(user_id, DEFAULT_SOURCE);
      await getHistoryDti();
      setLoaderStatus(false);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error creating request',
        })
      );
      setLoaderStatus(false);
    }
  };

  useEffect(() => {
    getHistoryDti();
  }, []);

  return (
    <div className="p-8">
      <Loader status={loaderStatus} />
      <div className="mx-auto flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex items-center">
            <h3 className="text-3xl font-light">History DTI</h3>
          </div>
          <div className="flex items-center gap-4">
            <button
              className="flex items-center gap-2 py-2 px-8 bg-orange-dti text-white rounded-md"
              onClick={createUnVerifiedDti}
            >
              REQUEST NOT VERIFIED
            </button>
            <button
              className="flex items-center gap-2 py-2 px-8 bg-dark-kiwi-v2 text-white rounded-md"
              onClick={createVerifiedDti}
            >
              REQUEST VERIFIED
            </button>
          </div>
        </div>
        {historyDti?.length ? (
          <ul className="flex flex-col bg-gray-dti rounded-md px-4 pb-2 mt-6">
            {historyDti.map((item, index) => (
              <li
                key={index}
                className="w-full px-4 flex justify-between items-center border-b-2"
              >
                <div className="flex gap-8 py-4">
                  <p className="font-bold min-w-[5rem]">DTI: {item.dti}</p>
                  <p className="min-w-[8rem]">
                    Income: {item.disposable_income}
                  </p>
                  <p>{format(new Date(item.created_at), 'MMMM dd, yyyy')}</p>
                </div>
                {item.is_verified ? (
                  <div className="tracking-extra-wide">
                    <p>
                      <spam className="text-dark-kiwi-v2 font-bold">
                        VERIFIED
                      </spam>{' '}
                      <spam className="text-slate-500">
                        - {item.source.toUpperCase()}
                      </spam>
                    </p>
                  </div>
                ) : (
                  <div className="tracking-extra-wide">
                    <p>
                      <spam className="text-orange-dti font-bold">
                        NOT VERIFIED
                      </spam>{' '}
                      <spam className="text-slate-500">
                        - {item.source.toUpperCase()}
                      </spam>
                    </p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex items-center gap-5 bg-yellow-100 text-yellow-700 rounded-md mt-5 p-5 cursor-pointer">
            <ExclamationTriangleIcon className="h-5 w-5" />
            <p>History Not Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryDTI;
