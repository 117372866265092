import { useEffect, useState } from "react";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import { CommandLineIcon } from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import Api from "redux/api";
import Modal from "components/UI/Modal";
import CreditDataRaw from "components/CreditDataRaw";
import findFactor from 'utils/explanation_codes.js';

import ComplianceCondition from 'components/UserCreditReportDetails/ComplianceCondition';
import Address from 'components/UserCreditReportDetails/Address';
import Characteristic from "components/UserCreditReportDetails/Characteristic";

const creditSummaryHeaders = [
    {
        label: 'High Credit',
        value: 'highCredit'
    },
    {
        label: 'Credit Limit',
        value: 'creditLimit'
    },
    {
        label: 'Balance',
        value: 'currentBalance'
    },
    {
        label: 'Past Due',
        value: 'pastDue'
    },
    {
        label: 'Payment',
        value: 'monthlyPayment'
    }
]

export default function CreditReportDetails() {

    const { user_id, report_id } = useParams();

    const [showCreditDataRaw, setShowCreditDataRaw] = useState(false);
    const [rawCreditReport, setRawCreditReport] = useState(null);
    const [creditReport, setCreditReport] = useState({
        status: null,
        first_name: '- -',
        last_name: '- -',
        ssn: '- -',
        birthdate: '- -',
        score: '- -',
        explanation: [],
        scorecard: '- -',
        addresses: [],
        employments: [],
        creditSummary: null

    });

    useEffect(() => {
        getReport();
    }, [])

    const getReport = async () => {
        const { data } = await Api.fetchCreditReport({ report_id });

        setRawCreditReport(data.credit_report);

        const credit_report = data.credit_report;

        const addresses = credit_report.addresses;
        const employments = credit_report.employments;

        const characteristics = credit_report.code_00wr3 ?
            Array.isArray(credit_report.code_00wr3.characteristic) ?
                credit_report.code_00wr3.characteristic : [credit_report.code_00wr3.characteristic] : [];

        setCreditReport({
            ...creditReport,
            ...credit_report,
            G059S: characteristics.find(item => item.id._text == "G059S"),
            G222S: characteristics.find(item => item.id._text == "G222S"),
            G069S: characteristics.find(item => item.id._text == "G069S"),
            AT103S: characteristics.find(item => item.id._text == "AT103S"),
            AU36S: characteristics.find(item => item.id._text == "AU36S"),
            BC36S: characteristics.find(item => item.id._text == "BC36S"),
            BI36S: characteristics.find(item => item.id._text == "BI36S"),
            BR36S: characteristics.find(item => item.id._text == "BR36S"),
            FI36S: characteristics.find(item => item.id._text == "FI36S"),
            FR36S: characteristics.find(item => item.id._text == "FR36S"),
            IN36S: characteristics.find(item => item.id._text == "IN36S"),
            AT57S: characteristics.find(item => item.id._text == "AT57S"),
            AT36S: characteristics.find(item => item.id._text == "AT36S"),
            G242S: characteristics.find(item => item.id._text == "G242S"),
            MT36S: characteristics.find(item => item.id._text == "MT36S"),
            RE36S: characteristics.find(item => item.id._text == "RE36S"),
            ST36S: characteristics.find(item => item.id._text == "ST36S"),
            explanation: credit_report.factors,
            addresses: addresses ? Array.isArray(addresses) ? addresses : [addresses] : [],
            employments: employments ? Array.isArray(employments) ? employments : [employments] || [] : [],
            collectionCount: credit_report.collection_count || 'n/a',
            historicalNegativeOccurrencesCount: credit_report.historical_negative_occurrences_count || '',
            historicalNegativeTradeCount: credit_report.historical_negative_trade_count || '',
            installmentTradeCount: credit_report.installment_trade_count || '',
            mortgageTradeCount: credit_report.mortgage_trade_count || '',
            negativeTradeCount: credit_report.negative_trade_count || '',
            openTradeCount: credit_report.open_trade_count,
            publicRecordCount: credit_report.public_record_count,
            revolvingTradeCount: credit_report.revolving_trade_count || '',
            totalInquiryCount: credit_report.total_inquiry_count || '',
            totalTradeCount: credit_report.total_trade_count || '',
            unspecifiedTradeCount: credit_report.unspecified_trade_count || '',
            creditSummary: credit_report.credit_summary,
            complianceCondition: credit_report.compliance_condition
        })


    }

    const getSummaryField = (row, column) => {
        if (!creditReport.creditSummary) {
            return '-'
        }

        if (!creditReport?.creditSummary[row]) {
            return '-'
        }

        if (!creditReport?.creditSummary[row][column]) {
            return '-'
        }

        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        });

        return formatter.format(Number(creditReport?.creditSummary[row][column]?._text)) || '-';
    }

    return (
        <div className="bg-white overflow-hidden">
            <div className="flex justify-between items-center px-4 py-5 sm:px-6">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Credit Report</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Credit details about trasunion data.</p>
                </div>
                <div>
                    <Link to={`/users/${user_id}/credit-reports`}>
                        <button className="border border-gray-300 rounded-md shadow-sm py-2 px-4 mr-4"><TableCellsIcon className="h-5 w-5 text-gray-500" /></button>
                    </Link>
                    <button className="border border-gray-300 rounded-md shadow-sm py-2 px-4" onClick={() => setShowCreditDataRaw(!showCreditDataRaw)}><CommandLineIcon className="h-5 w-5 text-gray-500" /></button>
                    {showCreditDataRaw && <Modal onHide={() => { setShowCreditDataRaw(!showCreditDataRaw) }}>
                        <CreditDataRaw value={rawCreditReport} />
                    </Modal>}
                </div>
            </div>
            {rawCreditReport && <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    {creditReport.status && <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div className="bg-red-500 text-white px-4 py-2 rounded">
                                <span className="inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-red-100 text-red-800 mb-1">
                                    <svg className="mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                                        <circle cx={4} cy={4} r={3} />
                                    </svg>
                                    Error
                                </span>
                                <p>This report failed, please check the console <CommandLineIcon className="inline h-4 w-4 text-red-100" /> for more details</p>
                            </div>
                        </dd>
                    </div>}
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">First name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{creditReport.first_name}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Last name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{creditReport.last_name}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Social Security Number</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{creditReport.ssn}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Date of birthdate</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{creditReport.birthdate}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Scoring</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
                            <div className="mr-20">
                                <p>Score</p>
                                <p className="text-2xl">{creditReport.score}</p>
                            </div>
                            <div>
                                <p>Score Card</p>
                                <p className="text-2xl">{creditReport.score_card}</p>
                            </div>
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Explanation</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div className="flex flex-col">
                                <div className="-my-2 -mx-4 overflow-hidden sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Code
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Description
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {creditReport.explanation.map((factor) => (
                                                        <tr key={factor.code._text}>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{factor.code._text}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{findFactor(factor.code._text).description}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>

                    {creditReport["G059S"] && <Characteristic
                        description="Number of trades 30 or more days past due in past 12 months"
                        id="G059S"
                        value={Number(creditReport["G059S"].value._text)}
                    />}

                    {creditReport["G222S"] && <Characteristic
                        description="Number of trades prior 30 days past due, now current, verified in past 12 months"
                        id="G222S"
                        value={Number(creditReport["G222S"].value._text)}
                    />}

                    {creditReport["G069S"] && <Characteristic
                        description="Number of trades 90 or more days past due in past 12 months"
                        id="G069S"
                        value={Number(creditReport["G069S"].value._text)}
                    />}


                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Credit Information Summary</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div className="grid grid-cols-4 gap-y-10 mb-8">
                                <div>
                                    <p className="mb-2">Public Records</p>
                                    <p className="font-bold">{Number(creditReport.publicRecordCount)}</p>
                                </div>
                                <div>
                                    <p className="mb-2">Collections</p>
                                    <p className="font-bold">{Number(creditReport.collectionCount)}</p>
                                </div>
                                <div>
                                    <p className="mb-2">Trades</p>
                                    <p className="font-bold">{Number(creditReport.totalTradeCount)}</p>
                                </div>
                                <div>
                                    <p className="mb-2">Inquiries</p>
                                    <p className="font-bold">{Number(creditReport.totalInquiryCount)}</p>
                                </div>
                                <div>
                                    <p className="mb-2">Negative Trade Accounts</p>
                                    <p className="font-bold">{Number(creditReport.negativeTradeCount)}</p>
                                </div>
                                <div>
                                    <p className="mb-2">Trade Accounts with Any Historical Negatives</p>
                                    <p className="font-bold">{Number(creditReport.historicalNegativeTradeCount)}</p>
                                </div>
                                <div>
                                    <p className="mb-2">Occurrence of Historical Negatives</p>
                                    <p className="font-bold">{Number(creditReport.historicalNegativeOccurrencesCount)}</p>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">

                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            High Credit
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Credit Limit
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Balance
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Past Due
                                                        </th>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Payment
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-3 text-sm font-semibold text-gray-900">Revolving</td>
                                                        {creditSummaryHeaders.map((head) => (
                                                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getSummaryField('revolvingAmount', head.value)}</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-3 text-sm font-semibold text-gray-900">Mortgage</td>
                                                        {creditSummaryHeaders.map((head) => (
                                                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getSummaryField('mortgageAmount', head.value)}</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-3 text-sm font-semibold text-gray-900">Installment</td>
                                                        {creditSummaryHeaders.map((head) => (
                                                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getSummaryField('installmentAmount', head.value)}</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-3 text-sm font-semibold text-gray-900">Open</td>
                                                        {creditSummaryHeaders.map((head) => (
                                                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getSummaryField('openAmount', head.value)}</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-3 text-sm font-semibold text-gray-900">Closed w/Balance</td>
                                                        {creditSummaryHeaders.map((head) => (
                                                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getSummaryField('closedWithBalanceAmount', head.value)}</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-3 text-sm font-semibold text-gray-900">Total</td>
                                                        {creditSummaryHeaders.map((head) => (
                                                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getSummaryField('totalAmount', head.value)}</td>
                                                        ))}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>


                    {creditReport.complianceCondition && <ComplianceCondition value={creditReport.complianceCondition} />}

                    {creditReport["AT103S"] && <Characteristic
                        description="Percentage of satisfactory open trades to all open trades"
                        id="AT103S"
                        value={Number(creditReport["AT103S"].value._text)}
                    />}

                    {creditReport["AU36S"] && <Characteristic
                        description="Months since most recent auto delinquency"
                        id="AU36S"
                        value={Number(creditReport["AU36S"].value._text)}
                    />}

                    {creditReport["BC36S"] && <Characteristic
                        description="Months since most recent credit card delinquency"
                        id="BC36S"
                        value={Number(creditReport["BC36S"].value._text)}
                    />}

                    {creditReport["BI36S"] && <Characteristic
                        description="Months since most recent bank installmet delinquency"
                        id="BI36S"
                        value={Number(creditReport["BI36S"].value._text)}
                    />}

                    {creditReport["BR36S"] && <Characteristic
                        description="Months since most recent bank revolving delinquency"
                        id="BR36S"
                        value={Number(creditReport["BR36S"].value._text)}
                    />}

                    {creditReport["FI36S"] && <Characteristic
                        description="Months since most recent finance installment delinquency"
                        id="FI36S"
                        value={Number(creditReport["FI36S"].value._text)}
                    />}

                    {creditReport["FR36S"] && <Characteristic
                        description="Months since most recent finance revolving delinquency"
                        id="FR36S"
                        value={Number(creditReport["FR36S"].value._text)}
                    />}

                    {creditReport["IN36S"] && <Characteristic
                        description="Most since most recent installment delinquency"
                        id="IN36S"
                        value={Number(creditReport["IN36S"].value._text)}
                    />}
                    {creditReport["AT57S"] && <Characteristic
                        description="Most since most recent installment delinquency"
                        id="AT57S"
                        value={Number(creditReport["AT57S"].value._text)}
                    />}
                    {creditReport["AT36S"] && <Characteristic
                        description="Most since most recent installment delinquency"
                        id="AT36S"
                        value={Number(creditReport["AT36S"].value._text)}
                    />}
                    {creditReport["G242S"] && <Characteristic
                        description="Most since most recent installment delinquency"
                        id="G242S"
                        value={Number(creditReport["G242S"].value._text)}
                    />}

                    {creditReport["MT36S"] && <Characteristic
                        description="Months since most recent mortage delinquency"
                        id="MT36S"
                        value={Number(creditReport["MT36S"].value._text)}
                    />}

                    {creditReport["RE36S"] && <Characteristic
                        description="Months since most recent revolving delinquency"
                        id="RE36S"
                        value={Number(creditReport["RE36S"].value._text)}
                    />}

                    {creditReport["ST36S"] && <Characteristic
                        description="Months since most recent student loan delinquency"
                        id="ST36S"
                        value={Number(creditReport["ST36S"].value._text)}
                    />}

                    <Address value={creditReport.addresses} />

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Where work?</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div className="flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Company Name
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Position
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Start / End
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            In File Since
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {creditReport.employments.map((employment) => (
                                                        <tr key={employment.id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {employment.employer?.unparsed?._text}
                                                            </td>
                                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employment.occupation._text}</td> */}
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employment.email}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employment.dateOnFileSince?._text}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>}
        </div>
    )
}