import Tooltip from "components/UI/Tooltip";
import { InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

const get_icono = (icon) => {
    let result = ''
    switch (icon) {
        case 'question':
            result = (<QuestionMarkCircleIcon className="h-4 w-4 text-gray-400" />)
            break;
        case 'info':
            result = (<InformationCircleIcon className="h-4 w-4 text-gray-400" />)
            break;
    }

    return result
}

export default function Help({ type = 'question', message, children }) {
    return (
        <>
            <div className="flex flex-wrap items-center">
                <div>
                    {children}
                </div>

                <div className="ml-1">
                    <Tooltip tooltip={message} place="top">
                        {get_icono(type)}
                    </Tooltip>
                </div>
            </div>
        </>
    )
}