import { XMarkIcon } from "@heroicons/react/24/outline";
import BaseModal from "components/BaseModal";

export default function TransactionModal({
  closeModal,
  isOpen,
  currentTier,
  columns,
  contentTable
}) {
  return (
    <BaseModal closeModal={closeModal} isOpen={isOpen}>
      <div className="p-8 min-w-[54rem] flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center gap-10">
            <h2 className="font-semibold text-lg text-gray-500 capitalize">
            {currentTier} Transactions
            </h2>
          </div>
          <XMarkIcon
            onClick={closeModal}
            className="h-6 w-6 stroke-gray-400 cursor-pointer"
          />
        </div>
        <>
          {contentTable?.length && contentTable?.length > 0 ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className=" text-sm text-gray-500 bg-gray-50 font-medium">
                  <tr>
                    {columns.map((column, index) =>
                        <th key={index} scope="col" className="px-6 py-3 font-semibold capitalize">
                            {column.replaceAll('_', ' ')}
                        </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                    {contentTable?.map((row, index) => 
                        <tr key={index} >
                            <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                                {row.account_id}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                                {row.amount}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                                {row.date}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                                {row.income_identification_category}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                                {row.name}
                            </td>
                        </tr>
                    )}
                </tbody>
              </table>
            </div>
          ) : (
            <h2>Transactions Not Found</h2>
          )}
        </>
      </div>
    </BaseModal>
  );
}
