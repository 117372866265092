import React, { useEffect, useState } from 'react'
import Filter from 'components/IncreaseLimitRequests/LateralNav'
import ListApplication from 'components/IncreaseLimitRequests/ListApplication';
import Loader from "components/UI/Loader";
import CreditLimitIncrease from 'services/CreditLimitIncrease/CreditLimitIncrease.services';

const IncreaseLimitRequests = () => {
  const [applicationsIncresaeLimit, setApplicationsIncresaeLimit] = useState(null)
  const [loader, setLoader] = useState(false);

  const handleSortList = (data) => {
    let sortedList = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    setApplicationsIncresaeLimit(sortedList)
  }

  const fetchApplicationsIncrease = async () => {
    setLoader(true);

    try {
      const response = await CreditLimitIncrease.getRequestLimitCredit()
      handleSortList(response.data.data)
    } catch (error) {
      alert("ERROR")
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (applicationsIncresaeLimit !== null) return

    fetchApplicationsIncrease()

  }, [applicationsIncresaeLimit])

  return (
    <div className="loans w-full">
      <Loader status={loader} />
      <Filter />
      <ListApplication applicationsIncresaeLimit={applicationsIncresaeLimit} />
    </div>
  )
}

export default IncreaseLimitRequests