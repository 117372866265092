import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "redux/api";
import Loader from "components/UI/Loader";

import Transations from 'pages/Dashboard/User/BankData/Transations'

export default function Example() {
    const user = useSelector(state => state.user);
    const [data_account, setDataAccount] = useState({})
    const { account_id } = useParams();
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (user) {
            getBankData()
        }
    }, [user])

    const getBankData = async () => {
        try {
            setLoader(true)
            let bank_data_report = await Api.fetchBankData({
                user_id: user.id,
                firebase_id: user.firebase_id
            });

            let accounts = bank_data_report.data.data?.accounts || '{}'
            let transactions = bank_data_report.data.data?.transactions || '[]'

            accounts = accounts?.accounts ? accounts : JSON.parse(accounts)
            transactions = transactions?.transactions ? transactions : JSON.parse(transactions)

            let account = accounts.accounts.find(e => e.account_id == account_id)
            let historical_transations = transactions.transactions.filter(e => e.account_id == account_id)

            setLoader(false)

            setDataAccount({
                account,
                historical_transations,
            })

        } catch (error) {
            setLoader(false)
            console.log("[Error, conultando bank data]", error)
        }
    }

    return (
        <div className="bg-white overflow-hidden">
            <div className="flex justify-between items-center px-4 py-5 sm:px-6">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Bank Data</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about bank data.</p>
                </div>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-900">Number in dictionary: </dt>
                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2"> {`${data_account.account?.account_id || ''} `} </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-900">Mask:</dt>
                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${data_account.account?.mask || ''}`}</dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-900">Name:</dt>
                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${data_account.account?.name || ''}`}</dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-900">Subtype: </dt>
                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${data_account.account?.subtype || ''}`}</dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-900">Available Balance:  </dt>
                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${data_account.account?.balances?.available || ''}`}</dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-900">Current Balance:  </dt>
                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${data_account.account?.balances?.current || ''}`}</dd>
                    </div>

                </dl>
            </div>

            <div>
                <div className={`mt-8 grid grid-cols-1 gap-4 `}>

                    <div className="inline-block min-w-full p-2 align-middle">
                        <Transations data={data_account.historical_transations} />
                    </div>

                </div>

            </div>
            <Loader status={loader}></Loader>
        </div>
    )
}