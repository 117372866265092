import axios from 'axios';

export const ReferralServices = {
    getReferralsReadyToPay: async ({ take, skip, referrer, order, search }) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/referrals/ready-to-pay`,
            params: {
                skip,
                take,
                referrer,
                order,
                search
            }
        });
    },
    gatReferralsPaid: async ({ take, skip, referrer, order, search }) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/referrals/paid`,
            params: {
                skip,
                take,
                referrer,
                order,
                search
            }
        });
    },
    newPaymentReferral: async (data) => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/referrals/create-pay`,
            data:{
                ...data
            }
        });
    },
    gatReferralsNoReady: async ({ take = 50, skip = 50, referrer, order, search }) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/referrals`,
            params: {
                skip,
                take,
                referrer,
                order,
                search
            }
        });
    },
    downloadReferrals: async () => {
        return await axios({
            url: `${process.env.REACT_APP_API_URL}/referrals/download`, //your url
            method: 'POST',
            responseType: 'blob' // important
        })
    },
    downloadReferralsRedayToPay: async () => {
        return await axios({
            url: `${process.env.REACT_APP_API_URL}/referrals/ready-to-pay/download`, //your url
            method: 'GET',
            responseType: 'blob' // important
        })
    },
    downloadReferralsPaid: async () => {
        return await axios({
            url: `${process.env.REACT_APP_API_URL}/referrals/paid/download`, //your url
            method: 'GET',
            responseType: 'blob' // important
        })
    },
}