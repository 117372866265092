import { useState } from "react";
import Api from "redux/api"
import { useSelector, useDispatch } from "react-redux";
import { actions } from "redux/reducer";

const type_rejections = [
    {
        label: "Bank Statements",
        value: "bank_statements"
    },
    {
        label: "Plaid",
        value: "plaid"
    }
]

const Pending = ({ application, onMove, onCreate }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [reason, setReason] = useState("");

    const handleReason = (event) => {
        setReason(event.target.value);
    }

    const create = async () => {
        try {
            if (!reason) {
                return dispatch(actions.setMessage({
                    type: 'wrong', title: 'Wrong', message: 'Select a reason'
                }))
            }

            const response = await Api.createLoanStatus({
                application_id: application.id,
                value: "pending",
                reason,
                member_id: auth.id
            })
            onCreate(response.data.status);
            onMove("list");
        } catch (error) {

        }
    }

    return <div className="mt-auto">
        <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Reasons
            </label>
            <select
                onChange={handleReason}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
            >
                <option>Choose an option</option>
                {type_rejections.map((item, key) => <option key={key} value={item.value}>{item.label}</option>)}
            </select>
        </div>
        <div className="mt-auto px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
                onClick={create}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
            >
                Create Status
            </button>
        </div>
    </div>
}

export default Pending;