import algoliasearch from 'algoliasearch';
import { useEffect, useState } from 'react';
import Api from 'redux/api';

const client = algoliasearch('JCDS3JARR4', '10eb232a1ac8f77573e75357c31b4777');
const index = client.initIndex('dev_kiwi');

const SearchUserInput = () => {

    const [users, setUsers] = useState(null);
    const [searchValue, setSearchValue] = useState('carlos');
    const [loading, setLoading] = useState(true);
		const [error, setError] = useState(null);

    useEffect(() => {
        Api.fetchUsers({ search_value: searchValue })
		.then((data) => {
				const users = data.data.data.users;
				users.forEach((user) => {
					user.objectID = user.id;
				});
				setUsers(users);
				//this is when we need upload the data in algolia
				index.saveObjects(users);
		})
		.catch((error) => {
				setError(error);
		})
		.finally(() => {
				setLoading(false);
		})
    }, []);

    useEffect(() => {
			const fetchData = async () => {
				const currentSearch = await index.search(searchValue);
				setUsers(currentSearch.hits);
				return;
			};
			fetchData();
		}, [searchValue]);


    return (
        <div>
			<input type="text" onChange={(e) => setSearchValue(e.target.value)} />
			{error && <div>{error}</div>}
            {loading && <div>Loading...</div>}
            <ul>
                {users?.map((user) => (
                    <li key={user.id}>
                        {user.name} ---- {user.email}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchUserInput;
