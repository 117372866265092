import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import { FolderOpenIcon } from '@heroicons/react/24/solid';
import { navigationRoutes } from 'components/Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [isLoading, setLoading] = useState(true);
  const [homeRoutes, setHomeRoutes] = useState([]);

  const formatNavigation = () => {
    let routes = {
      general: [],
    };
    for (let index = 0; index < navigationRoutes.length; index++) {
      const element = navigationRoutes[index];
      if (!element.children && !element.show) routes.general.push(element);
      if (element.children && (!element.show || element.href === '/users')) {
        if (routes[element.name]) routes[element.name].push(element);
        else {
          routes = { ...routes, ...{ [element.name]: element.children } };
        }
      }
    }

    setHomeRoutes(routes);
    setLoading(false);
  };

  useEffect(() => {
    if (navigationRoutes) formatNavigation();
  }, [navigationRoutes]);

  const openDashboardGpt = (item) => {
    if (item.name == 'Tickets') {
      let domain = process.env.REACT_APP_GPT_DASHBOARD || ""

      domain = `${domain.startsWith("localhost") ? 'http://' : 'https://'}${domain}`
      window.open(domain, '_blank').focus()
    }
  }

  return (
    <div className="w-full h-dashboard bg-gray-50-kiwi overflow-auto px-4 sm:px-6 lg:px-8 py-12 flex justify-center items-start">
      <div className="max-w-5xl">
        <div className="bg-blue-50-kiwi py-8 rounded-md px-4 mb-12">
          <h1 className="text-2xl font-bold text-dark-kiwi">Kiwi CMS 💡</h1>
          <p className="text-sm text-gray-500">
            Manage loans and clients information
          </p>
        </div>
        <div className='grid grid-cols-1 gap-10'>
          {!isLoading &&
            Object.keys(homeRoutes).map((group) => (
              <div className="">
                {group !== 'general' && <h3 className='mb-2'>{group}</h3>}
                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {homeRoutes[group].map((item) => (
                    <Link
                      key={item.href}
                      to={item.href}
                      className="bg-white relative hover:shadow-none py-6 px-5 rounded-md shadow-md h-full flex"
                      onClick={item.name === 'Tickets' ?  () => { openDashboardGpt(item) } : undefined}
                    >
                      <div className="absolute right-4 top-4">
                        <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
                      </div>
                      <div className="flex gap-4">
                        <i className="bg-blue-50-kiwi p-2 h-10 w-10 min-w-[2.5rem] text-blue-kiwi flex items-center justify-center rounded-lg">
                          {item.icon ? (
                            <item.icon className="h-6 w-6" />
                          ) : (
                            <FolderOpenIcon className="h-6 w-6" />
                          )}
                        </i>
                        <div>
                          <h1 className="text-xl font-semibold text-dark-kiwi">
                            {item.name}
                          </h1>
                          <p className="text-gray-400 text-xs mt-2">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
