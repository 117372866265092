import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from 'redux/api';

import GeneralError from 'components/GeneralError';
import List from './List';
import Sidebar from './Sidebar';

export default function Verifications() {
  const params = useParams();

  const [dataVerification, setDataVerification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchDataVerifications = async () => {
    setError(false);
    setLoading(true);
    setDataVerification(null);
    try {
      const response = await Api.fetchVerifications(params);
      const {
        data: { data: verifications, success },
      } = response;

      if (!success) throw new Error();

      setDataVerification(verifications.length > 0 ? verifications : null);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataVerifications();
  }, [params]);

  return (
    <div className="grid grid-cols-[auto_1fr] w-full">
      <Sidebar />
      <div className="bg-gray-100 w-full h-dashboard p-8 overflow-y-auto flex flex-col">
        {!error ? (
          <List data={dataVerification} loader={loading} />
        ) : (
          <GeneralError tryAgain={fetchDataVerifications} />
        )}
      </div>
    </div>
  );
}
