import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const DTIServices = {
    getHistory: async (user_id) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/ds/dti/history/${user_id}`,
            headers: HEADERS()
        });
    },
    requestVerifiedDTI: async (user_id, source) => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/ds/dti/create/verified`,
            data: {
                user_id,
                source
            },
            headers: HEADERS()
        });
    },
    requestUnVerifiedDTI: async (user_id, source) => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/ds/dti/create/unverified`,
            data: {
                user_id,
                source
            },
            headers: HEADERS()
        });
    }
}