import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from '@heroicons/react/24/outline';
import { UserServices } from "services/User/User.services";

export default function DeleteModal({ showModal = false, data, closeModal, onDelete } = {}) {

    const EMPTY_STRING = '';
    const DELETE_RESTRICTED = 'DELETE_RESTRICTED';

    const REASONS = [
        "Cuenta duplicada",
        "Cambio de número",
        "Familiar creo la cuenta",
        "Ya no necesita el prestamo",
        "Ingreso los datos incorrectos",
        "No recibe ingresos mensuales",
        "Intereses muy altos",
        "Autorelleno, datos incorrectos",
        "Quiere un monto mayor",
        "No quiere enviar estados bancarios",
        "No desea continuar",
        "Solicitud de eliminación de datos",
        "Posible robo de identidad",
        "Otra persona tenia el numero y ya estaba registrado"
    ]

    const navigate = useNavigate();
    const [errorReasonValidation, setErrorReasonValidation] = useState(false);
    const [errorVerificationCodeValidation, setErrorVerificationCodeValidation] = useState(false);
    const [reasonDelete, setReasonDelete] = useState(EMPTY_STRING);
    const [commentDelete, setCommentDelete] = useState(EMPTY_STRING);
    const [verificationCode, setVerificationCode] = useState(EMPTY_STRING);

    const resetDeleteUserInputs = () => {
        setReasonDelete(EMPTY_STRING);
        setCommentDelete(EMPTY_STRING);
    }

    const hasErrorsDeleteInputs = () => {
        if (reasonDelete.length === 0) {
            setErrorReasonValidation(true);
            return true;
        }

        if (verificationCode.toLowerCase() !== 'delete') {
            setErrorVerificationCodeValidation(true);
            return true;
        }

        return false;
    }

    const showDeleteNotification = (status, paramsNotification) => {
        onDelete(paramsNotification);
    }

    const returnToUsers = () => {
        navigate("/users");
    }

    const deleteUser = async () => {
        try {
            if (hasErrorsDeleteInputs()) {
                return;
            }
            if (data.user.is_deleted) {
                resetDeleteUserInputs();
                closeModal();
                showDeleteNotification(
                    true,
                    {
                        type: 'success',
                        title: 'Succesful',
                        message: 'Usuario eliminado'
                    });
                return;
            }
            await UserServices.deleteUser(data.user.id, data.auth.id, commentDelete, reasonDelete);
            showDeleteNotification(
                true,
                {
                    type: 'success',
                    title: 'Succesful',
                    message: 'Usuario eliminado'
                });
            resetDeleteUserInputs();
            closeModal();
            returnToUsers();
        } catch (error) {
            const { data } = error.response ?? {}
            if (data) {
                if (data.messages.includes(DELETE_RESTRICTED)) {
                    showDeleteNotification(
                        true,
                        {
                            type: 'wrong',
                            title: 'Error',
                            message: 'Este usuario no se puede eliminar'
                        });
                }
            } else {
                showDeleteNotification(
                    true,
                    {
                        type: 'wrong',
                        title: 'Error',
                        message: 'Fallo en la eliminación'
                    });
            }
        }
    }

    return (
        <>
            {
                showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 mx-auto max-w-5xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                    {/*header*/}
                                    <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                        <TrashIcon className="mt-1.5 mr-1 h-5 w-5" aria-hidden="true" />

                                        <h3 className="text-2xl font-semibold">
                                            Delete user
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={closeModal}
                                        >
                                            <span className="text-black-900 font-semibold h-6 w-6 block">
                                                ×
                                            </span>
                                        </button>
                                    </div>

                                    {/*body*/}
                                    <div className="relative  flex-auto">
                                        <div className="mt-10 sm:mt-0">

                                            <div className="mt-5 md:mt-0 md:col-span-2">

                                                <div className="px-4 py-4 bg-white ">
                                                    <div className="grid grid-cols-12 gap-3">

                                                        <div className="col-span-12 sm:col-span-12">
                                                            <div className="mt-6 grid gap-y-6 pb-4">
                                                                <div className="">
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700 cursor-pointer"
                                                                        htmlFor="delete-reason"
                                                                    >
                                                                        Reason
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <select
                                                                            id="delete-reason"
                                                                            onFocus={() => setErrorReasonValidation(false)}
                                                                            onChange={(event) => setReasonDelete(event.target.value)}
                                                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
                                                                        >
                                                                            <option disabled selected>Choose an option</option>
                                                                            {REASONS.map(item => <option value={item} key={item}>{item}</option>)}
                                                                            <option value="Otro">Otro</option>
                                                                        </select>
                                                                    </div>
                                                                    {errorReasonValidation ? <span className="text-red-400 text-sm">*Agregar razón</span> : ''}
                                                                </div>


                                                                <div className="">
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700 cursor-pointer"
                                                                        htmlFor="delete-comments"
                                                                    >
                                                                        Comments
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <textarea
                                                                            id="delete-comments"
                                                                            type="text"
                                                                            rows="3"
                                                                            className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md resize-none"
                                                                            value={commentDelete}
                                                                            onFocus={() => setErrorReasonValidation(false)}
                                                                            onChange={(event) => setCommentDelete(event.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-start justify-end">
                                                                    <p className="text-sm mr-2">¿Are you sure you want to delete this account? <br />Type 'delete' to the right and click 'confirm'</p>
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            value={verificationCode}
                                                                            onFocus={() => setErrorVerificationCodeValidation(false)}
                                                                            onChange={(event) => { setVerificationCode(event.target.value) }}
                                                                            placeholder="type delete"
                                                                            name="verification_code"
                                                                            autoComplete="verification_code"
                                                                            className="w-60 mr-2 shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                                                        />
                                                                        {errorVerificationCodeValidation ? <span className="text-red-400 text-sm">The field is empty or incorrect</span> : ''}
                                                                    </div>
                                                                    <button type="button"
                                                                        className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                        onClick={() => deleteUser()}
                                                                    >
                                                                        Confirm
                                                                    </button>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-4 rounded-b">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
        </>
    )
}