import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Listbox } from '@headlessui/react';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import GeneralAlert from '../../../../components/GeneralAlert';

const statusOptions = [
  { status: 'resolved', styles: 'bg-green-100 text-green-600' },
  { status: 'rejected', styles: 'bg-amber-100 text-amber-600' },
  { status: 'cancelled', styles: 'bg-red-100 text-red-600' },
];

export default function PendingVerifications({
  data,
  setPendingData,
  updateItemVerification,
  loader,
}) {
  const handleStatusChange = (item_id, new_status) => {
    setPendingData(prevData =>
      prevData.map(item =>
        item.id === item_id
          ? {
              ...item,
              current_status: new_status,
              styles: statusOptions.find(option => option.status === new_status)
                .styles,
            }
          : item
      )
    );
  };

  const handlePayloadChange = (item_id, new_payload) => {
    setPendingData(prevData =>
      prevData.map(item =>
        item.id === item_id ? { ...item, payload: new_payload } : item
      )
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <p className="font-semibold">Pending Verifications</p>
      {loader ? (
        <Skeleton height={64} borderRadius={8} />
      ) : (
        <>
          {data ? (
            <div className="grid grid-cols-2 gap-5 w-full">
              {data.map((item, index) => (
                <div
                  key={index}
                  className="border p-5 rounded-lg flex flex-col gap-2.5 items-start w-full h-min select-none"
                >
                  <div className="flex items-center gap-5 w-full">
                    <div className="bg-green-100 text-green-600 rounded-full min-h-[3rem] min-w-[3rem] h-12 w-12 flex items-center justify-center">
                      <p className="font-semibold text-sm">
                        {item?.label
                          .split(' ')
                          .slice(0, 2)
                          .map(word => word[0])
                          .join('')
                          .toUpperCase()}
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="font-semibold">{item.label}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2.5 w-full">
                    <div>
                      <p className="text-xs font-semibold">Created at</p>
                      <p className="font-light text-sm">
                        {format(new Date(item.created_at), 'MMM dd yyyy')}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs font-semibold">Status</p>
                      <Listbox
                        value={item.current_status}
                        onChange={new_status =>
                          handleStatusChange(item.id, new_status)
                        }
                        as="div"
                        className="relative"
                      >
                        <Listbox.Button>
                          <div
                            className={`${
                              item.styles || 'bg-gray-100 text-gray-600'
                            } font-light text-sm flex items-center gap-2.5 w-max px-4 py-0.5 rounded-lg`}
                          >
                            <p className="capitalize">{item.current_status}</p>
                            <ChevronDownIcon className="h-4" />
                          </div>
                        </Listbox.Button>
                        <Listbox.Options className="bg-white shadow-xl rounded-lg w-max p-2 absolute right-2.5 flex flex-col gap-2.5">
                          {statusOptions.map((status, index) => (
                            <Listbox.Option
                              key={index}
                              value={status.status}
                              className="px-2.5 py-1 rounded hover:bg-green-50 cursor-pointer text-sm capitalize"
                            >
                              {status.status}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </div>
                  </div>

                  <div className="w-full">
                    <p className="text-xs font-semibold">Comments</p>
                    <textarea
                      onChange={e =>
                        handlePayloadChange(item.id, e.target.value)
                      }
                      className="bg-gray-50 border-gray-200 w-full rounded-lg placeholder:text-sm placeholder:font-light"
                      placeholder="Enter a comment if required"
                    />
                  </div>
                  <button
                    onClick={() => updateItemVerification(item)}
                    disabled={item.current_status === item.status}
                    className={` ${
                      item.current_status !== item.status
                        ? 'bg-green-400 text-white'
                        : 'bg-gray-100 text-gray-600'
                    } w-full  text-sm py-2 rounded-lg`}
                  >
                    Update verification
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <GeneralAlert description="No information for this section" />
          )}
        </>
      )}
    </div>
  );
}
