import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/solid"

import Details from './Details'
import Actions from './Actions';
import { classNames } from 'utils/class_names';
import Help from "components/UI/Help";

export default ({ dataFile = {}, setLoader, refresh, defaultShow = false } = {}) => {
    const get_status = (status) => {
        let data = {}

        if ([null, ""].includes(status)) {
            data = { text: "Pending", class: "bg-yellow-100 text-yellow-800" };
        } else if (!status) {
            data = { text: "Declined", class: "bg-red-100 text-red-800" };
        } else if (status) {
            data = { text: "Approved", class: "bg-green-100 text-green-800" };
        }

        return <span
            className={"inline-flex rounded-full px-2 text-xs uppercase font-semibold leading-5 " + data.class}
        >
            {data.text}
        </span>
    }

    return (<>
        <Disclosure as="div" className="bg-white overflow-hidden mb-2">
            {
                ({ open }) => (<>
                    <h3 className="flow-root px-4 py-3 sm:px-6 bg-gray-100">
                        <div className={classNames(dataFile.active == false ? 'grid-cols-6' : 'grid-cols-4', 'w-full grid text-gray-400 hover:text-gray-500')}>
                            <h3 className="col-span-2 text-md leading-10 font-semibold text-gray-900 items-center">
                                {`${dataFile?.type || ''}`}
                            </h3>

                            <div className='flex justify-center items-center text-center'>
                                <span className='text-black font-medium '>Status: &nbsp;</span>
                                {get_status(dataFile.active)}
                            </div>

                            {
                                dataFile?.active == false && (
                                    <div className='ml-5 col-span-2 flex justify-center items-center text-center'>
                                        <span className='text-black font-medium'>Comment:</span>

                                        <div className='truncate'>
                                            &nbsp; {dataFile.comment} &nbsp;
                                        </div>

                                        <Help message={dataFile.comment} type='info'>
                                        </Help>
                                    </div>
                                )
                            }

                            <div className='flex justify-end'>
                                {(dataFile.active === null || dataFile.active === "") && <div>
                                    <Actions
                                        item={dataFile}
                                        setLoader={setLoader}
                                        refresh={refresh}
                                    />
                                </div>}
                                <Disclosure.Button>
                                    <span className="ml-2 flex items-center">
                                        {(defaultShow ? !open : open) ? (
                                            <MinusSmallIcon className="h-5 w-5" aria-hidden="true" />
                                        ) : (
                                            <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
                                        )}
                                    </span>
                                </Disclosure.Button>
                            </div>
                        </div>
                    </h3>

                    {
                        (defaultShow ? !open : open) && (
                            <div>
                                <Disclosure.Panel className="border-t-1 border-t-gray-300" static>
                                    <div className="border-gray-100 px-4 py-5 sm:p-0">
                                        <Details dataFiles={dataFile} />
                                    </div>
                                </Disclosure.Panel>
                            </div>
                        )
                    }
                </>)
            }
        </Disclosure>
    </>)
}