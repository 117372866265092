import React, { useState, useEffect } from "react";
import { UserIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import SwitchStatus from "components/UI/SwitchStatus";

import Loader from "components/UI/Loader";
import CollapsePermissions from "./CollapsePermissions";
import { useParams } from 'react-router-dom';

import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

const dataForm = () => {
    return {
        first_name: '',
        last_name: '',
        address: '',
        email: '',
        phone_number: '',
        password: '',
        profile: '',
        created_at: '',
        active: true,
        method: 'create'
    }
}

const ModalBNPLStore = ({ show = false, close, dataBNPLUser = {} }) => {
    const dispatch = useDispatch();

    const { store_id } = useParams();
    const [form, setForm] = useState(dataForm)

    const [loader, setLoader] = useState(false)

    const handleChangeForm = (e) => {
        const { name, value } = e.target;

        setForm(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    useEffect(() => {

        if (!dataBNPLUser.id) setForm(dataForm);
        else {
            dataBNPLUser.method = 'update'
            setForm(structuredClone(dataBNPLUser));
        }

    }, [dataBNPLUser])

    const validateForm = () => {
        if (!form.first_name) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'First name is requerid'
            }))
        } else if (!form.last_name) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Last name is requerid'
            }))
        } else if (!form.address) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Street address is requerid'
            }))
        } else if (!form.email) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Email is requerid'
            }))
        } else if (!form.phone_number) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Phone number is requerid'
            }))
        } else if (!form.password) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Password is requerid'
            }))
        } else if (!form.profile) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Profile is requerid'
            }))
        } else {
            saveBNPLUser(form);
        }
    }

    const saveBNPLUser = async (data) => {
        try {
            setLoader(true)

            await axios({
                method: data.method === 'update' ? `PUT` : 'POST',
                url: `${process.env.REACT_APP_API_URL}/bnpl_user`,
                data: {
                    ...data,
                    bnpl_store_id: store_id
                }
            })

            setLoader(false)
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'User store saved'
            }))
            close(true)
        } catch (error) {
            console.log(error)
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error saving'
            }))
        }
    }

    return (
        <>
            {show ? <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-500 outline-none focus:outline-none bg-black bg-opacity-20">
                <div className="relative w-[50rem] my-6 mx-auto max-w-2xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*header*/}
                        <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                            <UserIcon className="mt-1.5 mr-1 h-5 w-5" aria-hidden="true" />

                            <h3 className="text-2xl font-semibold">
                                {form?.method === 'create' ? 'New user store' : 'Update user store'}
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => close()}
                            >
                                <span className="text-black-900 font-semibold h-6 w-6 block">
                                    ×
                                </span>
                            </button>
                        </div>

                        {/*body*/}
                        <div className="relative flex-auto max-h-[570px] overflow-y-auto">
                            <div className="mt-10 sm:mt-0">

                                <div className="mt-5 md:mt-0 md:col-span-2">

                                    <div className="px-4 py-4 bg-white ">
                                        <div className="grid grid-cols-12 gap-3">
                                            <div className="col-span-12 sm:col-span-12 flex justify-start">

                                                <SwitchStatus
                                                    checked={form.active}
                                                    onChange={(e) => handleChangeForm({ target: { value: e, name: 'active' } })}
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={form.first_name}
                                                    onChange={handleChangeForm}
                                                    autoComplete="first_name"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Last name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={form.last_name}
                                                    onChange={handleChangeForm}
                                                    autoComplete="last_name"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Street Address
                                                </label>
                                                <input
                                                    type="text"
                                                    name="address"
                                                    value={form.address}
                                                    onChange={handleChangeForm}
                                                    autoComplete="address"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Email address
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={form.email}
                                                    onChange={handleChangeForm}
                                                    autoComplete="email"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Phone number
                                                </label>
                                                <input
                                                    type="text"
                                                    name="phone_number"
                                                    value={form.phone_number}
                                                    onChange={handleChangeForm}
                                                    autoComplete="phone_number"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    disabled={form?.method === 'create' ? false : true}
                                                    value={form.password}
                                                    onChange={handleChangeForm}
                                                    autoComplete="password"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-12 sm:col-span-12">
                                                <CollapsePermissions
                                                    selected={form?.profile}
                                                    setSelected={(e) => { handleChangeForm({ target: { value: e, name: 'profile' } }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-red-500 text-white active:bg-red-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-red-700 mr-1"
                                type="button"
                                onClick={() => close()}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-emerald-500 text-white active:bg-emerald-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-emerald-700"
                                type="button"
                                onClick={validateForm}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> : ''
            }

            <Loader status={loader}></Loader>
        </>
    )
}

export default ModalBNPLStore;