import { Link, useLocation } from "react-router-dom";

const folders = [
    {
        name: 'Referrals',
        options: [
            { id: '01', name: 'Ready to pay', description: '', value: 'ready_to_pay', },
            { id: '01', name: 'Paid', description: '', value: 'paid', }
        ]
    }
]

function ReferralsFilters() {

    const { pathname } = useLocation();

    return (<div className="min-w-fit top-0 border-r">
        <ul className="pt-6">
            {folders.map((folder, index) => <li className="px-5" key={index}>
                <p>{folder.name}</p>
                <ul className="ml-6 mt-1 mb-2 border-l border-black">
                    {folder.options.map((option, key) => <li className="flex" key={key}>
                        <Link to={`/referrals/${option.value}`} className={`px-4 py-2 ${pathname.includes(option.value) ? "text-blue-kiwi font-semibold" : "text-gray-600"}`}>
                            <p>{option.name} <span className="text-gray-400">{option.description}</span></p>
                        </Link>
                    </li>)}
                </ul>
            </li>)}
        </ul>
    </div>)
}

export default ReferralsFilters;