import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function Notification({ open, onHide, params }) {

    const getTypeIcon = (type) => {
        let icon = ''

        switch (type) {
            case 'success':
                icon = <CheckCircleIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                break;
            case 'warning':
                icon = <ExclamationCircleIcon className="h-6 w-6 text-amber-500" aria-hidden="true" />
                break;
            case 'wrong':
                icon = <ExclamationCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
                break;
            default:
                icon = <ExclamationCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
                break;
        }

        return icon
    }

    if (open) {
        let time = params.timeout ? params.timeout : 5000;
        setTimeout(() => { onHide() }, time);
    }

    return (
        <>
            {open ? <div id={Math.random()} aria-live="assertive"
                className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-end"
            >
                <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    {getTypeIcon(params?.type)}
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">{params?.title ? params.title : 'Notification'}</p>
                                    <p className="mt-1 text-sm text-gray-500">{`${params?.message}`}</p>
                                </div>
                                <div className="ml-4 flex-shrink-0 flex">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                                        onClick={onHide}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
            }
        </>
    )
}