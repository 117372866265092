import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const ModalNewTerms = ({
  showModal = false,
  closeModal,
  onSubmit,
  application,
  newData,
} = {}) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                  <InformationCircleIcon
                    className="mt-1.5 mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                  <h3 className="text-2xl font-semibold">
                    New Terms Validation
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeModal}
                  >
                    <span className="text-black-900 font-semibold h-6 w-6 block">
                      ×
                    </span>
                  </button>
                </div>

                {/*body*/}
                <div className="relative  flex-auto">
                  <div className="mt-10 sm:mt-0">
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="px-4 py-4 bg-white ">
                        <div className="grid grid-cols-12 gap-3">
                          <div className="col-span-12 sm:col-span-12">
                            <div className="mt-6 grid gap-y-6 pb-4">
                              <div className="">
                                <label
                                  className="block text-lg font-large text-gray-700 cursor-pointer"
                                  htmlFor="message"
                                >
                                  Are you sure to change the current terms?
                                </label>

                                <h1 class="text-lg font-bold mt-2">Current</h1>

                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                  <thead>
                                    <tr>
                                      <th>Interest rate</th>
                                      <th>Credit limit</th>
                                      <th>Months</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td> {application.interest_rate}</td>
                                      <td>{application.credit_limit}</td>
                                      <td>{application.term_months}</td>
                                    </tr>
                                  </tbody>
                                </table>

                                <h1 class="text-lg font-bold mt-2">New</h1>

                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                  <thead>
                                    <tr>
                                      <th>Interest rate</th>
                                      <th>Credit limit</th>
                                      <th>Months</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td> {newData.interest_rate}</td>
                                      <td>{newData.credit_limit}</td>
                                      <td>{newData.term_months}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="flex items-start justify-end">
                                <button
                                  type="button"
                                  className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-sky-500"
                                  onClick={() => onSubmit()}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-4 rounded-b"></div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalNewTerms;
