import {DocumentIcon} from '@heroicons/react/24/outline';
import {Link, useLocation} from 'react-router-dom';

const filtersBy = [
    {
        title: 'Type verification',
        icon: DocumentIcon,
        items: [
            {label: 'All', query: ''},
            {label: 'Final Verification', query: 'final_verification'},
            {
                label: 'Duplicated Bank Account',
                query: 'duplicated_bank_account',
            },
            {label: 'Disbursement Check', query: 'disbursement_check'},
            {label: 'Repayment Check', query: 'repayment_check'},
            {label: 'Answer Call', query: 'answer_call'},
        ],
    },
];

const isActive = (pathname, query) =>
    (query === '' && pathname === '/verifications') ||
    (query !== '' && pathname.includes(query));

const SidebarItem = ({section, pathname}) => (
    <Link
        to={
            section.query !== ''
                ? `/verifications/${section.query}`
                : '/verifications'
        }
        className={`${
            isActive(pathname, section.query) &&
            'border-blue-kiwi bg-blue-kiwi/10'
        } px-5 ml-2.5 border-l-2 flex flex-col gap-2.5 relative transition-all duration-300 ease-in-out rounded-r-lg py-2.5`}>
        {isActive(pathname, section.query) && (
            <div className="w-2 h-2 absolute -left-[0.3rem] top-[0.9rem] rounded-full bg-blue-kiwi"></div>
        )}
        <p
            className={`${
                isActive(pathname, section.query)
                    ? 'text-blue-kiwi font-medium'
                    : 'text-gray-600 font-light'
            } text-sm transition-all duration-300 ease-in-out w-max`}>
            {section.label}
        </p>
    </Link>
);

const SidebarSection = ({item, pathname}) => (
    <div className="ml-5 group flex flex-col gap-2.5">
        <div className="flex items-center gap-2.5">
            <item.icon className="h-5 stroke-gray-600" />
            <p className="text-gray-600 text-sm">{item.title}</p>
        </div>
        {item.items.map((section, index) => (
            <SidebarItem key={index} section={section} pathname={pathname} />
        ))}
    </div>
);

export default function Sidebar() {
    const {pathname} = useLocation();

    return (
        <div className="h-dashboard px-5 py-8 overflow-y-auto flex flex-col gap-5 min-w-[20rem]">
            <div className="flex flex-col gap-10">
                {filtersBy.map((item, index) => (
                    <SidebarSection
                        key={index}
                        item={item}
                        pathname={pathname}
                    />
                ))}
            </div>
        </div>
    );
}
