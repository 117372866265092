import GeneralAlert from 'components/GeneralAlert';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { getStateAcronym } from 'utils/getStateAcronym';
import { actions } from 'redux/reducer';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const SkeletonLoader = () => (
  <div className="flex flex-col gap-2.5">
    <Skeleton width="50%" height={32} borderRadius={8} />
    <Skeleton width="70%" height={32} borderRadius={8} />
    <Skeleton width="100%" height={200} borderRadius={8} />
    <Skeleton width="100%" height={200} borderRadius={8} />
  </div>
);

const DataRow = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = item.application || {};
  const { first_name, first_surname, firebase_id, state } = user || {};

  const goReview = path => {
    dispatch(
      actions.setBackPath({
        path: `/verifications/${params.value ?? ''}`,
      })
    );
    navigate(path);
  };

  return (
    <button
      onClick={() => goReview(`/users/${user?.id}/verifications`)}
      className="bg-white hover:bg-gray-50 min-w-fit w-full rounded-lg p-5 flex items-center justify-between text-left gap-5 text-sm"
    >
      <div className="whitespace-nowrap min-w-[10rem] max-w-[16rem] min-h-[3rem]">
        <p className="text-sm font-semibold">Full name</p>
        <p className="font-light max-w-xs truncate capitalize">
          {first_name} {first_surname}
        </p>
      </div>
      <div className="whitespace-nowrap min-w-[10rem] max-w-[16rem] min-h-[3rem]">
        <p className="text-sm font-semibold">Firebase ID</p>
        <p className="font-light max-w-xs truncate capitalize">{firebase_id}</p>
      </div>
      <div className="whitespace-nowrap min-w-[14rem] max-w-[16rem] min-h-[3rem]">
        <p className="text-sm font-semibold">Value</p>
        <p className="font-semibold text-green-600 bg-green-100 px-5 py-1 rounded w-max">
          {item.label}
        </p>
      </div>
      <div className="whitespace-nowrap min-w-[10rem] max-w-[16rem] min-h-[3rem]">
        <p className="text-sm font-semibold">Created at</p>
        <p className="font-light max-w-xs truncate">
          {format(new Date(item.created_at), 'MMM dd yyyy')}
        </p>
      </div>
      <div className="w-12 min-h-[3rem]">
        <p className="text-sm font-semibold">State</p>
        <p className="capitalize font-semibold flex gap-x-2 text-sm">
          <img
            src={
              getStateAcronym(state) === 'PR'
                ? require('../../../assets/puerto-rico.png')
                : require('../../../assets/estados-unidos.png')
            }
            className="w-4"
            alt="loading"
          />
          {getStateAcronym(state)}
        </p>
      </div>

      <span className="ml-auto hover:text-blue-dark-kiwi text-blue-kiwi underline transition-all duration-300 ease-in-out min-w-[2rem]">
        Review
      </span>
    </button>
  );
};

export default function List({ data, loader }) {
  return (
    <>
      {loader ? (
        <SkeletonLoader />
      ) : (
        <>
          {data ? (
            <>
              <div>
                <h1 className="text-xl font-semibold text-gray-900">
                  {data.length} Verifications
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  A list of all pending verifications including the user and
                  status.
                </p>
              </div>
              <div className="w-full my-8 border-b-2"></div>
              <div className="flex flex-col gap-2.5">
                {data.map(item => (
                  <DataRow key={item.id} item={item} />
                ))}
              </div>
            </>
          ) : (
            <GeneralAlert description="No information for this section" />
          )}
        </>
      )}
    </>
  );
}
