import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { getLoanApplications } from "redux/reducer";
import Item from "components/Applications/Item";
import Filters from "components/Applications/Filters";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import Loader from "components/UI/Loader";
import Api from "redux/api";

export default function Loans() {

    const dispatch = useDispatch();

    const [applications, setApplications] = useState(null);
    const reviewers = useSelector(state => state.reviewers);
    const [loans, setLoans] = useState(applications);
    const [keyword, setKeyword] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setKeyword("");
        getUsersWithoutApplication();
        setTimeout(() => {
            setLoader(false);
        }, 12000)
    }, []);

    useEffect(() => {
        if (applications) {
            setLoans(applications)
            setLoader(false);
        }
    }, [applications])

    useEffect(() => {
        if (applications && reviewers) {
            handleMatchReviewersApplication()
        }
    }, [reviewers, applications]);

    useEffect(() => {
        debouncedHandleSearch(keyword);
    }, [keyword]);

    const getUsersWithoutApplication = async () => {
        try {
            setLoader(true);
            const response = await Api.getUsersWithoutApplication();
            setApplications(response.data.data.map(item => {
                return {
                    id: item.id,
                    created_at: item.created_at,
                    status: [],
                    user: item,
                    user_id: item.id
                }
            }));
            setLoader(false);
        } catch (error) {
            setApplications([]);
        }
    }

    const handleMatchReviewersApplication = () => {

        if (!reviewers.length) {
            setLoans(applications);
            return "";
        }

        let loans_tmp = applications;
        loans_tmp = loans_tmp.map(loan => {
            const some = reviewers.filter(reviewer => reviewer.loan_id === loan.id);
            if (some.length) {
                loan = { ...loan, reviewers: some };
            }
            return loan;
        })
        setLoans(loans_tmp);
    }

    const handleSearch = (event) => {
        const value = event.target.value;
        setKeyword(value);
    }

    const search = (value) => {
        if (value !== "") {
            dispatch(getLoanApplications({
                search: value,
                where: {
                    archived: true
                }
            }));
        } else {
            dispatch(getLoanApplications({
                search: value,
                where: {
                    archived: true
                }
            }));
        }
    }

    const debouncedHandleSearch = useMemo(
        () => debounce(search, 1200),
        [keyword]);

    return (
        <div className="loans w-full">
            <Loader status={loader}></Loader>
            <Filters />
            {loans && (<div className="h-dashboard bg-gray-100 overflow-auto px-4 sm:px-6 lg:px-8 pt-8">
                <div className="sm:flex sm:items-center mb-4">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">{loans.length} User without application</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all loans with including the user, balance, payload and status.
                        </p>
                    </div>
                </div>
                <div className="mb-6">
                    <div>
                        <label for="email" className="block text-sm font-medium text-gray-700">Search Loan</label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    className="focus:ring-blue-kiwi focus:border-blue-kiwi block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                                    placeholder="Email, Phone, SSN, Loan ID ..."
                                    value={keyword}
                                    onChange={handleSearch}
                                />
                            </div>
                            <button onClick={() => { search(keyword) }} type="button" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-kiwi focus:border-blue-kiwi">
                                <span>Search</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="w-full">
                        {loans && loans.map((loan) => (
                            <Item data={loan} />
                        ))}
                    </div>
                </div>
            </div>)}
        </div>
    )
}