import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import PersonaDetails from "components/Persona/PersonaDetails";
import Api from "redux/api";
import Loader from "components/UI/Loader";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Tooltip from "components/UI/Tooltip";

/**
 * If user's doesn't have an inquiry_id or it doesn't start with inq_, this UI will be displayed
 * This UI allows adding or changing inquiry_id
 */
const UserPersonaMissing = ({
  openLinkPersona = () => {},
  user,
  update = () => {},
}) => {
  const [data, setData] = useState("");

  useEffect(() => {
    if (user?.inquiry_id !== "") setData(user?.inquiry_id);
  }, [user]);

  return (
    <div>
      <div className="sm:col-span-3 mx-6 ">
        <div className="my-5 border rounded py-5 px-5 border-gray-400 text-gray-700 shadow">
          This user doesn't have persona verification, add an ID to verify it's
          identity.
        </div>
        <label
          htmlFor="inquiry_id"
          className="block text-sm font-medium text-gray-700"
        >
          Persona inquiry ID
        </label>

        <div className="mt-1 flex justify-between">
          <input
            type="text"
            value={data}
            onChange={(e) => setData(e.target.value)}
            name="inquiry_id"
            autoComplete="inquiry_id"
            className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
          />
          <Tooltip tooltip="Open with persona">
            <button
              type="button"
              disabled={!data || !data?.startsWith("inq_")}
              className="ml-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              onClick={() => openLinkPersona(data)}
            >
              <ArrowTopRightOnSquareIcon
                className="h-5 w-5 text-blue-kiwi"
                aria-hidden="true"
              />
            </button>
          </Tooltip>
        </div>

        <div className="w-full h-16 bg-white sticky left-0 bottom-0 flex items-center">
          <div className="w-full max-w-6xl mx-auto flex justify-end">
            <button
              type="button"
              className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={() => update(data)}
              disabled={user?.inquiry_id === data || !data}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
/**
 * Verifies if user has an inquiry_id that starts with inq_ and shows the corresponding screen according to the results
 */
const UserPersona = () => {
  const dataUser = useSelector((state) => state.user);
  const [inquiries, setInquiries] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const getUserPersona = async (id) => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_API_URL}/persona/reference/${id}`
      );
      if (data?.length > 0) setInquiries(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (dataUser.id) getUserPersona(dataUser.id);
  }, [dataUser]);

  const updateUser = async (data) => {
    try {
      await Api.updateUser({
        ...dataUser,
        inquiry_id: data,
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const openLinkPersona = (inq) => {
    let url = `https://app.withpersona.com/dashboard/inquiries/${inq}`;
    window.open(url);
  };

  return !isLoading ? (
    <>
      {!dataUser?.inquiry_id?.startsWith("inq_") && (
        <UserPersonaMissing
          user={dataUser}
          update={updateUser}
          openLinkPersona={openLinkPersona}
        />
      )}
      {inquiries && (
        <>
          <div className="px-6 pt-6">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Current persona status
            </label>
            <input
              id="status"
              name="status"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
              value={dataUser.inquiry_status}
              disabled
            />
          </div>

          <PersonaDetails
            inquiries={inquiries}
            dataUser={dataUser}
            defaultInquiry={dataUser.inquiry_id}
            openLinkPersona={openLinkPersona}
          />
        </>
      )}
    </>
  ) : (
    <Loader status={isLoading}></Loader>
  );
};

export default UserPersona;
