import React, { useEffect, useState } from "react";
import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";
import Api from "redux/api"
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import Tooltip from "components/UI/Tooltip";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }

    return null;
}

const UserLeads = () => {
    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    const [searchBy, setSearchBy] = useState("phone_number")
    const [searchValue, setSearchValue] = useState()
    const [result, setResult] = useState(0)
    const [toasCopy, setToastCopy] = useState(false)

    const [loader, setLoader] = useState(false)
    const [user_leads, setUser_leads] = useState()
    const [keyword, setKeyword] = useState("");

    const setHandlers = {
        order: setOrder,
        search_by: setSearchBy,
        search_value: setSearchValue,
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const setHandler = setHandlers[name];
        if (setHandler) setHandler(value);
    }
    const handleChangeSource = (e) => {
        setSearchBy(e.target.name)
        setSearchValue(e.target.value)
    }

    const handleChangeDate = (e) => {
        setSearchBy(e.target.name)
        setSearchValue(e.target.value)
    }
    useEffect(() => {
        const delay = setTimeout(() => {
            getUserLeads()
        }, 1000)

        return () => clearTimeout(delay)
    }, [searchValue])

    useEffect(() => {
        getUserLeads()
    }, [order])

    useEffect(() => {
        getUserLeads();
    }, [])

    const copyText = (event) => {
        const { id } = event.target

        if (id) {
            navigator.clipboard.writeText(id);
            setToastCopy(true)

            setTimeout(() => {
                setToastCopy(false)
            }, 1500);
        }
    }



    const formatDate = (dateIso) => {
        if (!dateIso) return ''
        let params = {
            year: "numeric",
            month: "2-digit",
            day: '2-digit',
            hour: "2-digit",
            minute: '2-digit',
            second: "2-digit",
            hour12: false
        }

        let [date, hour] = new Date(dateIso).toLocaleString('es-CO', params).split(',')
        let format_date = date.split('/').reverse().join('-')
        return format_date + ',  ' + hour;
    }

    const getUserLeads = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true)
            setResult([])
            setUser_leads([])

            const response = await Api.fetchUserLeads({
                take,
                skip,
                order,
                search_by: searchBy,
                search_value: searchValue,
            });

            setLoader(false)
            setResult(response.data.data.count)
            setUser_leads(response.data.data.users);
        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get users'
            }))
        }
    }

    return (
        <div className="max-w-7xl mx-auto">
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-4 gap-4 sm:items-center my-5">
                    <div className="">
                        <label for="email" class="block text-sm font-medium text-gray-700">Search source url</label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    name="source_url"
                                    class="focus:ring-blue-kiwi focus:border-blue-kiwi block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                                    placeholder="gid=..."
                                    onChange={handleChangeSource}
                                />
                            </div>
                            <button onClick={() => { getUserLeads() }} type="button" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-kiwi focus:border-blue-kiwi">
                                <span>Search</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <label htmlFor="referrer" className="block text-sm font-medium text-gray-700">
                                Search by date
                            </label>
                            <input className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md" type="date" name="created_at" onChange={handleChangeDate} id="" />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <label htmlFor="orderByDateAt" className="block text-sm font-medium text-gray-700">
                                Order by created At
                            </label>
                            <select
                                id="orderByDateAt"
                                name="order"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm rounded-md"
                                value={order}
                                onChange={handleChange}
                            >
                                <option value="desc">Latest</option>
                                <option value="asc">Oldest</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                                    <Pagination count={result} onTake={getUserLeads} />
                                </div>

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Phone number
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Validated
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Registered
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Source Url
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Created At
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Update At
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {user_leads && user_leads.map((user_lead) => (
                                            <tr key={user_lead.phone_number}>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <div className="text-gray-900 font-semibold">{formatPhoneNumber(user_lead.phone_number)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">
                                                        {user_lead.validated ? <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                                            True
                                                        </span> : <span className="inline-flex items-center rounded-full bg-pink-100 px-3 py-0.5 text-sm font-medium text-pink-800">
                                                            False
                                                        </span>}
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">
                                                        {user_lead.registered ? <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                                            True
                                                        </span> : <span className="inline-flex items-center rounded-full bg-pink-100 px-3 py-0.5 text-sm font-medium text-pink-800">
                                                            False
                                                        </span>}
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="flex flex-row text-gray-500 w-32 tracking-widest text-ellipsis">
                                                        <p className="truncate text-ellipsis overflow-ellipsis">
                                                            {
                                                                user_lead.source_url

                                                            }
                                                        </p>
                                                        {
                                                            user_lead.source_url ? <Tooltip tooltip="Copy source url">
                                                                <ClipboardIcon id={user_lead.source_url} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-sky-400" />
                                                            </Tooltip> : ''
                                                        }

                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{
                                                        formatDate(user_lead.created_at || "")
                                                    }</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="text-gray-500 tracking-widest">{user_lead.updated_at}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {toasCopy ? <div id={Math.random()} aria-live="assertive"
                className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
                <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-center">
                    <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                        <div className="relative flex flex-col items-center group">

                            <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                                <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">Source url copied</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div> : ''}

            <Loader status={loader}></Loader>
        </div>
    )
}

export default UserLeads;