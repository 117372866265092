import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';
import Api from 'redux/api';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducer';
import me_salve_stores from 'utils/me_salve_stores';
import ItemActions from './ItemActions';
import { Popover } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { getStateAcronym } from 'utils/getStateAcronym';

function LoansItem(props) {
  const dispatch = useDispatch();

  const [data, setData] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [mesalveStore, setMesalveStore] = useState(null);
  const dataAuth = useSelector(state => state.auth);

  useEffect(() => {
    if (data.type === 'mesalve' && data.redeem_location !== null) {
      setMesalveStore(
        me_salve_stores.find(store => store.id === data.redeem_location)
      );
    }
  }, [data]);

  const fund = async id => {
    setLoading(true);
    try {
      const { data } = await Api.fundLoan({
        loan_id: id,
      });
      setData(data.loan);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Funding failed',
        })
      );
    }
    setLoading(false);
  };

  const deleteLoan = async (loanpro_id, user_id, member_id) => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await Api.deleteLoan(loanpro_id, user_id, member_id);
      window.location.reload();
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Delete failed',
        })
      );
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className="w-full relative flex border bg-white border-gray-300 first:rounded-t-lg last:rounded-b-lg border-b-1 mb-4"
        key={data.id}
      >
        <div className="w-full flex flex-wrap gap-x-4 justify-items-start">
          <div className="w-44 whitespace-nowrap py-4 text-sm text-gray-900 pl-4">
            <p className="text-sm font-semibold">Loanpro ID</p>
            <p>{data.loanpro_id}</p>
          </div>
          {data.user && (
            <Link
              to={`/users/${data.user.id}/loans`}
              className="w-80 justify-self-start whitespace-nowrap py-4 text-sm col-span-2 underline hover:text-blue-dark-kiwi"
            >
              <h3 className="text-sm font-semibold">{`${
                data.user.first_name ?? ''
              } ${data.user.second_name ?? ''} ${
                data.user.first_surname ?? data.user.last_name ?? ''
              } ${data.user.second_surname ?? ''}`}</h3>
              <p>{data.user.email}</p>
              <p>{data.user.firebase_id}</p>
              <p>{data.user.phone_number}</p>
            </Link>
          )}

          <div className="w-52 py-3 flex flex-col items-start">
            {data.disbursement_method === 'paper_check' && (
              <div className="text-sm py-1 px-2 rounded text-amber-600 bg-amber-100">
                Paper check
              </div>
            )}

            {data.disbursement_method === 'immediate' && (
              <div className="text-sm py-1 px-2 rounded text-red-700 bg-red-300 mb-2">
                Me Salve Method
              </div>
            )}

            {data.disbursement_method === 'transfer' && (
              <div className="text-sm py-1 px-2 rounded text-blue-kiwi bg-blue-50-kiwi">
                Transfer Method
              </div>
            )}

            {data.type === 'mesalve' && (
              <>
                <p className="text-sm">
                  {mesalveStore?.name} ({data.redeem_location})
                </p>
                <p className="text-sm">
                  Subportfolio: {mesalveStore?.subportfolio}
                </p>
              </>
            )}
          </div>

          <div className="w-32 py-4">
            <p className="text-sm font-semibold">Amount</p>
            <p className="text-sm">${data.amount} USD</p>
          </div>

          <div className="w-32 py-4">
            <p className="text-sm font-semibold">State</p>
            <p className="capitalize font-semibold flex gap-x-2 text-sm">
              {getStateAcronym(data?.user?.state) === 'PR' ? (
                <img
                  src={require('../../assets/puerto-rico.png')}
                  className="w-4"
                  alt="loading"
                />
              ) : (
                <img
                  src={require('../../assets/estados-unidos.png')}
                  className="w-4"
                  alt="loading"
                />
              )}

              {getStateAcronym(data?.user?.state)}
            </p>
          </div>
          {props.status === 'ready_fund' && (
            <ItemActions
              data={props.data}
              subportfolio={mesalveStore?.subportfolio}
            />
          )}
          <div className="w-full flex col-span-7">
            {dataAuth.profile === 'admin' ? (
              <Popover className="flex p-4 text-sm border-t border-r">
                <Popover.Button className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-400 py-1 px-1 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-dark-kiwi">
                  <TrashIcon className="h-2.5 w-2.5" aria-hidden="true" />
                </Popover.Button>

                <Popover.Panel className="absolute top-full left-0 mt-0.5 z-10 bg-white min-w-max">
                  {({ close }) => (
                    <div className="py-5 px-3 rounded-md bg-white border drop-shadow-2xl">
                      <h4 className="mb-2 text-sm text-gray-700">
                        ¿Are you sure you want to delete loan from CMS?
                      </h4>
                      <button
                        className="w-full rounded-md text-sm py-2 font-medium bg-red-kiwi text-white"
                        onClick={() => {
                          close();
                          deleteLoan(data.id, data.user.id, dataAuth.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </Popover.Panel>
              </Popover>
            ) : (
              <></>
            )}
            <div className="flex flex-1 p-4 text-sm border-t border-r">
              <p className="mr-4 font-semibold text-gray-900">Created at:</p>
              <p className="text-gray-500">
                {format(new Date(data.created_at), 'MMM dd, yyyy p')}
              </p>
            </div>
            <div className="flex flex-1 p-4 text-sm border-t border-r">
              <p className="mr-4 font-semibold text-gray-900">Contract at:</p>
              <p className="text-gray-500">
                {format(new Date(data.created_at), '--- --, ---- --:-- --')}
              </p>
            </div>
            <div className="flex flex-1 p-4 text-sm  border-t">
              <p className="mr-4 font-semibold text-gray-900">Funded at:</p>
              <p className="text-gray-500">
                {data.funded_at
                  ? format(
                      utcToZonedTime(new Date(data.funded_at), 'UTC'),
                      'MMM dd, yyyy p'
                    )
                  : '--- --, ---- --:-- --'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoansItem;
