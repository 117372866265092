export const mapTierTabs = (tier_tabs, data) => {
    let tmp_tier_tabs = tier_tabs;

    for(let i = 0; i<tier_tabs.length; i++){
        const find_references = getTierByReference(tmp_tier_tabs[i].reference, data);
        if(find_references){
            tmp_tier_tabs[i] = { ...tmp_tier_tabs[i], data: find_references }
        }
    }

    return tmp_tier_tabs;
}

const getTierByReference = (reference, data) => {
    const payload = {};
    const find_references = Object.entries(data).filter(([key]) => key.includes(reference));

    if(!find_references?.length){
        return null;
    }

    find_references.forEach(([key, value]) => {
        const format_key = key.split('_').slice(-1)
        payload[format_key] = value
    });

    return payload;
}