import React, {  useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";
import { format } from "date-fns-tz";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { DPDCampaignsServices } from "services/DPDCampaigns/DPDCampaigns.services";

const EMPTY_ARRAY = [];
const DPD_CAMPAIGN_STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
    UNAUTHORIZED: 'unauthorized'
}

const HistoryDPDCampaign = ({
    hasBack, 
    setStatusPage,
    statusPage
}) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [requests, setRequests] = useState();
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        getHistory();
    }, [])

    const getHistory = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true);
            const response = await DPDCampaignsServices.getHistory(take, skip);
            setRequests(response.data.data.history || EMPTY_ARRAY);
            setCount(response.data.data.count || 0);
        } catch (error) {
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error loading auths'
            }));
        }finally{
            setLoader(false);

        }
    }

    const formatDate = (date) => {
        const tmp_date = new Date(date);

        return format(tmp_date, 'yyyy-MM-dd')
    }

    const formatCampaignName = (name) => {
        if(!name?.length){
            return "N/A";
        }

        return name.replaceAll('_', '');
    }

    const renderTotalValues = (value) => {
        if(!value){
            return <div className="text-gray-500 tracking-widest"> - </div>
        }
        return <div className="text-gray-500 tracking-widest">{value}</div>
    }

    const renderStatus = (status) => {
        if(status === DPD_CAMPAIGN_STATUS.SUCCESS){
            return <span className="inline-flex items-center rounded-full bg-green-600 px-3 py-0.5 text-sm font-medium text-white">{status}</span>
        }

        if(status === DPD_CAMPAIGN_STATUS.ERROR){
            return <span className="inline-flex items-center rounded-full bg-orange-dti px-3 py-0.5 text-sm font-medium text-white">{status}</span>
        }

        if(status === DPD_CAMPAIGN_STATUS.UNAUTHORIZED){
            return <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-green-800">{status}</span>
        }

        return <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">{status}</span>
    }

    return (
        <div className="max-w-7xl mx-auto">
            <Loader status={loader} />
            { hasBack &&(<div className="flex items-center">
                <ChevronLeftIcon
                    onClick={() => setStatusPage(statusPage)}
                    className="w-5 h-5 text-slate-600 cursor-pointer" 
                />
                <p className="cursor-pointer" onClick={() => setStatusPage(statusPage)}>Volver</p>
            </div>
            )}
            <div className="mt-8 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">DPD Campaigns History</h1>
                </div>
            </div>
            <br />
            <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <div className="bg-white px-4 py-3 sm:px-6">
                                    <Pagination count={count} onTake={getHistory} />
                                </div>
                                <table className="min-w-full divide-y">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Campaign
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total sent
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Successful
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Not Applied
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Failed
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Sent By
                                            </th>
                                            <th scope="col" className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Sent At
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {requests && requests.map((request) => (
                                            <tr 
                                                key={request.id}
                                            >
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <div className="text-gray-500 tracking-widest capitalize">{formatCampaignName(request.name)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6 capitalize">
                                                    <div className="text-gray-500 tracking-widest">{renderStatus(request.status)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6 capitalize">
                                                    <div className="text-gray-500 tracking-widest">{renderTotalValues(request.total_sent)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6 capitalize">
                                                    <div className="text-gray-500 tracking-widest">{renderTotalValues(request.successful)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6 capitalize">
                                                    <div className="text-gray-500 tracking-widest">{renderTotalValues(request.not_applied)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6 capitalize">
                                                    <div className="text-gray-500 tracking-widest">{renderTotalValues(request.failed)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6 capitalize">
                                                    <div className="text-gray-500 tracking-widest">{request.member.full_name}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <div className="text-gray-500 tracking-widest">{formatDate(request.created_at)}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default HistoryDPDCampaign;