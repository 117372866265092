import React, { useEffect, useState } from 'react';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import Api from 'redux/api';

import Loader from 'components/UI/Loader';
import Pagination from 'components/UI/Pagination';
import Tooltip from 'components/UI/Tooltip';
import statusPlaid from 'components/UI/StatusPlaid';

import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import { getStateAcronym } from 'utils/getStateAcronym';

export default function Users() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  const [file, setFile] = useState();
  const [result, setResult] = useState(0);
  const [toasCopy, setToastCopy] = useState(false);
  const [loader, setLoader] = useState(true);
  const [order, setOrder] = useState();
  const [searchBy, setSearchBy] = useState('phone_number');
  const [searchValue, setSearchValue] = useState('');
  const [stateUser, setStateUser] = useState();

  const setHandlers = {
    order: setOrder,
    search_by: setSearchBy,
    search_value: setSearchValue,
    stateUser: setStateUser,
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const setHandler = setHandlers[name];
    if (setHandler) setHandler(value);
  };

  useEffect(() => {
    if (order) {
      getUsers();
    }
  }, [order]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getUsers();
    }, 1000);

    return () => clearTimeout(delay);
  }, [searchValue]);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (stateUser) {
        getUsers();
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [stateUser]);

  useEffect(() => {
    document.getElementById('userDownload').click();
  }, [file]);

  const getUsers = async ({ take = 50, skip = 0 } = {}) => {
    try {
      setLoader(true);
      setResult([]);
      setUsers([]);

      const response = await Api.fetchUsers({
        take,
        skip,
        order,
        search_by: searchBy,
        search_value: searchValue,
        stateUser,
      });

      setLoader(false);
      setResult(response.data.data.count);
      setUsers(response.data.data.users);
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error get users',
        })
      );
    }
  };

  const formatDate = dateIso => {
    if (!dateIso) return '';
    let params = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    let [date, hour] = new Date(dateIso)
      .toLocaleString('es-CO', params)
      .split(',');
    let format_date = date.split('/').reverse().join('-');
    return format_date + ',  ' + hour;
  };

  const bgStatus = score => {
    let classSpan =
      'inline-flex rounded-full px-2 text-xs uppercase font-semibold leading-5 ';

    if (score < 570) {
      return `${classSpan} bg-red-100 text-red-800`;
    } else if (score < 650) {
      return `${classSpan} bg-amber-100 text-yellow-800`;
    }

    return `${classSpan} bg-green-100 text-green-800`;
  };

  const copyText = event => {
    const { id } = event.target;

    if (id) {
      navigator.clipboard.writeText(id);
      setToastCopy(true);

      setTimeout(() => {
        setToastCopy(false);
      }, 1500);
    }
  };

  const downloadUsers = async () => {
    try {
      setLoader(true);
      const response = await Api.downloadUsers();

      setLoader(false);
      setFile(window.URL.createObjectURL(new Blob([response.data])));
      dispatch(
        actions.setMessage({
          type: 'success',
          title: 'Success',
          message: 'Generated report',
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error creating report',
        })
      );
    }
  };

  const validateConnect = item => {
    let name = '';
    let connect = item.plaid_token || item.finicity_token;
    let icon = (
      <CheckCircleIcon className="text-green-600 h-4 w-4" aria-hidden="true" />
    );

    if (connect) {
      name = item.plaid_token ? 'Plaid' : 'Finicity';
      icon = name === 'Plaid' ? statusPlaid(item) : icon;
    } else {
      icon = (
        <XCircleIcon className="text-red-500 h-5 w-5" aria-hidden="true" />
      );
    }

    return (
      <>
        <div className={connect ? 'flex w-full justify-evenly' : ''}>
          {connect ? <span> {name}</span> : ''}
          {icon}
        </div>
      </>
    );
  };

  return (
    <div className="w-full pt-8 h-dashboard bg-gray-100">
      <Loader status={loader}></Loader>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center pb-3 border-b">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <a
            id="userDownload"
            className="w-0 h-0 opacity-0"
            href={file}
            download={`user_${new Date().getTime()}.csv`}
          >
            .
          </a>
        </div>
        <br />
        <div className="grid grid-cols-4 gap-5 sm:items-center my-5">
          <div className="col-span-2">
            <div>
              <label
                htmlFor="referrer"
                className="block text-sm font-medium text-gray-700"
              >
                Search user
              </label>

              <div className="relative mt-1 rounded-md shadow-sm flex">
                <div className="flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm pr-px">
                  <select
                    name="search_by"
                    onChange={handleChange}
                    autoComplete="search_by"
                    className="h-full rounded-l-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 outline-none focus:border-blue-kiwi focus:ring-blue-kiwi"
                  >
                    <option value="phone_number">Phone Number</option>
                    <option value="email">Email</option>
                    <option value="firebase_id">Firebase ID</option>
                    <option value="ssn">SSN</option>
                    <option value="full_name">Name</option>
                  </select>
                </div>
                {searchBy === 'phone_number' && (
                  <input
                    type="text"
                    name="search_value"
                    onChange={handleChange}
                    className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                    placeholder="+1 (000) 000-0000 / 1787000000 / 5555(last 4 digits)"
                  />
                )}
                {searchBy === 'firebase_id' && (
                  <input
                    type="text"
                    name="search_value"
                    onChange={handleChange}
                    className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                    placeholder="MjaeEX1NFDWL9vv3NhvW0Axy8pT2"
                  />
                )}
                {searchBy === 'email' && (
                  <input
                    type="text"
                    name="search_value"
                    onChange={handleChange}
                    className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                    placeholder="user@mail.com"
                  />
                )}
                {searchBy === 'ssn' && (
                  <input
                    type="text"
                    name="search_value"
                    onChange={handleChange}
                    className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                    placeholder="501000550"
                  />
                )}
                {searchBy === 'full_name' && (
                  <input
                    type="text"
                    name="search_value"
                    onChange={handleChange}
                    className="block w-full rounded-r-md border-gray-300 pl-6 focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                    placeholder="First Name / Last Name / Full Name"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                  <Pagination count={result} onTake={getUsers} />
                </div>

                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created At
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Credit Report
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Bank Data
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Firebase ID
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white min-w-full">
                    {users &&
                      users.map((user, index) => (
                        <tr key={user.id}>
                          <td
                            className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 text-sm font-medium text-gray-900 truncate"
                            style={{ maxWidth: '200px' }}
                          >
                            <p>
                              {`${user.first_name ?? ''} ${
                                user.first_surname ?? user.last_name ?? ''
                              }`}
                            </p>
                            <p className="text-xs">{user.state}</p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.phone_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {formatDate(user.created_at)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                            <p className="capitalize font-semibold flex gap-x-2 text-sm">
                              {getStateAcronym(user?.state) === 'PR' ? (
                                <img
                                  src={require('../../assets/puerto-rico.png')}
                                  className="w-4"
                                  alt="loading"
                                />
                              ) : (
                                <img
                                  src={require('../../assets/estados-unidos.png')}
                                  className="w-4"
                                  alt="loading"
                                />
                              )}

                              {getStateAcronym(user?.state)}
                            </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                            <div>
                              <span className="text-gray-500 font-semibold mr-1">
                                {' '}
                                Score:{' '}
                              </span>
                              <span
                                className={`${bgStatus(
                                  user.credit_scorings?.length > 0
                                    ? user.credit_scorings[0].score
                                    : 0
                                )} `}
                              >
                                {user.credit_scorings?.length > 0
                                  ? user.credit_scorings[0].score
                                  : 0}
                              </span>
                            </div>

                            <div>
                              <span className="text-gray-500 font-semibold mr-1">
                                {' '}
                                Created at:{' '}
                              </span>
                              {formatDate(
                                user.credit_scorings?.length > 0
                                  ? user.credit_scorings[0].created_at
                                  : ''
                              )}
                            </div>
                          </td>

                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            style={{
                              textAlign: '-webkit-center',
                              minWidth: '98px',
                            }}
                          >
                            {validateConnect(user)}
                          </td>
                          <td className="flex whitespace-nowrap px-3 pt-6 pb-4 text-sm text-gray-500">
                            {user.firebase_id}

                            <Tooltip tooltip="Copy id Firebase">
                              <ClipboardIcon
                                id={user.firebase_id}
                                onClick={copyText}
                                className="text-blue-kiwi ml-1 mt-0.5 h-4 w-4 cursor-pointer hover:text-sky-400"
                              />
                            </Tooltip>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link
                              to={`/users/${user.id}/personal`}
                              className="text-blue-kiwi hover:text-sky-400"
                            >
                              Details
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toasCopy ? (
        <div
          id={Math.random()}
          aria-live="assertive"
          className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div
            id={Math.random()}
            className="w-full flex flex-col items-center space-y-4 sm:items-center"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="relative flex flex-col items-center group">
                <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                  <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">
                    Id copied successfully
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
