import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import Loader from "components/UI/Loader";
import { DPDCampaignsServices } from "services/DPDCampaigns/DPDCampaigns.services";

const ERROR = {
    INVALID_ACTION: 'INVALID_ACTION'
}

const SendDPDCampaigns = ({
    hasBack, 
    setStatusPage,
    statusPage
}) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [buttonsState, setButtonsState] = useState(false);

    const sendDPD91TO120Campaign = async () => {
        try{
            setLoader(true);
            setButtonsState(true);

            await DPDCampaignsServices.DPD91TO120Campaign();

            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Campaign sent'
            }));
        }catch(error){
            const message = error.response.data.messages;
            handleResponseError(message);            
        }finally{
            setLoader(false);
            setButtonsState(false);
        }
    }

    const sendDPD121TO160Campaign = async () => {
        try{
            setLoader(true);
            setButtonsState(true);

            await DPDCampaignsServices.DPD121TO160Campaign();

            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Campaign sent'
            }));
        }catch(error){
            const message = error.response.data.messages;
            handleResponseError(message);   
        }finally{
            setLoader(false);
            setButtonsState(false);
        }
    }

    const sendDPD161TO199Campaign = async () => {
        try{
            setLoader(true);
            setButtonsState(true);

            await DPDCampaignsServices.DPD161TO199Campaign();

            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Campaign sent'
            }));
        }catch(error){
            const message = error.response.data.messages;
            handleResponseError(message);   
        }finally{
            setLoader(false);
            setButtonsState(false);
        }
    }

    const sendDPDPlus200Campaign = async () => {
        try{
            setLoader(true);
            setButtonsState(true);

            await DPDCampaignsServices.DPDPlus200Campaign();

            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Campaign sent'
            }));
        }catch(error){
            const message = error.response.data.messages;
            handleResponseError(message);   
        }finally{
            setLoader(false);
            setButtonsState(false);
        }
    }

    const [currentCampaigns] = useState([
        {   
            id: 1,
            name: 'DPD 91 To 120',
            request: sendDPD91TO120Campaign
        },
        {   
            id: 2,
            name: 'DPD 121 To 160',
            request: sendDPD121TO160Campaign
        },
        {   
            id: 3,
            name: 'DPD 161 To 199',
            request: sendDPD161TO199Campaign
        },
        {   
            id: 4,
            name: 'DPD Plus 200',
            request: sendDPDPlus200Campaign
        }
    ]);

    const handleResponseError = (message) => {
        if(message === ERROR.INVALID_ACTION){
            return dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'INVALID ACTION'
            }));    
        }

        dispatch(actions.setMessage({
            type: 'wrong', title: 'Wrong', message: 'Error Sending Campaign'
        }));
    }


    return (
        <div className="max-w-7xl mx-auto">
            <Loader status={loader} />
            { hasBack &&(<div className="flex items-center">
                <ChevronLeftIcon
                    onClick={() => setStatusPage(statusPage)}
                    className="w-5 h-5 text-slate-600 cursor-pointer" 
                />
                <p className="cursor-pointer" onClick={() => setStatusPage(statusPage)}>Volver</p>
            </div>
            )}
            <div className="mt-8 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Current Campaigns</h1>
                </div>
            </div>
            <div className="flex flex-wrap gap-5">
                { currentCampaigns.map((item) => ((
                    <button
                        key={item.id}
                        onClick={async () => { await item.request() }}
                        className="text-dark-kiwi-v2 font-semibold rounded-xl mt-5 p-2 px-8 cursor-pointer outline outline-2 outline-dark-kiwi hover:bg-dark-kiwi hover:text-white"
                        disabled={buttonsState}
                    >
                        {item.name}
                    </button>
                )))}
            </div>       
        </div>
    )
}

export default SendDPDCampaigns;