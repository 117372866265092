import axios from 'axios';
import Cookies from 'js-cookie';

const HEADERS = () => {
  return { authorization: `Bearer ${Cookies.get('kiwi_auth_token')}` };
};

export const VerificationsService = {
  getDataVerificationByUserId: async user_id => {
    return await axios({
      headers: HEADERS(),
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/verifications/user/${user_id} `,
    });
  },
  updateDataVerificationById: async (id, data) => {
    return await axios({
      headers: HEADERS(),
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/verifications/${id}`,
      data,
    });
  },
};
