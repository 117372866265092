import React, { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { actions } from "redux/reducer";
import Loader from "components/UI/Loader";
import { ChangePaymentDateServices } from 'services/ChangePaymentDates/ChangePaymentDate.services';

const ERROR_RESPONSE = {
    ERROR_REQUEST_ID: 'ERROR_REQUEST_ID',
    ERROR_AUTOPAY_ID: 'ERROR_AUTOPAY_ID'
}

export default function UpdateAutopayModal({
    showModal = false,
    closeModal,
    requestId,
    updateTable
} = {}) {
   
    const onCloseModal = () => {
        resetFormInputs()
        closeModal()
    }

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [newLog, setNewLog] = useState(null);

    const [errorsYup, setErrorYup] = useState({
        autopay_id: null
    });

    let newLogSchema = yup.object().shape({
        autopay_id: yup.number("Invalid format").integer("Autopay ID should be a number").moreThan(0,"Autopay ID should be greater than 0").required("Autopay ID is required")
    });

    const handleUser = (e) => {
        const { name, value } = e.target;

        setNewLog(prevData => ({
            ...prevData,
            [name]: value
        }))
        setErrorYup(prevData => ({
            ...prevData,
            [name]: null
        }))
    };

    const handleError = ({ name, value = false }) => {
        setErrorYup((prevData) => ({
            ...prevData,
            [name]: value ? true : false
        }));
    }

    const canCreateNewLog = async (data) => {
        const payload = {
            autopay_id: data?.autopay_id
        }
        try {
            await newLogSchema.validate(payload, {abortEarly: false});
            return true;
        } catch (err) {
            let yupErrorsObject = {};
    
            err.inner.map((validationError) => {
                if (!yupErrorsObject.hasOwnProperty([validationError.path])) {
                  yupErrorsObject[validationError.path] = validationError.errors[0];
                }
            });
    
            setErrorYup((prevData) => ({
             ...prevData,
             ...yupErrorsObject
            }))
            return false;
        }
    }

    const errorResponseValidation = (error) => {
        if(error === ERROR_RESPONSE.ERROR_REQUEST_ID){
            dispatch(actions.setMessage({
                type: 'wrong', title: 'wrong', message: "can't update log"
            }));
        }

        if(error === ERROR_RESPONSE.ERROR_AUTOPAY_ID){
            dispatch(actions.setMessage({
                type: 'wrong', title: 'wrong', message: "Autopay id already exists"
            }));
        }
    }

    const handleSubmit = async (data) => {
        try{
            setLoader(true);
            const can_create = await canCreateNewLog(data);
            if(!can_create){
                setLoader(false);
                return;
            }

            data.autopay_id = parseInt(data.autopay_id);

            await ChangePaymentDateServices.updateLog(requestId, data)

            setLoader(false);
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Log updated'
            }));
            resetFormInputs()
            updateTable()
            closeModal()
        }catch(error){
            const { data: { messages } } = error.response;
            errorResponseValidation(messages);
            setLoader(false);
        }
    }

    const resetFormInputs = () => {
        setNewLog(null);
        setErrorYup({
            autopay_id: null
        })
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <Loader status={loader} />
                        <div className="relative w-full my-6 mx-auto max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Update Request
                                    </h3>
                                    <button
                                        className="ml-auto text-black float-right text-3xl "
                                        onClick={onCloseModal}>
                                        <XMarkIcon className="text-black-900 font-semibold h-6 w-6 block" />
                                    </button>
                                </div>

                                {/*body*/}
                                <div className="w-full mx-auto space-y-8 divide-y divide-gray-200 px-6 lg:px-8 ">
                                    <div>
                                        <div className="mt-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="autopay_id" className="block text-sm font-medium text-gray-700">
                                                    Autopay ID
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="number"
                                                        value={newLog?.autopay_id}
                                                        onChange={(e) => { handleUser(e); handleError({ name: e.target.name }) }}
                                                        name="autopay_id"
                                                        className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                    {errorsYup.autopay_id && (
                                                        <p className="text-red-500 text-sm mt-2">
                                                            {errorsYup.autopay_id}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-6 flex justify-end">
                                            <button type="button"
                                                className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                onClick={() => handleSubmit(newLog)}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}