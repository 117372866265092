import ReactTooltip from 'react-tooltip';

export default function Example({
  children,
  tooltip = 'No message',
  place = 'top',
  type = 'dark',
}) {
  let random = Math.random();
  return (
    <>
      <ReactTooltip id={`${random}`} place={place} type={type} />
      <div data-tip={tooltip} data-for={`${random}`} className="cursor-default">
        {children}
      </div>
    </>
  );
}
