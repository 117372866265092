import { useEffect, useState } from "react";
import Api from "redux/api";
import { useParams } from 'react-router-dom';

const UserDialpad = () => {
    const { user_id } = useParams();
    const [callsHistory, setCallHistory] = useState(null);

    useEffect(() => {
        getCallHistory();
    }, [])

    const formatDuration = (milliseconds) => {
        try {
            const totalDurationMinutes = Math.floor(milliseconds / 60000); // 1 minuto = 60000 milisegundos
            const remainingMillis = milliseconds % 60000;
            const totalDurationSeconds = Math.floor(remainingMillis / 1000); // 1 segundo = 1000 milisegundos
        
            const formattedDuration = `${totalDurationMinutes}:${totalDurationSeconds}s`;
            return formattedDuration;
        } catch (error) {
            return '0:0s';
        }
    }

    const getCallHistory = async () => {
        try {
            const response = await Api.getDialpadHistory(user_id);
            setCallHistory(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }
            
    return (
        <div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Call ID
                                        </th>
                                        <th scope="col" className="py-3.5 px-3 pr-3 text-left text-sm font-semibold text-gray-900">
                                            Date Started
                                        </th>
                                        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Direction
                                        </th>
                                        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Labels
                                        </th>
                                        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Total duration
                                        </th>
                                        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Call dispositions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    
                                    {callsHistory && callsHistory.map((call, index) => (
                                        <tr key={index}>
                                            <td className="py-4 px-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {call?.id ?? 'N/A'}
                                            </td>
                                            <td className="py-4 px-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {call?.date_started ? new Date(Number(call.date_started)).toLocaleDateString() : 'N/A'}
                                            </td>
                                            <td className="py-4 px-3 whitespace-nowrap text-sm text-gray-500">
                                                {call?.direction ?? 'N/A'}
                                            </td>
                                            <td className="py-4 px-3 whitespace-nowrap text-sm text-gray-500">
                                                {call?.labels ?? 'N/A'}
                                            </td>
                                            <td className="py-4 px-3 whitespace-nowrap text-sm t text-gray-500">
                                                {formatDuration(call?.total_duration) ?? 'N/A'}
                                            </td>
                                            <td className="py-4 px-3 whitespace-nowrap text-sm text-gray-500">
                                                {call?.disposition ?? 'N/A'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDialpad;