import { Popover } from '@headlessui/react'
import Tooltip from "components/UI/Tooltip";
import { CheckIcon } from "@heroicons/react/24/outline";

export default ({ title, icon, comment, setComment, submit }) => {
    return (
        <Popover>
            {({ close }) => (
                <>
                    <Tooltip tooltip={title}>
                        <Popover.Button>
                            {icon}
                        </Popover.Button>
                    </Tooltip>

                    <Popover.Panel className="absolute -translate-x-full flex flex-col items-center mb-2 group-hover:flex z-50">
                        <div className="overflow-hidden rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                            <div className="bg-gray-50 p-2 w-72">
                                <label htmlFor="about" className="block text-sm font-medium text-gray-700 text-left pb-1">
                                    Comment
                                </label>
                                <div className="mb-2">
                                    <textarea
                                        rows={2}
                                        name="comment"
                                        value={comment}
                                        onChange={(e) => { setComment(e.target.value) }}
                                        className="block w-full resize-none rounded-md border-gray-300 shadow-sm focus:border-blue-kiwi focus:ring-blue-kiwi sm:text-sm"
                                    />
                                </div>
                                <span className=" flex justify-end rounded-md shadow-sm text-right">
                                    <button
                                        type="button"
                                        className="outline outline-red-500 outline-1 inline-flex items-center px-2 py-1 rounded-md text-xs font-medium text-red-500 bg-white hover:bg-red-500 hover:text-white mr-2"
                                        onClick={() => { close(); setComment("") }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="outline outline-green-500 outline-1 bg-green-500 inline-flex items-center px-2 py-1 rounded-md text-sm font-medium text-white hover:bg-green-600"
                                        onClick={() => { close(); submit() }}
                                    >
                                        <CheckIcon className="h-5 w-5 text-green hover:text-white" aria-hidden="true" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    )
}