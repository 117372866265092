import { useEffect, useState } from 'react';
import { CursorArrowRaysIcon, BookOpenIcon, BellIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SendDPDCampaigns from './SendDPDCampaigns';
import HistoryDPDCampaign from './HistoryDPDCampaign';

const requestStatus = {
    NOT_STATUS: 'not_status',
    DPD_CAMPAIGNS_HISTORY: 'dpd_history',
    SEND_CAMPAIGNS: 'send_campaigns'
}

const ADMIN_PROFILE = 'admin';

export default function DPDCampaigns() {
    const navigate = useNavigate();
    const dataAuth = useSelector(state => state.auth);
    const [currentStatusPage, setCurrentStatusPage] = useState(requestStatus.NOT_STATUS);
    const request_status = [
        {
            id: 1,
            name: 'Requests History',
            description: 'A history of all dpd campaigns send',
            icon: () => <BookOpenIcon className="h-5 w-5 text-dark-kiwi" />,
            status: 'dpd_history'
        },
        {
            id: 2,
            name: 'DPD Campaigns',
            description: 'Send dpd campaigns by demand',
            icon: () => <BellIcon className="h-5 w-5 text-dark-kiwi" />,
            status: 'send_campaigns'
        },
    ];

    useEffect(() => {
        if(dataAuth && dataAuth.profile !== ADMIN_PROFILE){
            navigate('/');            
        }
    })

    return (
        <div className="w-full pt-8 h-dashboard bg-gray-100">

            { currentStatusPage === requestStatus.NOT_STATUS && (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center pb-3 border-b">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">
                              DPD Campaign Module
                            </h1>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 py-10">
                        {request_status.map((item, index) => (
                            <div
                              key={index}
                              to={item.path}
                              className="bg-white relative hover:shadow-none py-6 px-5 rounded-md shadow-md h-full flex cursor-pointer"
                              onClick={() => setCurrentStatusPage(item.status)}
                            >
                                <div className="absolute right-4 top-4">
                                  <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
                                </div>
                                <div className="flex gap-4">
                                    <i className="bg-blue-50-kiwi p-2 h-10 w-10 min-w-[2.5rem] flex items-center justify-center rounded-lg">
                                        <p>{item.icon()}</p>
                                    </i>
                                    <div>
                                        <h1 className="text-xl font-semibold text-dark-kiwi">
                                            {item.name}
                                        </h1>
                                        <p className="text-gray-400 text-xs mt-2">
                                             {item.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            { currentStatusPage === requestStatus.SEND_CAMPAIGNS && (
                <SendDPDCampaigns
                    hasBack={true}
                    setStatusPage={setCurrentStatusPage}
                    statusPage={requestStatus.NOT_STATUS}
                />
            )}
            
            { currentStatusPage === requestStatus.DPD_CAMPAIGNS_HISTORY && (
                <HistoryDPDCampaign 
                    hasBack={true}
                    setStatusPage={setCurrentStatusPage}
                    statusPage={requestStatus.NOT_STATUS}
                />
            )}
            
        </div>
    );
}
