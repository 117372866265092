import { RadioGroup, Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/solid'
import { classNames } from 'utils/class_names'

const tabs = [
    {
        id: 'profile',
        name: 'Profile',
        options: [
            {
                value: 'admin',
                name: 'Administrator',
                description: 'Full access to the platform (Statistics, credits, users)',
                checked: false
            },
            {
                value: 'atm',
                name: 'ATM',
                description: 'Allows to disburse credits to clients',
                checked: false
            },
            {
                value: 'inspector',
                name: 'Inspector',
                description: 'See list of credits that are active',
                checked: true
            },
        ]
    }
]

const CollapseProfiles = ({ selected, setSelected }) => {
    return (
        <>
            {tabs.map((item) => (
                <Disclosure as="div" key={item.id} className="px-2 py-3">
                    {({ open }) => (
                        <>
                            <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="bg-gray-100 px-2 py-3 w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">{item.name}</span>
                                    <span className="ml-6 flex items-center">
                                        {!open ? (
                                            <MinusSmallIcon className="h-5 w-5" aria-hidden="true" />
                                        ) : (
                                            <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
                                        )}
                                    </span>
                                </Disclosure.Button>
                            </h3>

                            {
                                !open && (
                                    <div>
                                        <Disclosure.Panel className="pt-6" static>
                                            <div>
                                                <RadioGroup value={selected} onChange={setSelected}>
                                                    <div className="space-y-3">
                                                        {item.options.map((rol) => (
                                                            <RadioGroup.Option
                                                                key={rol.value}
                                                                value={rol.value}
                                                                className={({ checked, active }) =>
                                                                    classNames(
                                                                        checked ? 'border-transparent' : 'border-gray-300',
                                                                        active ? 'border-blue-kiwi ring-2 ring-blue-kiwi' : '',
                                                                        'relative block bg-white border rounded-lg shadow-sm px-4 py-2 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                                                                    )
                                                                }
                                                            >
                                                                {({ active, checked }) => (
                                                                    <>
                                                                        <span className="flex items-center">
                                                                            <span className="text-sm flex flex-col">
                                                                                <RadioGroup.Label as="span" className="font-medium text-gray-900">
                                                                                    {rol.name}
                                                                                </RadioGroup.Label>
                                                                                <RadioGroup.Description as="span" className="text-gray-400">
                                                                                    <span className="block sm:inline">
                                                                                        {rol.description}
                                                                                    </span>
                                                                                </RadioGroup.Description>
                                                                            </span>
                                                                        </span>

                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'border' : 'border-2',
                                                                                checked ? 'border-blue-kiwi' : 'border-transparent',
                                                                                'absolute -inset-px rounded-lg pointer-events-none'
                                                                            )}
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )}
                                                            </RadioGroup.Option>
                                                        ))}
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </Disclosure.Panel>
                                    </div>
                                )
                            }
                        </>
                    )}
                </Disclosure>
            ))}</>
    )
}

export default CollapseProfiles;