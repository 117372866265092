import { format } from "date-fns"
import { FileIcon } from 'react-file-icon';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useState } from "react";

import Slide from "./Slide";
import Tooltip from "components/UI/Tooltip";
import Loader from "components/UI/Loader";
import axios from "axios";

export default ({ state = false, dataFiles = {}, close } = {}) => {
    const [loader, setLoader] = useState(false)

    const [slide, setSlide] = useState({
        status: false,
        files: [],
        index: 0
    })
    const icon_mime = (mime) => {
        const type = [
            { mime: 'image/jpeg', type: 'image', color: 'papayawhip', ext: 'JPEG', },
            { mime: 'image/png', type: 'image', color: 'aliceblue', ext: 'PNG', },
            { mime: 'application/pdf', type: 'acrobat', color: 'mistyrose', ext: 'PDF', }
        ]

        const select = type.find(e => e.mime == mime)

        return (<FileIcon extension={select?.ext || ''} type={select?.type || ''} color={select?.color || ''} />)
    }

    const formatBytes = (bytes, decimals = 3) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const order_data_slide = async (item) => {
        let files = []
        let idx = dataFiles.files.findIndex(e => e.id == item.id)
        setLoader(true)

        for await (const iterator of dataFiles.files) {
            const signed = await getUrlSigned(iterator)

            files.push({
                name: iterator.original_name,
                size: formatBytes(iterator.size),
                original: signed.url,
                thumbnail: signed.url,
            })
        }

        setSlide({ status: true, files, index: idx })
        setLoader(false)
    }

    const getUrlSigned = async (item) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/files/signed-url/${item.id}`,
            })

            return response.data?.data || {}

        } catch (error) {
            console.log(error)
            return {}
        }
    }

    return (<>
        <table className="min-w-full border rounded-md divide-y divide-gray-300">
            <thead className="bg-gray-100">
                <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ml-8">
                        Original name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Created At
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Actions</span>
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {dataFiles && dataFiles.files?.map((item, index) => (
                    <tr key={item.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div className="flex items-center">
                                <div className="h-6 w-6 flex-shrink-0">
                                    {icon_mime(item.mime_type)}
                                </div>
                                <div className="ml-4">
                                    <div className="font-medium text-gray-900">{item.original_name.length > 50 ? `${item.original_name.slice(0, 38)}...${item.original_name.slice(item.original_name.length - 8)}` : item.original_name }</div>
                                    <div className="text-xs text-gray-500">
                                        <span className="text-gray-900">Size: </span>
                                        {formatBytes(item.size)}
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{format(new Date(item.created_at), 'MMMM dd, yyyy - p')}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Tooltip tooltip={"Open file"}>
                                <button onClick={() => { order_data_slide(item) }}>
                                    <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue-kiwi hover:text-blue-kiwi" />
                                </button>
                            </Tooltip>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

        <Slide
            status={slide.status}
            files={slide.files}
            index={slide.index}
            close={() => {
                setSlide({
                    status: false,
                    files: [],
                    index: 0
                })
            }} />

        <Loader status={loader}></Loader>
    </>)
}