import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Api from "redux/api";
import StatusList from "./History/List";
import CreateStatus from "./History/CreateStatus";
import Loader from "components/UI/Loader";
import VerificationLog from "components/History/Verification";

const ApplicationLogs = ({ onChange }) => {

    const { user_id } = useParams();
    const [application, setApplication] = useState(null);

    const [loader, setLoader] = useState(false);
    const [statusPage, setStatusPage] = useState("list");
    const [status, setStatus] = useState([]);

    useEffect(() => {
        getApplication()
    }, [])

    useEffect(() => {
        if (application) {
            setStatus(application.status || []);
        }
    }, [application])

    const getApplication = async () => {
        try {
            setLoader(true);
            const response = await Api.fetchApplicationByUser({ user_id });
            setApplication(response.data.application);
        } catch (error) {
            console.log(error);
        }
        setLoader(false);
    }

    const handleStatusPage = (value) => {
        setStatusPage(value);
        if (value === "list") {
            getApplication()
            onChange()
        }
    }

    const addStatus = (new_status) => {
        setStatus([new_status, ...status])
    }

    return (
        application ? <div className="w-72 h-dashboard flex-none sticky border-l">
            <Loader status={loader}></Loader>
            {statusPage === "list" && <StatusList data={status} onMove={handleStatusPage} />}
            {statusPage === "create" && <CreateStatus application={application} onMove={handleStatusPage} onCreate={addStatus} />}
            {statusPage === "verification" && <VerificationLog 
                application={application}
                onMove={handleStatusPage} 
                onCreate={addStatus}
            />}
        </div> :
            <div className="w-72 h-dashboard flex-none sticky border-l"></div>
    )
}

export default ApplicationLogs;