import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerificationsService } from 'services/Verifications/Verifications.services';
import { actions } from 'redux/reducer';

import PendingVerifications from './PendingVerifications';
import PreviousVerifications from './PreviousVerifications';
import GeneralError from '../../../../components/GeneralError';

export default function Verification() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const [pendingData, setPendingData] = useState(null);
  const [notPendingData, setNotPendingData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const getVerificationsData = async () => {
    setError(false);
    setLoader(true);
    setPendingData(null);
    setNotPendingData(null);

    try {
      const {
        data: { data: verifications, success },
      } = await VerificationsService.getDataVerificationByUserId(user?.id);

      if (!success) throw new Error();

      if (!verifications.pending_verifications)
        return setNotPendingData(verifications?.not_pending_verifications);

      const currentDataPending = verifications?.pending_verifications.map(
        item => {
          return {
            ...item,
            current_status: 'pending',
            current_view: false,
          };
        }
      );
      setPendingData(currentDataPending);
      setNotPendingData(verifications?.not_pending_verifications);
    } catch (error) {
      setError(true);
    } finally {
      setLoader(false);
    }
  };

  const updateItemVerification = async item => {
    dispatch(
      actions.setMessage({
        type: 'warning',
        title: 'Wait for',
        message: 'We are updating the information',
      })
    );

    try {
      const { id, current_status, payload } = item;

      const {
        data: { success },
      } = await VerificationsService.updateDataVerificationById(id, {
        status: current_status,
        payload,
      });

      if (!success) throw new Error();
      dispatch(
        actions.setMessage({
          type: 'success',
          title: 'Successful',
          message: 'We have successfully updated the information',
        })
      );
      getVerificationsData();
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error, could not update the verification item, try again.',
        })
      );
    }
  };

  useEffect(() => {
    getVerificationsData();
  }, []);

  return (
    <div className="p-8">
      {!error ? (
        <>
          <PendingVerifications
            data={pendingData}
            setPendingData={setPendingData}
            updateItemVerification={updateItemVerification}
            loader={loader}
          />
          <div className="w-full border-b my-8"></div>
          <PreviousVerifications data={notPendingData} loader={loader} />
        </>
      ) : (
        <GeneralError tryAgain={getVerificationsData} />
      )}
    </div>
  );
}
