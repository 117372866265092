/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Item from 'components/UserLoansItem';
import Api from 'redux/api';
import { LoanServices } from 'services/Loan/Loan.services';
import LoanDocumentModal from 'components/Modals/LoanDocuments';
import DocumentViewLoanModal from 'components/Modals/DocumentViewLoan';

const LoanPro = () => {
  const { user_id } = useParams();
  const [loans, setLoans] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentDocuments, setCurrentDocuments] = useState([]);

  useEffect(() => {
    getLoans();
  }, []);

  const viewDocuments = async id => {
    try {
      setLoader(true);
      const response = await LoanServices.getAllDocuments(id);
      if (!response.data.success) throw new Error();

      setCurrentDocuments(response.data.data);
      setShowModal(true);
    } catch (error) {
      alert(error);
    } finally {
      setLoader(false);
    }
  };

  const openDocument = async (document_id, file_original_name, size) => {
    try {
      const config = {
        onDownloadProgress: progressEvent => {
          const total = size;
          const current = progressEvent.loaded;
          setProgress((total > 0 ? (current / total) * 100 : 0).toFixed(0));
        },
      };

      const response = await LoanServices.getDocumentByData(
        document_id,
        file_original_name,
        config
      );

      if (!response) throw new Error();

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setPdfUrl(url);
      setShowModalPDF(true);
      closeModal();
    } catch (error) {
      alert(error);
    }
  };

  const closeModal = () => {
    setCurrentDocuments([]);
    setShowModal(!showModal);
    setProgress(0);
  };

  const closeModalPDF = () => {
    setShowModalPDF(!showModalPDF);
    setPdfUrl(null);
  };

  const getLoans = async () => {
    try {
      const response = await Api.fetchLoansByUser({ user_id });
      setLoans(response.data.loans);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        LoanPro ID
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Created At
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Funding Method
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Credit Builder
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Documents
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {loans &&
                      loans.map((loan, index) => (
                        <Item
                          key={index}
                          loan={loan}
                          viewDocuments={viewDocuments}
                          loader={loader}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoanDocumentModal
        closeModal={closeModal}
        isOpen={showModal}
        documents={currentDocuments}
        openDocument={openDocument}
        progress={progress}
      />

      <DocumentViewLoanModal
        pdfUrl={pdfUrl}
        closeModal={closeModalPDF}
        isOpen={showModalPDF}
      />
    </>
  );
};

export default LoanPro;
