import React, { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { PaymentServices } from "services/Payment/Payment.services";
import { actions } from "redux/reducer";
import Loader from "components/UI/Loader";

const SOURCE_LOG = 'cms';
const ERROR_RESPONSE = {
    INVALID_VALUES: 'ERROR_FORMAT_VALUES',
    LOAN_ID_NOT_FOUND: 'ERROR_LOAN_ID',
    ERROR_CREATE: 'ERROR_CREATE',
}

const PAYMENT_TYPES = [
    {
      label: 'Single Payment',
      value: 'single_payment',
    },
    {
      label: 'Recurring Payment',
      value: 'recurring_payment',
    }
];

export default function CreateChangePaymentLogModal({
    showModal = false,
    closeModal,
    updateChangePaymentsTable
} = {}) {
   
    const onCloseModal = () => {
        resetFormInputs()
        closeModal()
    }

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [newLog, setNewLog] = useState(null);

    const [errorsYup, setErrorYup] = useState({
        loan_id: null,
        changed_date: null,
        new_date: null,
        payment_type: null
    });

    let newLogSchema = yup.object().shape({
        loan_id: yup.number("Invalid format").integer("Loan ID should be a number").moreThan(0,"Loan ID should be greater than 0").required("Loan ID is required"),
        changed_date: yup.date("Invalid format").required("Changed date is required"),
        new_date: yup.date("Invalid format").required("Changed date is required"),
        payment_type: yup.string().required("Payment type is required")
    });

    const handleUser = (e) => {
        const { name, value } = e.target;

        setNewLog(prevData => ({
            ...prevData,
            [name]: value
        }))
        setErrorYup(prevData => ({
            ...prevData,
            [name]: null
        }))
    };

    const handleError = ({ name, value = false }) => {
        setErrorYup((prevData) => ({
            ...prevData,
            [name]: value ? true : false
        }));
    }

    const canCreateNewLog = async (data) => {
        const payload = {
            loan_id: data?.loan_id,
            changed_date: data?.changed_date,
            new_date: data?.new_date,
            payment_type: data?.payment_type,
            autopay_id: data?.autopay_id
        }
        try {
            await newLogSchema.validate(payload, {abortEarly: false});
            return true;
        } catch (err) {
            let yupErrorsObject = {};
    
            err.inner.map((validationError) => {
                if (!yupErrorsObject.hasOwnProperty([validationError.path])) {
                  yupErrorsObject[validationError.path] = validationError.errors[0];
                }
            });
    
            setErrorYup((prevData) => ({
             ...prevData,
             ...yupErrorsObject
            }))
            return false;
        }
    }

    const errorResponseValidation = (error) => {
        if(error === ERROR_RESPONSE.ERROR_CREATE){
            dispatch(actions.setMessage({
                type: 'wrong', title: 'wrong', message: "can't create log"
            }));
        }

        if(error === ERROR_RESPONSE.INVALID_VALUES){
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Invalid values for amount or autopay ID '
            }));
        }

        if(error === ERROR_RESPONSE.LOAN_ID_NOT_FOUND){
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Loan ID not found'
            }));
        }
    }

    const handleSubmit = async (data) => {
        try{
            const AMOUNT_DEFAULT = 0;
            setLoader(true);
            const can_create = await canCreateNewLog(data);
            if(!can_create){
                setLoader(false);
                return;
            }

            await PaymentServices.newChangeDueDateLog(
                data.loan_id
                ,data.changed_date
                ,data.new_date
                ,AMOUNT_DEFAULT
                ,SOURCE_LOG
                ,data.payment_type
            )
            setLoader(false);
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Log created'
            }));
            resetFormInputs()
            closeModal()
            updateChangePaymentsTable()
        }catch(error){
            const { data: { messages } } = error.response;
            errorResponseValidation(messages);
            setLoader(false);
        }
    }

    const resetFormInputs = () => {
        setNewLog(null)
        setErrorYup({
            loan_id: null,
            changed_date: null,
            new_date: null,
            payment_type: null
        })
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <Loader status={loader} />
                        <div className="relative w-full my-6 mx-auto max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        New Request
                                    </h3>
                                    <button
                                        className="ml-auto text-black float-right text-3xl "
                                        onClick={onCloseModal}>
                                        <XMarkIcon className="text-black-900 font-semibold h-6 w-6 block" />
                                    </button>
                                </div>

                                {/*body*/}
                                <div className="w-full mx-auto space-y-8 divide-y divide-gray-200 px-6 lg:px-8 ">
                                    <div>
                                        <div className="mt-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="loan_id" className="block text-sm font-medium text-gray-700">
                                                    Loan ID
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="number"
                                                        value={newLog?.loan_id}
                                                        onChange={(e) => { handleUser(e); handleError({ name: e.target.name }) }}
                                                        name="loan_id"
                                                        className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                    {errorsYup.loan_id && (
                                                        <p className="text-red-500 text-sm mt-2">
                                                            {errorsYup.loan_id}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="changed_date" className="block text-sm font-medium text-gray-700">
                                                    Changed Date
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="date"
                                                        value={newLog?.changed_date}
                                                        onChange={(e) => { handleUser(e); handleError({ name: e.target.name }) }}
                                                        name="changed_date"
                                                        className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                    {errorsYup.changed_date && (
                                                        <p className="text-red-500 text-sm mt-2">
                                                            {errorsYup.changed_date}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="new_date" className="block text-sm font-medium text-gray-700">
                                                    New Date
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="date"
                                                        value={newLog?.new_date}
                                                        onChange={(e) => { handleUser(e); handleError({ name: e.target.name }) }}
                                                        name="new_date"
                                                        className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                    {errorsYup.new_date && (
                                                        <p className="text-red-500 text-sm mt-2">
                                                            {errorsYup.new_date}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="new_date" className="block text-sm font-medium text-gray-700">
                                                    Payment Type
                                                </label>
                                                <div className="mt-1">
                                                    <select
                                                        name="payment_type"
                                                        onChange={(e) => { handleUser(e); handleError({ name: e.target.name }) }}
                                                        className="shadow-sm focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
                                                    >
                                                        <option value="">Choose an option</option>
                                                        {PAYMENT_TYPES.map(item => (
                                                          <option value={item.value} key={item.value}>
                                                            {item.label}
                                                          </option>
                                                        ))}
                                                    </select>
                                                    {errorsYup.payment_type && (
                                                        <p className="text-red-500 text-sm mt-2">
                                                            {errorsYup.payment_type}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-6 flex justify-end">
                                            <button type="button"
                                                className="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                onClick={() => handleSubmit(newLog)}
                                            >
                                                Create Log
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}