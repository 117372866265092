import React, { useEffect, useState } from "react";
import {
  TrashIcon,
  UserIcon,
  UserPlusIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import Loader from "components/UI/Loader";
import Api from "redux/api";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

const dataForm = () => {
  return {
    phone_number: "",
    email: "",
    confirmEmail: "",
    password: "",
    profile: "",
    active: true,
    method: "create",
  };
};

const profiles = [
  { value: "admin", text: "Administrator" },
  { value: "sales", text: "Sales" },
  { value: "support", text: "Support" },
  { value: "viewer", text: "Viewer" },
];

const AdminUsers = () => {
  const dispatch = useDispatch();
  const [auths, setAuths] = useState();
  const [loader, setLoader] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);
  const [form, setForm] = useState(dataForm);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const validateForm = () => {
    if (!form.phone_number) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Phone number is requerid",
        })
      );
    } else if (!form.email) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Email is requerid",
        })
      );
    } else if (!form.confirmEmail) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Confirm email is requerid",
        })
      );
    } else if (form.email !== form.confirmEmail) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Emails must match",
        })
      );
    } else if (!form.profile) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Profile is requerid",
        })
      );
    } else {
      setShowModalUser(false);
      saveAuth(form);
      setForm(dataForm);
    }
  };

  useEffect(() => {
    getAuths();
  }, []);

  const getAuths = async () => {
    try {
      const response = await Api.fetchTeam();

      setAuths(response.data.data);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: "Error loading auths",
        })
      );
    }
  };

  const saveAuth = async (data) => {
    let response;
    try {
      setLoader(true);

      if (data.method === "update") {
        data.password = generateRandomPassword("update");

        response = await Api.updateTeam(data);
      } else if (data.method === "delete") {
        response = await Api.deleteTeam(data);
      } else {
        response = await Api.saveTeam(data);
      }

      setLoader(false);
      dispatch(
        actions.setMessage({
          type: "success",
          title: "Success",
          message: response?.data?.messages,
        })
      );

      setForm(dataForm);
      getAuths();
    } catch (error) {
      console.log(error);
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: "wrong",
          title: "Wrong",
          message: error?.response?.data?.messages,
        })
      );
    }
  };

  const getProfile = (id) => {
    let profile = profiles.find((e) => e.value === id);
    return profile ? profile.text : "";
  };

  const formatDate = (dateIso) => {
    if (!dateIso) return "";

    let [date, hour] = new Date(dateIso).toLocaleString().split(",");
    let format_date = date.split("/").reverse().join("-");

    return format_date + ",  " + hour;
  };

  const generateRandomPassword = (method) => {
    const validChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const specialChars = "!@#$%&*()-_+=,;:'\"<>/?\\";

    const allChars = validChars + specialChars;

    let password = "";
    const passwordLength = 10;

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      const randomChar = allChars[randomIndex];

      password += randomChar;
    }

    if (method === "update") return password;

    setForm((prevData) => ({
      ...prevData,
      password,
    }));
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="py-8 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Team</h1>
          </div>

          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
            onClick={() => {
              setForm(dataForm);
              setShowModalUser(true);
              generateRandomPassword("post");
            }}
          >
            New user
            <UserPlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <br />

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Profile
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created At
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {auths &&
                      auths.map((auth) => (
                        <tr key={auth.phone_number}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            <div className="text-gray-900 font-semibold">{`${auth.email}`}</div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                            <div className="text-gray-500">{`${auth.phone_number}`}</div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                            <div className="text-gray-500">
                              {getProfile(auth.profile)}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                            <div className="text-gray-500">
                              {formatDate(auth.created_at)}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                            <div className="text-gray-500 flex align-middle">
                              <div className="relative flex flex-col items-center group">
                                <TrashIcon
                                  id={auth.id}
                                  onClick={() =>
                                    saveAuth({
                                      ...auth,
                                      active: false,
                                      method: "delete",
                                    })
                                  }
                                  className="ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-red-600"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Tooltip on top"
                                />

                                <div className="absolute bottom-0 flex flex-col items-center hidden mb-6        group-hover:flex">
                                  <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                                    Delete user
                                  </span>
                                  <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
                                </div>
                              </div>

                              <div className="relative flex flex-col items-center group">
                                <ArrowPathIcon
                                  id={auth.id}
                                  onClick={() =>
                                    saveAuth({
                                      ...auth,
                                      active: false,
                                      method: "update",
                                    })
                                  }
                                  className="ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-red-600"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Tooltip on top"
                                />

                                <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex z-10">
                                  <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                                    Renew password
                                  </span>
                                  <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModalUser ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-96 my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                  <UserIcon
                    className="mt-1.5 mr-1 h-5 w-5"
                    aria-hidden="true"
                  />

                  <h3 className="text-2xl font-semibold">
                    {form?.method === "create" ? "New user" : "Update user"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModalUser(false)}
                  >
                    <span className="text-black-900 font-semibold h-6 w-6 block">
                      ×
                    </span>
                  </button>
                </div>

                {/*body*/}
                <div className="relative  flex-auto">
                  <div className="mt-10 sm:mt-0">
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="px-4 py-4 bg-white ">
                        <div className="grid grid-cols-12 gap-3">
                          <div className="col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Phone number
                            </label>
                            <input
                              disabled={
                                form?.method === "create" ? false : true
                              }
                              type="text"
                              name="phone_number"
                              value={form.phone_number}
                              onChange={handleChangeForm}
                              autoComplete="phone_number"
                              className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Email address
                            </label>
                            <input
                              type="text"
                              name="email"
                              value={form.email}
                              onChange={handleChangeForm}
                              autoComplete="off"
                              className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Confirm email address
                            </label>
                            <input
                              type="text"
                              name="confirmEmail"
                              value={form.confirmEmail}
                              onChange={handleChangeForm}
                              onPaste={handlePaste}
                              autoComplete="off"
                              className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Password
                            </label>
                            <input
                              type="password"
                              name="password"
                              value={form.password}
                              onChange={handleChangeForm}
                              autoComplete="off"
                              disabled={true}
                              className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Profile
                            </label>
                            <select
                              name="profile"
                              value={form.profile}
                              onChange={handleChangeForm}
                              autoComplete="profile"
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
                            >
                              <option value="">Select a profile</option>
                              {profiles &&
                                profiles.map((profile) => (
                                  <option
                                    key={profile.value}
                                    value={profile.value}
                                  >
                                    {" "}
                                    {`${profile.text}`}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-500 text-white active:bg-red-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-red-700 mr-1"
                    type="button"
                    onClick={() => {
                      setShowModalUser(false);
                      setForm(dataForm);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-emerald-700"
                    type="button"
                    onClick={validateForm}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <Loader status={loader}></Loader>
    </div>
  );
};

export default AdminUsers;
