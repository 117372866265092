import { HomeIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Plot from 'react-plotly.js';

import { classNames } from 'utils/class_names';

export default ({ records = [], chart = {} }) => {
    const [activeTab, setActiveTab] = useState("variables");

    return (<>
        <div className="border-b border-gray-200">
            <nav className="flex space-x-5 w-[100%] justify-around" aria-label="Tabs">
                <div
                    className={(classNames(activeTab == 'variables' ? 'border-blue-kiwi text-blue-kiwi' : '', 'group inline-flex items-center py-4 border-b-2 font-medium text-sm w-[100%] cursor-pointer'))}
                    onClick={() => setActiveTab("variables")}
                >
                    <HomeIcon className='mr-1 h-5 w-5' />
                    Home
                </div>
                <div
                    className={(classNames(activeTab == 'chart' ? 'border-blue-kiwi text-blue-kiwi' : '', 'group inline-flex items-center py-4 border-b-2 font-medium text-sm w-[100%] cursor-pointer'))}
                    onClick={() => setActiveTab("chart")}
                >
                    <PresentationChartLineIcon className='mr-1 h-5 w-5' />
                    Graphics
                </div>
            </nav>

            {
                activeTab == 'variables' ?
                    <div className="border-t border-gray-200 p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                            {records.map((item) => {
                                return (
                                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-4">
                                        <dt className="text-sm font-semibold text-gray-900">
                                            {item.title}
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-500 sm:mt-0">
                                            {item.value}
                                        </dd>
                                    </div>
                                )
                            })}
                        </dl>
                    </div>
                    : null
            }

            {
                activeTab == 'chart' ?
                    <div className='flex justify-center'>
                        <Plot data={chart.data} layout={chart.layout} />
                    </div>
                    : null
            }


        </div>
    </>)
}