import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  ArrowPathIcon,
  XMarkIcon,
  DocumentChartBarIcon,
  ChevronRightIcon,
  BanknotesIcon,
  DevicePhoneMobileIcon,
  ArchiveBoxIcon,
} from '@heroicons/react/24/solid';
import {
  AdjustmentsVerticalIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import Loader from 'components/UI/Loader';
import BankDataItem from 'components/BankDataItem';
import Tooltip from 'components/UI/Tooltip';

import ModalViewDataJson from './ModalViewDataJson';
import ModalEnhanced from './ModalEnhanced';

import { actions } from 'redux/reducer';
import Api from 'redux/api';
import { Disclosure, Transition } from '@headlessui/react';
import TransactionModal from './TransactionModal';

import { PlaidServices } from 'services/Plaid/Plaid.services';
import { mapTierTabs } from './mapper';

const ERROR_INCOME_IDENTIFICATION = {
  INTERNAL_SERVER_ERROR: 'ERROR_INCOME_DISPERSION',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  NOT_BANK_DATA: 'NOT_BANK_DATA',
};

export default function UserBankDataHistory() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [
    incomeVerificationSkeletonStatus,
    setIncomeVerificationSkeletonStatus,
  ] = useState(true);

  const [statusButton, setStatusButton] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [dataPlaid, setDataPlaid] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [showModalEnhanced, setShowModalEnhanced] = useState(false);
  const [enhanced, setEnhanced] = useState({ plaid: {}, plot: {} });

  const [hasIncomeVerificationStatus, setHasIncomeVerificationStatus] =
    useState(false);

  const [tierTabs, setTierTabs] = useState([
    {
      id: 1,
      reference: 'tier_1',
      name: 'Ssn - Penciones',
      icon: ArchiveBoxIcon,
      is_selected: true,
    },
    {
      id: 2,
      reference: 'tier_2',
      name: 'Payrolls',
      icon: BanknotesIcon,
      is_selected: false,
    },
    {
      id: 3,
      reference: 'tier_3',
      name: 'Ath-movil - Transferencias',
      icon: DevicePhoneMobileIcon,
      is_selected: false,
    },
  ]);

  const [currentTierTab, setCurrentTierTab] = useState(tierTabs[0]);
  const [incomeDetails, setIncomeDetails] = useState(null);
  const [incomeTransactions, setIncomeTransactions] = useState(null);
  const [transactionModalState, setTransactionModalState] = useState(false);
  const columnsTransactionModel = [
    'account_id',
    'amount',
    'date',
    'income_identification_category',
    'name',
  ];
  const [contentTransactionTable, setContentTransactionTable] = useState(null);

  useEffect(() => {
    if (user) {
      getBankData();
    }
  }, [user]);

  useEffect(() => {
    getIncomeIdentification();
  }, []);

  const closeTransactionModal = () => {
    setTransactionModalState(false);
  };

  const openTransactionModal = () => {
    setTransactionTableColumns(incomeTransactions);
    setTransactionModalState(true);
  };

  const getIncomeIdentification = async () => {
    try {
      const reques_income = await PlaidServices.getIncomeIdentification(
        user.id
      );
      const new_tier_tabs = mapTierTabs(
        tierTabs,
        reques_income.data.data.body.income_identification[0]
      );
      setTierTabs(new_tier_tabs);
      setCurrentTierTab(new_tier_tabs[0]);
      setHasIncomeVerificationStatus(true);
      setIncomeDetails({
        income:
          reques_income.data.data.body.income_identification[0].avg_income,
        description:
          reques_income.data.data.body.income_identification[0].flag.replaceAll(
            '_',
            ' '
          ),
      });
      setIncomeTransactions(reques_income.data.data.body.transactions);
    } catch (error) {
      const INTERNAL_SERVER_ERROR_MESSAGE = error?.response?.data?.messages;
      handleIncomeIdentificationError(INTERNAL_SERVER_ERROR_MESSAGE);
    } finally {
      setIncomeVerificationSkeletonStatus(false);
    }
  };

  const handleIncomeIdentificationError = error => {
    if (error === ERROR_INCOME_IDENTIFICATION.NOT_BANK_DATA) {
      return dispatch(
        actions.setMessage({
          type: 'warning',
          title: 'Warning',
          message: 'Error getting income identification',
        })
      );
    }

    if (error === ERROR_INCOME_IDENTIFICATION.USER_NOT_FOUND) {
      return dispatch(
        actions.setMessage({
          type: 'warning',
          title: 'Warning',
          message: 'USER NOT FOUND',
        })
      );
    }

    dispatch(
      actions.setMessage({
        type: 'warning',
        title: 'Warning',
        message: 'Income Identification Internal Server Error',
      })
    );
  };

  const setTransactionTableColumns = transactions => {
    const transactions_by_reference = transactions.filter(
      transaction => transaction.income_tier === currentTierTab?.reference
    );

    const content_table = [];
    for (let i = 0; i < transactions_by_reference.length; i++) {
      const rows = getTransactionTableRows(transactions_by_reference[i]);
      if (rows) {
        content_table[i] = rows;
      }
    }
    setContentTransactionTable(content_table);
  };

  const getTransactionTableRows = transaction => {
    const payload = {};
    const filter_columns = Object.entries(transaction).filter(([key]) =>
      columnsTransactionModel.some(column => key == column)
    );

    if (!filter_columns?.length) {
      return null;
    }

    filter_columns.forEach(([key, value]) => {
      payload[key] = value;
    });

    return payload;
  };

  const changeArrowButton = index => {
    setStatusButton(!statusButton);
    setCurrentIndex(index);
  };

  const getBankData = async () => {
    try {
      setLoader(true);
      const bank_data_report = await Api.fetchBankData({
        user_id: user.id,
        firebase_id: user.firebase_id,
      });

      bank_data_report.data.data.forEach(element => {
        let owners = element.owners || [];
        let ach_numbers = element.bank_numbers?.ach || [];
        let accounts = element.accounts || {};
        accounts = accounts?.accounts ? accounts : JSON.parse(accounts);

        accounts.accounts?.map(item => {
          let find = owners.find(e => e.account_id === item.account_id);
          item.owners = find?.owners || null;

          let bank_numbers = ach_numbers.find(
            e => e.account_id === item.account_id
          );
          item.bank_numbers = bank_numbers || null;
          return item;
        });

        let transactions =
          element.plaid_transactions.length > 0
            ? element.plaid_transactions
            : element.transactions;

        const new_transactions_set = deleteDuplicateTransactions(transactions);

        const new_transactions = new_transactions_set?.map(element => {
          element.type = element.transaction_type;
          return element;
        });

        element.transactions = new_transactions;
      });

      setDataPlaid(bank_data_report.data.data || {});
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Plaid: Error loading bank data',
        })
      );
      return true;
    }
  };

  const refreshBankData = async () => {
    try {
      setLoader(true);

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/bank-data`,
        data: {
          user_id: user.firebase_id,
        },
      });

      setTimeout(() => {
        getBankData();
      }, 750);
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Plaid error: Refresh bank data',
        })
      );
    }
  };

  const getEnhancedVariables = async () => {
    try {
      setLoader(true);

      const response = await Api.getEnhancedVariables({
        user_id: user.id,
      });

      setLoader(false);
      setEnhanced(response.data.data);
      setShowModalEnhanced(true);
    } catch (error) {
      setLoader(false);
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error get enhanced variables',
        })
      );
    }
  };

  const setTierTableData = id => {
    const find_tier = tierTabs.find(tab => tab.id === id);
    setCurrentTierTab({
      ...find_tier,
      is_selected: true,
    });
    updateTierTableSelected(id);
  };

  const updateTierTableSelected = id => {
    const update_selected = tierTabs.map(tab => {
      if (tab.id === id) {
        return {
          ...tab,
          is_selected: true,
        };
      }

      return {
        ...tab,
        is_selected: false,
      };
    });

    setTierTabs(update_selected);
  };

  const deleteDuplicateTransactions = data => {
    const unique_rows = [];
    const transactions_set = new Set();
    data.forEach(row => {
      const transaction_id = row.transaction_id;
      if (!transactions_set.has(transaction_id)) {
        transactions_set.add(transaction_id);
        unique_rows.push(row);
      }
    });

    return unique_rows;
  };

  const alert = () => {
    return (
      <div className="rounded-md bg-yellow-50 p-3 mb-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Click on refresh to consult the user's transactions
            </h3>
          </div>

          <div className="ml-auto pl-3 hidden">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:text-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                onClick={() => setShowAlert(!showAlert)}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white pb-10 px-6 lg:px-8">
      <Loader status={loader} />
      {incomeVerificationSkeletonStatus && (
        <div className="flex flex-col gap-5 pt-10">
          <Skeleton
            width={250}
            height={30}
            borderRadius={8}
            containerClassName="flex"
          />
          <Skeleton
            width={'100%'}
            height={30}
            borderRadius={8}
            count={2}
            containerClassName="flex gap-2.5"
          />

          <Skeleton
            width={'100%'}
            height={120}
            borderRadius={8}
            containerClassName="flex"
          />
        </div>
      )}

      {/* Income Verification Section */}
      {hasIncomeVerificationStatus && (
        <div>
          <h2 className="text-xl font-semibold py-5 text-gray-500">
            Income Identification
          </h2>
          <div className="grid grid-cols-2 w-full gap-5">
            <div>
              <h3 className="text-sm font-light">Result</h3>
              <p className="font-semibold text text-gray-500 capitalize">
                {incomeDetails?.description}
              </p>
            </div>
            <div>
              <h3 className="text-sm font-light">Income</h3>
              <p className="font-semibold text text-gray-500">
                USD {incomeDetails?.income}
              </p>
            </div>
          </div>
          <div className="w-full h-1 rounded-full bg-gray-100 my-5"></div>
          <div className="flex gap-2">
            {tierTabs.map((tab, index) => (
              <div
                key={index}
                className={`${
                  tab.is_selected
                    ? 'border-blue-kiwi text-blue-kiwi'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => {
                  setTierTableData(tab.id);
                }}
              >
                <div className="flex items-center py-4 px-1 font-medium text-sm cursor-pointer">
                  <tab.icon className="-ml-0.5 mr-2 h-5 w-5 flex-none" />
                  <p className="uppercase">{tab.name}</p>
                  {index + 1 != tierTabs.length && (
                    <div className="h-4 w-0.5 ml-2 bg-gray-300 inline-block"></div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-10">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className=" text-sm text-gray-500 bg-gray-50 font-medium">
                <tr>
                  <th scope="col" className="px-6 py-3 font-semibold">
                    Income
                  </th>
                  <th scope="col" className="px-6 py-3 font-semibold">
                    Variation
                  </th>
                  <th scope="col" className="px-6 py-3 font-semibold">
                    Weight
                  </th>
                  <th scope="col" className="px-6 py-3 font-semibold"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                    {currentTierTab.data.income}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                    {currentTierTab.data.variation === 'nan'
                      ? 'N/R'
                      : currentTierTab.data.variation}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                    {currentTierTab.data.weight}
                  </td>
                  <td
                    onClick={() => {
                      openTransactionModal();
                    }}
                    className="px-6 py-4 text-sm text-blue-dark-kiwi underline cursor-pointer whitespace-nowrap"
                  >
                    view transactions
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full h-1 rounded-full bg-gray-100 my-5"></div>
        </div>
      )}
      {/* Plaid Transaction Section */}
      {dataPlaid?.length > 0 ? (
        dataPlaid.map((item, index) => (
          <div className="pt-8" key={item.id}>
            <Disclosure key={item.id}>
              <div className="flex items-center gap-2.5 w-full rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium hover:bg-gray-200">
                <Disclosure.Button className="flex items-center gap-2.5">
                  <button
                    onClick={() => changeArrowButton(index)}
                    className="flex items-center gap-2.5"
                  >
                    <ChevronRightIcon
                      className={`${
                        currentIndex === index && statusButton
                          ? 'rotate-90 transform'
                          : ''
                      } h-5 w-5 text-gray-500`}
                    />
                    <div className="flex items-center text-left gap-2.5">
                      <div className="h-8 w-8 flex-shrink-0 mr-3">
                        <div
                          title="Plaid"
                          className="h-8 w-auto"
                          style={{
                            backgroundImage: `url(https://plaid.com/assets/img/favicons/apple-touch-icon.png)`,
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                          }}
                        ></div>
                      </div>
                      <div className="flex flex-wrap">
                        <h3
                          className="text-lg leading-6 font-medium text-gray-900"
                          style={{ width: '100%' }}
                        >
                          {item?.accounts?.accounts?.length || 0} Accounts
                        </h3>
                        <p
                          className="mt-1 max-w-2xl text-sm text-gray-500"
                          style={{ width: '100%' }}
                        >
                          <span className="text-gray-900">Plaid - </span>{' '}
                          History reports about bank data.
                        </p>
                      </div>
                    </div>
                  </button>
                </Disclosure.Button>
                <div className="ml-auto flex items-center">
                  <div className="flex justify-items-end">
                    {index === 0 && (
                      <Tooltip tooltip="Refresh plaid">
                        <button
                          type="button"
                          className="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
                          onClick={() => refreshBankData(item.item_id)}
                        >
                          <ArrowPathIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip tooltip="View data">
                      <button
                        type="button"
                        className="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
                        onClick={() => {
                          setDataModal(dataPlaid[index]);
                          setShowModal(true);
                        }}
                      >
                        <AdjustmentsVerticalIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>

                    {index === 0 && (
                      <Tooltip tooltip="Enhanced variables">
                        <button
                          type="button"
                          className="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
                          onClick={getEnhancedVariables}
                        >
                          <DocumentChartBarIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  <div className="ml-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        {item?.accounts?.accounts?.length
                          ? item.accounts.accounts.map((report, reportIdx) => {
                              return (
                                <div
                                  key={reportIdx}
                                  className="border overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-6"
                                >
                                  <BankDataItem
                                    transactions={item.transactions}
                                    account={report}
                                    viewDataJson={data => {
                                      setDataModal(data);
                                      setShowModal(true);
                                    }}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>

              {showAlert ? alert() : ''}
              <ModalViewDataJson
                showModal={showModal}
                bankData={dataModal}
                closeModal={() => setShowModal(false)}
              />
              <ModalEnhanced
                showModal={showModalEnhanced}
                data={enhanced}
                closeModal={() => setShowModalEnhanced(false)}
              />
            </Disclosure>
          </div>
        ))
      ) : (
        <div className="flex items-center gap-5 bg-yellow-100 text-yellow-700 rounded-md mt-5 p-5 cursor-pointer">
          <ExclamationTriangleIcon className="h-5 w-5" />
          <p>Transactions Not Found</p>
        </div>
      )}

      <TransactionModal
        isOpen={transactionModalState}
        closeModal={closeTransactionModal}
        currentTier={currentTierTab?.name}
        columns={columnsTransactionModel}
        contentTable={contentTransactionTable}
      />
    </div>
  );
}
