import {
  ArrowPathIcon,
  DocumentTextIcon,
  EyeIcon,
  ListBulletIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import BaseModal from 'components/BaseModal';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

export default function LoanDocumentModal({
  closeModal,
  isOpen,
  documents,
  openDocument,
  progress,
}) {
  const [viewMode, setViewMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setCurrentIndex(0);
    setLoader(false);
  }, [documents]);

  return (
    <BaseModal closeModal={closeModal} isOpen={isOpen}>
      <div className="p-8 min-w-[54rem] flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center gap-10">
            <h2 className="font-semibold text-lg text-gray-500">Documents</h2>
            <div className="flex items-center border rounded-md">
              <button
                onClick={() => setViewMode(false)}
                className={`px-2 py-1 rounded-md transition-all duration-300 ease-in-out outline-none ${
                  !viewMode ? 'bg-gray-100' : ''
                }`}
              >
                <Squares2X2Icon className="h-5 w-5 stroke-gray-500 cursor-pointer" />
              </button>
              <button
                onClick={() => setViewMode(true)}
                className={`px-2 py-1 rounded-md transition-all duration-300 ease-in-out outline-none ${
                  viewMode ? 'bg-gray-100' : ''
                }`}
              >
                <ListBulletIcon className="h-5 w-5 stroke-gray-500 cursor-pointer" />
              </button>
            </div>
          </div>
          <XMarkIcon
            onClick={closeModal}
            className="h-6 w-6 stroke-gray-400 cursor-pointer"
          />
        </div>
        <>
          {progress > 0 && (
            <ProgressBar
              completed={progress}
              transitionDuration="0.3"
              bgColor="#31c48d"
              isLabelVisible={false}
              height="5px"
              className="w-full"
            />
          )}
          {!viewMode ? (
            <div className="grid grid-cols-2 gap-6">
              {documents.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setLoader(true);
                    setCurrentIndex(index);
                    openDocument(
                      item?.document_id,
                      item?.file_attachment?.file_original_name,
                      item?.size_original
                    );
                  }}
                  className="border border-gray-200 rounded-lg px-6 py-4 flex items-center gap-4 justify-between cursor-pointer hover:border-gray-400 hover:bg-gray-100 transition-all duration-300 ease-in-out outline-none"
                >
                  <div className="flex items-center gap-4">
                    <div className="p-3 rounded-lg bg-gray-100">
                      <DocumentTextIcon className="h-6 w-6 stroke-gray-500 cursor-pointer" />
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="capitalize text-sm font-semibold text-gray-500 truncate max-w-[18rem]">
                        {item?.file_attachment?.file_name}
                      </p>
                      <p className="text-xs uppercase text-gray-500 font-medium">
                        {item?.size} - {item?.file_attachment?.file_type}
                      </p>
                      <p className="text-xs text-gray-500 font-medium">
                        {format(new Date(item?.created_at), 'MM/dd/yyyy')}
                      </p>
                    </div>
                  </div>

                  {currentIndex === index && loader ? (
                    <ArrowPathIcon className="h-6 w-6 stroke-gray-500 cursor-pointer animate-spin" />
                  ) : (
                    <EyeIcon className="h-6 w-6 stroke-gray-500 cursor-pointer" />
                  )}
                </button>
              ))}
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className=" text-sm text-gray-500 bg-gray-50 font-medium">
                  <tr>
                    <th scope="col" className="px-6 py-3 font-semibold">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 font-semibold">
                      Created
                    </th>
                    <th scope="col" className="px-6 py-3 font-semibold">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3 font-semibold">
                      Size
                    </th>

                    <th scope="col" className="px-6 py-3 font-semibold">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        setLoader(true);
                        setCurrentIndex(index);
                        openDocument(
                          item?.document_id,
                          item?.file_attachment?.file_original_name,
                          item?.size_original
                        );
                      }}
                      className="bg-white border-b  hover:bg-gray-50 "
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 capitalize text-sm font-medium text-gray-500 whitespace-nowrap truncate max-w-xs"
                      >
                        {item?.file_attachment?.file_name}
                      </th>
                      <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                        {format(new Date(item?.created_at), 'MM/dd/yyyy')}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap uppercase">
                        {item?.file_attachment?.file_type}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 font-medium whitespace-nowrap">
                        {' '}
                        {item?.size}
                      </td>

                      <td className="px-6 py-4 text-xs text-gray-500 font-medium whitespace-nowrap">
                        {currentIndex === index && loader ? (
                          <ArrowPathIcon className="h-6 w-6 stroke-gray-500 cursor-pointer animate-spin" />
                        ) : (
                          <EyeIcon className="h-6 w-6 stroke-gray-500 cursor-pointer" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      </div>
    </BaseModal>
  );
}
