import Pagination from "components/UI/Pagination";
import { format } from 'date-fns';

const TableStore = ({ dataWaitingList, count, onTake, onEdit }) => {

    return (
        <>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                            <Pagination count={count} onTake={onTake} />
                        </div>

                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Email
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Created at
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">
                                {dataWaitingList && dataWaitingList.map((item, index) => (
                                    <tr key={item.id} className={index % 2 === 0 ? undefined : 'bg-gray-100'}>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.email}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {
                                                format(new Date(item.created_at), 'MMMM dd, yyyy - p')
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableStore;