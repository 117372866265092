import { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import PersonaPhotoLoader from "./PersonaPhotoLoader";
import Tooltip from "components/UI/Tooltip";
import axios from "axios";
import Loader from "components/UI/Loader";
import Api from "redux/api";
import { useParams } from "react-router-dom";
import { PersonaServices } from "services/Persona/Persona.services";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

const PersonaDetails = ({
  inquiries,
  dataUser,
  defaultInquiry,
  openLinkPersona = () => {},
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(defaultInquiry);
  const [currentPersona, setCurrentPersona] = useState();
  const [detailedPersona, setDetailedPersona] = useState();
  const [isPhotoActive, setPhotoActive] = useState(false);
  const [photoURL, setPhotoURL] = useState("");

  const { user_id } = useParams();

  const selectInquiry = async (inq) => {
    try {
      setDetailedPersona(inq);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/persona/${inq.id}`
      );
      if (data?.data?.data) {
        setDetailedPersona({
          id: data?.data?.data?.id,
          data: data?.data?.data?.attributes,
          verifications: data?.data?.included,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const openPhotoPersona = (url) => {
    setPhotoURL(url);
    setPhotoActive(true);
  };

  const closePhotoPersona = () => {
    setPhotoURL("");
    setPhotoActive(false);
  };

  const retryInquiryStatus = async () => {
    console.log(dataUser);
    await Api.updateUser({
      ...dataUser,
      inquiry_status: "retry",
    });

    window.location.reload();
  };

  useEffect(() => {
    if (defaultInquiry?.startsWith("inq_")) {
      setCurrentPersona(inquiries.find((inq) => inq.id === defaultInquiry));
      selectInquiry(inquiries.find((inq) => inq.id === selectedInquiry));
    } else {
      setCurrentPersona(inquiries[0]?.id);
      if (!detailedPersona) {
        setSelectedInquiry(inquiries[0]?.id);
        selectInquiry(inquiries[0]);
      }
    }
  }, [selectedInquiry, inquiries]);

  return (
    <div className="mx-6 my-6">
      {defaultInquiry?.startsWith("inq_") && (
        <div className="mb-8 bg-gray-200 py-8 px-6 rounded-md border">
          <label>Current Persona Inquiry ID</label>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-1">
            <div className="flex justify-between items-end sm:col-span-4">
              <input
                type="text"
                value={defaultInquiry}
                disabled
                name="inquiry_id"
                autoComplete="inquiry_id"
                className="shadow-sm w-full focus:ring-blue-kiwi focus:border-blue-kiwi block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <Tooltip tooltip="Open with persona">
                <button
                  type="button"
                  className="ml-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  onClick={() => openLinkPersona(defaultInquiry)}
                >
                  <ArrowTopRightOnSquareIcon
                    className="h-5 w-5 text-blue-kiwi"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
            </div>

            <div
              className={`border sm:col-span-2 text-center shadow-sm p-2 block w-full sm:text-sm border-gray-300 bg-white rounded-md uppercase ${
                currentPersona?.attributes?.status === "approved" &&
                "text-green-500 font-bold border-green-500 bg-green-100"
              }`}
            >
              {currentPersona?.attributes?.status}
            </div>

            {/* <button className="bg-dark-kiwi rounded-md text-white">Skip</button> */}
          </div>
        </div>
      )}

      <div className="border-t border-t-gray-300 border-t-2 pt-8 px-2">
        <label className="block text-xl font-medium mb-6">History</label>
        <label htmlFor="location" className="block text-sm font-medium">
          Inquiries
        </label>
        <select
          id="inquiry"
          name="inquiry"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-kiwi focus:outline-none focus:ring-blue-kiwi sm:text-sm"
          defaultValue={selectedInquiry ?? inquiries[0].id}
          onChange={(ev) => setSelectedInquiry(ev.target.value)}
        >
          {inquiries.map((inquiry) => (
            <option key={inquiry.id} value={inquiry.id}>
              {inquiry.id}
            </option>
          ))}
        </select>
      </div>

      {isPhotoActive && (
        <PersonaPhotoLoader url={photoURL} onClose={closePhotoPersona} />
      )}

      {!isLoading && detailedPersona?.verifications ? (
        <div className="px-4 pt-4 flex flex-col gap-4">
          {detailedPersona?.verifications?.map((verification) => {
            return (
              <>
                {["verification/government-id"].includes(verification.type) && (
                  <Disclosure key={verification.id}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`
                        ${
                          verification?.attributes?.status === "failed" &&
                          "hover:bg-red-200 focus-visible:ring-red-500 text-red-900 bg-red-100"
                        }
                        ${
                          verification?.attributes?.status === "passed" &&
                          "hover:bg-green-200 focus-visible:ring-green-500 text-green-900 bg-green-100"
                        }
                        flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
                        >
                          <span>{verification?.type}</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-dark-kiwi`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 grid grid-cols-2 gap-2">
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Status
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.status ?? "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Confidence level
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.[
                                "entity-confidence-score"
                              ] ?? "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              First name
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.["name-first"] ?? "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Middle name
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.["name-middle"] ??
                                "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Last name
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.["name-last"] ?? "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Birthdate
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.birthdate ?? "--"}
                            </div>
                          </div>
                          <hr />
                          <hr />
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Address subdivision
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.[
                                "address-subdivision"
                              ] ?? "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Address city
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.["address-city"] ??
                                "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Address street 1
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.["address-street-1"] ??
                                "--"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Address street 2
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.["address-street-2"] ??
                                "---"}
                            </div>
                          </div>
                          <div>
                            <h3 className="leading-6 text-sm font-medium text-gray-900">
                              Address Postal Code
                            </h3>
                            <div className="text-xs p-2 border border-gray-300 rounded-md">
                              {verification?.attributes?.[
                                "address-postal-code"
                              ] ?? "--"}
                            </div>
                          </div>

                          <div></div>

                          <hr />
                          <hr />

                          {verification?.attributes?.["front-photo-url"] && (
                            <button
                              type="button"
                              className="w-full inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              onClick={() =>
                                openPhotoPersona(
                                  verification?.attributes?.["front-photo-url"]
                                )
                              }
                            >
                              <ArrowTopRightOnSquareIcon
                                className="h-5 w-5 mr-3 text-blue-kiwi"
                                aria-hidden="true"
                              />{" "}
                              Front
                            </button>
                          )}

                          {verification?.attributes?.["back-photo-url"] && (
                            <button
                              type="button"
                              className="w-full inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              onClick={() =>
                                openPhotoPersona(
                                  verification?.attributes?.["back-photo-url"]
                                )
                              }
                            >
                              <ArrowTopRightOnSquareIcon
                                className="h-5 w-5 mr-3 text-blue-kiwi"
                                aria-hidden="true"
                              />{" "}
                              Back
                            </button>
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
                {["verification/selfie"].includes(verification.type) && (
                  <Disclosure key={verification.id}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`
                        ${
                          verification?.attributes?.status === "failed" &&
                          "hover:bg-red-200 focus-visible:ring-red-500 text-red-900 bg-red-100"
                        }
                        ${
                          verification?.attributes?.status === "passed" &&
                          "hover:bg-green-200 focus-visible:ring-green-500 text-green-900 bg-green-100"
                        }
                        bg-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
                        >
                          <span>{verification?.type}</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-dark-kiwi`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 grid grid-cols-3 gap-2">
                          {verification?.attributes?.["left-photo-url"] && (
                            <button
                              type="button"
                              className="w-full inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              onClick={() =>
                                openPhotoPersona(
                                  verification?.attributes?.["left-photo-url"]
                                )
                              }
                            >
                              <ArrowTopRightOnSquareIcon
                                className="h-5 w-5 mr-3 text-blue-kiwi"
                                aria-hidden="true"
                              />{" "}
                              Left
                            </button>
                          )}
                          {verification?.attributes?.["center-photo-url"] && (
                            <button
                              type="button"
                              className="w-full inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              onClick={() =>
                                openPhotoPersona(
                                  verification?.attributes?.["center-photo-url"]
                                )
                              }
                            >
                              <ArrowTopRightOnSquareIcon
                                className="h-5 w-5 mr-3 text-blue-kiwi"
                                aria-hidden="true"
                              />{" "}
                              Center
                            </button>
                          )}
                          {verification?.attributes?.["right-photo-url"] && (
                            <button
                              type="button"
                              className="w-full inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              onClick={() =>
                                openPhotoPersona(
                                  verification?.attributes?.["right-photo-url"]
                                )
                              }
                            >
                              <ArrowTopRightOnSquareIcon
                                className="h-5 w-5 mr-3 text-blue-kiwi"
                                aria-hidden="true"
                              />{" "}
                              Right
                            </button>
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <Loader status={isLoading} />
      )}

      {detailedPersona?.id === currentPersona?.id && (
        <div className="w-full h-16 mt-6 bg-white border-t sticky left-0 bottom-0 flex items-center">
          <div className="w-full max-w-6xl mx-auto flex justify-end sm:px-6 lg:px-8 gap-3">
            {[null, "", "approved", "retry"].includes(
              dataUser.inquiry_status
            ) === false && (
              <button
                type="button"
                onClick={() => retryInquiryStatus()}
                className="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
              >
                Retry
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonaDetails;
