import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducer';
import {
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

import Api from 'redux/api';
import Loader from 'components/UI/Loader';
import { PricingModelServices } from 'services/PricingModel/PricingModel.services';

const TITLE_CONTENT = {
  HUDSON_MODEL: 'Kiwi score',
  CREDIT_SCORE: 'Credit Score',
  INCOME: 'Income',
  DTI: 'DTI',
  DISPOSIBLE_INCOME: 'Disposible income',
  PRICING_MODEL: 'Pricing model',
  LOAN_AMOUNT: 'Loan amount',
  INTERES_RATE: 'Interest rate',
  TERMS: 'Terms',
};

const CURRENCY_TYPE = {
  DOLAR: 'dolar',
  PERCENTAGE: 'percentage',
  MONTHS: 'months',
  PERCENTAGE_NUMBER: 'percentage_number',
};

const PrincingModel = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  const [princingVerified, setPricingVerified] = useState(null);
  const [princingUnVerified, setPricingUnVerified] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [detailUnverifiedStatus, setDetailUnverifiedStatus] = useState(false);
  const [detailVerifiedStatus, setDetailVerifiedStatus] = useState(false);

  const getVerifiedPricing = async () => {
    try {
      setLoaderStatus(true);
      const request = await PricingModelServices.getVerifiedPrincing(user_id);
      setPricingVerified(request?.data?.data || null);
      setLoaderStatus(false);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error Getting Pricing Model',
        })
      );
      setLoaderStatus(false);
    }
  };

  const getUnverifiedPricing = async () => {
    try {
      setLoaderStatus(true);
      const request = await PricingModelServices.getUnVerifiedPrincing(user_id);
      setPricingUnVerified(request?.data?.data || null);
      setLoaderStatus(false);
    } catch (error) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error Getting Pricing Model',
        })
      );
      setLoaderStatus(false);
    }
  };

  const changeUnverifiedStatusDetial = () => {
    setDetailUnverifiedStatus(!detailUnverifiedStatus);
  };

  const changeVerifiedStatusDetial = () => {
    setDetailVerifiedStatus(!detailVerifiedStatus);
  };

  const renderTextContent = (title, value) => {
    if (!value?.length) {
      return (
        <div>
          <h3 className="font-light text-gray-500">{title}</h3>
          <p className="font-semibold text-gray-500">N/R</p>
        </div>
      );
    }
    return (
      <div>
        <h3 className="font-light text-gray-500">{title}</h3>
        <p className="font-semibold text-gray-500">{value}</p>
      </div>
    );
  };

  const renderNumberContent = (title, value, currency_type) => {
    if (!value) {
      return (
        <div>
          <h3 className="font-light text-gray-500">{title}</h3>
          <p className="font-semibold text-gray-500">N/R</p>
        </div>
      );
    }

    if (currency_type === CURRENCY_TYPE.DOLAR) {
      return (
        <div>
          <h3 className="font-light text-gray-500">{title}</h3>
          <p className="font-semibold text-gray-500">${value}</p>
        </div>
      );
    }

    if (currency_type === CURRENCY_TYPE.PERCENTAGE) {
      const render_value = value * 100;
      return (
        <div>
          <h3 className="font-light text-gray-500">{title}</h3>
          <p className="font-semibold text-gray-500">
            {render_value.toFixed()}%
          </p>
        </div>
      );
    }

    if (currency_type === CURRENCY_TYPE.PERCENTAGE_NUMBER) {
      return (
        <div>
          <h3 className="font-light text-gray-500">{title}</h3>
          <p className="font-semibold text-gray-500">{value}%</p>
        </div>
      );
    }

    if (currency_type === CURRENCY_TYPE.MONTHS) {
      return (
        <div>
          <h3 className="font-light text-gray-500">{title}</h3>
          <p className="font-semibold text-gray-500">{value} months</p>
        </div>
      );
    }

    return (
      <div>
        <h3 className="font-light text-gray-500">{title}</h3>
        <p className="font-semibold text-gray-500">{value}</p>
      </div>
    );
  };

  const renderIcon = value => {
    if (!value) {
      return <XCircleIcon className="h-7 w-7 text-red-500" />;
    }

    return <CheckCircleIcon className="h-7 w-7 text-green-500" />;
  };

  const formatPricingResponse = response => {
    return response?.replaceAll('_', ' ');
  };

  const canApprove = princingModelResponse => {
    if (
      princingModelResponse === 'no_uw_bucket' ||
      !princingModelResponse?.length
    ) {
      return false;
    }

    return true;
  };

  const approveRequest = async () => {
    setLoaderStatus(true);
    const payload = {
      interest_rate:
        princingVerified?.pricing_model?.data?.approved_interest_rate,
      credit_limit:
        princingVerified?.pricing_model?.data?.approved_credit_limit,
      term_months: princingVerified?.pricing_model?.data?.approved_term_months,
      fee: null,
      archived: false,
    };

    try {
      await Api.updateApplication({
        id: user.application.id,
        data: payload,
      });
      await Api.createLoanStatus({
        application_id: user.application.id,
        value: 'approved',
        reason: 'pricing_model',
        member_id: auth.id,
      });
      await Api.updateUser({ ...user, black_list: false });
      window.location.reload();
    } catch (error) {
      return dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error in the creation of the approval',
        })
      );
    } finally {
      setLoaderStatus(false);
    }
  };

  useEffect(() => {
    getVerifiedPricing();
    getUnverifiedPricing();
  }, []);

  return (
    <div className="p-8">
      <Loader status={loaderStatus} />
      {princingUnVerified || princingVerified || true ? (
        <div className="grid grid-cols-2 gap-5 justify-center">
          <div className="w-full h-max rounded-2xl shadow-xl p-6">
            <h2 className="text-xl font-semibold text-gray-500">Unverified</h2>
            <div className="pt-4">
              <div className="flex gap-4 items-center">
                {renderIcon(princingUnVerified?.hudson?.credit_risk)}
                {renderTextContent(
                  TITLE_CONTENT.HUDSON_MODEL,
                  princingUnVerified?.hudson?.credit_risk
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingUnVerified?.credit_score)}
                {renderNumberContent(
                  TITLE_CONTENT.CREDIT_SCORE,
                  princingUnVerified?.credit_score
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingUnVerified?.income)}
                {renderNumberContent(
                  TITLE_CONTENT.INCOME,
                  princingUnVerified?.income,
                  CURRENCY_TYPE.DOLAR
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingUnVerified?.dti?.data?.dti)}
                {renderNumberContent(
                  TITLE_CONTENT.DTI,
                  princingUnVerified?.dti?.data?.dti,
                  CURRENCY_TYPE.PERCENTAGE
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingUnVerified?.dti?.data?.disposable_income)}
                {renderNumberContent(
                  TITLE_CONTENT.DISPOSIBLE_INCOME,
                  princingUnVerified?.dti?.data?.disposable_income,
                  CURRENCY_TYPE.DOLAR
                )}
              </div>
              <div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    {renderIcon(
                      princingUnVerified?.pricing_model?.data.uw_bucket
                    )}
                    {renderTextContent(
                      TITLE_CONTENT.PRICING_MODEL,
                      formatPricingResponse(
                        princingUnVerified?.pricing_model?.data.uw_bucket
                      )
                    )}
                  </div>
                  <button
                    className="text-green-500 underline cursor-pointer"
                    onClick={() => changeUnverifiedStatusDetial()}
                  >
                    Details
                  </button>
                </div>
                {detailUnverifiedStatus ? (
                  <div className="ml-12 mt-4 p-4 bg-green-50 rounded-lg">
                    <div>
                      {renderNumberContent(
                        TITLE_CONTENT.LOAN_AMOUNT,
                        princingUnVerified?.pricing_model?.data
                          ?.approved_credit_limit,
                        CURRENCY_TYPE.DOLAR
                      )}
                    </div>
                    <div>
                      {renderNumberContent(
                        TITLE_CONTENT.INTERES_RATE,
                        princingUnVerified?.pricing_model?.data
                          ?.approved_interest_rate,
                        CURRENCY_TYPE.PERCENTAGE_NUMBER
                      )}
                    </div>
                    <div>
                      {renderNumberContent(
                        TITLE_CONTENT.TERMS,
                        princingUnVerified?.pricing_model?.data
                          ?.approved_term_months,
                        CURRENCY_TYPE.MONTHS
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-max rounded-2xl shadow-xl p-6">
            <h2 className="text-xl font-semibold text-gray-500">Verified</h2>
            <div className="pt-4">
              <div className="flex gap-4 items-center">
                {renderIcon(princingVerified?.hudson?.credit_risk)}
                {renderTextContent(
                  TITLE_CONTENT.HUDSON_MODEL,
                  princingVerified?.hudson?.credit_risk
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingVerified?.credit_score)}
                {renderNumberContent(
                  TITLE_CONTENT.CREDIT_SCORE,
                  princingVerified?.credit_score
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingVerified?.income)}
                {renderNumberContent(
                  TITLE_CONTENT.INCOME,
                  princingVerified?.income,
                  CURRENCY_TYPE.DOLAR
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(princingVerified?.dti?.data?.dti)}
                {renderNumberContent(
                  TITLE_CONTENT.DTI,
                  princingVerified?.dti?.data?.dti,
                  CURRENCY_TYPE.PERCENTAGE
                )}
              </div>
              <div className="flex gap-4 items-center">
                {renderIcon(
                  princingVerified?.income
                    ? princingVerified?.dti?.data?.disposable_income
                    : null
                )}
                {renderNumberContent(
                  TITLE_CONTENT.DISPOSIBLE_INCOME,
                  princingVerified?.income
                    ? princingVerified?.dti?.data?.disposable_income
                    : null,
                  CURRENCY_TYPE.DOLAR
                )}
              </div>
              <div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    {renderIcon(
                      princingVerified?.pricing_model?.data.uw_bucket
                    )}
                    {renderTextContent(
                      TITLE_CONTENT.PRICING_MODEL,
                      formatPricingResponse(
                        princingVerified?.pricing_model?.data.uw_bucket
                      )
                    )}
                  </div>
                  <button
                    className="text-green-500 underline cursor-pointer"
                    onClick={() => changeVerifiedStatusDetial()}
                  >
                    Details
                  </button>
                </div>
                {detailVerifiedStatus ? (
                  <div className="ml-12 mt-4 p-4 bg-green-50 rounded-lg">
                    <div>
                      {renderNumberContent(
                        TITLE_CONTENT.LOAN_AMOUNT,
                        princingVerified?.pricing_model?.data
                          ?.approved_credit_limit,
                        CURRENCY_TYPE.DOLAR
                      )}
                    </div>
                    <div>
                      {renderNumberContent(
                        TITLE_CONTENT.INTERES_RATE,
                        princingVerified?.pricing_model?.data
                          ?.approved_interest_rate,
                        CURRENCY_TYPE.PERCENTAGE_NUMBER
                      )}
                    </div>
                    <div>
                      {renderNumberContent(
                        TITLE_CONTENT.TERMS,
                        princingVerified?.pricing_model?.data
                          ?.approved_term_months,
                        CURRENCY_TYPE.MONTHS
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {detailVerifiedStatus &&
              canApprove(princingVerified?.pricing_model?.data.uw_bucket) && (
                <button
                  className="w-full mt-4 bg-green-500 rounded-lg p-2 text-white"
                  onClick={() => {
                    approveRequest();
                  }}
                >
                  Approved
                </button>
              )}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-5 bg-yellow-100 text-yellow-700 rounded-md mt-5 p-5 cursor-pointer">
          <ExclamationTriangleIcon className="h-5 w-5" />
          <p>Pricing Not Found</p>
        </div>
      )}
    </div>
  );
};

export default PrincingModel;
