import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;
const HEADERS = () => {
  return { authorization: `Bearer ${Cookies.get('kiwi_auth_token')}` };
};

const api = {
  async saveTeam({
    phone_number,
    email,
    password,
    profile,
    active,
    user_name,
  }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/team`,
      headers: HEADERS(),
      data: {
        phone_number,
        email,
        password,
        profile,
        active,
        user_name,
      },
    });
  },
  async updateTeam({ phone_number, email, password, profile }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/team`,
      headers: HEADERS(),
      data: {
        phone_number,
        email,
        password,
        profile,
      },
    });
  },
  async deleteTeam({ id }) {
    return axios({
      method: 'DELETE',
      url: `${API_URL}/team/${id}`,
      headers: HEADERS(),
    });
  },
  async testing() {
    return axios({
      method: 'GET',
      url: `${API_URL}/testing`,
      headers: HEADERS(),
    });
  },
  async fetchTeam() {
    return axios({
      method: 'GET',
      url: `${API_URL}/team`,
      headers: HEADERS(),
    });
  },
  async fetchAuthUser() {
    return axios({
      method: 'GET',
      url: `${API_URL}/team/user`,
      headers: HEADERS(),
    });
  },
  async fetchApplications(payload) {
    return axios({
      method: 'GET',
      url: `${API_URL}/applications`,
      params: payload,
      headers: HEADERS(),
    });
  },
  async fetchLoan({ id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/applications/${id}`,
      headers: HEADERS(),
    });
  },
  async fetchApplicationByUser({ user_id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/applications/user/${user_id}`,
      headers: HEADERS(),
    });
  },
  async updateApplication({ id, data }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/applications/${id}`,
      headers: HEADERS(),
      data,
    });
  },
  async updateScoreKiwi({ application_id, value }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/applications/${application_id}/score-kiwi`,
      headers: HEADERS(),
      data: {
        value,
      },
    });
  },
  async updateVerification({ id, ...data }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/applications/verification/${id}`,
      headers: HEADERS(),
      data,
    });
  },
  async createLoanStatus({
    application_id,
    value,
    reason,
    sub_reason,
    member_id,
    payload,
  }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/application/${application_id}/status`,
      headers: HEADERS(),
      data: {
        application_id,
        value,
        reason,
        sub_reason,
        member_id,
        payload,
      },
    });
  },
  async getReports({ user_id }) {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/credit-reports`,
      headers: HEADERS(),
      params: {
        user_id,
      },
    });
  },
  async createReport({ user_id }) {
    return await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/cms/credit-reports`,
      headers: HEADERS(),
      data: {
        user_id,
      },
    });
  },
  async updateLog({ id, ...data }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/application/status/${id}`,
      headers: HEADERS(),
      data,
    });
  },
  async fetchUsers({ take, skip, order, search_by, search_value, stateUser }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/users`,
      headers: HEADERS(),
      params: {
        take,
        skip,
        order,
        search_by,
        search_value,
        stateUser,
      },
    });
  },
  async fetchUserLeads({ take, skip, order, search_by, search_value }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/user-leads`,
      headers: HEADERS(),
      params: {
        take,
        skip,
        order,
        search_by,
        search_value,
      },
    });
  },

  async fetchUserOldApp() {
    return axios({
      method: 'GET',
      url: `${API_URL}/remnants`,
      headers: HEADERS(),
    });
  },

  async updateUserOldApp({ id, assigned, solved }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/remnants/${id}`,
      data: {
        assigned_to: assigned,
        solved,
      },
      headers: HEADERS(),
    });
  },

  getUsersWithoutApplication() {
    return axios({
      method: 'GET',
      url: `${API_URL}/users/non/application`,
      headers: HEADERS(),
    });
  },
  async downloadUsers() {
    return axios({
      url: `${API_URL}/users/download`, //your url
      method: 'POST',
      responseType: 'blob', // important
      headers: HEADERS(),
    });
  },
  async fetchUser({ id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/users/${id}`,
      headers: HEADERS(),
    });
  },
  async fetchUserByAny({ parameter, data }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/users-check/`,
      headers: HEADERS(),
      data: {
        parameter,
        data,
      },
    });
  },

  async updateUserLoanpro({
    birthDate,
    firstName,
    lastName,
    ssn,
    email,
    primaryAddress,
    firebase_id,
  }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/user/loanpro/${firebase_id}`,
      headers: HEADERS(),
      data: {
        birthDate,
        firstName,
        lastName,
        ssn,
        email,
        primaryAddress,
      },
    });
  },

  async updateUser({
    id,
    first_name,
    second_name,
    last_name,
    first_surname,
    second_surname,
    email,
    birthdate,
    city,
    state,
    zipcode,
    address,
    inquiry_id,
    inquiry_status,
    phone_number,
    black_list,
  }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/users/${id}`,
      headers: HEADERS(),
      data: {
        first_name,
        second_name,
        last_name,
        first_surname,
        second_surname,
        email,
        birthdate,
        city,
        state,
        zipcode,
        address,
        inquiry_id,
        inquiry_status,
        phone_number,
        black_list,
      },
    });
  },
  async fetchBankData({ user_id, firebase_id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/bank-data`,
      headers: HEADERS(),
      params: {
        user_id,
        firebase_id,
      },
    });
  },
  async fetchCreditReport({ report_id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/credit-reports/${report_id}`,
      headers: HEADERS(),
    });
  },
  async fetchPaymentProfiles({ user_id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/payment-profiles/user/${user_id}`,
      headers: HEADERS(),
    });
  },
  async fetchUserLoanpro({ user_id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/users/${user_id}/loanpro`,
      headers: HEADERS(),
    });
  },
  async fetchLoans({ where, page = 1, pageSize = 50 }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/loans`,
      headers: HEADERS(),
      params: {
        where,
        page,
        pageSize,
      },
    });
  },
  async fetchLoansCount() {
    return axios({
      method: 'GET',
      url: `${API_URL}/loans/count`,
      headers: HEADERS(),
    });
  },
  async fetchLoansByUser({ user_id }) {
    return axios({
      method: 'GET',
      url: `${API_URL}/loans/user/${user_id}`,
      headers: HEADERS(),
    });
  },

  async fetchATHPayments(data) {
    return axios({
      method: 'GET',
      url: `${API_URL}/ath_payments`,
      headers: HEADERS(),
      params: { ...data },
    });
  },

  async fetchATHPaymentsAdd({ payment_id, member_id }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/ath_payments/add-payment-loan`,
      headers: HEADERS(),
      data: { payment_id, member_id },
    });
  },

  async activeLoan({ loan_id }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/loans/${loan_id}/active`,
      headers: HEADERS(),
    });
  },
  async assignPortfolio({ loan_id, portfolio, subportfolio }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/loans/${loan_id}/assign-portfolio`,
      headers: HEADERS(),
      data: {
        portfolio,
        subportfolio,
      },
    });
  },
  async createAutoPay({ loan_id }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/loans/${loan_id}/auto-pay`,
      headers: HEADERS(),
    });
  },
  async fund({ loan_id }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/loans/${loan_id}/fund`,
      headers: HEADERS(),
    });
  },
  async fundMeSalve({ loan_id }) {
    return axios({
      method: 'POST',
      url: `${API_URL}/loans/${loan_id}/fund-mesalve`,
      headers: HEADERS(),
    });
  },
  async fetchATHPaymentsUpdate({ payment_id, status, member_id, comment }) {
    return axios({
      method: 'PUT',
      url: `${API_URL}/ath_payments/status`,
      headers: HEADERS(),
      data: { payment_id, status, member_id, comment },
    });
  },
  async downloadATHPayments(data) {
    return axios({
      method: 'POST',
      url: `${API_URL}/ath_payments/download`,
      headers: HEADERS(),
      params: { ...data },
    });
  },
  refreshDocuments({ user_id }) {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/cms/credit-reports/refresh-documents`,
      headers: HEADERS(),
      data: { user_id },
    });
  },
  getTransunionReports({ user_id }) {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/files/transunion-report`,
      headers: HEADERS(),
      params: { user_id },
    });
  },
  getUrlSigned({ id }) {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/files/signed-url/${id}`,
    });
  },
  getEnhancedVariables({ user_id }) {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/plaid/enhanced-variables`,
      headers: HEADERS(),
      data: { user_id },
    });
  },
  getDialpadHistory(user_id) {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/dialpad/${user_id}`,
      headers: HEADERS(),
    });
  },
  deleteLoan(id, user_id, member_id) {
    return axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/loans/${id}`,
      headers: HEADERS(),
      data: {
        user_id,
        member_id,
      },
    });
  },
  getHudsonUserData({ user_id }) {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/hudson/${user_id}`,
      headers: HEADERS(),
    });
  },
  async fetchVerifications(payload) {
    return axios({
      method: 'GET',
      url: `${API_URL}/verifications`,
      params: payload,
      headers: HEADERS(),
    });
  },
};

export default api;
