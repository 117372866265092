
export default (code) => {
    return creditvision_factor_codes.find(factor => factor.code == code);
}

const creditvision_factor_codes = [
    {
        "code": "000",
        "description": "No adverse factors"
    },
    {
        "code": "001",
        "description": "Available credit on bankcard accounts is too low"
    },
    {
        "code": "002",
        "description": "Average balance of retail accounts is too high"
    },
    {
        "code": "003",
        "description": "Average balance of specialty retail accounts is too high"
    },
    {
        "code": "004",
        "description": "Number of refinanced or transferred accounts is too high"
    },
    {
        "code": "005",
        "description": "Not enough bank installment debt experience"
    },
    {
        "code": "006",
        "description": "Bankcard account balances are too high in proportion to credit limits"
    },
    {
        "code": "007",
        "description": "Collection amounts ever owed are too high"
    },
    {
        "code": "008",
        "description": "Collection, public record, or delinquency amounts or amounts past due are too high"
    },
    {
        "code": "009",
        "description": "Derogatory public record amounts are too high"
    },
    {
        "code": "010",
        "description": "Too many delinquencies"
    },
    {
        "code": "011",
        "description": "Installment account balances too high in proportion to credit limits"
    },
    {
        "code": "012",
        "description": "Length of time most recent installment account has been established is too short"
    },
    {
        "code": "013",
        "description": "Length of time finance accounts have been established is too short"
    },
    {
        "code": "014",
        "description": "Length of time most recent specialty retail account has been established is too short"
    },
    {
        "code": "015",
        "description": "Number of satisfactory accounts is too low in proportion to total number of accounts"
    },
    {
        "code": "016",
        "description": "Too few satisfactory accounts"
    },
    {
        "code": "017",
        "description": "Length of time oldest bank revolving account has been established is too short"
    },
    {
        "code": "018",
        "description": "Length of time travel and entertainment accounts have been established is too short"
    },
    {
        "code": "019",
        "description": "Length of time premium bankcard account has been established is too short"
    },
    {
        "code": "020",
        "description": "Length of time revolving accounts have been established is too short"
    },
    {
        "code": "021",
        "description": "Months since most recent bankcard delinquency is too short"
    },
    {
        "code": "022",
        "description": "Too many inquiries"
    },
    {
        "code": "023",
        "description": "Months since most recent delinquency is too short"
    },
    {
        "code": "024",
        "description": "Too many serious delinquencies"
    },
    {
        "code": "025",
        "description": "Months since most recent serious delinquency is too short"
    },
    {
        "code": "026",
        "description": "Number of delinquent accounts is too high in proportion to total number of accounts"
    },
    {
        "code": "027",
        "description": "Number of installment accounts is too high in proportion to total number of accounts"
    },
    {
        "code": "028",
        "description": "Personal finance account balances are too high in proportion to credit limits"
    },
    {
        "code": "029",
        "description": "Retail account balances are too high in proportion to credit limits"
    },
    {
        "code": "030",
        "description": "Not enough retail debt experience"
    },
    {
        "code": "031",
        "description": "Revolving account balances are too high in proportion to credit limits"
    },
    {
        "code": "032",
        "description": "Not enough revolving debt experience"
    },
    {
        "code": "033",
        "description": "Revolving department store and clothing account balances are too high in proportion to credit limits"
    },
    {
        "code": "034",
        "description": "Too few accounts opened since last serious delinquency"
    },
    {
        "code": "035",
        "description": "Length of time accounts have been established is too short"
    },
    {
        "code": "036",
        "description": "Length of time most recent account has been established is too short"
    },
    {
        "code": "037",
        "description": "Too few bankcard accounts"
    },
    {
        "code": "038",
        "description": "Too few mortgage accounts"
    },
    {
        "code": "039",
        "description": "Not enough debt experience on open revolving bank accounts"
    },
    {
        "code": "040",
        "description": "Too few premium bankcard accounts"
    },
    {
        "code": "041",
        "description": "Too few recently opened bank installment accounts"
    },
    {
        "code": "042",
        "description": "Too few retail accounts"
    },
    {
        "code": "043",
        "description": "Too few open revolving accounts"
    },
    {
        "code": "044",
        "description": "Too few satisfactory department store accounts"
    },
    {
        "code": "045",
        "description": "Too few satisfactory finance accounts"
    },
    {
        "code": "046",
        "description": "Too few satisfactory mortgage accounts"
    },
    {
        "code": "047",
        "description": "Too few specialty retail accounts"
    },
    {
        "code": "048",
        "description": "Too few active mortgage accounts"
    },
    {
        "code": "049",
        "description": "Too many accounts"
    },
    {
        "code": "050",
        "description": "Too many accounts with a balance"
    },
    {
        "code": "051",
        "description": "Too many bank installment accounts"
    },
    {
        "code": "052",
        "description": "Too many bankcard accounts"
    },
    {
        "code": "053",
        "description": "Too many derogatory accounts or public records"
    },
    {
        "code": "054",
        "description": "Too many finance installment accounts"
    },
    {
        "code": "055",
        "description": "Too many installment accounts"
    },
    {
        "code": "056",
        "description": "Too many accounts close to limit"
    },
    {
        "code": "057",
        "description": "Too many travel and entertainment accounts"
    },
    {
        "code": "058",
        "description": "Too many personal finance accounts"
    },
    {
        "code": "059",
        "description": "Too many recent inquiries"
    },
    {
        "code": "060",
        "description": "Too many recent mortgage delinquencies"
    },
    {
        "code": "061",
        "description": "Too many recently opened accounts"
    },
    {
        "code": "062",
        "description": "Too many recently opened bankcard accounts"
    },
    {
        "code": "063",
        "description": "Too many recently opened personal finance accounts"
    },
    {
        "code": "064",
        "description": "Too many recently opened retail accounts"
    },
    {
        "code": "065",
        "description": "Too many retail accounts with balances"
    },
    {
        "code": "066",
        "description": "Too many serious derogatory items"
    },
    {
        "code": "067",
        "description": "Too many unsatisfactory revolving accounts in proportion to total number of accounts"
    },
    {
        "code": "068",
        "description": "Total account balances excluding mortgages is too high"
    },
    {
        "code": "069",
        "description": "Not enough debt experience"
    },
    {
        "code": "070",
        "description": "Length of time since most recent bankcard account has been established is too short"
    },
    {
        "code": "071",
        "description": "Too many bankcard accounts with balances"
    },
    {
        "code": "072",
        "description": "Average balance of bankcard accounts is too high"
    },
    {
        "code": "073",
        "description": "Not enough mortgage debt experience"
    },
    {
        "code": "074",
        "description": "Too few satisfactory revolving accounts"
    },
    {
        "code": "075",
        "description": "Too many recently opened installment accounts"
    },
    {
        "code": "076",
        "description": "Total amount past due is too high"
    },
    {
        "code": "077",
        "description": "Too many active bankcard accounts"
    },
    {
        "code": "078",
        "description": "Too many recent collection inquiries"
    },
    {
        "code": "079",
        "description": "Too few bank revolving accounts"
    },
    {
        "code": "080",
        "description": "Too many accounts with a balance currently past due"
    },
    {
        "code": "081",
        "description": "Length of time since most recent derogatory public record is too short"
    },
    {
        "code": "082",
        "description": "Total balance of retail accounts is too high"
    },
    {
        "code": "083",
        "description": "Total balance of revolving accounts is too high"
    },
    {
        "code": "084",
        "description": "Not enough installment debt experience"
    },
    {
        "code": "085",
        "description": "Total balance of personal finance accounts is too high"
    },
    {
        "code": "086",
        "description": "Too many mortgage delinquencies"
    },
    {
        "code": "087",
        "description": "Too many active accounts with a balance"
    },
    {
        "code": "088",
        "description": "Length of time since most recent mortgage account delinquency is too short"
    },
    {
        "code": "089",
        "description": "Length of time retail accounts have been established is too short"
    },
    {
        "code": "090",
        "description": "Length of time since most recent bank inquiry is too short"
    },
    {
        "code": "091",
        "description": "Too few satisfactory specialty retail accounts"
    },
    {
        "code": "092",
        "description": "Length of time since most recent retail account delinquency is too short"
    },
    {
        "code": "093",
        "description": "Length of time since oldest mortgage account has been established is too short"
    },
    {
        "code": "094",
        "description": "Too few bank installment accounts"
    },
    {
        "code": "095",
        "description": "Total amount past due or collection amount is too high"
    },
    {
        "code": "096",
        "description": "Average balance of revolving accounts is too high"
    },
    {
        "code": "097",
        "description": "Bank revolving account balances are too high in proportion to credit limits"
    },
    {
        "code": "098",
        "description": "Finance revolving account balances are too high in proportion to credit limits"
    },
    {
        "code": "099",
        "description": "Too many revolving accounts with balances too high in proportion to credit limits"
    },
    {
        "code": "100",
        "description": "Too many inquiries in proportion to total number of accounts"
    },
    {
        "code": "101",
        "description": "Total balance of finance installment accounts is too high"
    },
    {
        "code": "102",
        "description": "Length of time since most recent inquiry is too short"
    },
    {
        "code": "103",
        "description": "Not enough available credit"
    },
    {
        "code": "104",
        "description": "Too many finance revolving accounts"
    },
    {
        "code": "105",
        "description": "Too few revolving accounts"
    },
    {
        "code": "106",
        "description": "Too many bank revolving accounts with balances too high in proportion to credit limits"
    },
    {
        "code": "107",
        "description": "Too many personal finance accounts with balances too high in proportion to credit limits"
    },
    {
        "code": "108",
        "description": "Length of time since most recent installment delinquency is too short"
    },
    {
        "code": "109",
        "description": "Too many finance installment accounts with balances too high in proportion to credit limits"
    },
    {
        "code": "110",
        "description": "Delinquency status of mortgage accounts is too high"
    },
    {
        "code": "111",
        "description": "Not enough bankcard debt experience"
    },
    {
        "code": "112",
        "description": "Total account balances excluding mortgages are too high in proportion to credit limits"
    },
    {
        "code": "113",
        "description": "Too many personal finance inquiries"
    },
    {
        "code": "114",
        "description": "Total amount past due on revolving accounts is too high"
    },
    {
        "code": "115",
        "description": "Too many secured accounts with balances too high in proportion to credit limits"
    },
    {
        "code": "116",
        "description": "Maximum balance of serious delinquencies is too high"
    },
    {
        "code": "117",
        "description": "Length of time since most seriously delinquent account has been established is too short"
    },
    {
        "code": "118",
        "description": "Finance account balances are too high in proportion to credit limits"
    },
    {
        "code": "119",
        "description": "Total balance of bankcard accounts is too high"
    },
    {
        "code": "120",
        "description": "Total balance of delinquencies is too high"
    },
    {
        "code": "121",
        "description": "Too few finance accounts"
    },
    {
        "code": "122",
        "description": "Too few satisfactory bankcard accounts in proportion to total number of bankcard accounts"
    },
    {
        "code": "123",
        "description": "Too few satisfactory retail accounts in proportion to total number of retail accounts"
    },
    {
        "code": "124",
        "description": "Too few installment accounts"
    },
    {
        "code": "125",
        "description": "Length of time since installment accounts have been established is too short"
    },
    {
        "code": "126",
        "description": "Length of time since most recent finance delinquency is too short"
    },
    {
        "code": "127",
        "description": "Length of time since most recent finance installment account has been established is too short"
    },
    {
        "code": "128",
        "description": "Too many auto accounts"
    },
    {
        "code": "129",
        "description": "Not enough auto debt experience"
    },
    {
        "code": "130",
        "description": "Maximum amount on auto accounts is too low"
    },
    {
        "code": "131",
        "description": "Length of time since most recent auto delinquency is too short"
    },
    {
        "code": "132",
        "description": "Too few open accounts"
    },
    {
        "code": "133",
        "description": "Maximum balance of department store accounts is too high"
    },
    {
        "code": "134",
        "description": "Too many retail accounts"
    },
    {
        "code": "135",
        "description": "Too many finance or installment accounts"
    },
    {
        "code": "136",
        "description": "Too many revolving accounts"
    },
    {
        "code": "137",
        "description": "Total account balances are too high in proportion to credit limits"
    },
    {
        "code": "138",
        "description": "Not enough available credit on active or open bankcard accounts"
    },
    {
        "code": "139",
        "description": "Insufficient prepayment activity on installment accounts over the last year"
    },
    {
        "code": "140",
        "description": "Not enough available installment credit"
    },
    {
        "code": "141",
        "description": "Too few accounts recently updated"
    },
    {
        "code": "142",
        "description": "Not enough balance decreases on active non-mortgage accounts"
    },
    {
        "code": "143",
        "description": "Not enough balance decreases on mortgage accounts"
    },
    {
        "code": "144",
        "description": "Change of indebtedness or too many balance increases on non-mortgage accounts"
    },
    {
        "code": "145",
        "description": "Total balances are too high in proportion to credit limits on mature mortgage accounts"
    },
    {
        "code": "146",
        "description": "Recency of a balance overlimit on a bankcard account"
    },
    {
        "code": "147",
        "description": "Ratio of payment to minimum obligation on installment accounts"
    },
    {
        "code": "148",
        "description": "Ratio of payment to minimum obligation on revolving accounts"
    },
    {
        "code": "149",
        "description": "Change of indebtedness on revolving accounts"
    },
    {
        "code": "150",
        "description": "Insufficient credit activity on revolving accounts"
    },
    {
        "code": "151",
        "description": "Ratio of payment to minimum obligation on bankcard accounts"
    },
    {
        "code": "152",
        "description": "Insufficient credit activity on bankcard accounts"
    },
    {
        "code": "153",
        "description": "Additionally, information sourced from you financial institution may have negatively impacted your score/results"
    },
    {
        "code": "154",
        "description": "Insufficient payment activity over the last year"
    },
    {
        "code": "155",
        "description": "Recency of max aggregate bankcard balance over the last year"
    },
    {
        "code": "156",
        "description": "Too many recent revolving payment activities"
    },
    {
        "code": "157",
        "description": "Too few recent transactor payment activities"
    },
    {
        "code": "158",
        "description": "Too few open retail accounts"
    },
    {
        "code": "159",
        "description": "Auto account balances are too high in proportion to credit limits"
    },
    {
        "code": "160",
        "description": "Too few open auto accounts"
    },
    {
        "code": "161",
        "description": "Too many recently opened revolving acccounts"
    },
    {
        "code": "162",
        "description": "Too few open premium bankcard accounts"
    },
    {
        "code": "163",
        "description": "Too many recent instances where bankcard balances exceed limits"
    },
    {
        "code": "164",
        "description": "Too few non-mortgage accounts"
    },
    {
        "code": "165",
        "description": "Too many months with a bankcard credit limit change over the last year"
    },
    {
        "code": "166",
        "description": "Too many bankcard balance shifts in recent months"
    },
    {
        "code": "167",
        "description": "Too high max aggregate bankcard utilization over the last quarter"
    },
    {
        "code": "168",
        "description": "Average balance of auto accounts is too high"
    },
    {
        "code": "169",
        "description": "Presence of non-conventional terms on most recent auto account"
    },
    {
        "code": "170",
        "description": "Recent revolving behavior on bankcards"
    },
    {
        "code": "171",
        "description": "Too few open non-mortgage accounts"
    },
    {
        "code": "172",
        "description": "Too many months with a non-mortgage account balance increase over the last year"
    },
    {
        "code": "173",
        "description": "Not enough bank revolving debt experience"
    },
    {
        "code": "174",
        "description": "Too few open bankcard accounts"
    },
    {
        "code": "175",
        "description": "Length of time since oldest bankcard account has been established is too short"
    },
    {
        "code": "176",
        "description": "Too few open installment accounts"
    },
    {
        "code": "177",
        "description": "Too many finance accounts"
    },
    {
        "code": "178",
        "description": "Too few satisfactory retail accounts"
    },
    {
        "code": "179",
        "description": "Too many recently opened finance installment accounts"
    },
    {
        "code": "180",
        "description": "Too many consecutive occurrences of bankcard past due amounts"
    },
    {
        "code": "181",
        "description": "High recent balance range relative to previous balance range"
    },
    {
        "code": "182",
        "description": "Monthly obligations are too high"
    },
    {
        "code": "183",
        "description": "Total balance of bank revolving accounts is too high"
    },
    {
        "code": "184",
        "description": "Length of time most recent bank revolving account has been established is too short"
    },
    {
        "code": "185",
        "description": "Change of total bankcard balance to payment ratio over the last year compared to the prior year"
    },
    {
        "code": "186",
        "description": "Bank installment account balances are too high in proportion to credit limits"
    },
    {
        "code": "187",
        "description": "Too few open bank installment accounts"
    },
    {
        "code": "188",
        "description": "Too few active bankcard accounts"
    },
    {
        "code": "189",
        "description": "Ratio of payment to minimum obligation on accounts"
    },
    {
        "code": "190",
        "description": "Insufficient account information or address information to assess"
    },
    {
        "code": "191",
        "description": "Too high max aggregate bankcard utilization"
    },
    {
        "code": "192",
        "description": "Not enough available credit on revolving accounts"
    },
    {
        "code": "193",
        "description": "Too many balance increases on revolving accounts"
    },
    {
        "code": "194",
        "description": "Balance of revolving accounts is too high"
    },
    {
        "code": "195",
        "description": "Balance of non-mortgage accounts is too high"
    },
    {
        "code": "196",
        "description": "Too many months with a balance increase on revolving accounts"
    },
    {
        "code": "197",
        "description": "Insufficient payment activity"
    },
    {
        "code": "198",
        "description": "Average balance of accounts is too high"
    },
    {
        "code": "199",
        "description": "Length of time since most recent unsecured installment account delinquency is too short"
    },
    {
        "code": "200",
        "description": "Unsecured installment account balances are too high in proportion to credit limits"
    },
    {
        "code": "201",
        "description": "Length of time since oldest auto account has been established is too short"
    },
    {
        "code": "202",
        "description": "Average amount past due is too high"
    },
    {
        "code": "203",
        "description": "Amount paid in excess of minimum amount due too low"
    },
    {
        "code": "204",
        "description": "Not enough available credit on retail accounts"
    },
    {
        "code": "205",
        "description": "Ratio of payment to minimum obligation on retail accounts"
    },
    {
        "code": "206",
        "description": "Ratio of payment to minimum obligation on auto accounts"
    },
    {
        "code": "207",
        "description": "Insufficient credit activity on retail accounts"
    },
    {
        "code": "208",
        "description": "Too few auto accounts"
    },
    {
        "code": "209",
        "description": "Insufficient prepayment activity on real estate accounts"
    },
    {
        "code": "210",
        "description": "Average amount past due is too high"
    },
    {
        "code": "211",
        "description": "Insufficient decrease in past due amount"
    },
    {
        "code": "212",
        "description": "Too few deduped inquiries"
    },
    {
        "code": "213",
        "description": "Too many credit limit decreases on non mortgage accounts"
    },
    {
        "code": "214",
        "description": "Not enough balance increases on active non-mortgage accounts"
    },
    {
        "code": "215",
        "description": "Ratio of payment to minimum obligation on personal loan accounts is too high"
    },
    {
        "code": "216",
        "description": "Length of time most recent revolving account has been established is too short"
    },
    {
        "code": "217",
        "description": "Length of time on file is too short"
    },
    {
        "code": "218",
        "description": "Months since most recent retail delinquency is too short"
    },
    {
        "code": "219",
        "description": "Credit line on open auto trades too low"
    },
    {
        "code": "220",
        "description": "Too many recent non-mortgage account balance increases"
    },
    {
        "code": "221",
        "description": "Length of time most recent auto account has been established is too short"
    },
    {
        "code": "222",
        "description": "Length of time since oldest unsecured installment account has been established is too short"
    },
    {
        "code": "223",
        "description": "Insufficient payment activity over the last two years"
    },
    {
        "code": "224",
        "description": "Length of time since most recent collection is too short"
    },
    {
        "code": "225",
        "description": "Ratio of open joint accounts to open accounts too low"
    },
    {
        "code": "226",
        "description": "Too few unsecured installment accounts"
    },
    {
        "code": "227",
        "description": "Too many authorized user accounts"
    },
    {
        "code": "228",
        "description": "Too few open unsecured installment accounts"
    },
    {
        "code": "229",
        "description": "Maximum monthly revolving spend is too high"
    },
    {
        "code": "230",
        "description": "Revolving spend vs prior year is too high"
    },
    {
        "code": "231",
        "description": "Too few individual accounts"
    },
    {
        "code": "232",
        "description": "Too few joint accounts"
    },
    {
        "code": "233",
        "description": "Too few real estate accounts"
    },
    {
        "code": "234",
        "description": "Too many open bank revolving accounts"
    },
    {
        "code": "235",
        "description": "Too many recently opened unsecured installment acounts"
    },
    {
        "code": "236",
        "description": "Maximum balance of unsecured installment accounts is too high"
    },
    {
        "code": "237",
        "description": "Too many real estate delinquencies"
    },
    {
        "code": "238",
        "description": "Length of time since most recent finance inquiry is too short"
    },
    {
        "code": "239",
        "description": "Too few real estate accounts"
    },
    {
        "code": "240",
        "description": "Total dollar amount of serious derogatory items too high "
    },
    {
        "code": "241",
        "description": "Too many months with high proportion of bankcard account balances to credit limits"
    },
    {
        "code": "242",
        "description": "Recent proportion of bankcard account balances to credit limits too high"
    },
    {
        "code": "243",
        "description": "Insufficient revolving account payment activity"
    },
    {
        "code": "244",
        "description": "Too few unsecured installment accounts recently updated"
    },
    {
        "code": "245",
        "description": "Too many open unsecured installment accounts in high proportion to credit limits"
    },
    {
        "code": "247",
        "description": "Not enough available credit on bankcard accounts"
    },
    {
        "code": "248",
        "description": "Recent proportion of retail account balances to credit limits too high"
    },
    {
        "code": "251",
        "description": "Total balance of unsecured installment accounts is too high"
    },
    {
        "code": "917",
        "description": "Insufficient phone number information"
    },
    {
        "code": "918",
        "description": "Too many different phone numbers reported"
    },
    {
        "code": "919",
        "description": "Presence of alternative credit lender reported bankruptcy"
    },
    {
        "code": "920",
        "description": "Total alternative credit balance amount too high"
    },
    {
        "code": "921",
        "description": "Too many payroll types on alternative credit file"
    },
    {
        "code": "922",
        "description": "Lack of club or subscription history"
    },
    {
        "code": "923",
        "description": "Lack of positive public records regarding home ownership"
    },
    {
        "code": "924",
        "description": "Evidence of inquiries and/or activity on debit data source"
    },
    {
        "code": "925",
        "description": "Evidence of high interest rate loan application and/or charge-off"
    },
    {
        "code": "926",
        "description": "Credit account chargeoff"
    },
    {
        "code": "927",
        "description": "High interest rate loan application"
    },
    {
        "code": "928",
        "description": "Recency of club or subscription last payment"
    },
    {
        "code": "929",
        "description": "Unfavorable club and/or subscription payment history"
    },
    {
        "code": "930",
        "description": "Insufficient club or subscription history"
    },
    {
        "code": "931",
        "description": "Excessive club and/or subscription enrollments"
    },
    {
        "code": "932",
        "description": "Possible unfavorable club or subscription purchase history and/or potential fraud"
    },
    {
        "code": "933",
        "description": "Presence of foreclosure"
    },
    {
        "code": "934",
        "description": "Too few conventional mortgage accounts"
    },
    {
        "code": "935",
        "description": "Length of alternative credit history too short"
    },
    {
        "code": "936",
        "description": "Previous market sales price too low"
    },
    {
        "code": "937",
        "description": "Time since most recent alternative credit activity too short"
    },
    {
        "code": "938",
        "description": "Insufficient address information on alternative credit file "
    },
    {
        "code": "939",
        "description": "Insufficient bank account information on alternative credit file "
    },
    {
        "code": "940",
        "description": "Too many bank accounts on alternative credit file"
    },
    {
        "code": "941",
        "description": "Insufficient cell phone number information on alternative credit file "
    },
    {
        "code": "942",
        "description": "Too many cell phone numbers on alternative credit file "
    },
    {
        "code": "943",
        "description": "Assessed property value too low"
    },
    {
        "code": "944",
        "description": "Too many alternative credit loans in collections"
    },
    {
        "code": "945",
        "description": "High alternative credit delinquent balance"
    },
    {
        "code": "946",
        "description": "Recent mortgage interest rate too high"
    },
    {
        "code": "947",
        "description": "Too many fha mortgages"
    },
    {
        "code": "948",
        "description": "Not enough mortgage refinance activity"
    },
    {
        "code": "949",
        "description": "Property acreage too low"
    },
    {
        "code": "950",
        "description": "Too many debit data source inquiries"
    },
    {
        "code": "951",
        "description": "Too few check orders"
    },
    {
        "code": "952",
        "description": "Lack of check order activity"
    },
    {
        "code": "953",
        "description": "Recent alternative credit delinquency"
    },
    {
        "code": "954",
        "description": "Checking account closure(s) on record"
    },
    {
        "code": "955",
        "description": "Time since checking account closure(s) too short"
    },
    {
        "code": "956",
        "description": "Time since debit data source inquiry too short"
    },
    {
        "code": "957",
        "description": "Unpaid checking account closure amount too high"
    },
    {
        "code": "958",
        "description": "Too many uncorroborated addresses reported"
    },
    {
        "code": "959",
        "description": "Too many different addresses reported"
    },
    {
        "code": "960",
        "description": "Length at current reported residence is too short"
    },
    {
        "code": "961",
        "description": "Income amount on alternative credit file too low"
    },
    {
        "code": "962",
        "description": "Too many home phone numbers on alternative credit file"
    },
    {
        "code": "963",
        "description": "Time since unsuccessful alternative credit loan payment from bank account is too short"
    },
    {
        "code": "964",
        "description": "Too few alternative credit loan payments from bank accounts"
    },
    {
        "code": "965",
        "description": "Insufficient email account information on alternative credit file "
    },
    {
        "code": "966",
        "description": "Total dollar amount of alternative credit loan payments from bank account too low"
    },
    {
        "code": "967",
        "description": "Too many different addresses reported on alternative credit file "
    },
    {
        "code": "968",
        "description": "Number of satisfactory alternative credit loans too low"
    },
    {
        "code": "969",
        "description": "Number of inquiries on alternative credit file too high"
    },
    {
        "code": "970",
        "description": "Error (not consumer facing, internal code)"
    },
    {
        "code": "971",
        "description": "Too many recently opened alternative credit loans"
    },
    {
        "code": "972",
        "description": "Too many high interest rate loans on alternative credit file "
    },
    {
        "code": "973",
        "description": "Insufficient home phone number information on alternative credit file"
    },
    {
        "code": "974",
        "description": "Percent of alternative credit loans with balance too high"
    },
    {
        "code": "975",
        "description": "Evidence of short-term/small dollar loan application or loan activity"
    },
    {
        "code": "976",
        "description": "Insufficient loan payment information on alternative credit file"
    },
    {
        "code": "977",
        "description": "Time since alternative credit loan payment from bank account is too short"
    },
    {
        "code": "978",
        "description": "Time since alternative credit loan payment from bank account is too long"
    },
    {
        "code": "979",
        "description": "Number of satisfactory accounts is too low in proportion to total accounts on alternative crdit file"
    },
    {
        "code": "980",
        "description": "Number of driver’s licenses on alternative credit file"
    },
    {
        "code": "981",
        "description": "Too many driver’s licenses on alternative credit file"
    },
    {
        "code": "982",
        "description": "Insufficient history on alternative credit file"
    },
    {
        "code": "983",
        "description": "Too many payroll frequencies on alternative credit file"
    },
    {
        "code": "984",
        "description": "Total alternative credit highest credit or credit limit amount too low"
    },
    {
        "code": "985",
        "description": "Alternative credit last payment amount too low"
    },
    {
        "code": "986",
        "description": "Number of unsuccessful alternative credit loan payments from bank account too high"
    },
    {
        "code": "987",
        "description": "Unsuccessful alternative credit loan payment amount from bank account too high"
    },
    {
        "code": "988",
        "description": "Too many alternative credit delinquencies"
    },
    {
        "code": "989",
        "description": "Too many email accounts on alternative credit file "
    },
    {
        "code": "990",
        "description": "Not enough debt management or property ownership experience"
    },
    {
        "code": "991",
        "description": "Insufficient income information on alternative credit file "
    },
    {
        "code": "992",
        "description": "Time since most recent alternative credit inquiry is too short"
    },
    {
        "code": "993",
        "description": "Time since most recent alternative credit loan origination is too short"
    },
    {
        "code": "994",
        "description": "Time since oldest alternative credit inquiry is too short"
    },
    {
        "code": "995",
        "description": "Number of alternative credit lenders too high"
    },
    {
        "code": "996",
        "description": "Total alternative credit balance is too high in proportion to high credit"
    },
    {
        "code": "997",
        "description": "Insufficient debit data source data"
    },
    {
        "code": "998",
        "description": "Insufficient short-term/small dollar lending data"
    },
    {
        "code": "999",
        "description": "Reserved - DO NOT USE"
    }
]