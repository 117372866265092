import WebViewer from '@pdftron/pdfjs-express';
import { useEffect, useRef } from 'react';

export default ({ file } = {}) => {
    const viewer = useRef(null);

    const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
    ]
    useEffect(() => {
        if (file?.original) {
            const disabledElements = []

            WebViewer({
                path: '/webviewer/lib',
                initialDoc: file.original,
                fullApi: true,
                disabledElements

            }, viewer.current).then(async (instance) => {
                const { docViewer } = instance

                const theme = instance.UI.Theme;
                instance.UI.setTheme(theme.DARK);

                instance.UI.setHeaderItems((header) => {
                    const elements = [
                        {
                            type: 'divider',
                            index: 3
                        },
                        {
                            img: "icon-header-page-manipulation-page-rotation-clockwise-line",
                            index: 0,
                            type: "actionButton",
                            element: 'rotateClockwiseButton',
                            toolName: 'Rotate clockwise',
                            index: 4,
                            onClick: () => {
                                instance.UI.rotateClockwise()
                            }
                        },
                        {
                            img: "icon-header-page-manipulation-page-rotation-counterclockwise-line",
                            index: 0,
                            type: "actionButton",
                            element: 'rotateCounterClockwiseButton',
                            toolName: 'Rotate counterclockwise',
                            index: 5,
                            onClick: () => {
                                instance.UI.rotateCounterClockwise()
                            }
                        }
                    ]

                    for (const item of elements) {
                        header.headers.default = insert(header.headers.default, item.index, item)
                    }
                });

                docViewer.on('documentLoaded', () => {
                    instance.setZoomLevel(1)
                });
            })
        }

    }, [file])

    return (<>
        <div className="h-full" ref={viewer}></div>
    </>)
}