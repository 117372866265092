import axios from "axios";
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

const creditLimitIncrease = {
    getRequestLimitCredit: async () => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/cms/credit-limit/open`,
            headers: HEADERS()
        })
    },

    putRejectCreditLimitIncrease: async ({ application_id, reason }) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/cms/credit-limit/reject`,
            headers: HEADERS(),
            data: {
                application_id,
                reason
            }
        })
    },

    putApprovedCreditLimitIncrease: async ({ application_id, reason,credit_limit, interest_rate }) => {
        return await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}/cms/credit-limit/approve`,
            headers: HEADERS(),
            data: {
                application_id,
                reason,
                credit_limit,
                interest_rate
            }
        })
    }
}

export default creditLimitIncrease