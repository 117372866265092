
const complianceCondition = ({ value }) => {

    return (<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Compliance Condition</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Trade
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Account Number / Docket Number
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            type
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Remark / Public Record Type
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {value && value.map((trade) => (
                                        <tr key={trade.sourceName.unparsed._text}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {trade.sourceName.unparsed._text}
                                            </td>
                                            {trade.accountNumber && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trade.accountNumber._text}</td>}
                                            {trade.docketNumber && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trade.docketNumber._text}</td>}
                                            {!trade.accountNumber && !trade.docketNumber && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">- -</td>}
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trade.type._text}</td>
                                            {trade.remark && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trade.remark.code._text}</td>}
                                            {trade.publicRecordType && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trade.publicRecordType.code._text}</td>}
                                            {!trade.remark && !trade.publicRecordType && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">- -</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </dd>
    </div>)
}

export default complianceCondition;