import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function GeneralError({ tryAgain }) {
  return (
    <div className="w-full bg-red-100 text-red-500 p-5 rounded-lg text-sm flex items-center gap-5">
      <ExclamationTriangleIcon className="h-6 min-w-[1.5rem]" />
      <p>An error has occurred, you may need to try again.</p>
      <button
        className="bg-red-200 px-5 py-2 rounded font-semibold min-w-max"
        onClick={tryAgain}
      >
        Try again
      </button>
    </div>
  );
}
