export const UserMapper = {
    convertToUpdateUserTrengoInfo: (data, new_phone, new_email, new_name) => {
        if(!data){
            return null
        }

        let payload = [];

        let tmp_phone = data.find(contact => contact.type === "voip");

        if(tmp_phone){
            tmp_phone.phone = new_phone;
            tmp_phone.name = new_name;
            payload.push(tmp_phone);
        }

        const tmp_email = data.find(contact => contact.type === "email");

        if(tmp_email){
            tmp_email.email = new_email;
            tmp_email.name = new_name;
            payload.push(tmp_email);
        }

        return payload;
    }
}