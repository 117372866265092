import { useState } from 'react';
import Pagination from 'components/UI/Pagination';
import Item from 'components/Applications/Item';

const ApplicationsList = ({
  applications,
  total,
  fetchApplications,
  state,
}) => {
  const [page, setPage] = useState(1);

  const handleTake = ({ page, ...payload }) => {
    setPage(page);
    fetchApplications(payload);
  };
  return (
    applications && (
      <div className="h-dashboard bg-gray-100 overflow-auto px-4 sm:px-6 lg:px-8 pt-8">
        <div className="sm:flex sm:items-center mb-4">
          <div className="sm:flex-auto flex items-center justify-between">
            <div>
              <h1 className="text-xl font-semibold text-gray-900">
                {total} Applications
              </h1>

              <p className="mt-2 text-sm text-gray-700">
                A list of all loans with including the user, balance, payload
                and status.
              </p>
            </div>

            <div className="bg-blue-kiwi/20 px-8 py-2 rounded-md">
              <p className="capitalize font-semibold">
                {state === 'puerto_rico' ? '🇵🇷 Puerto Rico' : `🇺🇸 ${state}`}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-full rounded-lg overflow-hidden mb-6 border-b border-gray-300">
            {applications &&
              applications.map(loan => (
                <Item data={loan} key={loan.id} state={state} />
              ))}
          </div>
        </div>
        <div className="bg-white px-4 py-3 border border-gray-300 rounded-lg sm:px-6 mb-4">
          <Pagination count={total} onTake={handleTake} page={page} />
        </div>
      </div>
    )
  );
};

export default ApplicationsList;
