import React, { useState, useEffect } from "react";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import axios from "axios";

import Loader from "components/UI/Loader";
import SwitchStatus from "components/UI/SwitchStatus";

import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";

const dataForm = () => {
    return {
        name: '',
        code: '',
        logo: '',
        address: '',
        office: '',
        email: '',
        phone_number: '',
        active: true,
        advanced: false,
        advanced_percentage: 0,
        kiwi_percentage: 0,
        loan_limit: 0,
        commission_limit: 0,
        domain: '',
        color1: '#ef4444',
        color2: '#eab308',
        color3: '#22c55e',
        method: 'create'
    }
}

const ModalBNPLStore = ({ show = false, close, dataBNPLStore = {} }) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState(dataForm)
    const [loader, setLoader] = useState(false)

    const handleChangeForm = (e) => {
        const { name, value } = e.target;

        setForm(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    useEffect(() => {
        if (!dataBNPLStore.id) setForm(dataForm);
        else {
            dataBNPLStore.method = 'update'
            setForm(structuredClone(dataBNPLStore));
        }
    }, [dataBNPLStore])

    const validateForm = () => {
        if (!form.name) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Name is requerid'
            }))
        } else if (!form.address) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Street address is requerid'
            }))
        } else if (!form.email) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Email is requerid'
            }))
        } else if (!form.phone_number) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Phone number is requerid'
            }))
        } else if (!form.logo) {
            dispatch(actions.setMessage({
                type: 'warning', title: 'Warning', message: 'Logo is requerid'
            }))
        } else {
            saveBNPLStore(form);
        }
    }

    const saveBNPLStore = async (data) => {
        try {
            console.log(data)
            setLoader(true)

            await axios({
                method: data.method === 'update' ? `PUT` : 'POST',
                url: `${process.env.REACT_APP_API_URL}/bnpl_store`,
                data: {
                    ...data
                }
            })

            setLoader(false)
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Store saved'
            }))
            close(true)
        } catch (error) {
            console.log(error)
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error saving'
            }))
        }
    }

    return (
        <>
            {show ? <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-500 outline-none focus:outline-none bg-black bg-opacity-20">
                <div className="relative w-[36rem] my-6 mx-auto max-w-2xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*header*/}
                        <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                            <BuildingOfficeIcon className="mt-1.5 mr-1 h-5 w-5" aria-hidden="true" />

                            <h3 className="text-2xl font-semibold">
                                {form?.method === 'create' ? 'New store' : 'Update store'}
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => close()}
                            >
                                <span className="text-black-900 font-semibold h-6 w-6 block">
                                    ×
                                </span>
                            </button>
                        </div>

                        {/*body*/}
                        <div className="relative flex-auto">
                            <div className="mt-10 sm:mt-0">

                                <div className="mt-5 md:mt-0 md:col-span-2">

                                    <div className="px-4 py-4 bg-white ">
                                        <div className="grid grid-cols-12 gap-3">
                                            <div className="col-span-6">
                                                <SwitchStatus
                                                    checked={form.active}
                                                    onChange={(e) => handleChangeForm({ target: { value: e, name: 'active' } })}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <SwitchStatus
                                                    checked={form.advanced}
                                                    text_label="Advanced Payment"
                                                    onChange={(e) => handleChangeForm({ target: { value: e, name: 'advanced' } })}
                                                />
                                            </div>

                                            <div className="col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Advanced Payment Percentage
                                                </label>
                                                <input
                                                    type="number"
                                                    name="advanced_percentage"
                                                    disabled={!form.advanced}
                                                    value={form.advanced_percentage}
                                                    onChange={handleChangeForm}
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi disabled:bg-gray-200 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                            
                                            <div className="col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Percentage Kiwi Charges
                                                </label>
                                                <input
                                                    type="number"
                                                    name="kiwi_percentage"
                                                    value={form.kiwi_percentage}
                                                    onChange={handleChangeForm}
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi disabled:bg-gray-200 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                            
                                            <div className="col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Loan Amount Limit 
                                                </label>
                                                <input
                                                    type="number"
                                                    name="loan_limit"
                                                    value={form.loan_limit}
                                                    onChange={handleChangeForm}
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi disabled:bg-gray-200 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                            
                                            <div className="col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Comission Limit
                                                </label>
                                                <input
                                                    type="number"
                                                    name="commission_limit"
                                                    value={form.commission_limit}
                                                    onChange={handleChangeForm}
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi disabled:bg-gray-200 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-12 sm:col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={form.name}
                                                    onChange={handleChangeForm}
                                                    autoComplete="name"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-12 sm:col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Code
                                                </label>
                                                <input
                                                    type="text"
                                                    name="code"
                                                    value={form.code || ''}
                                                    onChange={handleChangeForm}
                                                    autoComplete="code"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-12 grid grid-cols-2 gap-4">
                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Street Address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        value={form.address}
                                                        onChange={handleChangeForm}
                                                        autoComplete="address"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>

                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Office
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="office"
                                                        value={form.office || ''}
                                                        onChange={handleChangeForm}
                                                        autoComplete="office"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-span-12 grid grid-cols-2 gap-4">
                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Phone number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="phone_number"
                                                        value={form.phone_number}
                                                        onChange={handleChangeForm}
                                                        autoComplete="phone_number"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>

                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Email address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={form.email}
                                                        onChange={handleChangeForm}
                                                        autoComplete="email"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-span-12 sm:col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Url logo
                                                </label>
                                                <input
                                                    type="text"
                                                    name="logo"
                                                    value={form.logo}
                                                    onChange={handleChangeForm}
                                                    autoComplete="logo"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                            <div className="col-span-12 sm:col-span-12">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Domain
                                                </label>
                                                <input
                                                    type="text"
                                                    name="domain"
                                                    value={form.domain}
                                                    onChange={handleChangeForm}
                                                    autoComplete="logo"
                                                    className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                            <div className="col-span-12 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        First Color
                                                    </label>
                                                    <input
                                                        type="color"
                                                        name="color1"
                                                        value={form.color1}
                                                        onChange={handleChangeForm}
                                                        autoComplete="logo"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Second Color
                                                    </label>
                                                    <input
                                                        type="color"
                                                        name="color2"
                                                        value={form.color2}
                                                        onChange={handleChangeForm}
                                                        autoComplete="logo"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Third Color
                                                    </label>
                                                    <input
                                                        type="color"
                                                        name="color3"
                                                        value={form.color3}
                                                        onChange={handleChangeForm}
                                                        autoComplete="logo"
                                                        className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-red-500 text-white active:bg-red-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-red-700 mr-1"
                                type="button"
                                onClick={() => close()}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-emerald-500 text-white active:bg-emerald-600 rounded uppercase text-sm px-4 py-2 border border-transparent shadow-sm text-sm font-medium hover:bg-emerald-700"
                                type="button"
                                onClick={validateForm}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> : ''
            }

            <Loader status={loader}></Loader>
        </>
    )
}

export default ModalBNPLStore;