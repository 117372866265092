import { useState } from "react";
import Api from "redux/api"
import { useSelector } from "react-redux";

const options = [
    "a1",
    "a2",
    "b1",
    "b2",
    "c1",
    "c2"
]

const SetScore = ({ application, onMove, onCreate }) => {

    const auth = useSelector(state => state.auth);
    const [score, setScore] = useState("");

    const changeHandler = (event) => {
        setScore(event.target.value)
    }

    const create = async () => {
        try {
            await Api.updateScoreKiwi({
                application_id: application.id,
                value: score
            })
            const response = await Api.createLoanStatus({
                application_id: application.id,
                value: "set_score",
                member_id: auth.id
            })
            onCreate(response.data.status);
            onMove("list");
        } catch (error) {
            alert("ERROR");
        }
    }

    return <div className="mt-auto">
        <div className="mb-4">
            <label htmlFor="score" className="block text-sm font-medium text-gray-700">
                Score
            </label>
            <select
                id="score"
                name="score"
                onChange={changeHandler}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
            >
                <option>Choose an option</option>
                {options.map((item, key) => <option key={key} value={item}>{item.toUpperCase()}</option>)}
            </select>
        </div>
        <div className="mt-auto px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
                onClick={create}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
            >
                Create Status
            </button>
        </div>
    </div>
}

export default SetScore;