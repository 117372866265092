import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const PaymentServices = {
    newChangeDueDateLog: async (loan_id, changed_date, new_date, amount, source, payment_type) => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/change-log-payment-date`,
            data: {
                loan_id,
                changed_date,
                new_date,
                amount,
                source,
                payment_type
            },
            headers: HEADERS()
        });
    }
}