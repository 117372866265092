export const applicationTermsValidation = ({ application, data }) => {
  const { term_months, credit_limit, interest_rate } = application;

  if (!term_months && !credit_limit && !interest_rate) {
    return false;
  } else if (
    term_months !== data.term_months ||
    credit_limit !== data.credit_limit ||
    interest_rate !== data.interest_rate
  ) {
    return true;
  }
};

export const applicationPayloadHandler = ({ application, form }) => {
  let payload;
  const { term_months, credit_limit, interest_rate } = application;

  if (term_months && credit_limit && interest_rate) {
    payload = {
      term_months: term_months,
      credit_limit: credit_limit,
      interest_rate: interest_rate,
    };
  }

  if (form?.term_months && form?.credit_limit && form?.interest_rate) {
    const { term_months, credit_limit, interest_rate } = form;
    payload = {
      ...payload,
      new_term_months: term_months,
      new_credit_limit: credit_limit,
      new_interest_rate: interest_rate,
    };
  }

  return payload;
};
