import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Link, useLocation } from "react-router-dom";
import { format } from 'date-fns';
import Loader from "components/UI/Loader";
import EmptyCreditReport from "components/EmptyCreditReport";
import Api from "redux/api"
import { FileIcon } from 'react-file-icon';
import Tooltip from "components/UI/Tooltip";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { formatBytes } from 'utils/formatBytes';
import Slide from "../../../../components/Files/Slide";

export default () => {
    const { search } = useLocation();
    const user = useSelector(state => state.user);

    const [creditReports, setCreditReports] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dataFiles, setDataFiles] = useState([]);
    const [slide, setSlide] = useState({
        status: false,
        files: [],
        index: 0
    })

    useEffect(() => {
        if (user?.id) getReports();
    }, [user])

    const getReports = async () => {
        try {
            const { data } = await Api.getReports({ user_id: user.id });
            setCreditReports(data.credit_reports);
            getTransunionReports()
        } catch (error) {
            console.log(error);
        }
    }

    const createReport = async () => {
        try {
            setLoader(true);
            const { data } = await Api.createReport({ user_id: user.id });
            getReports()
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    }

    const refreshDocuments = async () => {
        try {
            setLoader(true)
            await Api.refreshDocuments({ user_id: user.id })

            getTransunionReports()
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }

    const getTransunionReports = async () => {
        try {
            setLoader(true);
            const { data } = await Api.getTransunionReports({ user_id: user.id })

            setDataFiles(data.data || [])
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    }

    const order_data_slide = async (item) => {
        let files = []
        let idx = dataFiles.findIndex(e => e.id == item.id)
        setLoader(true)

        for await (const iterator of dataFiles) {
            const { data } = await Api.getUrlSigned({ id: iterator.id })
            let signed = data.data

            files.push({
                name: iterator.original_name,
                size: formatBytes(iterator.size),
                original: signed.url,
                thumbnail: signed.url,
            })
        }

        setSlide({ status: true, files, index: idx })
        setLoader(false)
    }

    return (
        <div className="bg-white overflow-hidden px-6 lg:px-8">
            <div className='pt-8'>
                <div className="flex justify-between items-center pt-3 pb-2">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Credit Reports</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">History reports about transunion data.</p>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
                            onClick={() => { createReport() }}
                        >
                            <ArrowPathIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Refresh
                        </button>
                    </div>
                </div>
                <div className="mt-2 flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle ">
                            <div className="border overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Name
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Score
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Release at
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only"></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {creditReports.length ? creditReports.map((report, reportIdx) => (
                                            <tr key={report.id} className={reportIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {report?.full_name ? report.full_name : `${report.first_name} ${report.last_name}`}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {report.score}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {
                                                        format(new Date(report.created_at), 'MMMM dd, yyyy')
                                                    }
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {
                                                        report.status === 'error' ? (<span className="inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-red-100 text-red-800">
                                                            <svg className="mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                                                                <circle cx={4} cy={4} r={3} />
                                                            </svg>
                                                            Error
                                                        </span>)
                                                            :
                                                            (<span className="inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-green-100 text-green-800">
                                                                <svg className="mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                                                                    <circle cx={4} cy={4} r={3} />
                                                                </svg>
                                                                Success
                                                            </span>)
                                                    }
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link to={`${report.id}${search}`} className="text-blue-kiwi hover:text-sky-400">
                                                        See Details
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : (<tr><td colSpan={5} className="py-4"><EmptyCreditReport onRequest={() => { createReport() }} /></td></tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center pt-3 pb-2 mt-8">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Credit Report Documents</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Document history of transunion.</p>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-kiwi"
                            onClick={() => refreshDocuments()}
                        >
                            <ArrowPathIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Refresh
                        </button>
                    </div>
                </div>

                {dataFiles.length ? <div className="mt-2 flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle ">
                            <div className="border overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ml-8">
                                                Original name
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Created At
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">

                                        {dataFiles?.map((item, index) => (
                                            <tr key={item.id}>
                                                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    <div className="flex items-center">
                                                        <div className="h-6 w-6 flex-shrink-0">
                                                            <FileIcon extension='PDF' type='acrobat' color='mistyrose' />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900">{item.original_name.length > 50 ? `${item.original_name.slice(0, 38)}...${item.original_name.slice(item.original_name.length - 8)}` : item.original_name}</div>
                                                            <div className="text-xs text-gray-500">
                                                                <span className="text-gray-900">Size: </span>
                                                                {formatBytes(item.size)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{format(new Date(item.created_at), 'MMMM dd, yyyy - p')}</td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Tooltip tooltip={"Open file"}>
                                                        <button onClick={() => { order_data_slide(item) }}>
                                                            <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue-kiwi hover:text-blue-kiwi" />
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null
                }
            </div>
            <Slide
                status={slide.status}
                files={slide.files}
                index={slide.index}
                close={() => {
                    setSlide({
                        status: false,
                        files: [],
                        index: 0
                    })
                }} />

            <Loader status={loader}></Loader>
        </div>
    )
}