import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
import saga from "./sagas";

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ thunk: false }).prepend(sagaMiddleware);
    },
})

sagaMiddleware.run(saga);

export default store;