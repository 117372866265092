import React, {  useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducer";
import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { ChangePaymentDateServices } from "services/ChangePaymentDates/ChangePaymentDate.services";
import CreateChangePaymentLogModal from "components/CreateChangePaymentLogModal";
import Loader from "components/UI/Loader";
import ModalConfirm from "components/UI/ModalConfirm";
import UpdateAutopayModal from "components/UpdateAutopayModal";
import UpdateChangePaymentLogModal from "components/UpdateChangePaymentLogModal";
import { replaceUnderScores, capitalizeString } from 'utils/string.helper';
import Pagination from "components/UI/Pagination";

const ROLES = {
    admin: 'admin',
    support: 'support'
};
const ERROR_RESPONSE = {
    ERROR_REQUEST_ID: 'ERROR_REQUEST_ID',
    CANT_DELETE_REQUEST: 'CANT_DELETE_REQUEST'
}

const ChangeDueDate = () => {
    const dispatch = useDispatch();
    const dataAuth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [changedDueDates, setChangedDueDates] = useState();
    const [createLogModalStatus, setCreateLogModalStatus] = useState(false);
    const [modalConfirmStatus, setModalConfirmStatus] = useState(false);
    const [modalUpdateAutopayStatus, setModalUpdateAutopayStatus] = useState(false);
    const [updateChangePaymentLogModalStatus, setUpdateChangePaymentLogModalStatus] = useState(false);
    const [deleteLogId, setDeleteLogId] = useState();
    const [updateLogId, setUpdateLogId] = useState();
    const [logToUpdate, setLogToUpdate] = useState();
    const [count, setCount] = useState(0);

    const hasPermission = (role) => {
        return Object.keys(ROLES).some(key => ROLES[key] === role);
    }

    const canEdit = (role, autopay_id, member_id) => {
        if(role === ROLES.admin){
            return true;
        }

        if(role === ROLES.support && member_id === dataAuth.id  && !autopay_id){
            return true
        }

        return false;
    }

    const canDelete = (role, autopay_id, member_id) => {
        if(role === ROLES.admin && !autopay_id){
            return true;
        }

        if(role === ROLES.support && member_id === dataAuth.id  && !autopay_id){
            return true
        }

        return false;
    }
    
    useEffect(() => {
        getChangeDueDatesHistory();
    }, [])

    const getChangeDueDatesHistory = async ({ take = 50, skip = 0 } = {}) => {
        try {
            setLoader(true);
            const response = await ChangePaymentDateServices.getAllCmsLogs({ take, skip });

            setChangedDueDates(response.data.data.logs);
            setCount(response.data.data?.count?._count || 0);
            setLoader(false);
        } catch (error) {
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error loading auths'
            }))
        }
    }

    const deleteLog = async() => {
        try {
            setLoader(true);
            await ChangePaymentDateServices.deleteLog(deleteLogId);
            
            setLoader(false);
            setModalConfirmStatus(false);
            dispatch(actions.setMessage({
                type: 'success', title: 'Success', message: 'Log deleted'
            }));
            getChangeDueDatesHistory()
        } catch (error) {
            const { data: { messages } } = error.response;
            errorResponseValidation(messages);
            setLoader(false);
        }
    }

    const errorResponseValidation = async (error) => {
        if(error === ERROR_RESPONSE.ERROR_REQUEST_ID || error === ERROR_RESPONSE.CANT_DELETE_REQUEST){
            closeConfirModal()
            await getChangeDueDatesHistory()
            dispatch(actions.setMessage({
                type: 'wrong', title: 'wrong', message: "can't delete log"
            }));
        }
    }

    const onCreateNewLog = () => {
        setCreateLogModalStatus(true)
    }

    const onCloseCreateLogModalStatus = () => {
        setCreateLogModalStatus(false);
    }

    const onCloseUpdateChangePaymentLogModalStatus = () => {
        setUpdateChangePaymentLogModalStatus(false);
    }

    const formatDate = (date) => {
        const tmp_date = new Date(date);
        const año = tmp_date.getFullYear();
        const mes = ('0' + (tmp_date.getMonth() + 1)).slice(-2);
        const dia = ('0' + tmp_date.getDate()).slice(-2);
        const horas = ('0' + tmp_date.getHours()).slice(-2);
        const minutos = ('0' + tmp_date.getMinutes()).slice(-2);
        const segundos = ('0' + tmp_date.getSeconds()).slice(-2);

        const nuevaFecha = año + '-' + mes + '-' + dia + ' ' + horas + ':' + minutos + ':' + segundos;

        return nuevaFecha;
    }

    const openConfirmModal = (id) => {
        setDeleteLogId(id);
        setModalConfirmStatus(true);
    }

    const closeConfirModal = () => {
        setDeleteLogId(null);
        setModalConfirmStatus(false);
    }

    const openEditAutopayModal = (id, role, log) => {
        setUpdateLogId(id);
        if(role === ROLES.admin){
            setModalUpdateAutopayStatus(true);
            return;
        }

        setUpdateChangePaymentLogModalStatus(true);
        setLogToUpdate(log);
    }

    const closeUpdateAutopayModal = () => {
        setUpdateLogId(null);
        setModalUpdateAutopayStatus(false);
    }

    return (
        <div className="max-w-7xl mx-auto">
            <Loader status={loader} />
            <div className="mt-8 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Change Due Date Logs</h1>
                </div>
                <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                    onClick={() => onCreateNewLog() }
                >
                    <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                    New Change Due Date
                </button>
            </div>
            <br />
            <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                                    <Pagination count={count} onTake={getChangeDueDatesHistory} />
                                </div>
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                User
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Loan ID
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Changed Date
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                New Date
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Payment Type
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Autopay ID
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Source
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Request Date
                                            </th>
                                            {dataAuth && hasPermission(dataAuth?.profile) &&(
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Actions
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {changedDueDates && changedDueDates.map((log) => (
                                            <tr key={log.id}>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <div className="text-gray-500 tracking-widest">{log.user.first_name} {log.user.first_surname}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{log.loan.loanpro_id}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{log.changed_date.substring(0, 10)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{log.new_date.substring(0, 10)}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{capitalizeString(replaceUnderScores(log.payment_type))}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="text-gray-500 tracking-widest">{log.autopay_id}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="text-gray-500 tracking-widest">{log.source}</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                    <div className="text-gray-500 tracking-widest">{formatDate(log.created_at)}</div>
                                                </td>
                                                {dataAuth && hasPermission(dataAuth?.profile) && (
                                                    <td className="flex whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500 ">
                                                        { canEdit(dataAuth?.profile, log?.autopay_id, log.member_id) &&(
                                                            <PencilIcon 
                                                                className="h-5 w-5 mr-2 cursor-pointer" aria-hidden="true"
                                                                onClick={() => { openEditAutopayModal(log.id, dataAuth?.profile, log) }}
                                                            />
                                                        )}
                                                        { canDelete(dataAuth?.profile, log?.autopay_id, log.member_id) && (
                                                            <TrashIcon 
                                                                className="h-5 w-5 mr-2 cursor-pointer" aria-hidden="true"
                                                                onClick={() => { openConfirmModal(log.id) }}
                                                            />
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateChangePaymentLogModal
                    showModal={createLogModalStatus}
                    closeModal={onCloseCreateLogModalStatus}
                    updateChangePaymentsTable={getChangeDueDatesHistory}
                />
                <ModalConfirm
                    open={modalConfirmStatus}                    
                    message={"Are you sure that you want to delete this request?"}
                    onAccept={() => { deleteLog() }}
                    onCancel={() => { closeConfirModal() }}
                />
                <UpdateAutopayModal
                    showModal={modalUpdateAutopayStatus}
                    closeModal={closeUpdateAutopayModal}
                    requestId={updateLogId}
                    updateTable={getChangeDueDatesHistory}
                />
                <UpdateChangePaymentLogModal
                    showModal={updateChangePaymentLogModalStatus}
                    closeModal={onCloseUpdateChangePaymentLogModalStatus}
                    updateChangePaymentsTable={getChangeDueDatesHistory}
                    payload={logToUpdate}
                />

        </div>
    )
}

export default ChangeDueDate;