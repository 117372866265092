import { PencilSquareIcon } from "@heroicons/react/24/outline"
import Pagination from "components/UI/Pagination";
import { classNames } from 'utils/class_names'
import { formatDate } from 'utils/format_date'

const profiles = [
    {
        value: 'admin',
        name: 'Administrator',
        description: 'Acceso total a la plataforma (Estadisticas, créditos, usuarios)',
        checked: false
    },
    {
        value: 'atm',
        name: 'ATM',
        description: 'Permite desembolsar créditos a clientes',
        checked: false
    },
    {
        value: 'inspector',
        name: 'Inspector',
        description: 'Ver listado de los créditos que estan activos',
        checked: true
    },
]

const TableStoreUser = ({ dataUsers = [], count, onTake, onEdit }) => {
    const getProfile = (id) => {
        return profiles.find(e => e.value === id) || {}
    }
    return (
        <>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                            <Pagination count={count} onTake={onTake} />
                        </div>

                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Phone number
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Email
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Profile
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Active
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Created at
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">
                                {dataUsers && dataUsers.map((item, index) => (
                                    <tr key={item.id} className={index % 2 === 0 ? undefined : 'bg-gray-100'}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {item.first_name} {item.last_name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.phone_number}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.email}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                                            <span className={"text-gray-600 inline-flex rounded-full px-1 text-xs uppercase font-semibold"} >
                                                {getProfile(item.profile)?.name}
                                            </span>

                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span className={classNames(
                                                item.active ? 'bg-green-100 text-green-800`' : 'bg-red-100 text-red-800',
                                                'inline-flex rounded-full px-1 text-xs uppercase font-semibold leading-5'
                                            )}
                                            >
                                                {item.active ? 'Active' : 'Disabled'}
                                            </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                                            formatDate(item.created_at)}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                                            <PencilSquareIcon onClick={() => onEdit(item)} className="ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-sky-600" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableStoreUser;