export const states_offers = [
    {
        state: 'Puerto Rico',
        canStateCustomOffer: true,
        loan_offers: [
            {
                option: 1,
                loan_max: 300,
                term_months: 6,
                interest_rate: 129,
            },
            {
                option: 2,
                loan_max: 400,
                term_months: 6,
                interest_rate: 125,
            },
            {
                option: 3,
                loan_max: 500,
                term_months: 8,
                interest_rate: 119,
            },
            {
                option: 4,
                loan_max: 600,
                term_months: 9,
                interest_rate: 115,
            },
            {
                option: 6,
                loan_max: 800,
                term_months: 10,
                interest_rate: 99,
            },
            {
                option: 7,
                loan_max: 1000,
                term_months: 12,
                interest_rate: 89,
            },
        ],
        range_interest_rate: {
            min: 20,
            max: 150,
        },
    },
    {
        state: 'Florida',
        canStateCustomOffer: false,
        loan_offers: [
            {
                option: 2,
                loan_max: 400,
                term_months: 3,
                interest_rate: 99,
            },
            {
                option: 4,
                loan_max: 600,
                term_months: 3,
                interest_rate: 99,
            },
            {
                option: 6,
                loan_max: 800,
                term_months: 3,
                interest_rate: 99,
            },
            {
                option: 7,
                loan_max: 1000,
                term_months: 3,
                interest_rate: 99,
            },
        ],
        range_interest_rate: {
            min: 8,
            max: 99,
        },
    },
];

export const general_loan_offers = [
    {
        option: 2,
        loan_max: 400,
        term_months: 6,
        interest_rate: 150,
    },
    {
        option: 4,
        loan_max: 600,
        term_months: 10,
        interest_rate: 135,
    },
    {
        option: 6,
        loan_max: 800,
        term_months: 11,
        interest_rate: 110,
    },
    {
        option: 7,
        loan_max: 1000,
        term_months: 12,
        interest_rate: 99,
    },
];

export const general_interest_rate = {
    min: 0,
    max: 200,
};
