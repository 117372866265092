import React, { useEffect, useState, useRef } from "react";
import Loader from "components/UI/Loader";
import Api from "redux/api"
import { ClipboardIcon } from "@heroicons/react/24/outline";
import Tooltip from "components/UI/Tooltip";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducer";
import { format } from 'date-fns';


const UserLeads = () => {
    const dispatch = useDispatch();

    const [saved, setSaved] = useState(false)
    const [toasCopy, setToastCopy] = useState(false)

    const [loader, setLoader] = useState(false)
    const [user_leads, setUser_leads] = useState()

    const [solved, setSolved] = useState(false);
    const [assigned, setAssigned] = useState("");
    const [countSolved, setCountSolved] = useState({
        solved: 0,
        unsolved: 0,
        total: 0
    });


    const updateUser = async (id) => {
        setLoader(true)
        await Api.updateUserOldApp({ id, assigned, solved });
        setSaved(true)
        setTimeout(() => {
            setSaved(false)
            setLoader(false)
            getUserLeads()
        }, 1000);

        setAssigned("")
        setSolved(false)

    }

    useEffect(() => {
        getUserLeads()
    }, [])

    const copyText = (event) => {
        const { id } = event.target

        if (id) {
            navigator.clipboard.writeText(id);
            setToastCopy(true)

            setTimeout(() => {
                setToastCopy(false)
            }, 1500);
        }
    }

    const rowRef = useRef(null);

    const getUserLeads = async () => {
        try {
            setLoader(true)
            setUser_leads([])

            const response = await Api.fetchUserOldApp();

            setLoader(false)

            let unsolved = response.data.data.remnants.filter(remnant => remnant.solved === false)
            let solved = response.data.data.remnants.filter(remnant => remnant.solved === true)
            setCountSolved({
                unsolved: unsolved.length,
                solved: solved.length,
                total: response.data.data.remnants.length
            }
            )

            setUser_leads(unsolved);

        } catch (error) {
            setLoader(false)
            dispatch(actions.setMessage({
                type: 'wrong', title: 'Wrong', message: 'Error get users'
            }))
        }
    }

    return (
        <div className="max-w-7xl mx-auto">
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                {
                    saved ? <div id={Math.random()} aria-live="assertive"
                        className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                    >
                        <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-center">
                            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                                <div className="relative flex flex-col items-center group">

                                    <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-green-500 shadow-lg rounded-md">Saved</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> : ''


                }
                <div className="border font-semibold border-gray-200 rounded-lg h-10 flex gap-5 justify-center items-center">
                    <p className="text-green-400">
                        Solved: {
                            countSolved.solved
                        }
                    </p>
                    <p className="text-red-400">
                        Unsolved: {
                            countSolved.unsolved
                        }
                    </p>
                    <p className="">
                        Total: {
                            countSolved.total
                        }
                    </p>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Firebase ID
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Solved
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Assigned to
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Device
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Last activity date
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {user_leads && user_leads.map((user_lead) => (
                                            <tr id={user_lead.firebase_id} ref={rowRef} key={user_lead.firebase_id}>

                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="flex flex-row text-gray-500 tracking-widest text-ellipsis">
                                                        <p className="truncate text-ellipsis">
                                                            {user_lead.firebase_id}
                                                        </p>
                                                        {
                                                            true ? <Tooltip tooltip="Copy firebase_id">
                                                                <ClipboardIcon id={user_lead.firebase_id} onClick={copyText} className="text-blue-kiwi ml-1 mt-1 h-4 w-4 cursor-pointer hover:text-sky-400" />
                                                            </Tooltip> : ''
                                                        }

                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">

                                                    <div className="text-gray-500 tracking-widest">
                                                        {user_lead.solved ? <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                                            True
                                                        </span> : <div className="flex justify-center">

                                                            <input value={true} onChange={(e) => setSolved(JSON.parse(e.currentTarget.value))} type="checkbox" name="true" id="" />
                                                            {/* <select onChange={(e) => setSolved(JSON.parse(e.currentTarget.value))} name="" id="">
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select> */}
                                                        </div>}

                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">

                                                    <div className="text-gray-500 tracking-widest">
                                                        {
                                                            user_lead.assigned_to ?
                                                                user_lead.assigned_to :

                                                                <div>
                                                                    <select onChange={(e) => setAssigned(e.target.value)} name="" id="">
                                                                        <option>Choose an option</option>
                                                                        <option value="Luz Darys R">Luz Darys R</option>
                                                                        <option value="Hernando J">Hernando J</option>
                                                                        <option value="Camila R">Camila R</option>
                                                                        <option value="Josue C">Josue C</option>
                                                                        <option value="Manuel S">Manuel S</option>
                                                                        <option value="Obed M">Obed M</option>
                                                                        <option value="Sol A">Sol A</option>
                                                                        <option value="Fatima H">Fatima H</option>
                                                                        <option value="Adrian H">Adrian H</option>
                                                                        <option value="Frekner M">Frekner M</option>
                                                                    </select>
                                                                </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">{
                                                        user_lead.device
                                                    }</div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <div className="text-gray-500 tracking-widest">
                                                        {format(new Date(user_lead.last_activity_date), 'MMMM dd, yyyy - p')}

                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-2.5 pr-3 text-sm text-gray-500">
                                                    <button onClick={() => updateUser(user_lead.id)} type="button" className="rounded-md bg-green-100 px-6 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-200">Save</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {toasCopy ? <div id={Math.random()} aria-live="assertive"
                className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
                <div id={Math.random()} className="w-full flex flex-col items-center space-y-4 sm:items-center">
                    <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                        <div className="relative flex flex-col items-center group">

                            <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                                <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">Firebase id copied</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div> : ''}

            <Loader status={loader}></Loader>
        </div>
    )
}

export default UserLeads;