import { useState } from "react";
import Api from "redux/api"
import { useSelector } from "react-redux";

const type_rejections = [
    {
        label: "No income (less than $750.00)",
        value: "no_income",
        reasons: [
            {
                title: "Unable to verify income",
                value: "unable_to_verify_income"
            },
            {
                title: "Temporary or irregular employment",
                value: "temporary_or_irregular_employment"
            },
            {
                title: "Unable to verify employment",
                value: "unable_to_verify_employment"
            },
            {
                title: "Length of employment",
                value: "length_of_employment"
            }
        ]
    },
    {
        label: "Negative minimum balances",
        value: "negative_minimum_balances",
        reasons: [
            {
                title: "Plaid",
                value: "plaid"
            },
            {
                title: "Bank Statements",
                value: "bank_statements"
            }
        ]
    },
    {
        label: "Monthly Obligations",
        value: "monthly_obligations",
        reasons: [
            {
                title: "Excessive obligations relative to income",
                value: "excessive_obligations_relative_to_income"
            },
            {
                title: "Income insufficient for amount of credit requested",
                value: "income_insufficient_for_amount_of_credit_requested"
            }
        ]
    },
    {
        label: "NSF fees",
        value: "nsf_fees",
        reasons: [
            {
                title: "Plaid",
                value: "plaid"
            },
            {
                title: "Bank statements",
                value: "bank_statements"
            }
        ]
    },
    {
        label: "Bad credit history",
        value: "bad_credit_history",
        reasons: [
            {
                title: "Delinquent past or present credit obligations",
                value: "delinquent_past_or_present_credit_obligations"
            },
            {
                title: "Limited credit experience",
                value: "limited_credit_experience"
            },
            {
                title: "Bankruptcy",
                value: "bankruptcy"
            },
            {
                title: "No credit file",
                value: "no_credit_file"
            }
        ]
    },
    {
        label: "Overdraft fees",
        value: "overdraft_fees",
        reasons: [
            {
                title: "Plaid",
                value: "plaid"
            },
            {
                title: "Bank Statements",
                value: "bank_statements"
            }
        ]
    },
    {
        label: "Suspicious / Manipulated Bank Statements",
        value: "suspicious/manipulated_bank_statements"
    }
]

const EMPTY_STRING = ""
const EMPTY_ARRAY = []

const Rejected = ({ application, onMove, onCreate }) => {

    const auth = useSelector(state => state.auth);

    const [reason, setReason] = useState(EMPTY_STRING);

    const [typesReasons, setTypesReasons] = useState(EMPTY_ARRAY)

    const [reasonSelected, setReasonSelected] = useState(EMPTY_STRING)

    const [statusCreate, setStatusCreate] = useState(false)

    const handleReason = (event) => {
        setReason(event.target.value);
        setReasonSelected(EMPTY_STRING)
        const resultReason = type_rejections.find(type => type.value === event.target.value);
        setTypesReasons(resultReason?.reasons ? resultReason?.reasons : null);
        validateCreate(event.target.value)
    }

    const handleSubReason = (event) => {
        setReasonSelected(event.target.value)
        setStatusCreate(true)
    }

    const validateCreate = (reasonValue) =>{
        const reasonValidate = 'suspicious/manipulated_bank_statements'
        if(reasonValue === reasonValidate){
            setStatusCreate(true)
            return
        }
        setStatusCreate(false)
        return
    }
    
    const create = async () => {
        try {
            const response = await Api.createLoanStatus({
                application_id: application.id,
                value: "rejected",
                reason,
                sub_reason: reasonSelected,
                member_id: auth.id
            })
            onCreate(response.data.status);
            onMove("list");
        } catch (error) {
            console.log(error);
        }
    }

    return <div className="mt-auto">
        <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Reason
            </label>
            <select
                onChange={handleReason}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
            >
                <option disabled selected>Choose an option</option>
                {type_rejections.map((item, key) => <option key={key} value={item.value}>{item.label}</option>)}
            </select>
        </div>

        {typesReasons?.length > 0  && <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Sub reason
            </label>
            <select
                value={reasonSelected}
                onChange={handleSubReason}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
            >
                <option value={EMPTY_STRING} disabled selected >Choose an option reason</option>
                {typesReasons.map((item, key) => <option key={key} value={item.value}>{item.title}</option>)}
            </select>
        </div>}

        <div className="mt-auto px-4 py-3 bg-gray-50 text-right sm:px-6">
            {statusCreate &&
                <button
                    onClick={create}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                >
                    Create Status
                </button> 
            }
        </div>
    </div>
}

export default Rejected;