export const isMilitary = (data) => {
  if (data && data.credit_scorings && data.credit_scorings.length > 0) {
    const sortedData = [...data.credit_scorings].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA;
    });
    if (sortedData[0].military) {
      return true;
    }
  }
  return false;
};