import { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import Api from 'redux/api';
import {
  CheckCircleIcon,
  ClockIcon,
  PlayCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

const DISBURSEMENT_METHOD = {
  IMMEDIATE: 'immediate',
  TRANSFERS: 'transfers',
  PAPER_CHECK: 'paper_check',
};

const REPAYMENT_METHOD = {
  TRANSFERS: 'transfers',
  PAPER_CHECK: 'paper_check',
};

function ItemActions(props) {
  const [data, setData] = useState(null);
  const [subportfolio, setSubportfolio] = useState(props.subportfolio);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingAssignPortfolio, setLoadingAssignPortfolio] = useState(false);
  const [loadingCreateAutopay, setLoadingCreateAutopay] = useState(false);
  const [loadingFund, setLoadingFund] = useState(false);

  useEffect(() => {
    setData(props.data);
    setSubportfolio(props.subportfolio);
  }, [props]);

  const processLoan = async () => {
    await active();
    await assignPortfolio();
    if (data?.repayment_method !== REPAYMENT_METHOD.PAPER_CHECK) {
      await createAutoPay();
    }
    if (data?.disbursement_method !== DISBURSEMENT_METHOD.PAPER_CHECK) {
      await fund();
    }
  };

  const active = async () => {
    try {
      setLoadingActive(true);
      if (data.actived != null) {
        return 'It already activated';
      }

      await Api.activeLoan({
        loan_id: data.id,
      });
      setData(previousData => ({ ...previousData, actived: true }));
    } catch (error) {
      setData(previousData => ({ ...previousData, actived: false }));
    }
    setLoadingActive(false);
  };

  const definePortfolio = () => {
    if (data.user && data.user.state === 'Florida') {
      return 24;
    }

    return data.type === 'mesalve' ? 3 : 1;
  };

  const assignPortfolio = async () => {
    try {
      setLoadingAssignPortfolio(true);
      if (data.portfolio != null) {
        return 'It already assigned portfolio';
      }

      await Api.assignPortfolio({
        loan_id: data.id,
        portfolio: definePortfolio(),
        subportfolio,
      });
      setData(previousData => ({ ...previousData, portfolio: true }));
    } catch (error) {
      setData(previousData => ({ ...previousData, portfolio: false }));
    }
    setLoadingAssignPortfolio(false);
  };

  const createAutoPay = async () => {
    try {
      setLoadingCreateAutopay(true);
      if (data.autopay != null) {
        return 'It already created autopay';
      }

      await Api.createAutoPay({
        loan_id: data.id,
      });
      setData(previousData => ({ ...previousData, autopay: true }));
    } catch (error) {
      setData(previousData => ({ ...previousData, autopay: false }));
    }
    setLoadingCreateAutopay(false);
  };

  const fund = async () => {
    try {
      setLoadingFund(true);
      if (data.funded != null) {
        return 'It already funded';
      }

      if (data.type === 'mesalve') {
        await Api.fundMeSalve({
          loan_id: data.id,
        });
      } else {
        await Api.fund({
          loan_id: data.id,
        });
      }

      setData(previousData => ({ ...previousData, funded: true }));
    } catch (error) {
      setData(previousData => ({ ...previousData, funded: false }));
    }
    setLoadingFund(false);
  };

  const Button = ({ label, status, loading }) => {
    if (status === true) {
      return (
        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm cursor-auto"
        >
          <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          {label}
        </button>
      );
    }

    if (status === false) {
      return (
        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm cursor-auto"
        >
          <XCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          {label}
        </button>
      );
    }

    return (
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 py-2 px-3 text-sm font-semibold text-gray-600 shadow-sm cursor-auto"
      >
        {loading ? (
          <img
            src={require('../../assets/loader.gif')}
            className="w-5"
            alt="loading"
          />
        ) : (
          <ClockIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        )}

        {label}
      </button>
    );
  };

  return (
    data && (
      <div className="flex flex-wrap items-center justify-end relative whitespace-nowrap gap-2 py-4 pl-3 pr-4 sm:pr-6 ml-auto">
        {data.type === 'mesalve' && (
          <Button label="Cashed" status={data.cashed} />
        )}

        <Button label="Actived" status={data.actived} loading={loadingActive} />

        <Button
          label="Assigned Portfolio"
          status={data.portfolio}
          loading={loadingAssignPortfolio}
        />

        <Button
          label="Auto Pay"
          status={data.autopay}
          loading={loadingCreateAutopay}
        />

        <Button label="Funded" status={data.funded} loading={loadingFund} />

        <Popover className="relative">
          <Popover.Button
            disabled={
              loadingAssignPortfolio ||
              loadingCreateAutopay ||
              loadingActive ||
              loadingFund
            }
            className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-kiwi py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-dark-kiwi"
          >
            {loadingAssignPortfolio ||
            loadingCreateAutopay ||
            loadingActive ||
            loadingFund ? (
              <img
                src={require('../../assets/loader.gif')}
                className="w-5"
                alt="loading"
              />
            ) : (
              <PlayCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            )}
          </Popover.Button>

          <Popover.Panel className="absolute top-full right-0 mt-2 z-10 bg-white">
            {({ close }) => (
              <div className="py-5 px-3 rounded-md bg-white border drop-shadow-2xl">
                <h4 className="mb-2 text-sm text-gray-700">
                  ¿Are you sure you want to continue?
                </h4>
                <button
                  className="w-full rounded-md text-sm py-2 font-medium bg-blue-dark-kiwi text-white"
                  onClick={() => {
                    processLoan();
                    close();
                  }}
                >
                  Accept
                </button>
              </div>
            )}
          </Popover.Panel>
        </Popover>
      </div>
    )
  );
}

export default ItemActions;
