import axios from 'axios';
import Cookies from "js-cookie";

const HEADERS = () => {
    return { authorization: `Bearer ${Cookies.get("kiwi_auth_token")}` };
};

export const DPDCampaignsServices = {
    DPD91TO120Campaign: async () => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/campaign/dpd-91-120`,
            headers: HEADERS()
        })
    },
    DPD121TO160Campaign: async () => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/campaign/dpd-121-160`,
            headers: HEADERS()
        })
    },
    DPD161TO199Campaign: async () => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/campaign/dpd-161-199`,
            headers: HEADERS()
        })
    },
    DPDPlus200Campaign: async () => {
        return await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/campaign/dpd-plus-200`,
            headers: HEADERS()
        })
    },
    getHistory: async (take, skip, order) => {
        return await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/dpd-requests/history`,
            headers: HEADERS(),
            params: {
                take,
                skip,
                order
            }
        });
    }
}